<template>
  <div class="container">
    <CreateBookingFlow
      :bookingId="booking.id"
      :get-setup-intent="getSetupIntent"
      :initial-state="initialState"
      :submit-booking="submitBooking"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";

import CreateBookingFlow from "@/flows/createBooking/Base.vue";

import {type BookingSubmitParams, useBookings} from "@/rental/stores/bookings";
import usePayments from "@/store/payments";
import type { Booking } from "@/rental/stores/bookings";

const props = withDefaults(defineProps<{
  bookingId: Booking['id'];
  initialState: string;
}>(), {
  initialState: "created",
});

const bookings = useBookings();
const payments = usePayments();
const route = useRoute();

await bookings.sync();

const booking = await bookings.ensureById(props.bookingId);

if (!booking) {
  throw "404";
}

const getSetupIntent = () =>
  payments.getIntent(
    typeof route.query.si === "string" ? route.query.si : undefined,
  );

const submitBooking = (data: BookingSubmitParams) =>
  bookings.submitBooking(booking.id, data);
</script>
