<template>
  <div class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap">
    <FormKit type="group">
      <FormKit
        inner-class="has-icons-left"
        name="lower"
        placeholder="0"
        type="number"
      >
        <template #prefixIcon>
          <Icon
            class="formkit-prefix-icon formkit-icon"
            name="outlined/attach_money"
            variant="inherit"
          />
        </template>
      </FormKit>
      <FormKit
        :delay="1500"
        inner-class="has-icons-left"
        name="upper"
        placeholder="no limit"
        type="number"
      >
        <template #prefixIcon>
          <Icon
            class="formkit-prefix-icon formkit-icon"
            name="outlined/attach_money"
            variant="inherit"
          />
        </template>
      </FormKit>
    </FormKit>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
