import type { InjectionKey } from "vue";

import type { IApi } from "@/plugins/Api";
import type { IAppContext } from "@/plugins/AppContext";
import type { IAppService } from "@/plugins/AppService";
import type { helpers } from "@/plugins/Form";
import type useUser from "@/store/user";
import type { IStore } from "@/plugins/Pinia";
import type { EventBus } from "ts-bus";
import type {IAuth} from "@/app/auth";

export const CurrentUserInjectionKey: InjectionKey<ReturnType<typeof useUser>> =
  Symbol("currentUser");
export const HelpersInjectionKey: InjectionKey<typeof helpers> =
  Symbol("helpers");
export const ApiInjectionKey: InjectionKey<IApi> = Symbol("api");
export const AuthInjectionKey: InjectionKey<IAuth> = Symbol("auth");
export const AppServiceInjectionKey: InjectionKey<IAppService> =
  Symbol("appService");
export const AppContextInjectionKey: InjectionKey<IAppContext> =
  Symbol("appContext");
export const StoresInjectionKey: InjectionKey<IStore> = Symbol("stores");
export const EventBusInjectionKey: InjectionKey<EventBus> = Symbol("eventBus");
