<template>
  <Sidebar
    :is-open="isOpen"
    position="right"
    @close="closeSidebarNav"
  >
    <template #header>
      <div
        class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between"
      >
        <Avatar :user="actor" />

        <div class="has-text-grey">
          Logged in as {{ actor.fullName || actor.email }}
        </div>

        <PsButton
          is-pill
          is-rounded
          color="secondary"
          styling="ghost"
          icon="close"
          @click="closeSidebarNav"
        />
      </div>
    </template>

    <PsButton
      class="mb-4"
      icon="outlined/post_add"
      full-width
      @click="router.push('/listings/create')"
    >
      New listing
    </PsButton>

    <MenuUser
      has-dividers
      show-messages
      @close-sidebar-nav="closeSidebarNav"
    />
  </Sidebar>
</template>

<script setup>
import { useRouter } from "vue-router";

import MenuUser from "@/components/MenuUser.vue";
import Sidebar from "@/components/Sidebar.vue";
import Avatar from "@/elements/Avatar.vue";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";

const router = useRouter();
const session = useSession();
const {actor} = storeToRefs(session);

const emit = defineEmits(["close"]);

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

function closeSidebarNav() {
  emit("close");
}
</script>

<style lang="scss">
@import '../styles/mixins.scss';
</style>
