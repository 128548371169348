<template>
  <EmailSignIn
    :loading="loading"
    @sign-in="handleSignIn($event)"
  />
  <div class="field">
    <span
      v-if="error"
      class="help is-error"
    >{{ error }}</span>
  </div>

  <div class="field has-text-centered">
    <a
      class="is-hidden-tablet link mx-auto has-text-primary"
      @click="router.push({ name: 'forgotPassword' })"
    >Forgot your password</a>
    <a
      class="is-hidden-mobile link mx-auto has-text-primary"
      @click="showForgotPassword"
    >Forgot your password</a>
  </div>

  <span class="hr">OR</span>
  <div class="field">
    <PsButton
      class="is-fullwidth"
      color="secondary"
      icon="apple"
      styling="outlined"
      @click="signInApple"
    >
      Continue with Apple
    </PsButton>
  </div>
  <div class="field">
    <PsButton
      class="is-fullwidth"
      color="secondary"
      icon="google"
      styling="outlined"
      @click="signInGoogle"
    >
      Continue with Google
    </PsButton>
  </div>

  <hr>

  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <p>Don't have an account yet?</p>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <a
          class="is-hidden-tablet link has-text-primary"
          @click="router.push({ name: 'signUp' })"
        >Sign up</a>
        <a
          class="is-hidden-mobile link has-text-primary"
          @click="showSignUp"
        >Sign up</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {inject, ref, watch} from "vue";
import { useRouter } from "vue-router";

import EmailSignIn from "@/forms/EmailSignIn.vue";

import {useSession} from "@/app/stores/session";
import {InvalidActionError} from "@/errors";
import {storeToRefs} from "pinia";

defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const session = useSession();
const { setModal, closeModal } = inject('authOverlay');
const { isAuthenticated } = storeToRefs(session);

const error = ref(null);
const loading = ref(false);
watch(isAuthenticated, (value) => {
  if (value) {
    router.push({
      name: "home",
    });
  }
}, { immediate: true});

function showForgotPassword() {
  setModal("forgotPassword");
}

function showSignUp() {
  setModal("signUp");
}

async function handleSignIn({ formData }) {
  loading.value = true;
  error.value = null;

  try {
    await session.signIn({
      username: formData.email,
      password: formData.password,
    });
    closeModal();
  } catch (err) {
    if (err instanceof InvalidActionError) {
      throw (err);
    }
    error.value = err.message;
  } finally {
    loading.value = false;
  }
}

async function signInGoogle() {
  await session.signInGoogle();
}

async function signInApple() {
  await session.signInApple();
}

async function signInFacebook() {
  await session.signInFacebook();
}
</script>

<style lang="scss" scoped></style>
