<template>
  <div class="filtering">
    <Chip
      v-for="item in iterItems"
      :key="item.label"
      styling="outlined"
      :color="item.id === activeItem ? 'primary' : 'secondary'"
      class="is-clickable"
      @click="handleClick(item)"
    >
      <span>{{ item.label }}</span>
      <span
        v-if="!hideCounts"
        class="filtering-amount"
      >{{
        item.count >= 20 ? '20+' : item.count
      }}</span>
    </Chip>
  </div>
</template>

<script setup>
import { computed } from "vue";

import Chip from "@/elements/Chip.vue";

const emit = defineEmits(["filtered"]);

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  activeItem: {
    type: Number,
    default: 0,
  },
  hideCounts: {
    type: Boolean,
    default: false,
  },
});

const iterItems = computed(() => {
  return props.items.map((el) => {
    const items = typeof el.items === "function" ? el.items() : el.items;
    return {
      ...el,
      items,
      count: el.amount ?? items?.length ?? 0,
    };
  });
});

function handleClick(item) {
  emit("filtered", item.id);
}
</script>

<style scoped lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.filtering {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin: 1em 0;

  .filtering-amount {
    margin-left: 8px;
    font-weight: normal;
  }
}
</style>
