<template>
  <FormKit id="emailSignupEmail" v-model="formData" submit-label="Continue with email" type="form"
    @submit="handleEmail">
    <div class="field">
      <FormKit name="email" placeholder="Email address" type="email" validation="required"
        validation-label="Email address" />
    </div>
  </FormKit>
</template>

<script setup>
import { FormKit } from "@formkit/vue";
import { ref } from 'vue';

const emit = defineEmits(["next"]);

const formData = ref({});

function handleEmail() {
  emit("next", {
    flow: "email",
    email: formData.value.email ?? "",
  });
}
</script>
