<template>
  <Modal
    title="Welcome to prettyshell"
    :model-value="isActive"
    size="small"
    @update:model-value="checkModalState"
  >
    <p class="has-text-centered my-3">
      <img src="/Sheldon1.png">
    </p>
    <p class="my-3">
      You will need to verify your email address to complete registration.
    </p>
    <p class="help has-text-grey">
      An account activation link has been sent to the email address you provided to verify your
      account. If you have not received the email after a few minutes, please check your spam
      folder.
    </p>

    <hr>

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <p>Didn't receive an email?</p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a class="link has-text-primary">Resend</a>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";

import {inject} from "vue";

defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

function checkModalState(value) {
  if (value === false) {
    closeModal();
  }
}

const { closeModal } = inject('authOverlay');
</script>
