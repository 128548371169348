<template>
  <button
    :class="classes"
    :disabled="disabled"
  >
    <Icon
      v-if="icon"
      :name="icon"
      variant="inherit"
    />
    <slot>{{ caption }}</slot>
    <div
      v-if="showAlert"
      class="alert"
    />
  </button>
</template>

<script setup lang="ts">
import { type PropType, computed } from "vue";

import type {
  ColorType,
  SizeType,
  StyleType,
  ThemeType,
} from "./PsButton.props.ts";

const props = defineProps({
  color: {
    type: String as PropType<ColorType>,
    default: "primary",
  },
  styling: {
    type: String as PropType<StyleType>,
    default: "normal",
  },
  theme: {
    type: String as PropType<ThemeType>,
    default: "default",
  },
  caption: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  size: {
    type: String as PropType<SizeType>,
    default: "medium",
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  isRounded: {
    type: Boolean,
    default: false,
  },
  isPill: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isInactive: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showAlert: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  const result = [
    "ps-button",
    `ps-button-${props.color}`,
    `is-${props.size}`,
    `is-${props.styling}`,
    ...(props.theme !== "default" ? [`is-${props.theme}`] : []),
  ];
  if (props.fullWidth) {
    result.push("is-fullwidth");
  }
  if (props.isRounded) {
    result.push("is-rounded");
  }
  if (props.isPill) {
    result.push("is-pill");
  }
  if (props.isLoading) {
    result.push("is-loading");
  }
  if (props.isInactive) {
    result.push("is-inactive");
  }
  if (props.disabled) {
    result.push("disabled");
  }
  return result;
});
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.ps-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  gap: 8px;

  cursor: pointer;
  white-space: nowrap;
  min-width: 40px;
  height: 40px;
  line-height: 24px;

  padding: 8px 16px;

  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;

  border: 1px solid transparent;
  border-radius: 8px;
  color: $gray-slate;
  background-color: transparent;
  box-shadow: inset 0 -1px 0 rgba(6, 30, 62, 0.1);

  transition: 0.1s;

  &.is-fullwidth {
    min-width: 100% !important;
  }

  &.is-rounded {
    border-radius: 20px;
  }

  &.is-small {
    min-width: 24px;
    height: 24px;
    padding: 6px 8px;
    font-size: 0.75rem;
  }

  &.is-medium {
    min-width: 40px;
    height: 40px;
  }

  &.is-large {
    min-width: 48px;
    height: 48px;
    border-radius: 12px;
    font-size: 1.25rem;
  }

  &.is-extra-large {
    min-width: 56px;
    height: 56px;
    border-radius: 18px;
    font-size: 1.5rem;
  }

  &.is-dark {
    color: $gray-snow;
    background: $gray-A30;
  }

  &.is-outlined {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.is-pill {
    padding: 0;
    .icon {
      margin: 0;
    }
  }

  &.is-ghost {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .icon {
    max-height: 20px;
    max-width: 20px;

    color: $primary-invert;
    fill: $primary-invert;

    path {
      fill: $primary-invert;
    }
  }
  &.is-small {
    .icon {
      max-height: 16px;
      max-width: 16px;
    }
  }
  &.is-large {
    .icon {
      font-size: 1.5rem;
    }
  }

  .alert {
    background-color: $peach-500;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    margin-left: 20px;
    margin-bottom: 18px;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.is-loading:before {
    animation: spinAround 0.5s infinite linear;
    border: 2px solid $gray-slate;
    border-radius: 9999px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
    margin-right: 4px;
  }

  &.is-inactive {
    color: $inactive;

    .icon {
      color: $inactive;
      fill: $inactive;
      path {
        fill: $inactive;
      }
    }
  }
}

.ps-button-primary {
  background-color: $peach-500;
  color: $gray-snow;

  .icon {
    color: $gray-snow;
    fill: $gray-snow;
    path {
      fill: $gray-snow;
    }
  }

  &:focus-visible {
    outline-color: $peach-500;
  }

  &.is-normal .alert {
    background-color: $gray-snow;
  }

  &.is-normal:hover {
    background-color: $peach-600;
  }

  &.is-normal:active,
  &.is-normal[active] {
    background-color: $peach-700;

    &.is-dark {
      background-color: $gray-A30;
    }
  }

  &.is-loading:before {
    border: 2px solid $gray-snow;
    border-right-color: transparent;
    border-top-color: transparent;
  }

  &.is-inactive {
    opacity: 0.5;
    background-color: $peach-500;
    color: $gray-snow;

    &.is-dark {
      background-color: $gray-A30;
    }

    .icon {
      color: $gray-snow;
      fill: $gray-snow;
      path {
        fill: $gray-snow;
      }
    }
  }

  &.is-outlined {
    border-color: $peach-500;
    color: $peach-500;

    &.is-loading:before {
      border: 2px solid $peach-500;
      border-right-color: transparent;
      border-top-color: transparent;
    }

    .icon {
      color: $peach-500;
      fill: $peach-500;
      path {
        fill: $peach-500;
      }
    }

    &:hover {
      color: $peach-600;
      border-color: $peach-600;
      background-color: $peach-100;

      .icon {
        color: $peach-600;
        fill: $peach-600;
        path {
          fill: $peach-600;
        }
      }
    }

    &:active,
    &[active] {
      color: $peach-700;
      border-color: $peach-700;
      background-color: $peach-200;

      .icon {
        color: $peach-700;
        fill: $peach-700;
        path {
          fill: $peach-700;
        }
      }
    }

    &.is-inactive {
      opacity: 0.5;
      background-color: transparent;
      border-color: $peach-500;
      color: $peach-500;

      .icon {
        color: $peach-500;
        fill: $peach-500;
        path {
          fill: $peach-500;
        }
      }
    }
  }

  &.is-ghost {
    color: $peach-500;

    &.is-loading:before {
      border: 2px solid $peach-500;
      border-right-color: transparent;
      border-top-color: transparent;
    }

    .icon {
      color: $peach-500;
      fill: $peach-500;
      path {
        fill: $peach-500;
      }
    }

    &:hover {
      color: $peach-600;
      background-color: $peach-100;

      .icon {
        color: $peach-600;
        fill: $peach-600;
        path {
          fill: $peach-600;
        }
      }
    }

    &:active,
    &[active] {
      color: $peach-700;
      background-color: $peach-200;

      .icon {
        color: $peach-700;
        fill: $peach-700;
        path {
          fill: $peach-700;
        }
      }
    }

    &.is-inactive {
      opacity: 0.5;
      background-color: transparent;
      color: $peach-500;

      .icon {
        color: $peach-500;
        fill: $peach-500;
        path {
          fill: $peach-500;
        }
      }
    }
  }
}



.ps-button-secondary {
  border-color: $gray-pebble;
  background-color: $gray-mist;

  .icon {
    color: $gray-muted;
    fill: $gray-muted;
    path {
      fill: $gray-muted;
    }
  }

  &:hover:not([disabled]) {
    color: $gray-charcoal;
    border-color: $gray-pebble;
    background-color: $gray-smoke;

    .icon {
      color: $gray-charcoal;
      fill: $gray-charcoal;
      path {
        fill: $gray-charcoal;
      }
    }
  }

  &.is-normal:active,
  &.is-normal[active] {
    border-color: $gray-ash;
    background-color: $gray-pebble;

    &.is-dark {
      background-color: $gray-A30;
    }
  }

  &.is-dark.is-inactive {
    color: $gray-snow;
  }

  &.is-outlined {
    border-color: $gray-pebble;

    &.is-light {
      color: $gray-snow;
      border-color: $gray-snow;

      .icon {
        color: $gray-snow;
        fill: $gray-snow;
        path {
          fill: $gray-snow;
        }
      }

      &:hover:not([disabled]) {
        color: $gray-charcoal;
        border-color: $gray-pebble;
        background-color: $gray-smoke;

        .icon {
          color: $gray-charcoal;
          fill: $gray-charcoal;
          path {
            fill: $gray-charcoal;
          }
        }
      }
      &:active,
      &[active] {
        color: $gray-charcoal;
        border-color: $gray-pebble;
        background-color: $gray-smoke;

        .icon {
          color: $gray-charcoal;
          fill: $gray-charcoal;
          path {
            fill: $gray-charcoal;
          }
        }
      }
    }

    &:hover:not([disabled]) {
      border-color: $gray-ash;
    }
    &:active,
    &[active] {
      border-color: $gray-muted;
    }
  }

  &.is-ghost {
    &:hover:not([disabled]) {
      background-color: $gray-mist;
    }
    &:active,
    &[active] {
      background-color: $gray-smoke;
    }
  }
}

.ps-button-white {
  border-color: $gray-pebble;
  background-color: $white;

  .icon {
    color: $gray-muted;
    fill: $gray-muted;
    path {
      fill: $gray-muted;
    }
  }

  &:hover:not([disabled]) {
    color: $gray-charcoal;
    border-color: $gray-pebble;
    background-color: $gray-ash;

    .icon {
      color: $gray-charcoal;
      fill: $gray-charcoal;
      path {
        fill: $gray-charcoal;
      }
    }
  }

  &.is-normal:active,
  &.is-normal[active] {
    border-color: $gray-ash;
    background-color: $white;
  }

  &.is-outlined {
    border-color: $gray-pebble;
    background-color: $white;

    &:hover:not([disabled]) {
      color: $gray-charcoal;
      border-color: $gray-pebble;
      background-color: $gray-smoke;

      .icon {
        color: $gray-charcoal;
        fill: $gray-charcoal;
        path {
          fill: $gray-charcoal;
        }
      }
    }
    &:active,
    &[active] {
      color: $gray-charcoal;
      border-color: $gray-pebble;
      background-color: $gray-smoke;

      .icon {
        color: $gray-charcoal;
        fill: $gray-charcoal;
        path {
          fill: $gray-charcoal;
        }
      }
    }
  }

  &.is-ghost {
    &:hover:not([disabled]) {
      background-color: $gray-mist;
    }
    &:active,
    &[active] {
      background-color: $gray-smoke;
    }
  }
}

.ps-button-success {
  color: $gray-mist;
  border-color: $green-500;
  background-color: $green-500;

  .icon {
    color: $gray-mist;
    fill: $gray-mist;
    path {
      fill: $gray-mist;
    }
  }

  &.is-normal:hover:not([disabled]) {
    background-color: $green-600;
  }

  &.is-normal:active:not([disabled]),
  &.is-normal[active]:not([disabled]) {
    background-color: $green-700;
  }

  &.is-inactive {
    opacity: 0.5;
    color: $gray-mist;
    border-color: $green-500;
    background-color: $green-500;

    .icon {
      color: $gray-mist;
      fill: $gray-mist;
      path {
        fill: $gray-mist;
      }
    }
  }

  &.is-outlined {
    border-color: $green-500;
    color: $green-500;
    background-color: transparent;

    .icon {
      color: $green-500;
      fill: $green-500;
      path {
        fill: $green-500;
      }
    }

    &:hover:not([disabled]) {
      border-color: $green-600;
      color: $green-600;

      .icon {
        color: $green-600;
        fill: $green-600;
        path {
          fill: $green-600;
        }
      }
    }
    &:active:not([disabled]),
    &[active]:not([disabled]) {
      border-color: $green-700;
      color: $green-700;

      .icon {
        color: $green-700;
        fill: $green-700;
        path {
          fill: $green-700;
        }
      }
    }

    &.is-inactive {
      opacity: 0.5;
      border-color: $green-500;
      color: $green-500;

      .icon {
        color: $green-500;
        fill: $green-500;
        path {
          fill: $green-500;
        }
      }
    }
  }

  &.is-ghost {
    color: $green-500;

    .icon {
      color: $green-500;
      fill: $green-500;
      path {
        fill: $green-500;
      }
    }

    &:hover:not([disabled]) {
      background-color: $green-100;
    }
    &:active:not([disabled]),
    &[active]:not([disabled]) {
      background-color: $green-200;
    }

    &.is-inactive {
      opacity: 0.5;
      color: $green-500;
      background-color: transparent;

      .icon {
        color: $green-500;
        fill: $green-500;
        path {
          fill: $green-500;
        }
      }
    }
  }
}

.ps-button-neutral {
  background-color: $gray-charcoal;
  color: $gray-snow;

  .icon {
    color: $gray-snow;
    fill: $gray-snow;
    path {
      fill: $gray-snow;
    }
  }

  &:focus-visible {
    outline-color: $gray-pebble;
  }

  &.is-normal .alert {
    background-color: $gray-snow;
  }

  &.is-normal:hover {
    background-color: $gray-muted;
  }

  &.is-normal:active,
  &.is-normal[active] {
    background-color: $gray-slate;

    &.is-dark {
      background-color: $gray-A30;
    }
  }

  &.is-loading:before {
    border: 2px solid $gray-snow;
    border-right-color: transparent;
    border-top-color: transparent;
  }

  &.is-inactive {
    opacity: 0.5;
    background-color: $peach-500;
    color: $gray-snow;

    &.is-dark {
      background-color: $gray-A30;
    }

    .icon {
      color: $gray-snow;
      fill: $gray-snow;
      path {
        fill: $gray-snow;
      }
    }
  }

  &.is-outlined {
    border-color: $peach-500;
    color: $peach-500;

    &.is-loading:before {
      border: 2px solid $peach-500;
      border-right-color: transparent;
      border-top-color: transparent;
    }

    .icon {
      color: $peach-500;
      fill: $peach-500;
      path {
        fill: $peach-500;
      }
    }

    &:hover {
      color: $peach-600;
      border-color: $peach-600;
      background-color: $peach-100;

      .icon {
        color: $peach-600;
        fill: $peach-600;
        path {
          fill: $peach-600;
        }
      }
    }

    &:active,
    &[active] {
      color: $peach-700;
      border-color: $peach-700;
      background-color: $peach-200;

      .icon {
        color: $peach-700;
        fill: $peach-700;
        path {
          fill: $peach-700;
        }
      }
    }

    &.is-inactive {
      opacity: 0.5;
      background-color: transparent;
      border-color: $peach-500;
      color: $peach-500;

      .icon {
        color: $peach-500;
        fill: $peach-500;
        path {
          fill: $peach-500;
        }
      }
    }
  }

  &.is-ghost {
    color: $peach-500;

    &.is-loading:before {
      border: 2px solid $peach-500;
      border-right-color: transparent;
      border-top-color: transparent;
    }

    .icon {
      color: $peach-500;
      fill: $peach-500;
      path {
        fill: $peach-500;
      }
    }

    &:hover {
      color: $peach-600;
      background-color: $peach-100;

      .icon {
        color: $peach-600;
        fill: $peach-600;
        path {
          fill: $peach-600;
        }
      }
    }

    &:active,
    &[active] {
      color: $peach-700;
      background-color: $peach-200;

      .icon {
        color: $peach-700;
        fill: $peach-700;
        path {
          fill: $peach-700;
        }
      }
    }

    &.is-inactive {
      opacity: 0.5;
      background-color: transparent;
      color: $peach-500;

      .icon {
        color: $peach-500;
        fill: $peach-500;
        path {
          fill: $peach-500;
        }
      }
    }
  }
}
</style>
