import {createDomain} from "@/app";
import {routes} from "@/rental/routes";
import {addRoutesToRouter} from "@/app/router";
import {SessionSignedIn} from "@/app/events";
import {useFavoriteListings, usePrivateListings} from "@/rental/stores/listings";
import {useBookings} from "@/rental/stores/bookings";
import {PulseExecuted} from "@/account/events";

export const registerRentalDomain = createDomain('rental', ({app, bus, router}) => {
  const favorites = useFavoriteListings();
  const bookings = useBookings();
  const privateListings = usePrivateListings();
  bus.subscribe(SessionSignedIn, async (event) => {
    await Promise.all([
      favorites.sync(),
      privateListings.sync(),
      bookings.sync(),
    ])
  })

  bus.subscribe(PulseExecuted, async () => {
    await bookings.sync();
  })
  addRoutesToRouter(router, routes);
})
