<template>
  <div>
    <p class="title is-3 mb-4">
      Pricing
    </p>
    <p class="my-4">
      Set the daily rental price for your item. Consider its value, demand, and condition to ensure competitive pricing
      that attracts renters while providing you with fair earnings.
    </p>

    <div class="mt-6 mb-4">
      <FormKit
        help="This price will not be shown on the listing. We will use this for optional insurance."
        name="marketValue"
        step="100"
        type="number"
        validation="required|number"
      >
        <template #label>
          <label class="formkit-label label">Estimated market value <span class="form-required-label">(Required)</span></label>
        </template>
        <template #prefixIcon>
          <Icon
            class="formkit-prefix-icon formkit-icon"
            name="outlined/attach_money"
            variant="inherit"
          />
        </template>
      </FormKit>

      <FormKit
        label="Pricing"
        name="pricingOptions"
        type="list"
        validation="length:1"
      >
        <FormKit type="group">
          <div
            class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap flex-align-items-center"
          >
            <FormKit
              name="amount"
              :placeholder="formatMoney({ value: 0.0, hideCurrencySymbol: true })"
              type="number"
              validation="required|number|min:5"
            >
              <template #label>
                <label class="formkit-label label">Price <span class="form-required-label">(Required)</span></label>
              </template>
              <template #prefixIcon>
                <Icon
                  class="formkit-prefix-icon formkit-icon"
                  name="outlined/attach_money"
                  variant="inherit"
                />
              </template>
            </FormKit>
            <FormKit
              label="Rate"
              :options="intervalOptions"
              value="daily"
              name="interval"
              type="select"
              disabled
            />
          </div>
        </FormKit>
      </FormKit>
    </div>
  </div>
</template>

<script setup lang="ts">
import Formats from "@/helpers/formats";
import {useTranslationOptions} from "@/composables/form";

const intervalOptions = useTranslationOptions('intervals');

const formatMoney = Formats.money;
</script>

<style scoped lang="scss">
@import '../../styles/variables';
@import '../../styles/mixins';

.required-label {
  color: $gray-muted
}
</style>
