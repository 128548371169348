<template>
  <div class="hero-home">
    <div class="home-gallery">
      <img src="/dash/hero.jpg">
    </div>
    <div class="container">
      <div class="hero hero-full-height is-primary is-half-height">
        <div class="hero-body">
          <p class="title mb-0">
            Spend less,<br/>experience more
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";

const router = useRouter();

const { isAuthenticated } = storeToRefs(useSession());

function onClickStartRenting() {
  if (isAuthenticated.value) {
    gotoCategories();
  } else {
    goToSignUp();
  }
}

function goToSignUp() {
  router.push({
    name: "signUp",
  });
}

function gotoCategories() {
  router.push({
    name: "categories",
  });
}
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.hero-home {
  margin: 0 auto;

  .home-gallery {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: $gray-charcoal;
    width: 100%;
    height: 800px;
    max-height: 100vh;

    @include touch {
      height: 100vh;
    }

    img {
      opacity: 0.75;
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .hero {
    background-color: transparent !important;
    height: calc(800px - $header-top-padding);
    max-height: calc(100vh - $header-top-padding);

    @include touch {
      height: calc(100vh - $header-top-padding-mobile);
      max-height: calc(100vh - $header-top-padding-mobile);
    }

    .hero-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 12px;
      background-size: cover;
      background-position: center center;

      & > p:not(.title):not(.subtitle):not(.super-title) {
        padding: 0 20%;
        margin: 1rem 0;
        font-size: 1.2rem;
        text-align: center;

        @include touch {
          padding: 0 1em;
        }
      }
    }

    .title {
      font-family: Poppins;
      font-size: var(--display-extra-large) !important;
      font-weight: 700;
      line-height: var(--display-line-height-extra-large);
    }

    .subtitle {
      font-size: 1.5em;
      font-weight: 600;
      margin: 0.5em;
      padding: 0;
    }
  }
}
</style>
