<template>
  <div :class="classes">
    <div
      v-if="$slots.header"
      class="card-header"
    >
      <slot name="header" />
    </div>
    <div
      class="click-wrap"
      @click="emits('cardClicked')"
    >
      <div class="card-preview">
        <div class="card-overlay">
          <div class="overlay-top">
            <slot name="overlay-top" />
          </div>
          <div class="overlay-bottom">
            <slot name="overlay-bottom" />
          </div>
        </div>
        <slot name="preview" />
      </div>
      <div
        v-if="$slots.details"
        class="card-details"
      >
        <slot name="details" />
      </div>
    </div>
    <div
      v-if="$slots.actions"
      class="card-actions"
    >
      <slot name="actions" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { type PropType, computed } from "vue";

import type {
  OrientationType,
  SizeType,
} from "@/components/display/card/props";

const OrientationDefault = "vertical";

const emits = defineEmits(["cardClicked"]);

const props = defineProps({
  orientation: {
    type: String as PropType<OrientationType>,
    default: "default",
  },
  size: {
    type: String as PropType<SizeType>,
    default: "medium",
  },
  isClickable: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  const orientation =
    props.orientation === "default" ? OrientationDefault : props.orientation;
  const result = ["card-base", `is-${orientation}`, `is-${props.size}`];
  if (props.isClickable) {
    result.push("card-is-clickable");
  }
  return result;
});
</script>
<style lang="scss">
@import '../styles/variables.scss';
@import '../styles/typography.css';
@import '../styles/mixins.scss';

.card-base {
  font-size: 14px;
  display: flex;

  .card-preview {
    width: 280px;
    height: 180px;

    background: $gray-mist;
    border: 1px solid opacify($gray-A20, 0.1);

    box-shadow: 0px 4px 12px -4px rgba(6, 30, 62, 0.15);
    border-radius: 8px;
    overflow: hidden;
    transform: translateY(0px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include touch {
      width: auto;
      height: 180px;
    }

    img {
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
      object-position: center center;
      transition: 0.3s;
    }

    > .icon {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      color: $gray-ash;
      transition: 0.3s;
    }

    .card-overlay {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      padding: 12px;
      gap: 10px;
      isolation: isolate;

      position: absolute;
      left: 0.29px;
      right: -0.29px;
      top: 0px;
      bottom: 0px;
      z-index: 1;
      transition: 0.3s;

      .overlay-top {
        transition: 0.3s;
        opacity: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .overlay-bottom {
        opacity: 1;
      }

      .ps-button {
        background: transparentize($gray-A30, 0.03);
        color: $gray-pebble;

        &:hover {
          background: $gray-A40;
          color: $gray-snow;
        }
      }
    }
  }

  &.is-vertical {
    flex-direction: column;
    max-width: 280px;
    min-width: 280px;

    .click-wrap {
      flex-direction: column;
    }

    @include touch {
      max-width: 100%;
      min-width: 100%;
    }

    .card-preview {
      width: 100%;
    }
  }

  &.is-horizontal {
    width: 100%;
    flex-direction: row;
    gap: 12px;

    .click-wrap {
      display: flex;
      gap: 12px;
      flex-direction: row;
      flex-grow: 1;
    }

    .card-details {
      padding: 0;
      justify-content: start;
      flex-grow: 1;
    }
  }

  &.is-tiny {
    .card-preview {
      width: 48px;
      height: 48px;
    }

    .card-details {
      font-size: 14px;

      > * {
        padding: 2px 0;
      }
    }
  }

  &.is-small {
    .card-preview {
      width: 80px;
      height: 80px;
    }

    .card-details {
      font-size: var(--text-small);
      &:first-child {
        font-size: var(--text-medium);
      }
      > * {
        padding: 2px 0;
      }
    }
  }

  &.is-medium {
    .card-preview {
      width: 160px;
      height: 120px;
    }

    .card-details {
      font-size: var(--text-small);
      &:first-child {
        font-size: var(--text-medium);
      }
      > * {
        padding: 2px 0;
      }
    }
  }

  &.is-medium-square {
    max-width: 220px;
    min-width: 220px;

    .card-preview {
      width: 220px;
      height: 220px;
    }
  }

  .card-details {
    > * {
      padding: 4px 0;
    }

    font-size: var(--text-small);
    & > *:first-child {
      font-size: var(--text-medium);
    }

    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 8px 0px;
  }

  &.card-is-clickable > .click-wrap:hover {
    cursor: pointer;

    .card-preview > img,
    .card-preview > .icon {
      opacity: 1;
      transform: scale(1.05) translateY(-4px);
    }

    .card-overlay {
      background-color: $gray-A10;
    }

    .card-overlay .overlay-top {
      opacity: 1;
    }
  }
}
</style>
