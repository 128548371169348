import { camelCase, snakeCase } from "change-case";

export const serialize = {
  date: (v: Date) => (v.toISOString ? v.toISOString().slice(0, 10) : v),
};

export const deserialize = {
  date: (v: string) => Date.parse(v),
  cents_amount: (v: number) => v / 100,
};

export const camelizeObject = (obj: object) => {
  /* Returns a new object from `obj` where the keys are camel case */
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [camelCase(key), value]),
  );
};

export const snakeObject = (obj: object) => {
  /* Returns a new object from `obj` where the keys are snake case */
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [snakeCase(key), value]),
  );
};
