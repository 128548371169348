<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM5 10.5L8.47 14L15 7.41L13.6 6L8.47 11.17L6.4 9.09L5 10.5Z"
      fill="#93D7C0"
      fill-rule="evenodd"
    />
  </svg>
</template>
