import { ref } from "vue";

export const useLoading = (cb: () => Promise<void>) => {
  const isLoading = ref(false);
  const trigger = async () => {
    isLoading.value = true;
    await cb();
    isLoading.value = false;
  };

  return {
    trigger,
    isLoading,
  };
};
