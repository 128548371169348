<template>
  <div class="content p-1">
    {{ message.body }}
  </div>
</template>
<script>
export default {
  name: "TextMessage",
  props: ["message"],
};
</script>
