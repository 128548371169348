import {createDomain} from "@/app";
import {useNotifications} from "@/account/stores/notifications";
import {useTimeoutPoll} from "@vueuse/core";

import {routes} from "@/account/routes";
import {AppActiveToIdle, AppIdleToActive, SessionSignedIn, SessionSignedOut} from "@/app/events";
import {Logger} from "@/app/logger";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";
import {addRoutesToRouter} from "@/app/router";
import type {EventBus} from "ts-bus";
import {PulseExecuted} from "@/account/events";
import {useAppContext} from "@/app/stores/context";

// Retrieve notifications every X milliseconds
const PULSE_INTERVAL = 10000;

const createPulser = (bus: EventBus) => {
  const pulser = async () => {
    const notifications = useNotifications();
    await notifications.fetch();
    bus.publish(PulseExecuted());
  }
  return useTimeoutPoll(pulser, PULSE_INTERVAL, {immediate: false} );
}

export const registerDomain = createDomain('account', ({app, bus, router }) => {
  const pulser = createPulser(bus);

  bus.subscribe(SessionSignedIn, async (event) => {
    Logger.info("👋 Start pulser");
    pulser.resume();
  })

  bus.subscribe(SessionSignedOut, async (event) => {
    Logger.info("👋 Stop pulser");
    pulser.pause();
  })

  bus.subscribe(AppActiveToIdle, () => {
    Logger.info("👋 Idle: stop pulser");
    pulser.pause();
  });

  bus.subscribe(AppIdleToActive, () => {
    Logger.info("👋 Active again: resume pulser");
    const {isAuthenticated} = storeToRefs(useSession());
    if (isAuthenticated.value) {
      pulser.resume();
    }
  })
  addRoutesToRouter(router, routes);
});
