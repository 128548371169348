<template>
  <PsButton
    is-rounded
    styling="outlined"
    :color="isActive ? 'primary' : 'secondary'"
    :icon="category.icon"
  >
    {{ category.name }}
  </PsButton>
</template>
<script>
export default {
  name: "CategoryButton",
  components: {},
  props: {
    category: {
      type: Object,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.button {
  height: 40px;
  gap: 13px;
  justify-content: center;
}
</style>
