import { assign, createMachine } from "xstate";

export interface AppMachineContext {
  authInitialized: boolean;
  appInitialized: boolean;
}

const appReady = ({ context }: { context: AppMachineContext }): boolean => {
  return context.authInitialized && context.appInitialized;
};

export default () => {
  return createMachine({
    id: "app",
    initial: "uninitialized",
    types: {} as {
      context: AppMachineContext;
    },
    context: () => ({
      authInitialized: false,
      appInitialized: false,
    }),
    states: {
      uninitialized: {
        on: {
          INITIALIZED: {
            target: "loading",
          },
        },
      },
      loading: {
        on: {
          AUTH_INITIALIZED: {
            actions: assign({ authInitialized: true }),
          },
          APP_INITIALIZED: {
            actions: assign({ appInitialized: true }),
          },
          READY: {
            target: "ready",
            guard: appReady,
          },
        },
        always: {
          target: "ready",
          guard: appReady,
        },
      },
      ready: {},
    },
  });
};
