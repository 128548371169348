<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    size?: "small" | "medium";
  }>(),
  {
    size: "medium",
  },
);

const classes = computed(() => {
  const result = ["empty-state"];
  result.push(`is-${props.size}`);
  return result;
});
</script>

<template>
  <div :class="classes">
    <img src="/SheldonShrugGray.png">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.empty-state {
  width: 100%;
  color: $gray-A60;
  margin: 4em auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  text-align: center;

  @include touch {
    margin: 2em auto;
  }

  &.is-small img {
    width: 124px;
  }
}
</style>
