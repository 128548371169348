<template>
  <div
    :class="classes"
  >
    <img src="/logo_map.png">
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  variant: {
    type: String,
    default: "color",
  },
  size: {
    type: String, // 'small' | 'normal'
    default: "normal",
  },
});

const classes = computed(() => {
  return [
    "logo",
    `is-${props.variant}`,
    props.size !== "normal" ? `logo-${props.size}` : "",
  ];
});
</script>

<style scoped lang="scss">
.logo {
  overflow: hidden;
  width: 230px;
  height: 40px;

  img {
    max-width: inherit;
    object-fit: none;

    object-position: -20px -80px;
    width: 230px;
    height: 40px;
  }

  &.is-color img {
    object-position: -20px -80px;
  }

  &.is-dark img {
    object-position: -20px -140px;
  }

  &.is-light img {
    object-position: -20px -20px;
  }

  &.logo-small {
    width: 138px;
    height: 30px;

    img {
      object-fit: cover;

      object-position: -8px -40px;
      width: 148px;
      height: 30px;
    }

    &.is-color img {
      object-position: -8px -40px;
    }

    &.is-dark img {
      object-position: -8px -75px;
    }

    &.is-light img {
      object-position: -8px -6px;
    }
  }
}
</style>
