<template>
  <div class="booking-next-action" v-if="nextAction">
    <Icon
      name="outlined/schedule"
      variant="secondary"
    />
    {{ nextAction }}
  </div>
</template>

<script setup lang="ts">
import {computed} from "vue";
import type {BookingStatus} from "@/rental/types";

const props = defineProps<{
  status: BookingStatus,
  isRenter: boolean,
}>();

const nextAction = computed((): string => {
  let actionString = "";
  switch (props.status) {
    case "ready_for_pickup":
      actionString = "Waiting for you to confirm pickup";
      break;
    case "waiting_pickup_lender":
      actionString =
        !props.isRenter
          ? "Waiting for you to confirm pickup"
          : "Waiting for the owner to confirm pickup";
      break;
    case "waiting_pickup_renter":
      actionString =
        props.isRenter
          ? "Waiting for you to confirm pickup"
          : "Waiting for the renter to confirm pickup";
      break;
    case "waiting_deliver_lender":
      actionString =
        !props.isRenter
          ? "Waiting for you to confirm the renter has returned the item"
          : "Waiting for the owner to confirm you have returned the item";
      break;
    case "waiting_deliver_renter":
      actionString =
        props.isRenter
          ? "Waiting for you to decline or confirm the return"
          : "Waiting for the renter to decline or confirm the return";
      break;
    default:
      actionString = "";
  }
  return actionString;
});
</script>

<style scoped lang="scss">

</style>
