<template>
  <div
    v-if="error"
    class="help is-error"
  >
    {{ error }}
  </div>

  <EnterSignUp
    v-if="step === 'enter'"
    @next="handleNext($event)"
  />
  <EmailSignUp
    v-if="step === 'email'"
    :email="email"
    @signed-up="showSignedUp"
    @next="handleNext($event)"
  />

  <span
    v-if="step === 'enter'"
    class="hr"
  >OR</span>
  <div
    v-if="step === 'enter'"
    class="field"
  >
    <PsButton
      class="is-fullwidth"
      color="secondary"
      icon="apple"
      styling="outlined"
      @click="signUpApple"
    >
      Continue with Apple
    </PsButton>
  </div>
  <div
    v-if="step === 'enter'"
    class="field"
  >
    <PsButton
      class="is-fullwidth"
      color="secondary"
      icon="google"
      styling="outlined"
      @click="signUpGoogle"
    >
      Continue with Google
    </PsButton>
  </div>

  <hr>

  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <p>Already have an account?</p>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <a
          class="is-hidden-tablet link has-text-primary"
          @click="router.push({ name: 'signIn' })"
        >Sign in</a>
        <a
          class="is-hidden-mobile link has-text-primary"
          @click="showSignIn"
        >Sign in</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {inject, ref} from "vue";
import { useRouter } from "vue-router";

import EmailSignUp from "@/forms/EmailSignUp.vue";
import EnterSignUp from "@/forms/EnterSignUp.vue";

import {useSession} from "@/app/stores/session";

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  goToStep: {
    type: String,
    default: "enter",
  },
});

const router = useRouter();
const session = useSession();

const email = ref("");
const step = ref(props.goToStep || "enter");
const error = ref(null);

const { setModal } = inject('authOverlay');

function showSignIn() {
  setModal("signIn");
}

function showSignedUp() {
  setModal("signedUp");
}

function handleNext(e) {
  email.value = e.email;
  step.value = e.flow;
  error.value = e.error;
}

async function signUpGoogle() {
  await session.signInGoogle();
}

async function signUpApple() {
  await session.signInApple();
}
</script>

<style lang="scss" scoped></style>
