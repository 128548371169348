<template>
  <div class="container">
    <div class="message-page">
      <div :class="sidebarClasses">
        <div class="search">
          <PsButton
            color="secondary"
            is-pill
            is-rounded
            styling="outlined"
            @click="$router.push({ name: 'home' })"
          >
            <Icon
              name="outlined/chevron_left"
              variant="secondary"
            />
          </PsButton>
          <p class="title is-4 is-display">
            {{ $t('conversations.title') }}
          </p>
          <!-- <Icon
            class="float-right"
            name="search"
            variant="secondary"
          /> -->

          <PsButton
            v-if="showSidebarMessages"
            class="is-hidden-tablet"
            color="secondary"
            is-pill
            is-rounded
            styling="outlined"
            @click="toggleSidebarMessages"
          >
            <Icon
              name="outlined/chevron_right"
              variant="secondary"
            />
          </PsButton>
        </div>
        <hr>
        <div
          v-if="store.loading"
          class="nothing"
        >
          <p>Loading...</p>
        </div>
        <div
          v-else-if="store.items.length === 0"
          class="nothing"
        >
          <p>No messages yet</p>
        </div>
        <div
          v-else
          class="inbox"
        >
          <div
            v-for="item in conversations"
            :key="item.sender_id"
            :class="selectedConversation?.id === item.id ? 'is-active' : null"
            class="message is-clickable"
            @click="gotoChat(item.id)"
          >
            <Avatar
              :user="item.sender"
              class="is-clickable"
              size="large"
            />
            <div class="details">
              <label><span class="name is-clickable">{{ item.sender.fullname }}</span>
                <span class="time">{{ $helpers.fromNow(item.updatedAt) }}</span></label>
              <div class="content">
                {{ item.lastMessage?.body }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider" />
      <div class="content">
        <div
          v-if="!selectedConversation && !showSidebarMessages"
          class="is-hidden-tablet message-header"
        >
          <PsButton
            color="secondary"
            is-pill
            is-rounded
            styling="outlined"
            @click="toggleSidebarMessages"
          >
            <Icon
              name="outlined/chevron_left"
              variant="secondary"
            />
          </PsButton>
        </div>
        <div
          v-if="loadingMessages"
          class="nothing"
        >
          Loading...
        </div>
        <div
          v-else-if="!selectedConversation"
          class="nothing"
        >
          <EmptyState>
            <p>Requests and converstations end up here!</p>
          </EmptyState>
        </div>
        <div
          v-else
          class="conversation"
        >
          <div class="message-header">
            <PsButton
              v-if="!showSidebarMessages"
              class="is-hidden-tablet"
              color="secondary"
              is-pill
              is-rounded
              styling="outlined"
              @click="toggleSidebarMessages"
            >
              <Icon
                name="outlined/chevron_left"
                variant="secondary"
              />
            </PsButton>

            <Avatar :user="selectedConversation.sender" />
            <p class="title is-4 is-display">
              {{ selectedConversation.sender.fullname }}
            </p>
          </div>
          <hr>
          <div class="messages">
            <EmptyState v-if="!selectedConversation.hasMessages">
              No messages yet
            </EmptyState>
            <div
              v-else
              ref="conversationMessages"
            >
              <div
                v-for="{ message, prevMessage } in dialog"
                :key="message.id"
                :class="getMessageClass(message)"
                :style="getMessageStyle(message)"
                class="dialog-section"
              >
                <div
                  v-if="
                    !prevMessage || $helpers.diff(message.createdAt, prevMessage.createdAt) !== 0
                  "
                  class="time"
                >
                  {{ $helpers.dateTimeLocalFormat(message.createdAt) }}
                </div>
                <div
                  :class="getMessageClass(message)"
                  :style="getMessageStyle(message)"
                  class="message"
                >
                  <div
                    v-if="
                      !message.isAuthor(currentUser.id) &&
                        message.author.id !== prevMessage?.author?.id
                    "
                    class="message-avatar"
                  >
                    <Avatar :user="message.author" />
                  </div>
                  <div
                    v-if="message === 'booking:Booking:event:submit'"
                    class="bubble"
                  />
                  <div
                    v-if="message.resourceType === 'BookingRequestedMessage'"
                    class="bubble booking-bubble"
                  >
                    <BookingRequestedMessage :message="message" />
                  </div>
                  <div
                    v-else
                    class="bubble"
                  >
                    <TextMessage :message="message" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="message-input">
            <div
              :class="isActivated ? 'is-active' : null"
              class="control has-icons-right"
            >
              <FormKit
                v-model="messageInput"
                autocomplete="nofill"
                input-class="message-input-group is-rounded"
                name="message"
                outer-class="message-input-group-outer"
                placeholder="Write something..."
                type="text"
                @focusin="onFocus"
                @focusout="onFocusOut"
                @keydown.enter="sendMessage"
              />
              <Icon
                v-if="!!messageInput"
                class="button icon-remove is-right is-rounded is-clickable"
                name="close"
                variant="secondary"
                @click="onClearMessage"
              />
              <Icon
                ref="sendButton"
                class="button icon-send is-right is-rounded is-clickable"
                name="send"
                variant="secondary"
                @click="sendMessage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/EmptyState.vue";
import Avatar from "@/elements/Avatar.vue";

import messages from "@/components/chat";
import { useBookings } from "@/rental/stores/bookings";
import { useConversationStore } from "@/store/chatter/chats";

export default {
  components: {
    EmptyState,
    Avatar,
    TextMessage: messages.TextMessage,
    BookingRequestedMessage: messages.BookingRequestedMessage,
  },
  inject: ["currentUser"],
  props: {
    chatId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      store: useConversationStore(),
      bookingStore: useBookings(),
      loadingMessages: false,
      selectedConversation: null,
      messageInput: null,
      showSidebarMessages: true,
      isActivated: false,
    };
  },
  computed: {
    sidebarClasses() {
      return [
        "aside",
        ...(!this.showSidebarMessages ? ["is-sidebar-closed"] : [""]),
      ];
    },
    conversations() {
      return this.store.items;
    },
    messagesStyle() {
      return [""];
    },
    dialog() {
      let prev = null;
      return this.selectedConversation.messages.map((el) => {
        const result = {
          message: el,
          prevMessage: prev,
        };
        prev = el;
        return result;
      });
    },
  },
  async created() {
    await this.store.sync();
    if (this.chatId) {
      const convo = this.store.getById(this.chatId);
      await this.selectConversation(convo);
    }
  },
  methods: {
    toggleSidebarMessages() {
      this.showSidebarMessages = !this.showSidebarMessages;
    },
    scrollToBottom() {
      const el = this.$refs.conversationMessages;
      el.scrollTop = el.scrollHeight;
    },
    getMessageStyle() {},
    getMessageClass(message) {
      if (message.isAuthor(this.currentUser.id)) {
        return "author-me";
      }
    },
    async sendMessage() {
      await this.$helpers.useLoading(this.$refs.sendButton, async () => {
        await this.store.sendMessage(
          this.selectedConversation.id,
          this.messageInput,
        );
        this.messageInput = null;
      });
      this.$nextTick(this.scrollToBottom);
    },
    gotoChat(chatId) {
      this.$router.push({
        name: "chat",
        params: {
          chatId,
        },
      });
      const convo = this.store.getById(chatId);
      this.selectConversation(convo);
      this.toggleSidebarMessages();
    },
    async selectConversation(convo) {
      this.loadingMessages = true;
      await this.store.fetchMessages(convo.id);
      this.selectedConversation = convo;
      this.loadingMessages = false;
      this.$nextTick(this.scrollToBottom);
    },
    onFocus() {
      this.isActivated = true;
    },
    onFocusOut() {
      setTimeout(() => {
        this.isActivated = false;
      }, 1000);
    },
    onClearMessage() {
      this.messageInput = "";
      this.onFocusOut();
    },
  },
};
</script>

<style lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.message-page {
  display: flex;
  flex-direction: row;
  gap: 1em;
  max-height: calc(100vh - $header-top-padding);
  height: calc(100vh - $header-top-padding);
  position: relative;

  @include touch {
    max-height: calc(100vh - $header-top-padding-mobile);
    height: calc(100vh - $header-top-padding-mobile);
  }

  hr {
    margin: 0;
    flex-shrink: 1;
    flex-grow: 0;
  }

  .divider {
    border-right: 1px solid var(--gray-smoke);

    @include touch {
      display: none;
    }
  }

  .aside {
    position: relative;
    max-height: calc(100vh - $header-top-padding);
    height: calc(100vh - $header-top-padding);
    max-width: 30%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include touch {
      position: absolute;
      background-color: white;
      z-index: 10;
      max-height: calc(100vh - $header-top-padding-mobile);
      height: calc(100vh - $header-top-padding-mobile);
      max-width: 100vw;
      width: 100vw;
      left: -1rem;
      top: 1px;
      bottom: 0;
      padding: 0 1em;
      transition: all 500ms ease-in-out;

      &.is-sidebar-closed {
        left: -200%;
      }
    }

    .search {
      padding: 1em;
      display: flex;
      align-items: center;
      gap: 20px;

      .title {
        flex-grow: 1;
      }
    }

    .message {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      background: none;
      padding: 1em;
      margin: 1em 0;
      border-radius: 12px;

      .avatar {
        width: 40px;
        height: 40px;
        margin: 0;
        flex-grow: 0;
      }

      .details {
        flex-grow: 1;
        max-width: calc(100% - 60px);

        label .name {
          font-weight: 600;
        }

        .content {
          color: var(--gray-slate);
          max-width: 100%;
          width: 100%;
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
        }
      }

      &.is-active {
        background-color: var(--gray-mist);
      }

      &:hover {
        background-color: var(--gray-mist);
      }

      label {
        font-size: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .time {
          font-size: 0.8em;
          color: var(--gray-muted);
        }
      }
    }

    .nothing {
      display: flex;
      height: 50%;
      color: var(--gray-muted);
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .content {
    position: relative;
    flex-grow: 1;

    .nothing {
      height: calc(100% - 8em);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .conversation {
    display: flex;
    flex-direction: column;
    height: 100%;

    .message-header {
      padding: 1em;
      background: none;
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      margin-bottom: 0;
      flex-grow: 0;
      flex-shrink: 1;
    }

    .messages {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 1em;
      overflow-y: scroll;
      flex-grow: 1;
    }

    .time {
      text-align: center;
      color: var(--gray-muted);
      margin: 1em;
    }

    .message {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;
      background: none;
      margin-top: 8px;
      margin-bottom: 0;

      &.author-me {
        justify-content: flex-end;

        .message-avatar {
          float: right;
        }

        .bubble {
          background-color: var(--gray-smoke);
        }
      }

      .message-avatar {
        height: 40px;

        .avatar {
          flex-grow: 1;
          width: 40px;
        }
      }

      .bubble {
        flex-grow: 0;
        border: 1px solid var(--gray-smoke);
        border-radius: 24px;
        max-width: 60%;
        text-wrap: wrap;
        overflow: hidden;

        .listing-image {
          border-radius: 24px 24px 0 0;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 8px;
          margin: 0 0.75em;
        }

        &.booking-bubble {
          width: 50%;
        }
      }
    }

    .message-input {
      padding: 1em;

      .message-input-group-outer {
        margin-bottom: 0 !important;
      }

      .message-input-group {
        height: 3em;
      }

      .icon {
        &.icon-send {
          height: calc(2em - 8px) !important;
          width: calc(2em - 8px) !important;
          margin: 4px;
          padding: 0;
          border: none;
        }

        &.icon-remove {
          height: calc(2em - 16px) !important;
          width: calc(2em - 16px) !important;
          margin: 8px;
          margin-right: 52px;
          padding: 0;
          border: none;
        }
      }
    }
  }
}
</style>
