import type * as ioModels from "@/io/account";
import type { CollectionModel } from "@/store/base/models";
import {Avatar} from "@/io/account";

export class Actor implements CollectionModel {
  id!: string;
  createdAt!: string;
  updatedAt!: string;
  firstName?: string;
  lastName?: string;
  avatar?: {
    id: string;
    url?: string;
  };
  numListings?: number;
  reviews?: number;
  rating = 0;

  constructor(params: ioModels.Actor | Actor) {
    if (params instanceof Actor) {
      Object.assign(this, params);
      return;
    }
    this.id = params.id ?? "";
    this.createdAt = params.created_at;
    this.updatedAt = params.updated_at;
    this.firstName = params.first_name;
    this.lastName = params.last_name;
    this.avatar = params.avatar;
    this.numListings = params.num_listings;
    this.reviews = params.reviews;
    this.rating = params.rating ?? 0;
  }

  get avatarUrl() {
    return this.avatar?.url;
  }

  get fullName() {
    if (!this.firstName && !this.lastName) {
      return `#${this.id}`;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  get initials() {
    if (this.firstName && this.lastName) {
      return this.firstName.slice(0, 1) + this.lastName.slice(0, 1);
    } else if (this.firstName) {
      return this.firstName.slice(0, 1);
    } else if (this.lastName) {
      return this.lastName.slice(0, 1);
    }
    return undefined;
  }
}
