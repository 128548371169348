import {defineStore} from "pinia";
import {inject} from "vue";

import type * as ioModels from "@/io/account";
import {CurrentUserInjectionKey} from "@/plugins/symbols";
import {Actor} from "@/store/account/models";
import type {Upload} from "@/plugins/Form";

export {Actor};

interface UserMutables {
  avatar_id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  phone_number?: string | null;
}

export class PhoneNumber {
  region?: string;
  area?: string;
  local?: string;

  constructor(value: PhoneNumber) {
    this.region = String(value.region || 1).replace(/\+/, "");
    this.area = String(value.area);
    this.local = value.local?.replaceAll(/[- ]/gi, "");
  }

  get local1() {
    if (!this.local) return undefined;
    return String(this.local).slice(0, 3);
  }

  get local2() {
    if (!this.local) return undefined;
    return String(this.local).slice(3, 7);
  }

  static fromString(value: string) {
    const matches = /(?<region>\+\d)? (?<area>\d+)-(?<local>[\d -]+)/.exec(
      value,
    );
    if (!matches || !matches.groups) {
      return;
    } else {
      const groups: PhoneNumber = matches.groups as unknown as PhoneNumber;
      return new PhoneNumber(groups);
    }
  }

  toString() {
    if (this.local) {
      return `+${this.region} (${this.area}) ${this.local}`;
    } else {
      return undefined;
    }
  }

  serialize() {
    if (this.local) {
      return `+${this.region} ${this.area}-${this.local1}-${this.local2}`;
    } else {
      return undefined;
    }
  }
}

const useUserStore = defineStore("user", {
  state: (): {
    id?: Actor["id"];
    mutables: UserMutables;
    attributes: {
      account_type?: string;
      email?: string;
      hasNewMessages: boolean;
      created_at?: string;
      updated_at?: string;
    };
    relations: {
      avatar?: {
        id: string;
        url?: string;
      };
      favorites?: unknown[];
    };
  } => ({
    id: undefined,
    attributes: {
      account_type: undefined,
      email: undefined,
      hasNewMessages: false,
      created_at: undefined,
      updated_at: undefined,
    },
    mutables: {
      avatar_id: undefined,
      first_name: undefined,
      last_name: undefined,
      phone_number: undefined,
    },
    relations: {
      avatar: undefined,
      favorites: undefined,
    },
  }),
  getters: {
    email(state) {
      return state.attributes.email;
    },
    hasNewMessages(state) {
      return state.attributes.hasNewMessages;
    },
    hasAvatar(state) {
      return state.relations.avatar?.url !== undefined;
    },
    firstName(state) {
      return state.mutables.first_name;
    },
    lastName(state) {
      return state.mutables.last_name;
    },
    initials(state) {
      const firstName = state.mutables.first_name;
      const lastName = state.mutables.last_name;

      if (firstName && lastName) {
        return firstName.slice(0, 1) + lastName.slice(0, 1);
      } else if (firstName) {
        return firstName.slice(0, 1);
      } else if (lastName) {
        return lastName.slice(0, 1);
      }
      return undefined;
    },
    avatarUrl(state) {
      return state.relations.avatar?.url;
    },
    fullName(state) {
      if (!state.mutables.first_name && !state.mutables.last_name) {
        return null;
      }
      return `${state.mutables.first_name || ""} ${state.mutables.last_name || ""}`;
    },
    phoneNumber(state) {
      return state.mutables.phone_number
        ? PhoneNumber.fromString(state.mutables.phone_number)
        : null;
    },
  },
  actions: {
    load(user: ioModels.User) {
      if (!user) {
        return;
      }
      this.id = user.id;
      this.attributes = {
        account_type: user.account_type,
        email: user.email ?? user.username,
        created_at: user.created_at,
        updated_at: user.updated_at,
        hasNewMessages: user.has_new_messages ?? false,
      };
      this.mutables = {
        avatar_id: user.avatar?.id,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
      };
      this.relations = {
        avatar: user.avatar ?? undefined,
        favorites: user.favorites ?? [],
      };
    },
    asActor(): Actor {
      return new Actor({
        id: this.id,
        created_at: this.attributes.created_at,
        updated_at: this.attributes.updated_at,
        first_name: this.firstName || "",
        last_name: this.lastName || "",
        avatar: this.relations.avatar,
      });
    },
    async setName(firstName: string, lastName: string) {
      await this.updateUser({
        ...(firstName !== this.mutables.first_name
          ? {first_name: firstName}
          : {}),
        ...(lastName !== this.mutables.last_name
          ? {last_name: lastName}
          : {}),
      });
    },
    async setPhoneNumber(phoneNumber: PhoneNumber) {
      await this.updateUser({
        phone_number: phoneNumber.serialize(),
      });
    },
    async setAvatar(upload: Upload) {
      await this.updateUser({
        avatar_id: upload.id,
      });
    },
    async deleteAvatar() {
      await this.updateUser({
        avatar_id: null,
      });
      this.relations.avatar = undefined;
    },
    async updateUser(mutables: UserMutables) {
      const result = await this.$api.c.me.patch(null, mutables);
      this.load(result);
    },
  },
});

export const useCurrentUser = (): ReturnType<typeof useUserStore> => {
  const result = inject(CurrentUserInjectionKey);
  if (!result) {
    throw "No current user";
  }
  return result;
};

export default useUserStore;

export const models = {
  Actor,
};
