<template>
  <div v-if="appState === 'loading'">
    Loading... {{ appState }}
  </div>
  <AuthOverlay v-else>
    <component
      :is="layouts[layout]"
      :is-authenticated="isAuthenticated"
      :with-action-nav="route.meta.withActionNav"
      :with-footer="route.meta.withFooter"
    />
  </AuthOverlay>
  <DebugOverlay/>
</template>

<script setup>
import {computed, inject, onErrorCaptured, provide, toRefs} from "vue";
import {useRoute} from "vue-router";

import AuthOverlay from "@/components/auth/AuthOverlay.vue";
import DefaultLayout from "@/layouts/Default.vue";
import HomeLayout from "@/layouts/Home.vue";
import {CurrentUserInjectionKey,} from "@/plugins/symbols";
import useUser from "@/store/user.ts";
import DebugOverlay from "@/components/debug/DebugOverlay.vue";
import {useAppContext} from "@/app/stores/context";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";
import {useCategories} from "@/rental/stores/categories";
import {useAppNotifications} from "@/plugins/notifications";
import { Logger } from '@/app/logger';
import {ClientError} from "@/errors";


const context = useAppContext();
const {appService} = context;
const {snapshot} = toRefs(appService);


const appState = computed(() => snapshot.value.status);
const appContext = computed(() => snapshot.value.context);

const userStore = useUser();
const session = useSession();
const {isAuthenticated} = storeToRefs(session);
const categoriesStore = useCategories();


const layouts = {
  default: DefaultLayout,
  home: HomeLayout,
};

const route = useRoute();
const auth = inject("auth");
const notify = useAppNotifications();

const layout = computed(() => {
  return route.meta.layout || "default";
});

provide("currentUser", userStore);
provide(CurrentUserInjectionKey, userStore);

onErrorCaptured((err) => {
  if (err instanceof ClientError) {
    notify.error(err.message);
  } else {
    Logger.error(err);
  }
});

Promise.all([auth.init(), categoriesStore.fetchCategories()]).then(async () => {
  appService.send({type: "APP_INITIALIZED"});
});
appService.send({type: "INITIALIZED"});
</script>
