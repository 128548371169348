<script lang="ts" setup>
import { ref } from "vue";

const value = ref("");
</script>
<template>
  <progress
    class="progress is-primary"
    max="3"
    value="2"
  />
  <p class="title is-6 m-0 mb-5">
    Describe your experience
  </p>
  <FormKit
    type="textarea"
    v-bind="$attrs"
    validation="length:0,360"
    validation-visibility="live"
    :help="`${$attrs.modelValue ? $attrs.modelValue.length : 0} / 360`"
  />
</template>

<style lang="scss" scoped></style>
