<script lang="ts" setup>
import { ref } from "vue";

import Modal from "@/components/Modal.vue";
import PsList from "@/components/display/list/PsList.vue";
import PsListItem from "@/components/display/list/PsListItem.vue";
import Icon from "@/elements/Icon.vue";
import PsButton from "@/elements/PsButton.vue";

const isActive = ref(false);

const openModal = () => (isActive.value = true);
const closeModal = () => (isActive.value = false);
</script>

<template>
  <Modal
    v-model="isActive"
    :title="$t('listing.deposit')"
  >
    <section>
      <Icon
        is-backed
        name="deposit"
        size="large"
        variant="success"
      />
    </section>

    <p>
      In order to book this item, the owner has requested a refundable deposit be collected to
      protect against loss or damage.
    </p>

    <PsList>
      <PsListItem :icon="{ name: 'done', variant: 'success' }">
        <label>Protection against loss or damage</label>
        <p>Extra protection in the event of loss or damage.</p>
      </PsListItem>
      <PsListItem :icon="{ name: 'done', variant: 'success' }">
        <label>Your money is safe with us</label>
        <p>The short term deposit will be securely stored on Pretty Shell&apos;s account.</p>
      </PsListItem>
      <PsListItem :icon="{ name: 'done', variant: 'success' }">
        <label>Returned upon completion</label>
        <p>
          Upon completion of the rental and return of the item in compliance with the agreed terms,
          the deposit will be credited back to the renter&apos;s account.
        </p>
      </PsListItem>
    </PsList>

    <template #foot>
      <section class="is-flex is-justify-content-flex-end">
        <PsButton @click="closeModal">
          Done
        </PsButton>
      </section>
    </template>
  </Modal>

  <slot
    :close-modal="closeModal"
    :open-modal="openModal"
  >
    <PsButton
      color="secondary"
      styling="outlined"
      @click.prevent="openModal"
    >
      More information
    </PsButton>
  </slot>
</template>

<style lang="scss" scoped></style>
