<template>
  <div class="booker">
    <FormKit
      v-model="selectedDateRange"
      :available-weekdays="listing.availableWeekdays"
      :dropoff-time-range="dropoffTimeRange"
      :is-disabled-date="(d: AnyDateType) => listing.isDisabledDate(d)"
      :on-select-pickup-dropoff-time-range="onSelectPickupDropoffTimeRange"
      :pickup-drop-off-range="listing.pickupDropoffTimerange"
      :pickup-time-range="pickupTimeRange"
      :show-pickup-dropoff-time-selection="true"
      :validation-messages="{
        isValidDate: 'Selected dates are not available for this listing',
      }"
      :validation-rules="{ isValidDate: validDate }"
      type="bookrange"
      validation="isValidDate"
      validation-visibility="live"
    />
    <div
      v-if="!listing.pricingOptions || listing.pricingOptions.length === 0"
      class="my-4 has-background-light p-4"
    >
      No pricing options available.
      <span class="help">Edit this listing's pricing options first.</span>
    </div>
    <div
      v-if="!pricing"
      class="my-4 has-background-light p-4"
    >
      No pricing available.
      <span class="help">Change the dates first.</span>
    </div>
    <div v-else>
      <span class="help my-3">Fee and tax details</span>
      <div class="pricing">
        <div
          v-for="(priceRecord, idx) in pricing.rentalItems"
          :key="idx"
          class="price-record"
        >
          <label><b> <money :amount="priceRecord.ppu" /> </b><span class="help">x {{ priceRecord.units }}
            {{ $t(`units.${priceRecord.unitType}`, priceRecord.units) }}</span>
          </label>
          <money :amount="priceRecord.amount" />
        </div>

        <div class="price-record">
          <label>Taxes</label>
          <Money :amount="pricing.taxes" />
        </div>
        <div class="price-record">
          <label>Service fee</label>
          <Money :amount="pricing.fees" />
        </div>
      </div>
      <hr class="mt-4">
      <div class="pricing">
        <div class="price-record my-4 total title">
          <label class="is-4">Total</label>
          <Money :amount="subTotal" />
        </div>
      </div>
      <div class="pricing is-small my-2">
        <div
          v-if="listing.protection === 'deposit' && pricing.sumBy(CostType.DEPOSIT) > 0"
          class="price-record my-2"
        >
          <Icon name="deposit" />
          <label>
            <span class="pl-1">Deposit</span>
            <DepositModal v-slot="slotProps">
              <Icon
                is-inline
                name="outlined/help"
                variant="secondary"
                @click.prevent="slotProps.openModal"
              />
            </DepositModal>
          </label>
          <Money :amount="pricing.sumBy(CostType.DEPOSIT)" />
        </div>
        <div
          v-if="listing.protection === 'insurance' && insurancePremium"
          class="price-record my-2"
        >
          <Icon name="protection" />
          <div>
            <span><Money :amount="listing.insurancePremium" />
              <span class="help is-inline">x {{ durationInDays }} days</span></span>
            <label>
              Insurance premium
              <InsuranceModal
                v-slot="slotProps"
                :is-owner="isOwner"
              >
                <Icon
                  is-inline
                  name="outlined/help"
                  variant="secondary"
                  @click.prevent="slotProps.openModal"
                />
              </InsuranceModal>
            </label>
          </div>
          <Money :amount="insurancePremium" />
        </div>
      </div>
    </div>

    <div
      v-if="!isAuthenticated"
      class="control my-5"
    >
      <PsButton
        class="is-fullwidth"
        disabled
        v-test-id="'button-request-booking'"
      >
        Sign in to book this rental
      </PsButton>
    </div>
    <div
      v-else-if="!isOwner"
      class="control my-5"
    >
      <PsButton
        :class="loading ? 'is-loading' : null"
        :disabled="!isValid"
        class="is-fullwidth"
        color="primary"
        @click.prevent="submitBookingForm"
        v-test-id="'button-request-booking'"
      >
        Request to book
      </PsButton>
      <span class="help">A notification about your request will be sent to the owner after confirmation, they&apos;ll need to respond within 24 hours to confirm your booking.</span>
    </div>
    <div
      v-else
      class="control my-5"
    >
      <PsButton
        class="is-fullwidth"
        disabled
      >
        Can't book your own listing
      </PsButton>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useToggle } from "@vueuse/core";
import dayjs from "dayjs";
import { type Ref, computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import DepositModal from "@/components/rental/explainers/DepositModal.vue";
import InsuranceModal from "@/components/rental/explainers/InsuranceModal.vue";
import Money from "@/elements/Money.vue";

import { useBookerCosts } from "@/composables/costs";
import { useLoading } from "@/composables/loaders";
import { DEFAULT_API_MAJOR_TIME_FORMAT } from "@/constants/date";
import Formats, { type AnyDateType } from "@/helpers/formats";
import { useAppNotifications } from "@/plugins/notifications";
import {type BookingCreateParams, useBookings} from "@/rental/stores/bookings";
import useCauses from "@/store/causes";
import type { Listing } from "@/rental/stores/listings";
import { CostType } from "@/store/rental/types";
import {Logger} from "@/app/logger";

const props = withDefaults(
  defineProps<{
    listing: Listing;
    isOwner?: boolean;
    isAuthenticated?: boolean;
    selectedDates: [AnyDateType, AnyDateType];
  }>(),
  {
    selectedDates: () =>
      Formats.dateOrDatesFormatForApiWithoutTimezone([
        new Date(),
        dayjs().add(1, "day"),
      ]) as [AnyDateType, AnyDateType],
  },
);

const store = useBookings();
const causes = useCauses();
const router = useRouter();
const notify = useAppNotifications();

await causes.fetchCauses();

const [isValid, toggleIsValid] = useToggle(false);

const selectedCauseId = ref(null);
const selectedDateRange: Ref<[AnyDateType, AnyDateType]> = ref(
  props.selectedDates,
);
const pickupTimeRange: Ref<[string, string] | undefined> = ref(undefined);
const dropoffTimeRange: Ref<[string, string] | undefined> = ref(undefined);

watch(
  props.listing,
  (listing) => {
    const tr = listing.pickupDropOffTimeRange;
    const ranges: [string, string][] = tr.map((el, ix): [string, string] => {
      const t = Formats.parseMajorTime("2024-01-01 " + el);
      return [
        t.format(DEFAULT_API_MAJOR_TIME_FORMAT),
        t.add(30 - ix * 60, "minutes").format(DEFAULT_API_MAJOR_TIME_FORMAT),
      ].sort() as [string, string];
    });
    pickupTimeRange.value = ranges[0];
    dropoffTimeRange.value = ranges[0];
  },
  { immediate: true },
);

const { isLoading: loading, trigger: submitBookingForm } = useLoading(
  async () => {
    const data: BookingCreateParams = {
      listingId: props.listing.id,
      dates: selectedDateRange.value,
      renterCauseId: selectedCauseId.value,
      pickupTimeRange: pickupTimeRange.value,
      dropoffTimeRange: dropoffTimeRange.value,
    };
    try {
      const booking = await store.placeNewBooking(data);
      await router.push({
        name: "bookingCreated",
        params: {
          bookingId: booking.id,
          initialState: "created",
        },
      });
    } catch (err) {
      notify.handleException(err);
    }
  },
);

const durationInDays = computed(() => {
  const duration = Formats.getDuration(...selectedDateRange.value);
  return Math.ceil(duration.asDays()) || 1;
});

const pricing = useBookerCosts({
  listingId: props.listing.id,
  dateRange: selectedDateRange,
  pickupTimeRange,
  dropoffTimeRange,
});

const insurancePremium = computed((): number => {
  if (!props.isOwner || (props.listing.insurancePremium ?? 0) === 0) {
    return 0;
  }
  return props.listing.insurancePremium * durationInDays.value;
});

const subTotal = computed(() => {
  if (!pricing.value) {
    return 0;
  }
  return (
    pricing.value.rentalCosts +
    pricing.value.fees +
    pricing.value.taxes +
    pricing.value.insurance
  );
});

const onSelectPickupDropoffTimeRange = (newTimes: {
  pickupTimeRange?: [string, string];
  dropoffTimeRange?: [string, string];
}) => {
  pickupTimeRange.value = newTimes.pickupTimeRange;
  dropoffTimeRange.value = newTimes.dropoffTimeRange;
};

const validDate = (node: HTMLInputElement) => {
  const l = durationInDays.value + 1;
  if (l === 0) {
    return false;
  }
  const availableDayInts = Formats.daysToInts(props.listing.availableWeekdays);
  const days = [...Array(l).keys()];
  try {
    for (const ix of days) {
      const day = Formats.parse(node.value[ix]);
      if (Formats.isBefore(day, Formats.now().subtract(1, "day"))) {
        throw new Error("Invalid, day is in the past");
      }
      const dayFmt = day.format("YYYY-MM-DD");
      if (availableDayInts.indexOf(day.day()) === -1) {
        throw new Error("Invalid, weekday is not available");
      }
      if (props.listing.unavailableDays.indexOf(dayFmt) > -1) {
        throw new Error("Invalid, day is unavailable");
      }
    }
  } catch (err) {
    Logger.info("⚠", err);
    toggleIsValid(false);
    return false;
  }
  toggleIsValid(true);
  return true;
};
</script>

<style lang="scss">
.booker {
  .pricing {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .price-record {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .price,
      button {
        flex-grow: 0;
      }

      &.main label {
        font-weight: 600;
      }

      &.total {
        font-size: 1.5em;
      }

      .icon + label {
        padding-left: 0.25rem;
      }

      label {
        flex-grow: 1;
        display: flex;
        gap: 8px;
        align-items: center;

        .tag {
          margin-left: 0.75rem;
        }
      }
    }
  }

  hr {
    margin: 0;
  }
}
</style>
