import dayjs from "dayjs";
import * as yup from "yup";
import type { InferType } from "yup";

import type { CollectionModel } from "@/store/base/models";
import type { DateRange } from "@/store/browse";
import { Cause } from "@/store/causes";
import { CostType, type CostUnitType } from "@/store/rental/types";
import { type Actor, models as userModels } from "@/store/user";
import {BookingStatus} from "@/rental/types";

const STATUS_MAPPER = [
  ["new", "reserved"],
  ["requested", "requested"],
  ["review", "requested"],
  ["open", "confirmed"],
  ["pending", "confirmed"],
  ["locked", "confirmed"],
  ["ready_for_pickup", "ready for pickup"],
  ["waiting_pickup_renter", "ready for pickup"],
  ["waiting_pickup_lender", "ready for pickup"],
  ["active", "active"],
  ["waiting_deliver_renter", "returned"],
  ["waiting_deliver_lender", "returned"],
  ["returned", "returned"],
  ["completed", "completed"],
  ["closed", "completed"],
  ["disputed", "disputed"],
  ["rejected", "rejected"],
  ["canceled", "canceled"],
  ["expired", "expired"],
];

const STATUS_COLOR_MAPPER = [
  ["new", ""],
  ["review", ""],
  ["open", "success"],
  ["pending", "success"],
  ["locked", "success"],
  ["ready_for_pickup", "success"],
  ["waiting_pickup_renter", "success"],
  ["waiting_pickup_lender", "success"],
  ["active", "success"],
  ["waiting_deliver_renter", "success"],
  ["waiting_deliver_lender", "success"],
  ["returned", ""],
  ["completed", ""],
  ["closed", ""],
  ["disputed", "danger"],
  ["rejected", "danger"],
  ["canceled", "warning"],
  ["expired", "warning"],
];

const mapper = (mapped: [string, string][]) => {
  return (lookup: string) => {
    try {
      return (mapped.find((el) => el.indexOf(lookup) > -1) ?? [undefined])[1];
    } catch (err) {
      console.log(`Couldn't find status ${lookup}`);
    }
    return "unknown";
  };
};

export const MAPPERS = {
  status: mapper(STATUS_MAPPER),
  statusColor: mapper(STATUS_COLOR_MAPPER),
};

export interface BookingCost {
  id: string;
  amount: number;
  owed_by: string;
  owed_to: string;
  description: string;
  label: string;
  unit_type: CostUnitType;
  cost_type: CostType;
  units: number;
  ppu: number;
}

export const bookingSchema = yup.object({
  id: yup.string().uuid().required(),
  status: yup.string().default("new"),
  chatId: yup.string().uuid(),
  dates: yup.array(yup.date()),
  renter_intent_id: yup.string(),
  renter_insurance_enabled: yup.boolean().default(false),
  cancellation_period_ends_at: yup.date(),
  reviewed_at: yup.date(),
  created_at: yup.date(),
  cause: yup.object({
    id: yup.string(),
    image: yup.string(),
  }),
  costs: yup.array(
    yup.object({
      amount: yup.number(),
      owned_by: yup.string().uuid(),
      owned_to: yup.string().uuid(),
      cost_type: yup.string(),
      unit_type: yup.string(),
      units: yup.number(),
      ppu: yup.number(),
    }),
  ),
});

export type IBooking = InferType<typeof bookingSchema>;

export class Booking implements Partial<IBooking> {
  id: string;
  chatId?: string;
  status: string;
  dates: DateRange;
  cause?: Cause;
  renter_intent_id?: string;
  cancellationPeriodEndsAt?: Date;
  lender: Actor;
  renter: Actor;
  reviewedAt?: Date;
  listing: Listing;
  createdAt?: string;
  updated_at?: string;
  costs: BookingCost[];
  renter_insurance_enabled: boolean;
  pickup_timerange: string[];
  dropoff_timerange: string[];
  starts_at: string;
  ends_at: string;
  renter_confirmed_pickup_at?: string;
  renter_return_at?: string;
  lender_confirmed_pickup_at?: string;
  lender_return_at?: string;

  constructor(params: Booking | IBooking) {
    this.id = params.id;

    if (params instanceof Booking) {
      Object.assign(this, params);
      return;
    }

    this.status = params.state;
    this.createdAt = params.created_at;
    this.listing = params.listing;
    this.lender = new userModels.Actor(params.lender);
    this.renter = new userModels.Actor(params.renter);
    this.dates = params.dates;
    this.starts_at = params.dates[0];
    this.ends_at = params.dates[1];
    this.costs = params.costs ?? [];
    this.chatId = params.chat;
    this.cause = params.cause ? new Cause(params.cause) : undefined;
    this.renter_intent_id = params.renter_intent_id;
    this.renter_insurance_enabled = params.renter_insurance_enabled;
    this.cancellationPeriodEndsAt = params.cancellation_period_ends_at;
    this.reviewedAt = params.reviewed_at;

    this.pickup_timerange = params.pickup_timerange;
    this.dropoff_timerange = params.dropoff_timerange;

    // Pickup and return confirmations
    this.renter_confirmed_pickup_at = params.renter_confirmed_pickup_at;
    this.renter_return_at = params.renter_return_at;
    this.lender_confirmed_pickup_at = params.lender_confirmed_pickup_at;
    this.lender_return_at = params.lender_return_at;

    // reviews
    this.rating = params.rating;

    this.parseStatus();
  }

  get canCancel() {
    const now = dayjs.utc();
    return now < dayjs(this.cancellationPeriodEndsAt);
  }

  parseStatus() {
    if (this.status === "ready_for_pickup") {
      if (this.renter_confirmed_pickup_at) {
        this.status = "waiting_pickup_lender";
      }
      if (this.lender_confirmed_pickup_at) {
        this.status = "waiting_pickup_renter";
      }
    }
    if (this.status === "active") {
      if (this.renter_return_at) {
        this.status = "waiting_deliver_lender";
      }
      if (this.lender_return_at) {
        this.status = "waiting_deliver_renter";
      }
    }
  }

  localStatus() {
    return MAPPERS.status(this.status) || BookingStatus.closed;
  }

  costsBy(userId: Actor["id"], costTypes?: CostType[]) {
    let costs = this.costs;
    if (this.isOwner(userId)) {
      costs = costs.filter((el) => el.owed_to === userId);
    } else {
      costs = costs.filter((el) => el.owed_by === userId);
    }
    if (costTypes) {
      costs = costs.filter((el) => costTypes.includes(el.cost_type));
    }
    return costs;
  }

  get startsAt() {
    return `${this.starts_at} ${this.pickup_timerange[0]}`;
  }

  get endsAt() {
    return `${this.ends_at} ${this.dropoff_timerange[1]}`;
  }

  totalCosts(userId: Actor["id"], costTypes?: CostType[]) {
    const costs = this.costsBy(userId, costTypes);
    return costs.reduce((s, el) => s + el.amount, 0);
  }

  totalCostsWithoutDeposit(userId: Actor["id"]) {
    const costs = this.costsBy(userId).filter(
      (el) => el.cost_type !== CostType.DEPOSIT,
    );
    return costs.reduce((s, el) => s + el.amount, 0);
  }

  isOwner(userId: Actor["id"]) {
    return this.lender.id === userId;
  }

  isRenter(userId: Actor["id"]) {
    return this.renter.id === userId;
  }
}

export interface BookingPriceBreakDown {
  bookingId: Booking["id"];
}

export interface Review extends CollectionModel {
  booking: Booking;
  author: Actor;
  subject: Actor;
  rating: number;
  comment: string;
}
