import { useI18n } from "vue-i18n";

import { toast } from 'vue3-toastify';

import { DomainError } from "@/errors";

interface AppNotifications {
  error: (msg: string) => void;
  info: (msg: string) => void;
  success: (msg: string) => void;
  // Handles and error class so that the proper translation is applied depending on the error type
  handleException: (err: unknown) => void;
}

export const useAppNotifications = (): AppNotifications => {
  const translate = useI18n();
  return {
    error: (msg: string) => toast.error(msg),
    info: (msg: string) => toast.info(msg),
    success: (msg: string) => toast.success(msg),
    handleException: (err: unknown) => {
      if (err instanceof DomainError) {
        toast.error(translate.t(`errors.${err.tl_key}`, { msg: err.message }));
      } else if (err instanceof Error) {
        console.error(err);
        toast.error(translate.t("errors.domain-error"));
      } else {
        console.error(err);
      }
    },
  };
};
