<script lang="ts" setup>
import BookingCard from "@/components/BookingCard.vue";
import EmptyState from "@/components/EmptyState.vue";
import StripeLoginButton from "@/components/account/transactions/StripeLoginButton.vue";
import SectionTitle from "@/components/display/SectionTitle.vue";
import CTAVerifyIdentity from "@/components/transactions/CTAVerifyIdentity.vue";
import PayoutStatus from "@/components/transactions/PayoutStatus.vue";
import Avatar from "@/elements/Avatar.vue";

import Formats from "@/helpers/formats";
import { usePayoutAccount } from "@/store/payments/account";
import { useTransactionStore } from "@/store/transactions";
import { computed } from "vue";

const store = useTransactionStore();
await store.sync();

const payoutAccountStore = usePayoutAccount();
payoutAccountStore.sync();

const payoutSchedule = computed(() => {
  const schedule = payoutAccountStore.account?.settings.payouts.schedule;
  return `every ${schedule?.delay_days} ${schedule?.interval === "daily" ? "days" : "weeks"}`;
});
</script>

<template>
  <p class="title is-3">
    Transactions
  </p>
  <div class="section py-5">
    <p>Your balance</p>
    <p
      v-if="store.isLoading"
      class="title is-4"
    >
      Loading
    </p>
    <p
      v-else
      class="title is-4"
    >
      <Money
        :amount="!store.totalIn ? 0 : store.totalIn / 100"
        colored
      />
    </p>
    <section class="is-justify-content-start is-align-items-center flex-gap-1 my-4">
      <StripeLoginButton
        v-if="false"
        :disabled="!payoutAccountStore.account?.onboarded"
        color="secondary"
      >
        Pay out now
      </StripeLoginButton>
      <p v-if="!payoutAccountStore.account?.onboarded">
        Verify your identity to enable payouts for your account
      </p>
    </section>

    <div class="section my-6">
      <SectionTitle>
        Payouts
        <template #actions>
          <PsButton
            color="secondary"
            disabled
          >
            Export
          </PsButton>
          <PsButton
            color="secondary"
            disabled
          >
            Show all payouts
          </PsButton>
        </template>
      </SectionTitle>
      <CTAVerifyIdentity v-if="!payoutAccountStore.account?.onboarded" />
      <EmptyState
        v-else
        size="small"
      >
        <p>You have no payouts yet.</p>
        <p>Your payout schedule is set to <b>{{ payoutSchedule }}</b></p>
      </EmptyState>
    </div>

    <SectionTitle>
      Recent payments
      <template #actions>
        <PsButton
          color="secondary"
          disabled
        >
          Export
        </PsButton>
        <PsButton
          color="secondary"
          disabled
        >
          Show all payments
        </PsButton>
      </template>
    </SectionTitle>
    <table
      v-if="!store.isEmpty && store.items.length > 0"
      class="table is-fullwidth"
    >
      <thead>
        <tr>
          <th>From</th>
          <th>
            <span class="is-flex is-align-items-center">
              Date
              <Icon
                is-inline
                name="outlined/expand_more"
                variant="secondary"
              />
            </span>
          </th>
          <th>Booking</th>
          <th>State</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in store.items"
          :key="item.id"
        >
          <td>
            <div class="is-inline-flex is-align-items-center flex-gap-1">
              <Avatar :user="item.user" />
              {{ item.user.fullname }}
            </div>
          </td>
          <td>
            {{ Formats.dateTimeLocalFormat(item.createdAt, 'MMM D, hh:mm A') }}
          </td>
          <td>
            <BookingCard
              v-if="item.booking"
              :booking="item.booking"
              click-target="booking"
              is-clickable
              orientation="horizontal"
              show-booking-period
              :show-renter="false"
              :show-price="false"
              size="tiny"
            />
          </td>
          <td>
            <PayoutStatus
              v-if="item.type === 'payout'"
              :reason="item.statusReason"
              :status="item.status"
            />
            <span v-else>{{ item.status }}</span>
          </td>
          <td :class="item.type">
            {{ Formats.money({ value: item.amount / 100 }) }}
          </td>
        </tr>
      </tbody>
    </table>
    <EmptyState v-else>
      You have no transactions yet. Transactions will appear here when you have rented out your
      first item.
    </EmptyState>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/mixins.scss';

table.table td,
table.table th {
  padding: 1em;
  vertical-align: middle;
  max-width: 240px;
  text-overflow: ellipsis;

  @include touch {
    max-width: 100%;
  }
}

td.payment {
  color: var(--red-500);
}

td.payout {
  color: var(--green-500);
}

.totals > .tile {
  margin: 2em 0;
  gap: 16px;
}
</style>
