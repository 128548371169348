import { type Store, createPinia } from "pinia";
import { type App, inject } from "vue";

import { StoresInjectionKey } from "@/plugins/symbols";

const pinia = createPinia();

export interface IStore {
  resetAll(): void;
}

const _Stores = (stores: Store[]) => {
  return {
    resetAll: () => {
      stores.forEach((store) => {
        store.$reset();
      });
    },
  };
};

export default {
  install(app: App) {
    const stores: Store[] = [];

    app.use(pinia);

    app.provide(StoresInjectionKey, _Stores(stores));
  },
};

export const useStores = (): IStore => {
  const result = inject(StoresInjectionKey);
  if (!result) throw "Misconfigured, no stores";
  return result;
};
