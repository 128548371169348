<template>
  <p class="title is-3">
    My listings
  </p>

  <div v-if="store.loading">
    <LoadingFullSection />
  </div>
  <CardGallery v-else>
    <template #modifiers>
      <Filtering
        :active-item="currentFilterId"
        :items="filters"
        @filtered="handleFilterChange($event)"
      />
    </template>
    <template #default="slotProps">
      <EmptyState v-if="!currentFilterGroup?.items?.length">
        <p>
          You don&apos;t have any "{{ currentFilterGroup?.label }}" listings yet.<br>
          {{
            currentFilterGroup?.label === 'Published'
              ? "Publish on of your drafts or hit the 'New Listing' button below to get started!"
              : ''
          }}
          {{
            currentFilterGroup?.label === 'Drafts'
              ? "Hit the 'New Listing' button below to get started!"
              : ''
          }}
        </p>
        <div class="field is-grouped">
          <p class="control">
            <PsButton color="secondary">
              Browse listings
            </PsButton>
          </p>
          <p class="control">
            <PsButton
              icon="list"
              @click="gotoCreateListing"
            >
              New listing
            </PsButton>
          </p>
        </div>
      </EmptyState>
      <ListingCard
        v-for="listing in currentFilterGroup.items"
        v-else
        :key="listing.id"
        :listing="listing"
        :orientation="slotProps.orientation"
        :show-favorite-button="false"
      >
        <template #actions>
          <div
            class="listing-actions full-height is-flex is-align-items-center is-justify-content-center flex-gap-1 is-flex-wrap-wrap"
          >
            <PsButton
              color="secondary"
              @click.prevent="gotoListingEditor(listing.id)"
            >
              Edit
            </PsButton>
          </div>
        </template>
      </ListingCard>
    </template>
  </CardGallery>
</template>

<script lang="ts" setup>
import { type Ref, computed, ref } from "vue";
import { useRouter } from "vue-router";

import CardGallery from "@/components/CardGallery.vue";
import EmptyState from "@/components/EmptyState.vue";
import Filtering from "@/components/Filtering.vue";
import ListingCard from "@/rental/components/ListingCard.vue";
import LoadingFullSection from "@/sections/LoadingFull.vue";

import {usePrivateListings} from "@/rental/stores/listings";

const router = useRouter();
const store = usePrivateListings();

const filters = [
  {
    id: 0,
    label: "Published",
    items: () => store.getByStatus("active"),
  },
  {
    id: 1,
    label: "Drafts",
    items: () => store.getByStatus("draft"),
  },
];

const currentFilterId: Ref<string> = ref(
  filters.find((filter) => !!filter.items?.length)?.id ?? filters[0].id,
);

const currentFilterGroup = computed(() => {
  const foundGroup = filters.find(
    (filter) => filter.id === currentFilterId.value,
  );

  return {
    ...foundGroup,
    items:
      typeof foundGroup.items === "function"
        ? foundGroup.items()
        : foundGroup.items,
  };
});

function handleFilterChange(filterId: string) {
  currentFilterId.value = filterId;
}

function gotoCreateListing() {
  router.push({
    name: "createListing",
  });
}

function gotoListingEditor(listingId: string) {
  router.push({
    name: "updateListing",
    params: { listingId },
  });
}
</script>

<style lang="scss" scoped></style>
