export class PhoneNumber {
  region?: string;
  area?: string;
  local?: string;

  constructor(value: PhoneNumber) {
    this.region = String(value.region || 1).replace(/\+/, "");
    this.area = String(value.area);
    this.local = value.local?.replaceAll(/[- ]/gi, "");
  }

  get local1() {
    if (!this.local) return undefined;
    return String(this.local).slice(0, 3);
  }

  get local2() {
    if (!this.local) return undefined;
    return String(this.local).slice(3, 7);
  }

  static fromString(value: string) {
    const matches = /(?<region>\+\d)? (?<area>\d+)-(?<local>[\d -]+)/.exec(
      value,
    );
    if (!matches || !matches.groups) {
      return;
    }
    const groups: PhoneNumber = matches.groups as unknown as PhoneNumber;
    return new PhoneNumber(groups);
  }

  toString() {
    if (this.local) {
      return `+${this.region} (${this.area}) ${this.local}`;
    }
    return undefined;
  }

  serialize() {
    if (this.local) {
      return `+${this.region} ${this.area}-${this.local1}-${this.local2}`;
    }
    return undefined;
  }
}
