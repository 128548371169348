<template>
  <svg
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.53554 10.95L6 7.41443L2.46447 10.95C2.07556 11.3389 1.43917 11.3389 1.05026 10.95C0.661348 10.5611 0.661348 9.92466 1.05026 9.53575L4.58579 6.00022L1.05026 2.46468C0.661348 2.07578 0.661348 1.43938 1.05026 1.05047C1.43917 0.661562 2.07556 0.661562 2.46447 1.05047L6 4.586L9.53554 1.05047C9.92445 0.661562 10.5608 0.661562 10.9498 1.05047C11.3387 1.43938 11.3387 2.07578 10.9498 2.46468L7.41422 6.00022L10.9498 9.53575C11.3387 9.92466 11.3387 10.5611 10.9498 10.95C10.5608 11.3389 9.92445 11.3389 9.53554 10.95Z"
    />
  </svg>
</template>
