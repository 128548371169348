<script setup lang="ts"></script>

<template>
  <progress
    class="progress is-primary"
    value="1"
    max="2"
  />
  <div class="rating">
    <p class="title is-6 m-0 mb-5">
      How many stars would you give?
    </p>
    <div class="is-flex is-justify-content-center">
      <FormKit
        type="rating"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.rating {
  text-align: center;
  margin: 2em 0;
}
</style>
