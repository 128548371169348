<template>
  <span :class="classes">{{ formatMoney({ value: props.amount }) }}</span>
</template>
<script lang="ts" setup>
import { computed } from "vue";

import Formats from "@/helpers/formats";

const props = defineProps<{
  amount?: number;
  colored?: boolean;
}>();

type AmountVariantType = -1 | 0 | 1;

const formatMoney = Formats.money;
const variant = computed((): AmountVariantType => {
  if (props.amount === undefined || props.amount === 0) {
    return 0;
  }
  if (props.amount < 0) {
    return -1;
  }
  return 1;
});

const classes = computed(() => {
  const result = ["price"];
  if (props.colored) {
    switch (variant.value) {
      case -1:
        result.push("is-danger");
        break;
      case 1:
        result.push("is-success");
        break;
    }
  }
  return result;
});
</script>
<style scoped>
.price {
  font-weight: 600;
}

.price.is-success {
  color: var(--green-500);
}
.price.is-danger {
  color: var(--red-500);
}
</style>
