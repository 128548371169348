import { computed } from "vue";

export const useClasses = <T extends Record<string, boolean | string>>(
  inputs: T,
  mapping: Record<keyof T, string | Record<string, string>>,
  defaults: string[] = [],
) => {
  return computed(() => {
    const result = Object.entries(mapping)
      .map(([key, value]) => {
        if (inputs[key]) {
          const candidate = inputs[key];
          if (typeof candidate === "boolean" && candidate) {
            return value;
          } else if (
            typeof candidate === "string" &&
            typeof value === "object"
          ) {
            return value[candidate];
          }
        }
      })
      .filter((el) => el ?? false);
    return [...defaults, ...result];
  });
};
