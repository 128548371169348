<template>
  <div :class="{ 'rating-input': true, 'read-only': context.readOnly }">
    <Icon
      v-for="ix in Array(5).keys()"
      :key="ix"
      :variant="variant(ix)"
      name="base/star"
      size="medium"
      @click="handleSelect(ix)"
      @mouseout="handleHover(null)"
      @mouseover="handleHover(ix)"
    />
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      nodeId: this.$id(),
      hovering: null,
    };
  },
  methods: {
    variant(idx) {
      const ix = Number(idx) + 1;
      if (!this.hovering && !this.context.value) {
        return "secondary";
      }
      if (this.hovering !== null) {
        if (Number(ix) <= this.hovering) {
          return "primary";
        }
      } else if (Number(ix) <= this.context.value) {
        return "primary";
      }
      return "secondary";
    },
    handleSelect(val) {
      this.context.node.input(Number(val) + 1);
    },
    handleHover(ix) {
      if (this.context.readOnly) {
        this.hovering = null;
        return;
      }
      this.hovering = ix !== null ? Number(ix) + 1 : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-input {
  display: flex;

  span.icon {
    cursor: pointer;
  }

  &.read-only span.icon {
    cursor: default;
  }
}
</style>
