<template>
  <div class="menu">
    <ul class="menu-list">
      <li>
        <router-link
          :to="{ name: 'accountBookings' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            :name="
              isRouteMatched('accountBookings') ? 'base/calendar_today' : 'outlined/calendar_today'
            "
            is-inline
            variant="dark"
          />
          <span>Bookings</span>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'accountFavorites' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            :name="isRouteMatched('accountFavorites') ? 'base/favorite' : 'outlined/favorite'"
            is-inline
            variant="dark"
          />
          <span>Saved listings</span>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'accountNotifications' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            :name="
              isRouteMatched('accountNotifications')
                ? 'base/notifications'
                : 'outlined/notifications'
            "
            is-inline
            variant="dark"
          />
          <span>Notifications</span>
          <PsButton
            v-if="notifications.hasNew"
            class="ml-auto"
            is-pill
            is-rounded
            size="small"
          >
            {{ notifications.newCount }}
          </PsButton>
        </router-link>
      </li>

      <li v-if="showMessages">
        <router-link
          :to="{ name: 'messages' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            :name="isRouteMatched('messages') ? 'base/forum' : 'outlined/forum'"
            is-inline
            variant="dark"
          />
          <span>Messages</span>
        </router-link>
      </li>
    </ul>

    <hr v-if="hasDividers">

    <p class="menu-label">
      Renting
    </p>

    <ul class="menu-list">
      <li>
        <router-link
          :to="{ name: 'accountListings' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            :name="isRouteMatched('accountListings') ? 'base/view_agenda' : 'outlined/view_agenda'"
            is-inline
            variant="dark"
          />
          <span>My listings</span>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'accountTransactions' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            :name="
              isRouteMatched('accountTransactions') ? 'base/receipt_long' : 'outlined/receipt_long'
            "
            is-inline
            variant="dark"
          />
          <span>{{ $t('account.transactions') }}</span>
        </router-link>
      </li>
    </ul>

    <hr v-if="hasDividers">

    <p class="menu-label">
      Account
    </p>

    <ul class="menu-list">
      <li>
        <router-link
          :to="{ name: 'accountProfile' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            :name="
              isRouteMatched('accountProfile') ? 'base/account_circle' : 'outlined/account_circle'
            "
            is-inline
            variant="dark"
          />
          <span>Profile</span>
        </router-link>
      </li>
      <li>
        <a @click="logOut">
          <Icon
            is-inline
            name="signout"
            variant="dark"
          />
          <span>Sign out</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

import {useAppNotifications} from "@/plugins/notifications";
import {useNotifications} from "@/account/stores/notifications";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";

const router = useRouter();
const route = useRoute();
const session = useSession();
const {actor} = storeToRefs(session);

const notifications = useNotifications();
const notify = useAppNotifications();

const emits = defineEmits(["closeSidebarNav"]);

defineProps({
  hasDividers: {
    type: Boolean,
    default: false,
  },
  showMessages: {
    type: Boolean,
    default: false,
  },
});

async function logOut() {
  emits("closeSidebarNav");

  await session.signOut(() => {
    if (!isRouteMatched("home")) {
      router.push("/");
    }
    notify.info("You are logged out successfully");
  });
}

function isRouteMatched(routeName) {
  return route.matched.some(({name}) => name === routeName);
}
</script>

<style lang="scss" scoped></style>
