/**
 * @jest-environment jsdom
 */
import DOMPurify from 'dompurify';
import {marked} from 'marked';
import {computed, MaybeRefOrGetter, type Ref, toRef} from "vue";


export const useMarkdown = (s: MaybeRefOrGetter<string>) => {
  const purify = DOMPurify(window);
  const r = toRef(s);
  return computed(() => {
    return purify.sanitize(marked.parse(r.value) as string);
  });
};
