<template>
  <div :class="classes">
    <div class="overlay">
      <Icon
        :name="category.icon"
        size="large"
        variant="inherit"
      />
      <label>{{ category.name }}</label>
    </div>
    <img
      v-if="hasMedia"
      class="category-image"
      :src="category.media"
    >
  </div>
</template>

<script>
export default {
  name: "CategoryCard",
  props: {
    category: {
      type: Object,
    },
    isActive: Boolean,
  },
  computed: {
    classes() {
      const result = ["listing-category"];
      if (this.hasMedia) {
        result.push("category-with-media");
      } else {
        result.push("category-without-media");
      }
      if (this.isActive) {
        result.push("is-active");
      }
      return result;
    },
    hasMedia() {
      return this.category.media && this.category.media.length > 0;
    },
  },
};
</script>

<style scoped>
.listing-category {
  box-sizing: border-box;
  height: 220px;
  width: 164px;
  background-color: #000;
  flex: 0 0 164px;
  overflow: hidden;
  position: relative;
  color: #fff;
  box-shadow: 0px 4px 8px -6px rgba(6, 30, 62, 0.1);
  border-radius: 8px;
  transition: 0.15s;
  margin: 8px 0;
}

.listing-category.is-active {
  border: 3px solid var(--primary);
}

.listing-category.category-without-media {
  background-color: var(--gray-mist);
  color: var(--gray-slate);
}

.listing-category::after {
  content: '';
  position: absolute;
  border-radius: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 1px solid rgba(6, 30, 62, 0.1);
  z-index: 1;
}

.listing-category:hover::after {
  border-color: rgba(6, 30, 62, 0.2);
}

.listing-category:focus-visible {
  outline: 2px solid #828e9f;
  outline-offset: 2px;
  transform: translateY(-4px);
  box-shadow: 0px 4px 12px -2px rgba(6, 30, 62, 0.2);
}

.listing-category:active {
  transform: translateY(0px);
  box-shadow: 0px 4px 8px -6px rgba(6, 30, 62, 0.1);
}

.listing-category .icon {
  font-size: 48px;
  width: 1em;
  height: 1em;
  line-height: 0 !important;
}

.listing-category img.category-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.listing-category:hover img {
  transform: scale(1.05) translateY(-4px);
  opacity: 0.8;
}

.listing-category .overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 2;
  padding: 16px;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.listing-category.category-without-media .overlay {
  background: transparent;
  color: var(--gray-slate);
}

.listing-category:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 12px -2px rgba(6, 30, 62, 0.2);
}

.listing-category .overlay label {
  color: var(--gray-snow);
  font-weight: 600;
  text-align: center;
  min-height: 25%;
}
.listing-category.category-without-media .overlay label {
  color: var(--gray-slate);
}
</style>
