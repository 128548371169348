import { deserialize, serialize } from "./helpers";
import ListingIO from "./listings";

const serializer = (d) => {
  d.dates = d.dates ? d.dates.map(serialize.date) : undefined;
  return d;
};

const deserializer = (d) => {
  const result = { ...d };
  result.listing = d.listing ? ListingIO.deserializer(d.listing) : undefined;
  result.costs = d.costs.map((el) => ({
    ...el,
    amount: deserialize.cents_amount(el.amount),
    ppu: deserialize.cents_amount(el.ppu),
    donation_amount: deserialize.cents_amount(el.donation_amount),
  }));
  return result;
};

export default {
  serializer,
  deserializer,
};
