<script setup lang="ts"></script>

<template>
  <div class="section-title">
    <p class="title is-4">
      <slot />
    </p>
    <div class="section-title-actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-title {
  display: flex;
  justify-content: space-between;
  margin: 0.8rem 0;
  .section-title-actions {
    display: flex;
    gap: 0.5rem;
  }
}
</style>
