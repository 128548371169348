<script lang="ts" setup></script>

<template>
  <div class="panel-select">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.panel-select {
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  grid-gap: 16px;
  margin: 2em 0;
}
</style>
