import {useI18n} from "vue-i18n";

export const useTranslationOptions = (key: string) => {
  const { tm } = useI18n();
  const translationObj = tm(key);
  return Object.entries(translationObj).map(([value, label]) => ({
    label,
    value
  }))
}
