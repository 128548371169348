<script lang="ts" setup>
import { ref } from "vue";

import { useLoading } from "@/composables/loaders";
import { usePayoutAccount } from "@/store/payments/account";
import PayoutStatus from "@/components/transactions/PayoutStatus.vue";

const { getOnboardingLink } = usePayoutAccount();

const buttonRef = ref();
const { isLoading, trigger } = useLoading(async () => {
  const url = await getOnboardingLink();
  window.location.href = url;
});
</script>

<template>
  <section class="cta verify-identity">
    <img src="/static/visuals/sheldon-payouts.png">
    <div class="body">
      <p class="has-text-weight-bold my-2">
        Verify your identity to enable payouts
      </p>
      <p>
        We'll ask a few questions to verify your identity. This information is needed to enable
        payouts on your account. Payments towards you will stay in status <PayoutStatus status="waiting" />.
      </p>
    </div>
    <div class="actions">
      <PsButton
        ref="buttonRef"
        color="success"
        :is-loading="isLoading"
        @click="trigger()"
      >
        Verify your identity
      </PsButton>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '../../styles/variables';

.cta {
  display: flex;
  background-color: $gray-mist;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 1.8rem;

  > div {
    padding: 1.5rem 0;
  }
}
</style>
