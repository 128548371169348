<template>
  <p class="title">
    Payment
  </p>
  <div v-if="mode === 'SELECT'">
    <p class="mb-4">
      Select an payment option below to complete the booking. After your payment we will send the
      booking request to the owner.
    </p>

    <div class="payment-method-select">
      <div
        v-for="pmItem in paymentMethodSelect.items"
        :key="pmItem.pm.id"
        :class="pmItem.classes"
        @click="pmItem.select"
      >
        <Icon
          v-show="pmItem.selected"
          name="base/check_circle"
          variant="primary"
        />
        <Icon
          v-show="!pmItem.selected"
          name="outlined/circle"
          variant="secondary"
        />
        <PaymentMethod :payment-method="pmItem.pm" />
      </div>

      <AddPaymentMethodModal
        v-slot="slotProps"
        @updated="updateList"
      >
        <div
          class="has-background-white"
          @click="slotProps.toggle()"
        >
          <Icon
            name="outlined/add"
            variant="secondary"
          />
          <p>Add new payment method</p>
        </div>
      </AddPaymentMethodModal>
    </div>

    <div
      class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap is-justify-content-end mt-4 px-8"
    >
      <PsButton
        color="secondary"
        @click.prevent="emit('previous')"
      >
        <Icon
          name="base/chevron_left"
          variant="inherit"
        />
        Back
      </PsButton>
      <PsButton @click.prevent="gotoSuccess">
        Next: choose a cause
      </PsButton>
    </div>
  </div>
  <div v-else>
    <AddPaymentMethod
      v-if="setupIntent"
      :client-secret="setupIntent?.client_secret"
    >
      <template #actions="slotProps">
        <PsButton
          color="secondary"
          @click.prevent="emit('previous')"
        >
          <Icon
            name="base/chevron_left"
            variant="inherit"
          />
          Back
        </PsButton>
        <PsButton
          :disabled="!slotProps.isReady"
          color="primary"
          @click="handlePayment(slotProps.onSubmit)"
        >
          Add payment method
        </PsButton>
      </template>
    </AddPaymentMethod>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import AddPaymentMethod from "@/components/transactions/AddPaymentMethod.vue";
import AddPaymentMethodModal from "@/components/transactions/AddPaymentMethodModal.vue";
import PaymentMethod from "@/components/transactions/PaymentMethod.vue";
import Icon from "@/elements/Icon.vue";
import PsButton from "@/elements/PsButton.vue";

import { PaymentMethod as PMModel } from "@/store/PaymentMethods";
import usePayments, { type SetupIntent } from "@/store/payments";
import type { Booking } from "@/rental/stores/bookings";

const emit = defineEmits(["previous", "next", "update:modelValue"]);

defineProps<{
  booking: Booking;
  modelValue?: SetupIntent;
}>();

const store = usePayments();

const setupIntent = ref(await store.getIntent());
const setupIntents = ref([] as SetupIntent[]);

const updateList = async () => {
  setupIntents.value = await store
    .listIntents()
    .then((items) => items.filter((el) => el.payment_method));
};
await updateList();

const mode = ref(setupIntents.value.length > 0 ? "SELECT" : "ADD");
if (
  setupIntent.value.status === "succeeded" &&
  setupIntents.value.length === 0
) {
  setupIntent.value = await store.createIntent();
}
const selectedSetupIntent = ref<SetupIntent | undefined>(undefined);

const getPaymentMethodExpiredStatus = (paymentMethod?: PMModel) => {
  if (!paymentMethod) {
    return false;
  }

  const expYear = paymentMethod.details?.exp_year ?? undefined;
  const expMonth = paymentMethod.details?.exp_month ?? undefined;

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Note: getMonth is indexed 0

  if (expYear && currentYear > expYear) {
    return true;
  } else if (
    expYear &&
    expMonth &&
    currentYear === expYear &&
    currentMonth >= expMonth
  ) {
    return true;
  }
  return false;
};

const paymentMethodSelect = computed(() => {
  const items = setupIntents.value.map((setupIntentItem: SetupIntent) => {
    const item = new PMModel(setupIntentItem.payment_method, setupIntent.value);
    const isExpired = getPaymentMethodExpiredStatus(item);
    return {
      si: setupIntentItem,
      pm: item,
      select: isExpired
        ? () => {}
        : () => (selectedSetupIntent.value = setupIntentItem),
      selected: selectedSetupIntent.value?.id === setupIntentItem.id,
      classes: [
        selectedSetupIntent.value?.id === setupIntentItem.id
          ? "is-selected"
          : null,
        isExpired ? "is-expired" : "",
      ],
      isExpired,
    };
  });
  return {
    items,
  };
});

const gotoSuccess = () => {
  emit("update:modelValue", selectedSetupIntent.value);
  emit("next", { query: { si: selectedSetupIntent.value?.id } });
};

const handlePayment = async (
  submitPaymentMethod: () => Promise<SetupIntent>,
) => {
  setupIntent.value = await submitPaymentMethod();
  selectedSetupIntent.value = setupIntent.value;
  gotoSuccess();
};
</script>

<style lang="scss" scoped>
.payment-method-select {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;

  & > div {
    display: flex;
    padding: 12px 24px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px;
    gap: 1em;
    border: 2px solid var(--gray-smoke, #e5ecf4);

    &.is-selected {
      border: 2px solid var(--peach-peach-500, #ff6d65);
      background: var(--neutrals-solid-snow, #fff);
    }

    &.is-expired {
      background: var(--neutrals-solid-mist, #f7f9fc);
      cursor: not-allowed;
    }
  }
}
</style>
