<template>
  <slot />
  <span
    v-if="props.protection"
    :class="`protection has-text-${iconVariant}`"
  >
    <Icon
      :name="iconName"
      :variant="iconVariant"
      class="mx-1"
      size="small"
    /><span
      v-show="!hideLabel"
    >{{ protection }}</span>
  </span>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type {ProtectionType} from "@/rental/types";


const valToIconName = {
  insurance: "protection",
  deposit: "deposit",
};
const valToIconVariant = {
  insurance: "primary",
  deposit: "success",
};

const props = defineProps({
  protection: {
    type: String as PropType<ProtectionType>,
    default: "none",
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
});

const iconName = valToIconName[props.protection];
const iconVariant = valToIconVariant[props.protection];
</script>

<style scoped lang="scss">
.protection {
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;

  .icon + span {
    margin-left: 4px;
  }
}
</style>
