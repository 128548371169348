<template>
  <div class="card-gallery">
    <div class="gallery-head">
      <slot name="modifiers">
        <div />
      </slot>

      <div
        v-if="!hideControls"
        class="controls"
      >
        <PsButton
          :color="view === 'list' ? 'primary' : 'secondary'"
          styling="ghost"
          icon="outlined/list"
          @click="setView('list')"
        >
          <span>List</span>
        </PsButton>
        <PsButton
          :color="view === 'grid' ? 'primary' : 'secondary'"
          styling="ghost"
          icon="outlined/grid_view"
          @click="setView('grid')"
        >
          <span>Grid</span>
        </PsButton>
      </div>
    </div>
    <div :class="classes">
      <slot :orientation="orientation" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

withDefaults(
  defineProps<{
    hideControls?: boolean;
  }>(),
  {
    hideControls: false,
  },
);
const view = ref("list");

const classes = computed(() => {
  return ["results", `is-${view.value}`];
});

const orientation = computed(() => {
  return view.value === "list" ? "horizontal" : "vertical";
});

const setView = (newView: string) => {
  view.value = newView;
};
</script>

<style scoped lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.card-gallery {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .gallery-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include touch {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .controls {
      display: flex;
      gap: 8px;
    }
  }

  .results {
    display: flex;

    &.is-list {
      flex-direction: column;
      gap: 36px;
    }

    &.is-grid {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 16px;
    }
  }
}
</style>
