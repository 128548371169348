<template>
  <footer>
    <div class="container">
      <div class="columns columns-top is-multiline-mobile">
        <div class="column is-one-quarter is-full-mobile">
          <h3 class="title is-size-6 my-0 mb-2">
            <router-link :to="{ name: 'helpInformation' }">
              <span>Help & Information</span>
            </router-link>
          </h3>
          <ul>
            <li>
              <router-link :to="{ name: 'termsConditionsInsurance' }">
                <span>Pretty Shell Insurance</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div class="column is-one-quarter is-full-mobile">
          <h3 class="title is-size-6 my-0 mb-2">
            <router-link :to="{ name: 'causes' }">
              <span>Our causes</span>
            </router-link>
          </h3>
          <ul>
            <li>
              <router-link :to="{ name: 'causes' }">
                <span>What drives us</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div class="column is-one-quarter is-full-mobile">
          <h3 class="title is-size-6 my-0 mb-2">
            <router-link :to="{ name: 'process' }">
              <span>Our process</span>
            </router-link>
          </h3>
          <ul>
            <li>
              <router-link :to="{ name: 'process' }">
                <span>Transparency</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div
          v-if="!!appDownloadUrlAppleStore || !!appDownloadUrlGooglePlayStore"
          class="column is-one-quarter is-full-mobile"
        >
          <h3 class="title is-size-6 my-0 mb-2">
            Get the App
          </h3>
          <div class="is-flex is-align-content-center flex-gap-1 is-flex-wrap-wrap">
            <a
              v-if="!!appDownloadUrlAppleStore"
              :href="appDownloadUrlAppleStore"
              target="_blank"
            ><img src="/app-store.svg"></a>
            <a
              v-if="!!appDownloadUrlGooglePlayStore"
              :href="appDownloadUrlGooglePlayStore"
              target="_blank"
            ><img src="/google-play-store.svg"></a>
          </div>
        </div>
      </div>
      <hr>
      <div class="columns columns-bottom is-multiline-mobile">
        <div class="column is-full-mobile">
          <ul>
            <li>
              <span>&copy; Copyright Pretty Shell Inc. {{ new Date().getFullYear() }}</span>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'home' }">
                <span>Contact us</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'home' }">
                <span>Terms of service</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'home' }">
                <span>Privacy policy</span>
              </router-link>
            </li> -->
          </ul>
        </div>
        <div
          class="column columns-bottom-buttons is-flex is-align-content-center is-align-self-flex-end-desktop has-text-right-desktop is-full-mobile"
        >
          <PsButton
            icon="web"
            color="secondary"
            styling="ghost"
            size="small"
            disabled
          >
            English
          </PsButton>
          <PsButton
            icon="currencyUsd"
            color="secondary"
            styling="ghost"
            size="small"
            disabled
          >
            USD
          </PsButton>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import {
  APP_DOWNLOAD_URL_APPLE_STORE,
  APP_DOWNLOAD_URL_GOOGLE_PLAY_STORE,
} from "@/constants/generic";

const appDownloadUrlAppleStore = APP_DOWNLOAD_URL_APPLE_STORE;
const appDownloadUrlGooglePlayStore = APP_DOWNLOAD_URL_GOOGLE_PLAY_STORE;
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $gray-mist;
  color: $gray-slate;
  font-size: 14px;
  line-height: 32px;
  padding: 3em 0;
  margin-top: 2em;

  a {
    color: $gray-slate !important;

    &:hover,
    &:active {
      color: $gray-muted !important;
    }
  }

  h3 {
    line-height: 32px;
  }

  .columns-bottom {
    .columns-bottom-buttons {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @include desktop {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        button:nth-child(n + 2)::before {
          content: '•';
          padding: 0 1em 0 0;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @include desktop {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        li:nth-child(n + 2)::before {
          content: '•';
          padding: 1em;
        }
      }
    }
  }
}
</style>
