<script lang="ts" setup>
import { ref } from "vue";

import Modal from "@/components/Modal.vue";
import PsList from "@/components/display/list/PsList.vue";
import PsListItem from "@/components/display/list/PsListItem.vue";
import Icon from "@/elements/Icon.vue";
import PsButton from "@/elements/PsButton.vue";

defineProps({
  isOwner: {
    type: Boolean,
    default: false,
  },
});

const isActive = ref(false);

const openModal = () => (isActive.value = true);
const closeModal = () => (isActive.value = false);
</script>

<template>
  <Modal
    v-model="isActive"
    :title="$t('listing.insurance')"
  >
    <section>
      <Icon
        is-backed
        name="protection"
        size="large"
      />
    </section>

    <p v-if="isOwner">
      The Owner Policy provides coverage for rental transactions where the rented item is lost,
      stolen, or damaged.
    </p>
    <p v-else>
      The Renter Policy provides coverage if you accidentally lose or damage the item while it is
      being rented.
    </p>

    <PsList v-if="isOwner">
      <PsListItem :icon="{ name: 'protection' }">
        <label>Protection up to $4000</label>
        <p>
          Coverage will reimburse the owner based on the market value of similar items up to a
          maximum of $4,000, with a $250 deductible.
        </p>
      </PsListItem>
      <PsListItem :icon="{ name: 'protection' }">
        <label>Liability</label>
        <p>Insurance excludes liability coverage.</p>
      </PsListItem>
      <PsListItem :icon="{ name: 'protection' }">
        <label>Protects Owner</label>
        <p>
          This coverage protects the owner and is secondary to other policies and loss mitigation
          efforts.
        </p>
      </PsListItem>
    </PsList>
    <PsList v-else>
      <PsListItem :icon="{ name: 'protection' }">
        <label>Protection up to $4000</label>
        <p>
          Coverage is based on the market value of similar items and limited to a maximum of $4,000
          after a deductible of $250.
        </p>
      </PsListItem>
      <PsListItem :icon="{ name: 'protection' }">
        <label>Liability</label>
        <p>
          The renter is still obligated to fulfill their duty to the owner and this policy will
          reimburse the renter in the event a claim is approved.
        </p>
      </PsListItem>
      <PsListItem :icon="{ name: 'protection' }">
        <label>Protects renter</label>
        <p>
          This coverage protects the renter and is secondary to other policies and loss mitigation
          efforts.
        </p>
      </PsListItem>
    </PsList>

    <template #foot>
      <section class="is-flex is-justify-content-flex-end">
        <PsButton @click="closeModal">
          Done
        </PsButton>
      </section>
    </template>
  </Modal>

  <slot
    :close-modal="closeModal"
    :open-modal="openModal"
  >
    <PsButton
      color="secondary"
      styling="outlined"
      @click.prevent="openModal"
    >
      More information
    </PsButton>
  </slot>
</template>

<style lang="scss" scoped></style>
