<script setup lang="ts"></script>

<template>
  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    <Icon
      name="outlined/celebration"
      size="large"
      is-backed
      class="my-4"
    />
    <p class="title is-6 m-0 mb-5">
      Thank you for your review
    </p>
    <p class="has-text-centered">
      Your review will be visible to all users within a day
    </p>
  </div>
</template>

<style scoped lang="scss"></style>
