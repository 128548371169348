<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0001 0H14.0001V3H11.0001C11.0101 3.01 11.0001 5 11.0001 5H14.0001V7.99C14.0101 8 16.0001 7.99 16.0001 7.99V5H19.0001V3H16.0001V0Z"
      fill="white"
    />
    <path
      d="M14.0001 17.22H2.00012V5H9.00012V3H2.00012C0.900122 3 0.00012207 3.9 0.00012207 5V17C0.00012207 18.1 0.900122 19 2.00012 19H14.0001C15.1001 19 16.0001 18.1 16.0001 17V10H14.0001V17.22Z"
    />
    <path d="M4.00012 7H12.0001V9H4.00012V7Z" />
    <path d="M4.00012 10V12H12.0001V10H4.00012Z" />
    <path d="M4.00012 13H12.0001V15H4.00012V13Z" />
  </svg>
</template>
