<template>
  <div class="content">
    <div>
      <h2>
        <b>What is Pretty Shell?</b>
      </h2>
      <p>
        Pretty Shell is an online platform that allows users to rent items from other users who have listed those items. Whether you need equipment, tools, or luxury items, Pretty Shell connects owners and renters with ease. We also offer extra protection to ensure a safe and secure experience for both parties.
      </p>
    </div>

    <hr>

    <div class="mt-6">
      <h2>
        <b>How do I create an account on Pretty Shell?</b>
      </h2>
      <p>
        Creating an account on Pretty Shell is simple! Click on the "Sign Up" button on our homepage, provide the required information, and follow the on-screen instructions. You'll receive a confirmation email to verify your account.
      </p>
    </div>

    <hr>

    <div class="mt-6">
      <h2>
        <b>I'm unable to sign in, what to do now?</b>
      </h2>
      <p>
        When you create an account on Pretty Shell, we'll send you a confirmation email to verify your address. Sometimes, this email might end up in your spam or junk folder. Please check those folders, locate the confirmation email, and follow the instructions to complete your sign-up process.
      </p>
    </div>

    <hr>

    <div class="mt-6">
      <h2>
        <b>What should I do if I forget my password?</b>
      </h2>
      <p>
        If you forget your password, click on the "Forgot Password" link on the login page. Enter your registered email address, and we'll send you a link to reset your password.
      </p>
    </div>

    <hr>

    <div class="mt-6">
      <h2>
        <b>Where can I leave feedback, requests, and ideas?</b>
      </h2>
      <p>
        We're always striving to improve our services, and your valuable feedback, requests, and ideas are essential to this process. You'll find a detailed description on how to give feedback further down on this page.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss"></style>
