<template>
  <div class="menu">
    <ul class="menu-list">
      <li>
        <router-link
          :to="{ name: 'home' }"
          @click="emits('closeSidebarNav')"
        >
          <Icon
            variant="dark"
            name="outlined/home"
            is-inline
          />
          <span>Home</span>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'categories' }"
          @click="emits('closeSidebarNav')"
          v-test-id="'button-browse-categories'"
        >
          <Icon
            variant="dark"
            name="outlined/search"
            is-inline
          />
          <span>Browse categories</span>
        </router-link>
      </li>
    </ul>

    <hr v-if="hasDividers">

    <p class="menu-label">
      About us
    </p>

    <ul class="menu-list">
      <li>
        <router-link
          :to="{ name: 'causes' }"
          @click="emits('closeSidebarNav')"
        >
          Our causes
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'process' }"
          @click="emits('closeSidebarNav')"
        >
          Our process
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'helpInformation' }"
          @click="emits('closeSidebarNav')"
        >
          Help & Information
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
const emits = defineEmits(["closeSidebarNav"]);

defineProps({
  hasDividers: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss"></style>
