<template>
  <a id="how-it-works"/>

  <div class="container">
    <article>
      <section>
        <p>Introducing Pretty Shell</p>
        <h2>Find what you need, rent out what you don’t</h2>
        <p>Pretty Shell makes it easy to rent out unused items for extra cash or find affordable, short-term rentals
          without the hassle of buying. Whether you’re an outdoor adventurer, a DIY enthusiast, or just someone looking
          to cut costs, Pretty Shell is the go-to platform for secure, hassle-free peer-to-peer rentals.</p>
      </section>

      <hr/>

      <section>
        <p>Join the revolution</p>
        <h2>Renting ≠ Buying</h2>
        <p>Whether you’re planning a dream wedding, gearing up for an epic adventure, or just trying out the latest
          kitchen gadgets, renting gives you access without the commitment.</p>
        <fieldset v-if="!isAuthenticated">
          <PsButton color="neutral" @click="openSignUpModal">Sign up today</PsButton>
        </fieldset>
      </section>

      <section class="full-width">
        <ul class="is-horizontal">
          <li>
            <figure><span>💸</span></figure>
            <label>Earn extra income</label>
            <p>Turn your garage clutter into a side hustle.</p>
          </li>
          <li>
            <figure><span>💰</span></figure>
            <label>Save money</label>
            <p>No need to buy expensive items you'll only use once.</p>
          </li>
          <li>
            <figure><span>🌱</span></figure>
            <label>Sustainable living</label>
            <p>Reduce waste by sharing and reusing.</p>
          </li>
          <li>
            <figure><span>🏷</span></figure>
            <label>Try before you buy</label>
            <p>Test out gear before committing to a purchase.</p>
          </li>
          <li>
            <figure><span>🛒</span></figure>
            <label>Convenience & secure</label>
            <p>Enjoy safe transactions backed by optional insurance.</p>
          </li>
        </ul>
      </section>

      <hr/>

      <section>
        <p>Benefits</p>
        <h2>Discover how you can benefit from Pretty Shell starting today</h2>
        <fieldset class="benefits">
          <PsButton v-for="binds in benefitButtons" is-rounded v-bind="binds"></PsButton>
        </fieldset>
      </section>
      <Transition name="fade" mode="out-in">
        <section v-if="perspective === 'lender'" class="split full-width">
          <div class="is-flex is-flex-direction-column flex-gap-1">
            <h3>The "Garage treasure" Rentreprenuer</h3>
            <b>Meet Alex, a DIY enthusiast with a cluttered garage.</b>
            <p>Alex has accumulated power tools, an extra tent, and a pressure washer that he rarely uses. Instead of
              letting these items collect dust, he decides to list them on Pretty Shell.</p>
          </div>
          <figure>
            <img alt="Alex lending items to Pretty Shell" src="/frame_home_lending.png"/>
          </figure>
        </section>
        <section v-else class="split full-width">
          <figure>
            <img alt="Sarah the smart renter" src="/frame_home_renter.png"/>
          </figure>
          <div class="is-flex is-flex-direction-column flex-gap-1">
            <h3>The "One-Time Need" smart renter</h3>
            <b>Meet Sarah, a college student moving into a new apartment.</b>
            <p>Sarah needs a carpet cleaner to freshen up her new place, but buying one for $200 makes no sense for a
              single-use need. Instead, she searches on Pretty Shell and finds a local renter offering one for just
              $20/day.</p>
          </div>
        </section>
      </Transition>

      <Transition name="fade" mode="out-in">
        <section v-if="perspective === 'lender'" class="full-width">
          <p>How it works for lenders</p>
          <h2>Turn your stuff into cash!</h2>
          <ul class="is-horizontal my-6">
            <li>
              <figure class="counter">
                <span>1</span>
              </figure>
              <label>List your item</label>
              <p>Snap a photo, set a price, and publish.</p>
            </li>
            <li>
              <figure class="counter">
                <span>2</span>
              </figure>
              <label>Maximize exposure</label>
              <p>Share your listing on social media.</p>
            </li>
            <li>
              <figure class="counter">
                <span>3</span>
              </figure>
              <label>Accept bookings</label>
              <p>Approve requests & connect with your renters.</p>
            </li>
            <li>
              <figure class="counter">
                <span>4</span>
              </figure>
              <label>Secure payments</label>
              <p>Get paid instantly after succesful returns.</p>
            </li>
            <li>
              <figure class="counter">
                <span>5</span>
              </figure>
              <label>Build a reputation</label>
              <p>Earn great reviews & repeat customers!</p>
            </li>
          </ul>
        </section>
        <section v-else class="full-width">
          <p>How it works for renters</p>
          <h2>Find & book easily!</h2>
          <ul class="is-horizontal my-6">
            <li>
              <figure class="counter is-success">
                <span>1</span>
              </figure>
              <label>Search & compare</label>
              <p>Find the perfect item in your area.</p>
            </li>
            <li>
              <figure class="counter is-success">
                <span>2</span>
              </figure>
              <label>Book with confidence</label>
              <p>Secure payments & optional insurance.</p>
            </li>
            <li>
              <figure class="counter is-success">
                <span>3</span>
              </figure>
              <label>Pick up & enjoy</label>
              <p>Use the item stress-free.</p>
            </li>
            <li>
              <figure class="counter is-success">
                <span>4</span>
              </figure>
              <label>Return & review</label>
              <p>Easy returns, helpful reviews.</p>
            </li>
          </ul>
        </section>
      </Transition>

      <hr/>

      <section class="full-width">
        <h2>Featured categories</h2>
        <p>People are renting out more than you think!</p>

        <CategoryCollage :categories="galleryItems"/>
        <fieldset>
          <PsButton
            class="mt-6 is-hidden-tablet"
            color=neutral
            full-width
            icon="outlined/search"
            styling="outlined"
            @click="gotoCategories"
          >
            Browse all categories
          </PsButton>
        </fieldset>
      </section>

      <section class="full-width split paper">
        <div class="is-flex is-flex-direction-column flex-gap-1">
          <p>Safety & Trust: Your rentals, Secured!</p>
          <h2>At Pretty Shell, we take security seriously. That's why we offer:</h2>
        </div>
        <ul class="checked is-primary">
          <li><label>$4,000 Insurance Protection</label>Optional coverage for peace of mind.</li>
          <li><label>Verified users</label>Trusted profiles with ratings & reviews.</li>
          <li><label>Security Deposits</label>Extra protection for high-value rentals.</li>
          <li><label>Secure Payments</label>Transactions processed through our encrypted system.</li>
        </ul>
      </section>

      <section>
        <h2>Start Renting & Earning Today!</h2>
        <p>Your items have value—turn them into cash! Start today!</p>
        <fieldset v-if="!isAuthenticated">
          <PsButton color="neutral" @click.prevent="openSignUpModal">Sign up for free</PsButton>
        </fieldset>
      </section>
    </article>
  </div>
</template>

<script lang="ts" setup>
import {computed, inject, ref} from "vue";
import {useRouter} from "vue-router";
import CategoryCollage from "@/components/CategoryCollage.vue";

import {
  APP_DOWNLOAD_URL_APPLE_STORE,
  APP_DOWNLOAD_URL_GOOGLE_PLAY_STORE,
  INTRO_PRETTYSHELL_VIDEO_SRC,
} from "@/constants/generic";
import {useCategories} from "@/rental/stores/categories";
import useCauses from "@/store/causes";
import {useSession} from "@/app/stores/session.js";
import {storeToRefs} from "pinia";

const appDownloadUrlAppleStore = APP_DOWNLOAD_URL_APPLE_STORE;
const appDownloadUrlGooglePlayStore = APP_DOWNLOAD_URL_GOOGLE_PLAY_STORE;
const intoPrettyshellVideoSrc = INTRO_PRETTYSHELL_VIDEO_SRC;

const router = useRouter();

const categoryStore = useCategories();
const causes = useCauses();
const showVideoModal = ref(false);
const {setModal} = inject('authOverlay');
const {isAuthenticated} = storeToRefs(useSession());

categoryStore.fetchCategories();
causes.fetchCauses();

const perspective = ref<"lender" | "renter">("lender");

const benefitButtons = computed(() => ([
  "lender", "renter"
].map(type => ({
  color: type === perspective.value ? "neutral" : "secondary",
  styling: type === perspective.value ? "filled" : "ghost",
  textContent: type === 'lender' ? 'Lending' : 'Renting',
  onClick: () => {
    perspective.value = type;
  }
}))))

const galleryItems = categoryStore.useGalleryItems();

function gotoCauses() {
  router.push({
    name: "causes",
  });
}

function gotoProcess() {
  router.push({
    name: "process",
  });
}

function gotoCategories() {
  router.push({
    name: "categories",
  });
}

function openSignUpModal() {
  setModal('signUp');
}

function handleWatchVideo() {
  showVideoModal.value = true;
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

article {
  margin-top: 82px;
  @include vertical-list($gap: var(--gap-extra-large));
}

article > section {
  & {
    @include vertical-list();
    margin: 2em auto;
    text-align: center;
    max-width: 700px;
  }

  &.full-width {
    max-width: 100%;
    margin: 0;
    text-align: left;
  }

  &.split {
    @include horizontal-list($gap: var(--gap-large));
    align-items: center;

    & > * {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  &.paper {
    background-color: var(--gray-mist);
    padding: 4rem;
    border-radius: 24px;
  }

  fieldset {
    @include horizontal-list($gap: var(--gap-small));
    justify-content: center;
  }

  p:first-child {
    color: var(--gray-muted);
  }

  h2 {

  }
}

section fieldset.benefits button {
  text-transform: capitalize;
}

hr {
  margin: 2rem 0;
  overflow: visible;

  &:before {
    content: '';
    position: absolute;
    display: block;
    justify-content: center;
    background: no-repeat center url(/logo.svg) white;
    color: black;
    width: 94px;
    height: 30px;
    margin: 0 calc(50% - 94px / 2);
    left: 0;
    margin-top: -15px;
  }
}

.hero-body {
  &.wide {
    max-width: 100%;
  }

  margin: 0 auto;
  max-width: 700px;
}

.causes-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5em;
  width: 100%;
  text-align: center;

  .cause {
    border-radius: 8px;
    color: var(--gray-snow);
    font-size: 15px;
    font-weight: 600;

    background: $gray-A10;
    border: 1px solid rgba(6, 30, 62, 0.1);

    .cause-overlay {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:hover {
      background-color: var(--peach-200);
    }
  }
}

.benefits-home {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1.5em;
  margin: 2rem 0;

  @include touch {
    grid-template-columns: auto;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: left;
    gap: 1em;

    @include touch {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .subject {
      font-weight: 700;
      margin-bottom: 1em;
    }

    .num {
      color: var(--gray-snow);
      font-size: 2em;
      font-weight: 700;
      height: 2em;
      width: 2em;
      text-align: center;
      border-radius: 100%;
      line-height: 2em;
      background-color: var(--mint);

      @include touch {
        flex: 0 0 1.5em;
        height: 1.5em;
        width: 1.5em;
        font-size: 1.5em;
        line-height: 1.5em;
      }
    }
  }
}

.intro-video-modal {
  video {
    width: 100%;
    height: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
