import {defineStore} from "pinia";
import {type ApiCollectionModel, useApiCollection} from "@/app/api";
import type {IActor} from "@/app/models/actor";
import type {Category} from "./categories";
import type {CollectionModel} from "@/store/base/models";
import {
  deserialize,
  type Extension,
  serialize,
  useCollectionDeserializer,
  useDeserializer,
  useSerializer
} from "@/app/io";
import type {
  ApiPosition,
  BookingInterval,
  ListingLocation,
  ListingStatus,
  Position,
  PricingOption
} from '@/rental/types';
import {INTERVAL_TO_DAYS} from "@/rental/constants";
import {useCollection} from "@/helpers/store";
import {Logger} from "@/app/logger";
import {computed, type Ref} from "vue";
import Formats, {type AnyDateType} from "@/helpers/formats";
import {actorDeserializer, type ApiActor} from "@/app/stores/session";
import {PositionField} from "@/rental/io";
import {type ApiPricingItem, Pricing, pricingItemDeserializer} from "@/rental/composables/pricing";

interface ApiPricingOption {
  interval: BookingInterval;
  amount: number; // in cents
}

type MediaItem = {
  id: string;
  url: string;
}

interface SubCategory extends Category {
  parent: Category;
}

interface ApiListingLocation {
  state_long: string;
  geo_position: ApiPosition,
  street_address: string;
  country: string;
  city: string;
}

export interface ApiListingReview extends ApiCollectionModel {
  author: ApiActor;
  rating: number;
  comment: string;
}

export interface ApiListing extends ApiCollectionModel {
  name: string;
  description: string;
  pricing_options: ApiPricingOption[];
  protection: "insurance" | "deposit" | "none";
  deposit_value: number;
  unavailable_days: string[];
  explicit_unavailable_days: string[];
  available_weekdays: string[];
  pickup_dropoff_timerange: string[];
  pickup_location?: ApiListingLocation;
  owner: ApiActor;
  features: string[];
  restrictions: string[];
  insurance_premium: number | null;
  state: ListingStatus;
  category_id: Category["id"];
  condition: string;
  age: number;
  media: MediaItem[];
  category: SubCategory;
  num_reviews: number;
  rating: number;
}

type ApiListingMutableKeys =
  "name"
  | "description"
  | "pricing_options"
  | "protection"
  | "deposit_value"
  | "explicit_unavailable_days"
  | "available_weekdays"
  | "pickup_dropoff_timerange"
  | "pickup_location"
  | "features"
  | "restrictions"
  | "insurance_premium"
  | "category_id"
  | "condition"
  | "age"
  | "media"

interface ApiPublicListing extends ApiListing {
}

interface ApiPrivateListing extends ApiListing {
  market_value: number;
  explicit_unavailable_days: string[];
}

type ApiPrivateListingMutableKeys = ApiListingMutableKeys
  | "explicit_unavailable_days"
  | "market_value"

export interface ListingReview extends CollectionModel {
  author: IActor;
  rating: number;
  comment: string;
}

export type Listing = CollectionModel & {
  name: string;
  description: string;
  pricingOptions: PricingOption[];
  protection: "insurance" | "deposit" | "none";
  depositValue: number;
  unavailableDays: string[];
  availableWeekdays: string[];
  pickupDropOffTimeRange: string[];
  pickupLocation?: ListingLocation;
  owner: IActor;
  features: string[];
  restrictions: string[];
  insurancePremium: number | null;
  status: ListingStatus;
  condition: string;
  age: number;
  media: MediaItem[];
  category: SubCategory;
  rootCategoryId: Category["id"];
  categoryId: Category["id"];
  numReviews: number;
  rating: number;
  isDisabledDate(d: AnyDateType): boolean;
  readonly mainImageSrc: string | undefined;
  images: string[];
  readonly hasImage: boolean;
  // getters
  isOwner: (candidate: IActor) => boolean;
  bestPricingOption: PricingOption;
  getPricingOption: (interval: BookingInterval) => PricingOption | undefined;
  getRate: (interval: BookingInterval) => number | undefined;
  hasPrices: boolean;
  localLocation?: string;
  isFavorite?: Ref<boolean>;
} // most basic form

type PublicListing = Listing & {}

export type PrivateListing = PublicListing & {
  explicitUnavailableDays: Date[];
  marketValue: number;
}

type ExtensionKeys =
  "hasImage"
  | "isOwner"
  | "isDisabledDate"
  | "mainImageSrc"
  | "images"
  | "bestPricingOption"
  | "getPricingOption"
  | "getRate"
  | "hasPrices"
  | "localLocation"
  | "isFavorite";

const getListingPricingOption = (listing: Omit<Listing, ExtensionKeys>, interval: BookingInterval) => {
  return listing.pricingOptions.find(el => el.interval === interval);
}

const listingExtensions: Extension<Listing, ExtensionKeys> = {
  hasImage: (listing) => listing.media.length > 0,
  mainImageSrc: (listing) => listing.media[0]?.url,
  images: (listing) => listing.media.map((item) => item.url),
  isOwner: (listing) => (candidate: IActor) => listing.owner.id === candidate.id,
  isDisabledDate: (listing) => (d) => {
    if (Formats.isBefore(d, Formats.now().subtract(1, "day"))) {
      return true;
    }
    const calDate = Formats.dateSystemWithoutTimezoneFormat(d);
    return listing.unavailableDays.indexOf(calDate) > -1;
  },
  bestPricingOption: (listing) => {
    return listing.pricingOptions.reduce((best, current) => {
      if (current.pricePerDay < best.pricePerDay) {
        return current;
      }
      return best;
    }, listing.pricingOptions[0]);
  },
  hasPrices: (listing) => listing.pricingOptions.length > 0,
  getPricingOption: (listing) => (interval: BookingInterval) => getListingPricingOption(listing, interval),
  getRate: (listing) => {
    return (interval: BookingInterval) => getListingPricingOption(listing, interval)?.amount
  },
  localLocation: (listing) => {
    if (!listing.pickupLocation?.city) {
      return undefined;
    }
    if (listing.pickupLocation.state) {
      return `${listing.pickupLocation.state}, ${listing.pickupLocation.city}`;
    }
    return listing.pickupLocation.city;
  },
  isFavorite: (listing) => {
    const favorites = useFavoriteListings();
    return favorites.useIsFavorite(listing.id);
  },
};

const PickupLocationDeserializer = useDeserializer<ApiListingLocation, ListingLocation>({
  state: "state_long",
  position: (obj) => PositionField.deserialize(obj.geo_position) as Position,
  streetAddress: "street_address",
  country: "country",
  city: "city",
})

const PickupLocationSerializer = useSerializer<ListingLocation, ApiListingLocation>({
  state_long: "state",
  geo_position: (obj) => PositionField.serialize(obj.position),
  street_address: "streetAddress",
  country: "country",
  city: "city",
})

const PricingOptionDeserializer = useDeserializer<ApiPricingOption, PricingOption, "pricePerDay">({
  interval: "interval",
  amount: (obj) => obj.amount / 100,
}, {
  pricePerDay: (obj) => obj.amount / INTERVAL_TO_DAYS[obj.interval],
});

const PricingOptionSerializer = useSerializer<PricingOption, ApiPricingOption>({
  interval: "interval",
  amount: (obj) => serialize.cents_amount(obj.amount),
})

const ReviewDeserializer = useCollectionDeserializer<ApiListingReview, ListingReview>({
  author: (obj) => actorDeserializer(obj.author),
  comment: "comment",
  rating: "rating",
}, {})

const {
  deserializer: listingDeserializer,
  schema: listingSchema
} = useCollectionDeserializer<ApiListing, Listing, keyof typeof listingExtensions>({
  name: "name",
  description: "description",
  pricingOptions: (obj) => obj.pricing_options.map(PricingOptionDeserializer.deserializer),
  protection: "protection",
  depositValue: (obj) => deserialize.cents_amount(obj.deposit_value),
  insurancePremium: (obj) => obj.insurance_premium ? deserialize.cents_amount(obj.insurance_premium) : 0,
  unavailableDays: "unavailable_days",
  availableWeekdays: "available_weekdays",
  pickupDropOffTimeRange: (obj) => obj.pickup_dropoff_timerange ?? [],
  pickupLocation: (obj) => obj.pickup_location ? PickupLocationDeserializer.deserializer(obj.pickup_location) : undefined,
  owner: (obj) => actorDeserializer(obj.owner),
  features: "features",
  restrictions: "restrictions",
  status: "state",
  categoryId: (obj) => obj.category.id,
  rootCategoryId: (obj) => obj.category.parent.id,
  condition: "condition",
  age: "age",
  media: "media",
  category: "category",
  numReviews: "num_reviews",
  rating: (obj) => (obj.rating ?? 0) / 10,
}, listingExtensions);


export const PublicListingDeserializer = useCollectionDeserializer<ApiPublicListing, PublicListing, keyof typeof listingExtensions>(listingSchema, listingExtensions);
const PrivateListingDeserializer = useCollectionDeserializer<ApiPrivateListing, PrivateListing, keyof typeof listingExtensions>({
  ...listingSchema,
  marketValue: (obj) => obj.market_value / 100,
  explicitUnavailableDays: (obj) => (obj.explicit_unavailable_days ?? []).map(deserialize.date),
}, listingExtensions);

const PrivateListingSerializer = useSerializer<PrivateListing, Pick<ApiPrivateListing, ApiPrivateListingMutableKeys>>({
  name: "name",
  description: "description",
  pricing_options: (obj) => (obj.pricingOptions ?? []).map(el => PricingOptionSerializer.serializer(el)),
  protection: "protection",
  deposit_value: (obj) => serialize.cents_amount(obj.depositValue),
  insurance_premium: (obj) => obj.insurancePremium ? serialize.cents_amount(obj.insurancePremium) : null,
  explicit_unavailable_days: (obj) => (obj.explicitUnavailableDays ?? []).map(serialize.date),
  available_weekdays: "availableWeekdays",
  pickup_dropoff_timerange: (obj) => obj.pickupDropOffTimeRange,
  pickup_location: (obj) => obj.pickupLocation ? PickupLocationSerializer.serializer(obj.pickupLocation) : undefined,
  features: "features",
  restrictions: "restrictions",
  category_id: "categoryId",
  condition: "condition",
  age: "age",
  media: (obj) => (obj.media ?? []).filter(el => el !== undefined).map(el => el.id),
  market_value: (obj) => obj.marketValue * 100,
});

export const usePublicListings = defineStore("publicListings", () => {
  const listingsApi = useApiCollection<PublicListing, ApiPublicListing>("rental/listings", {
    isCached: false,
    deserializer: PublicListingDeserializer.deserializer,
  });
  const {items, sync, ensureById} = useCollection<PublicListing>(async () => {
    return await listingsApi.list({range: [0]});
  });

  const useById = (id: Ref<PublicListing["id"]>) => computed(() => items.value.getById(id.value));

  return {
    items,
    sync,
    reviews: (listingsId: PublicListing["id"]) => listingsApi.subResource<ApiListingReview[], ListingReview[]>(listingsId, "reviews", {}, {
      deserializer: (arr: ApiListingReview[]) => arr?.map(ReviewDeserializer.deserializer) ?? []
    }),
    ensureById: (listingId: PublicListing["id"]) => ensureById(listingId, () => listingsApi.get(listingId)),
    costs: async (listingId: PublicListing["id"], startsAt: string, endsAt: string) => {
      const result = await listingsApi.sub<ApiPricingItem[]>(listingId, "costs", {
        starts_at: startsAt,
        ends_at: endsAt,
      }) as ApiPricingItem[];
      return new Pricing(
        result.map(pricingItemDeserializer)
      )
    }
  }
})

export const usePrivateListings = defineStore("privateListings", () => {
  const listingsApi = useApiCollection<PrivateListing, ApiPrivateListing>("me/listings", {
    isCached: false,
    deserializer: PrivateListingDeserializer.deserializer,
    serializer: PrivateListingSerializer.serializer,
  });
  const {
    items,
    sync,
    ensureById,
    updateById,
    hasId,
    getById,
    loading,
    add,
    withAction
  } = useCollection<PrivateListing>(async () => {
    return await listingsApi.list({range: [0]});
  });

  return {
    items,
    sync,
    hasId,
    getById,
    loading,
    reviews: (listingsId: PublicListing["id"]) => listingsApi.subResource<ApiListingReview[], ListingReview[]>(listingsId, "reviews", {}, {
      deserializer: (arr: ApiListingReview[]) => arr?.map(ReviewDeserializer.deserializer) ?? []
    }),
    create: async (listing: Partial<PrivateListing>) => {
      const newListing = await listingsApi.create(listing, {isPartial: true});
      add(newListing);
      return newListing;
    },
    update: async (listingId: PrivateListing["id"], listing: Partial<PrivateListing>) => {
      const updatedListing = await listingsApi.patch(listingId, listing);
      updateById(listingId, updatedListing);
      return updatedListing;
    },
    getByStatus: (status: ListingStatus) => items.value.filter(el => el.status === status),
    ensureById: (listingId: PrivateListing["id"]) => ensureById(listingId, () => listingsApi.get(listingId)),
    canPublish: async (listingId: PrivateListing["id"]): Promise<boolean> => {
      const response = await listingsApi.subResource<{ can_publish: boolean }>(listingId, "can_publish");
      return response.can_publish;
    },
    publish: withAction(async (rid) => listingsApi.action(rid, "publish")),
    archive: withAction(async (rid) => listingsApi.action(rid, "archive")),
  }
})

export const useFavoriteListings = defineStore("favoriteListings", () => {
  const listingsApi = useApiCollection<PublicListing, ApiPublicListing>("me/favorites", {
    isCached: false,
    deserializer: PublicListingDeserializer.deserializer,
  });

  const collection = useCollection<PublicListing>(async () => {
    return await listingsApi.list();
  });

  return {
    items: collection.items,
    sync: collection.sync,
    isLoading: collection.loading,
    isFavorite: (listingId: Listing["id"]) => collection.hasId(listingId),
    useIsFavorite: (listingId: Listing["id"]) => computed(() => collection.hasId(listingId)),
    add: async (listingId: Listing["id"]) => {
      const newFavourite = await listingsApi.create({listing_id: listingId});
      collection.items.value.push(newFavourite);
      Logger.log("Added new favorite", newFavourite);
      return newFavourite;
    },
    remove: async (listingId: Listing["id"]) => {
      await listingsApi.del(listingId);
      Logger.log("Removed favorite", listingId);
      collection.items.value.removeById(listingId);
    },
  }
})

export const ListingDeserializer = listingDeserializer;
