import { defineStore } from "pinia";
import { type Ref, ref } from "vue";

import { useApi } from "@/plugins/Api";

interface accountSettings {
  payouts: {
    schedule: {
      delay_days: number;
      interval: string;
    };
  };
}

export class PayoutAccount {
  id: string;
  onboarded: boolean;
  settings: accountSettings;

  constructor(params) {
    this.id = params.id;
    this.onboarded = params.onboarded;
    this.settings = params.settings;
  }
}

export const usePayoutAccount = defineStore("payoutAccount", () => {
  const api = useApi();
  const account: Ref<PayoutAccount | undefined> = ref();

  const sync = async () => {
    const result = await api.c.get("/me/stripe_account");
    account.value = new PayoutAccount(result);
  };

  const getOnboardingLink = async (): Promise<string> => {
    const result = await api.c.get("/me/stripe_onboarding", {
      return_url: window.location.href,
    });
    return result.url;
  };

  const getLoginLink = async (): Promise<string> => {
    const result = await api.c.get("/me/stripe_login");
    return result.url;
  };

  return {
    account,
    sync,
    getOnboardingLink,
    getLoginLink,
  };
});
