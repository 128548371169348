import { generateClasses } from "@formkit/themes";
import { createInput, defaultConfig, plugin } from "@formkit/vue";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { inject } from "vue";

import AddressPicker from "@/form/AddressPicker.vue";
import Autocomplete from "@/form/Autocomplete.vue";
import BookRangeInput from "@/form/BookRangeInput.vue";
import DatePickerInput from "@/form/DatePickerInput.vue";
import MapInput from "@/form/MapInput.vue";
import PhotoInput from "@/form/PhotoInput.vue";
import PriceRangeInput from "@/form/PriceRangeInput.vue";
import ProtectionInput from "@/form/ProtectionInput.vue";
import RatingInput from "@/form/RatingInput.vue";
import WeekdayInput from "@/form/WeekdayInput.vue";

import Formats from "@/helpers/formats";
import { HelpersInjectionKey } from "@/plugins/symbols";

export function generateUUID() {
  return uuidv4();
}

const STATUS_TO_COLORS = {
  active: "success",
  draft: "secondary",
};

export interface Upload {
  id: string;
  url: string;
}

export const helpers = {
  CDNImage(url) {
    if (!url) {
      return null;
    }
    return url.replace(
      /https:\/\/prettyshell-app.*.com\//,
      import.meta.env?.VITE_APP_CDN_URL,
    );
  },
  highlight(target, query) {
    const check = new RegExp(query, "ig");
    return target.replace(
      check,
      (match) => `<span class="highlight">${match}</span>`,
    );
  },
  statusToColor(status) {
    return STATUS_TO_COLORS[status] || "primary";
  },
  async useLoading(node, cb) {
    const el = node.$el;
    const initialClassName = el.className;
    try {
      el.className += " is-loading";
      await cb();
    } catch (err) {
      console.log(err);
    } finally {
      el.className = initialClassName;
    }
  },
  ...Formats,
};

const Uploader = (app) => {
  async function uploadFile(f): Upload {
    const upload = await app.$api.c.uploads.create({
      content_type: f.type,
    });
    const form = new FormData();
    for (const key in upload.options.fields) {
      form.append(key, upload.options.fields[key]);
    }
    form.append("file", f);
    await axios.post(upload.options.url, form, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    const url = helpers.CDNImage(
      upload.options.url + upload.options.fields.key,
    );
    return {
      id: upload.id,
      url: url,
    };
  }

  return uploadFile;
};

export default {
  install(app) {
    app.$id = uuidv4;
    app.helpers = helpers;
    app.helpers.uploader = Uploader(app);
    app.config.globalProperties.$id = uuidv4;
    app.config.globalProperties.$helpers = helpers;
    app.provide("$helpers", app.helpers);
    app.provide(HelpersInjectionKey, helpers);
    /*
    app.use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyDSaMyYSdrRV3RHgcCDw8fg8HZEBdqgrfA',
        libraries: ['places']
      }
    })
    */
    app.use(
      plugin,
      defaultConfig({
        inputs: {
          photo: createInput(PhotoInput, {
            props: ["size"],
          }),
          weekdays: createInput(WeekdayInput, {
            props: [
              "highlightUnselected",
              "showSummaryText",
              "summaryTextPrefix",
            ],
          }),
          address: createInput(AddressPicker, {}),
          map: createInput(MapInput, {
            props: [
              "initialCoordinates",
              "zoom",
              "city",
              "state",
              "street_address",
              "street_address_2",
            ],
          }),
          protection: createInput(ProtectionInput, {
            props: ["insuranceDisabled", "insurancePremium", "isOwner"],
          }),
          rating: createInput(RatingInput, {
            props: ["readOnly"],
          }),
          autocomplete: createInput(Autocomplete, {
            props: [
              "options",
              "searchHandler",
              "lookupHandler",
              "prefetchItems",
              "showLimit",
              "noResultHandler",
            ],
          }),
          bookrange: createInput(BookRangeInput, {
            props: [
              "availableWeekdays",
              "isDisabledDate",
              "pickupDropOffRange",
              "pickupTimeRange",
              "dropoffTimeRange",
              "allowEmpty",
              "showPickupDropoffTimeSelection",
              "onSelectPickupDropoffTimeRange",
            ],
          }),
          datepicker: createInput(DatePickerInput, {
            props: [
              "inline",
              "autoApply",
              "multiDates",
              "enableTimePicker",
              "disabledWeekDays",
              "range",
              "disabledDates",
              "variant",
              "multiCalendars",
            ],
          }),
          pricerange: createInput(PriceRangeInput, {}),
        },
        config: {
          classes: generateClasses({
            global: {
              input: "input",
              inner: "control",
              wrapper: "control-wrapper",
              label: "label",
              message: "is-danger help",
              help: "help",
            },
            button: {
              input: "button",
            },
            submit: {
              input: "button is-primary",
            },
            textarea: {
              input: "textarea",
            },
            select: {
              input: "select",
              inner: "select",
            },
          }),
        },
      }),
    );
  },
};

export function useHelpers(): typeof helpers {
  const result = inject(HelpersInjectionKey);
  if (!result) throw "Misconfigured, no helpers";
  return result;
}
