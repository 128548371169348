import { defineStore } from "pinia";
import { inject } from "vue";

import { useCollection } from "@/helpers/store";
import { ApiInjectionKey } from "@/plugins/symbols";
import usePayments, { type SetupIntent } from "@/store/payments";

export interface PaymentMethodDetails {
  brand: "visa" | "mastercard";
  last4: number;
  exp_year: number;
  exp_month: number;
}

export interface IPaymentMethod {
  id: string;
  type: string;
  details: PaymentMethodDetails;
}

export class PaymentMethod implements IPaymentMethod {
  id: string;
  type: string;
  details: PaymentMethodDetails;
  isDefault: boolean;

  constructor(params, setupIntent: SetupIntent) {
    Object.assign(this, params);
    this.isDefault = this.id === setupIntent.payment_method.id;
  }
}

export const usePaymentMethodStore = defineStore("paymentMethods", () => {
  const api = inject(ApiInjectionKey);
  const payments = usePayments();

  const collection = useCollection<PaymentMethod>(async () => {
    const setupIntent = await payments.getIntent();
    const result = await api.c.paymentMethods.list();
    return [...result.map((el) => new PaymentMethod(el, setupIntent))];
  });

  collection.sync();

  return {
    ...collection,
  };
});
