import {type ApiPosition, Position} from "@/rental/types";


const locationHandlers = {
  lnglat: (v:Position) => v,
  latlng: (v:Position):Position => v.reverse() as Position,
};
export const PositionField = {
  deserialize(v?: ApiPosition | [number, number]) {
    if (v === undefined) {
      return undefined;
    }
    if (typeof v === "object" && !Array.isArray(v)) {
      return new Position(v.longitude, v.latitude);
    }
    return new Position(...v);
  },
  serialize(v: Position, format: keyof typeof locationHandlers = "lnglat"): ApiPosition {
    if (v.length !== 2) {
      throw new Error("Invalid position");
    }
    const toLngLat = locationHandlers[format](v);
    return {
      longitude: toLngLat[0],
      latitude: toLngLat[1],
    };
  }
};
