export default {
  cognito: {
    REGION: import.meta.env.VITE_APP_REGION,
    USER_POOL_ID: import.meta.env.VITE_APP_USER_POOL_ID,
    APP_CLIENT_ID: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: import.meta.env.VITE_APP_IDENTITY_POOL_ID,
    REDIRECT_URL: import.meta.env.VITE_APP_REDIRECT_URL,
    SIGN_OUT_URL: import.meta.env.VITE_APP_SIGN_OUT_URL,
  },
};
