<script lang="ts" setup>
import type { ActionProps } from "@/components/rental/booking-actions/types";

const props = defineProps<ActionProps>();
</script>

<template>
  <PsButton
    color="secondary"
    :disabled="!props.booking.canCancel"
  >
    Cancel request
  </PsButton>
</template>

<style lang="scss" scoped></style>
