export enum BookingInterval {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}

export type PricingOption = {
  interval: BookingInterval;
  amount: number;
  pricePerDay: number;
}

export enum CostUnitType {
  hour = "hour",
  day = "day",
  month = "month",
  week = "week",
  rental = "rental",
}

export enum CostType {
  RENTAL_COST = "rental_cost",
  INSURANCE = "insurance",
  DEPOSIT = "deposit",
  TAX = "tax",
  FEE = "fee",
}

export const costTypesWithoutProtection = [
  CostType.RENTAL_COST,
  CostType.FEE,
  CostType.TAX,
];

export type ApiPosition = {
  longitude: number;
  latitude: number;
};

export class Position extends Array<number> {
  get longitude() {
    return this[0];
  }

  get latitude() {
    return this[1];
  }
}

export type ListingLocation = {
  state: string;
  position: Position;
  streetAddress: string;
  country: string;
  city: string;
}

export type ProtectionType = "none" | "deposit" | "insurance";
export enum ListingStatus {
  draft="draft",
  active="active",
}

export enum ApiBookingStatus {
  new = "new",
  review = "review",
  open = "open",
  pending = "pending",
  locked = "locked",
  ready_for_pickup = "ready_for_pickup",
  active = "active",
  closed = "closed",
  disputed = "disputed",
  canceled = "canceled",
  rejected = "rejected",
  expired = "expired",
}

export enum BookingStatus {
  new = "new",
  requested = "requested",
  review = "review",
  open = "open",
  pending = "pending",
  locked = "locked",
  confirmed = "confirmed",
  ready_for_pickup = "ready_for_pickup",
  waiting_pickup_renter = "waiting_pickup_renter",
  waiting_pickup_lender = "waiting_pickup_lender",
  active = "active",
  waiting_deliver_renter = "waiting_deliver_renter",
  waiting_deliver_lender = "waiting_deliver_lender",
  rejected = "rejected",
  disputed = "disputed",
  closed = "closed",
  canceled = "canceled",
  expired = "expired",
}
