<template>
  <Sidebar
    :is-open="isOpen"
    @close="closeSidebarNav"
  >
    <template #header>
      <div
        class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between"
      >
        <div class="hamburger-wrapper">
          <PsButton
            class="is-hidden-desktop"
            is-pill
            is-rounded
            color="secondary"
            styling="ghost"
            icon="menu"
            @click="closeSidebarNav"
          />

          <div class="logo-wrapper is-flex flex-gap-1 is-flex-wrap-nowrap">
            <Logo
              class="is-clickable"
              variant="color"
              size="small"
              @click="router.push('/')"
            />
            <img src="/beta.svg">
          </div>
        </div>

        <PsButton
          is-pill
          is-rounded
          color="secondary"
          styling="ghost"
          icon="close"
          @click="closeSidebarNav"
        />
      </div>
    </template>

    <MenuNav
      has-dividers
      @close-sidebar-nav="closeSidebarNav"
    />
  </Sidebar>
</template>

<script setup>
import { useRouter } from "vue-router";

import MenuNav from "@/components/MenuNav.vue";
import Sidebar from "@/components/Sidebar.vue";
import Logo from "@/elements/Logo.vue";

const router = useRouter();

const emit = defineEmits(["close"]);

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

function closeSidebarNav() {
  emit("close");
}
</script>

<style lang="scss">
@import '../styles/mixins.scss';

.hamburger-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  @include touch {
    flex-grow: 0.5;
    gap: 8px;
  }

  .logo-wrapper {
    img {
      max-width: inherit;
    }
  }
}
</style>
