<template>
  <div
    class="image-carousel"
    :class="hasMultipleImages ? 'has-multiple' : null"
  >
    <div class="image-carousel-container">
      <div class="overlay">
        <div class="overlay-top">
          <PsButton
            is-pill
            is-rounded
            color="white"
            styling="outlined"
            @click="goFullscreen"
          >
            <Icon
              name="base/fullscreen"
              variant="secondary"
            />
          </PsButton>
        </div>
        <div class="overlay-center">
          <PsButton
            is-pill
            is-rounded
            color="white"
            styling="outlined"
            icon="base/chevron_left"
            :disabled="!canGoPrev"
            @click="gotoPrev"
          />
          <PsButton
            is-pill
            is-rounded
            color="white"
            styling="outlined"
            icon="base/chevron_right"
            :disabled="!canGoNext"
            @click="gotoNext"
          />
        </div>
        <div
          v-if="hasMultipleImages"
          class="overlay-bottom"
        >
          <div
            v-for="(image, idx) in images"
            :key="idx"
            class="thumbnail"
            :class="current === idx ? 'is-active' : null"
            @click="gotoImage(idx)"
          >
            <img :src="image">
          </div>
        </div>
      </div>
      <img :src="images[current]">
    </div>
    <div
      class="modal"
      :class="isFullscreen ? 'is-active' : null"
    >
      <div
        class="modal-background"
        @click="closeFullscreen"
      >
        <div class="modal-head is-pulled-right p-4">
          <PsButton
            is-pill
            color="secondary"
            theme="dark"
            is-rounded
            @click="closeFullscreen"
          >
            <Icon
              name="base/close"
              variant="inherit"
            />
          </PsButton>
        </div>
      </div>
      <div
        class="modal-content is-align-items-center is-justify-content-center is-flex is-flex-direction-row"
      >
        <img :src="images[current]">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onKeyStroke } from "@vueuse/core";
import { type PropType, computed, ref } from "vue";

const props = defineProps({
  images: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

const current = ref(0);
const isFullscreen = ref(false);
// const currentSrc = ref(props.images[0])
const hasMultipleImages = ref(props.images.length > 1);

const canGoNext = computed(() => {
  return current.value < props.images.length - 1;
});
const canGoPrev = computed(() => {
  return current.value > 0;
});

function gotoImage(idx: number) {
  current.value = idx;
}

function goFullscreen() {
  isFullscreen.value = true;
}

function closeFullscreen() {
  isFullscreen.value = false;
}

function gotoNext() {
  if (!canGoNext.value) {
    return;
  }
  current.value += 1;
}

function gotoPrev() {
  if (!canGoPrev.value) {
    return;
  }
  current.value -= 1;
}

onKeyStroke("Escape", closeFullscreen);
</script>

<style lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.image-carousel {
  width: 100%;
  height: 440px;
  border-radius: 8px;
  background: $gray-mist;
  border: 1px solid #061e3e1a;
  overflow: hidden;

  @include touch {
    height: 300px;
  }

  .image-carousel-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  .overlay {
    padding: 1em;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .overlay-top {
    display: flex;
    justify-content: flex-end;
    height: 4em;
  }

  .overlay-center {
    opacity: 0;
    display: flex;
    justify-content: space-between;
  }

  .overlay-bottom {
    opacity: 0;
    display: flex;
    justify-content: center;
    gap: 1em;
    height: 4em;

    .thumbnail {
      border-radius: 0.5em;
      background: $gray-smoke;
      box-shadow:
        0px 0px 0px 1px rgba(6, 30, 62, 0.1),
        0px 2px 6px -2px rgba(6, 30, 62, 0.25);
      overflow: hidden;
      width: 4em;
      cursor: pointer;

      &.is-active {
        box-shadow: none;
        border: 2px solid $primary;
      }

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }
  }

  &.has-multiple {
    .overlay-center,
    .overlay-bottom {
      opacity: 1;
    }
  }
}
</style>
