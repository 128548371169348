<template>
  <Modal
    v-model="isActive"
    :title="title ?? t('listing.confirms.cancel.title')"
  >
    <template #default>
      {{ message ?? t('listing.confirms.cancel.message') }}
    </template>
    <template #foot>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <PsButton
              color="secondary"
              @click.prevent="discard"
            >
              {{ t('listing.confirms.cancel.discard') }}
            </PsButton>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <PsButton
              color="secondary"
              @click.prevent="closeModal"
            >
              {{ t('common.cancel') }}
            </PsButton>
          </div>
          <div class="level-item">
            <PsButton
              ref="saveDraft"
              :disabled="saveDisabled"
              @click.prevent="save"
            >
              {{ labelSave }}
            </PsButton>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import {computed, ref} from "vue";
import {useModalActions} from "@/composables/modal";
import {useI18n} from "vue-i18n";
import {ListingStatus} from "@/rental/types";

const emits = defineEmits(["save", "discard"]);
const props = defineProps<{
  listingStatus?: ListingStatus,
  saveDisabled: boolean,
  title?: string,
  message?: string,
}>();
const isActive = defineModel<boolean>();

const [openModal, closeModal] = useModalActions(isActive);

const {t} = useI18n();

const save = () => {
  emits("save");
  closeModal();
};

const discard = () => {
  emits("discard");
  closeModal();
};

const labelSave = computed(() => (
  props.listingStatus !== ListingStatus.active ? t('listing.confirms.cancel.action') : t('common.save')
))
</script>
