<template>
  <ListingImage :src="message.booking.listing.mainImageSrc" />

  <div class="content p-4">
    <div class="mb-2">
      <BookingStatus
        :has-hover="false"
        :status="message.booking.localStatus()"
        class="is-uppercase"
      />
    </div>

    <div
      class="is-clickable is-flex flex-gap-1 is-align-items-center is-justify-content-space-between"
      @click="gotoBooking(message.booking.id)"
    >
      <div class="is-flex flex-gap-1 is-flex-direction-column">
        <label>{{ message.booking.listing.name }}</label>
        <span>
          {{ $helpers.dateSystemWithoutTimezoneFormat(message.booking.starts_at, 'MMM D') }} -
          {{ $helpers.dateSystemWithoutTimezoneFormat(message.booking.ends_at, 'MMM D') }}
        </span>
      </div>
      <Icon
        name="outlined/chevron_right"
        variant="secondary"
      />
    </div>

    <div v-if="message.isAuthor(currentUser.id)" />
    <div
      v-else-if="message.booking.status === 'review'"
      class="control is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap is-justify-content-space-evenly"
    >
      <PsButton
        ref="rejectButton"
        color="secondary"
        class="is-fullwidth"
        @click="bookingReject"
      >
        Reject
      </PsButton>
      <PsButton
        ref="acceptButton"
        color="success"
        class="is-fullwidth"
        @click="bookingAccept"
      >
        Accept
      </PsButton>
    </div>
    <div
      v-else
      class="help"
    >
      You already responded to this request, booking is now {{ message.booking.status }}
    </div>

    <hr :class="[message.isAuthor(currentUser.id) ? 'white' : '']">

    <p>{{ message.body }}</p>
  </div>
</template>
<script>
import BookingStatus from "@/elements/BookingStatus.vue";
import ListingImage from "@/elements/ListingImage.vue";

import {useBookings} from "@/rental/stores/bookings";

export default {
  name: "BookingRequestedMessage",
  components: { BookingStatus, ListingImage },
  inject: ["currentUser"],
  props: ["message"],
  data() {
    return {
      store: useBookings(),
    };
  },
  methods: {
    async bookingReject() {
      await this.$helpers.useLoading(this.$refs.rejectButton, async () => {
        const result = await this.store.rejectBooking(this.message.booking.id);
        Object.assign(this.message.booking, result);
      });
    },
    async bookingAccept() {
      await this.$helpers.useLoading(this.$refs.acceptButton, async () => {
        const result = await this.store.acceptBooking(this.message.booking.id);
        Object.assign(this.message.booking, result);
      });
    },
    gotoBooking(bookingId) {
      this.$router.push({
        name: "accountBooking",
        params: {
          bookingId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  line-height: 1;
  font-size: 14px;

  hr {
    margin-top: 0.5em;

    &.white {
      background-color: white;
    }
  }

  p {
    margin: 1em 0 0.5em;
  }

  label {
    font-weight: 600;
  }
}
</style>
