<template>
  <div class="content">
    <div>
      <h2>
        <b>1. Insurance coverage</b>
      </h2>
      <p>
        <b>A.</b> The Company provides insurance coverage for incidents occurring during the rental
        period, up to a maximum coverage of $4,000 per occurrence ("Coverage Limit").
      </p>
      <p><b>B.</b> All claims are subject to a deductible of $250 per occurrence.</p>
      <p>
        <b>C.</b> The insurance coverage is secondary to all private insurance policies held by both
        the renter and the owner, as well as any other forms of mitigation efforts such as security
        deposits, all other insurance coverages from credit cards, private insurance policies, and
        all other reasonable recovery efforts permitted by law.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>2. Claims process</b>
      </h2>
      <p>
        <b>A.</b> Claims must be filed within 7 business days from the date an item was scheduled
        for return or returned damaged.
      </p>
      <p>
        <b>B.</b> The claimant must provide adequate proof of loss and mitigation efforts to the
        Company including police report if item was stolen.
      </p>
      <p><b>C.</b> The Company reserves the right to investigate and assess the claim.</p>
      <p>
        <b>D.</b> Claims are subject to review and approval by the Company. Approval of a claim is
        at the Company's sole discretion.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>3. Exclusions</b>
      </h2>
      <p>
        <b>A.</b> The insurance coverage does not apply to acts of God, including but not limited to
        natural disasters.
      </p>
      <p>
        <b>B.</b> The insurance coverage does not extend to incidents resulting from negligence of
        the claimant.
      </p>
      <p>
        <b>C.</b> Claims may be denied if the claimant fails to provide adequate proof of loss or
        mitigation efforts.
      </p>
      <p>
        <b>D.</b> The Company reserves the right to exclude other reasonable exclusions as deemed
        necessary.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>4. Limitation of Liability</b>
      </h2>
      <p>
        <b>A.</b> The Company's liability under this insurance coverage is limited to the Coverage
        Limit per occurrence.
      </p>
      <p>
        <b>B.</b> The Company shall not be liable for any incidental, consequential, or punitive
        damages arising from the use of its services, or any incidents related thereto.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>5. Termination or Modification</b>
      </h2>
      <p>
        <b>A.</b> The Company reserves the right to modify or terminate the insurance coverage at
        any time without prior notice.
      </p>
      <p>
        <b>B.</b> The Company may suspend or revoke insurance coverage for participants who violate
        our terms of service or engage in fraudulent activities.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>6. Governing Law</b>
      </h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of Utah, without
        regard to its conflict of law principles.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>7. Acceptance of Terms</b>
      </h2>
      <p>
        By participating in peer-to-peer rental activities facilitated by the Company, you
        acknowledge and agree to be bound by these Terms and Conditions.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>8. Contact information</b>
      </h2>
      <p>
        For any inquiries regarding insurance coverage or to report an incident, please contact
        <b>Pretty Shell LLC</b> at
        <a href="mailto:customerservice@prettyshell.com">customerservice@prettyshell.com</a>.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>9. Severability</b>
      </h2>
      <p>
        If any provision of these Terms is deemed invalid or unenforceable, the remaining provisions
        shall remain in full force and effect.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>10. Entire Agreement</b>
      </h2>
      <p>
        These Terms constitute the entire agreement between the parties regarding insurance coverage
        for peer-to-peer rental activities and supersede all prior or contemporaneous agreements and
        understandings.
      </p>
      <p>
        By accepting these Terms, you acknowledge that you have
        <b>read, understood, and agree</b> to be bound by all of its provisions. If you do not agree
        with any part of these Terms, please refrain from using our services.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss"></style>
