<template>
  <svg
    fill="none"
    height="15"
    viewBox="0 0 16 15"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11.9525L12.635 14.75L11.405 9.4775L15.5 5.93L10.1075 5.4725L8 0.5L5.8925 5.4725L0.5 5.93L4.595 9.4775L3.365 14.75L8 11.9525Z"
    />
  </svg>
</template>
