import type { AxiosError } from "axios";

export class DomainError extends Error {
  tl_key = "domain-error";
}

export class InvalidArgumentsError extends DomainError {
  /* A component has been called with invalid arguments, making it impossible to render */
}

export class CompileError extends DomainError {}

export class ClientError extends DomainError {}

export class SessionExpiredError extends DomainError {
  tl_key = "session-expired";
}

export class ObjectNotFoundError extends DomainError {
  tl_key = "object-not-found";

  constructor(message?: string) {
    super(message);
    this.name = "ObjectNotFoundError";
  }
}

export class InvalidActionError extends ClientError {
  tl_key = "invalid-action";
}

export class ApiError extends DomainError {
  name = "ApiError";
  tl_key = "api-error";
  error: AxiosError;

  constructor(error: AxiosError) {
    super("APIError");
    this.error = error;
    this.message = this.createMessage();
  }

  createMessage() {
    return `ApiError on ${this.error.config?.method} to ${this.error.config?.url}`;
  }
}
export class ApiValidationError extends ApiError {
  name = "ApiValidationError";
  tl_key = "api-validation-error";

  createMessage(): string {
    const responseData = this.error.response?.data;
    let detail = "Unknown error";
    if (responseData?.detail) {
      return responseData.detail;
    }
    if (Array.isArray(responseData)) {
      return responseData.join(", ");
    }
    if (responseData instanceof Object) {
      detail = Object.entries(responseData)
        .map(([key, value]: [string, string]) => `${key}: ${value}`)
        .join(",");
    }
    return detail;
  }
}
