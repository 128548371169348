import {type CollectionOpts, GenericCollection, type ListQueryParams, Resource, type Service} from "@/plugins/Api";
import {Collection, useApi} from "@/plugins/Api";

type ResourceOpts<T extends Model, TApi extends Model> = CollectionOpts<T, TApi>;

export interface Model {
  id: string;
}

export interface ApiCollectionModel extends Model {
  created_at: string;
  updated_at: string;
}

export const useApiCollection = <T extends Model, TApi extends Model>(path: string, opts: CollectionOpts<T, TApi>) => {
  const api = useApi();
  return Collection<T>(api.c as unknown as Service, path, opts);
}

export const useApiResource = <T extends Model, TApi extends Model>(path: string, opts: ResourceOpts<T, TApi>) => {
  const api = useApi();
  return Resource<T>(api.c as unknown as Service, path, opts);
}

export const useGenericApiCollection = <T, TApi>(path: string, opts: CollectionOpts<T, TApi>) => {
  const api = useApi();
  return GenericCollection<T, TApi>(api.c as unknown as Service, path, opts);
}

export type {ListQueryParams}
