<template>
  <div class="field is-relative search-bar">
    <div
      :class="isActivated ? 'is-active' : null"
      class="control has-icons-right"
    >
      <FormKit
        v-model="query"
        autocomplete="nofill"
        input-class="searchinput is-rounded"
        name="search"
        outer-class="searchinput-outer"
        placeholder="What are you looking for?"
        type="text"
        @focusin="onFocus"
        @focusout="onFocusOut"
        @keydown.enter="handleSearch"
      />
      <Icon
        v-if="!!query"
        class="button icon-remove is-right is-rounded is-clickable"
        name="close"
        variant="secondary"
        @click="onClearSearch"
      />
      <Icon
        :variant="isActivated ? 'primary' : 'secondary'"
        class="button icon-search is-right is-rounded is-clickable"
        name="search"
        @click="handleSearch"
      />
    </div>
    <div
      v-show="isActivated"
      class="search-dropdown"
    >
      <div
        v-if="query.length && !isLoading"
        class="results"
      >
        <h5>Categories</h5>
        <div
          v-if="store.categories.length === 0"
          class="empty"
        >
          No categories found
        </div>
        <ul v-else>
          <li
            v-for="item in store.categories"
            :key="item.id"
            @click="gotoCategory(item)"
          >
            <Icon :name="item.icon" />
            <label v-html="$helpers.highlight(item.label, query)" />
          </li>
        </ul>
        <h5>Results</h5>
        <div
          v-if="store.items.length === 0"
          class="empty"
        >
          No results found
        </div>
        <ul v-else>
          <li
            v-for="item in store.items"
            :key="item.id"
            class="item"
            @click="gotoListing(item)"
          >
            <Icon
              name="list"
              variant="secondary"
            />
            <label v-html="$helpers.highlight(item.label, query)" />
          </li>
        </ul>
      </div>
      <div
        v-else-if="query.length && isLoading"
        class="empty"
      >
        Loading results...
      </div>
      <div
        v-else
        class="empty"
      >
        Start typing for results...
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watchDebounced } from "@vueuse/core";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ObjectNotFoundError } from "@/errors";
import { useCategories } from "@/rental/stores/categories";
import { type SearchResult, useSearch } from "@/store/search";

const store = useSearch();
const route = useRoute();
const props = defineProps({
  initialQuery: {
    type: String,
    default: "",
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();

const categoryStore = useCategories();
const isActivated = ref(props.isActive || false);
const isLoading = ref(false);
const query = ref<string>(route.query.q || props.initialQuery || "");

async function handleSuggest() {
  isLoading.value = true;
  await store.doQuery(query.value);
  isLoading.value = false;
}

function handleSearch() {
  router.replace({
    name: "listings",
    query: {
      q: query.value,
    },
  });
  isActivated.value = false;
}

function onFocus() {
  isActivated.value = true;
}

function onFocusOut() {
  setTimeout(() => {
    isActivated.value = false;
  }, 1000);
}

function onClearSearch() {
  query.value = "";
  onFocusOut();
  handleSearch();
}

function gotoListing(listing) {
  router.push({
    name: "listing",
    params: {
      id: listing.object_id,
    },
  });
}

function gotoCategory(result: SearchResult) {
  const category = categoryStore.getById(result.object_id);

  if (!category) {
    throw new ObjectNotFoundError("Category does not exist");
  }

  query.value = "";
  const params = {
    mainCategoryName: "",
    subCategoryName: "",
  };
  if (category.parent) {
    params.mainCategoryName = category.parent.name;
    params.subCategoryName = category.name;
  } else {
    params.mainCategoryName = category.name;
  }
  router.push({
    name: "listingsCategory",
    params,
  });
}

onMounted(() => {
  if (query.value && isActivated.value) {
    handleSuggest();
  }
});

watchDebounced(query, handleSuggest, {
  debounce: 500,
  maxWait: 1000,
});
</script>

<style lang="scss">
@import '../styles/mixins.scss';

.search-bar {
  position: relative;

  .searchinput-outer {
    margin-bottom: 0 !important;
  }

  .searchinput {
    height: 3em;
  }

  .icon {
    &.icon-search {
      height: calc(2em - 8px) !important;
      width: calc(2em - 8px) !important;
      margin: 4px;
      padding: 0;
      border: none;
    }

    &.icon-remove {
      height: calc(2em - 16px) !important;
      width: calc(2em - 16px) !important;
      margin: 8px;
      margin-right: 52px;
      padding: 0;
      border: none;
    }
  }

  .search-dropdown {
    position: absolute;
    width: 120%;
    max-width: 100vw;
    max-height: calc(95vh - 80px);
    overflow-y: scroll;
    margin-left: -10%;
    margin-top: 0.25em;
    z-index: 90;
    background-color: var(--gray-snow);
    border-radius: 12px;
    box-shadow:
      0px 0px 0px 1px rgba(6, 30, 62, 0.1),
      0px 2px 6px -2px rgba(6, 30, 62, 0.25);
    padding: 1em 0;
    color: var(--gray-slate);

    @include touch {
      width: 100%;
      max-height: calc(95vh - 132px);
      margin: 0;
      margin-top: 0.25em;
      left: 0;
      right: 0;
    }

    .results {
      display: flex;
      flex-direction: column;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 2em;
    display: block;
    margin: 0.5em 0;
    padding: 0 1em;
  }

  .empty {
    padding: 1em;
    color: var(--gray-muted);
  }

  ul li {
    padding: 1em;
    height: 3em;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 8px;

    .icon {
      width: 1em !important;
      margin: 0.25em;
      padding: 0.5em;
      flex-grow: 0;
    }

    label {
      padding: 1em 0;
      color: var(--gray-muted);
      cursor: pointer;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-grow: 0;

      span.highlight {
        font-weight: 600;
        color: var(--gray-slate);
      }
    }

    &:hover {
      background-color: var(--gray-mist);
    }
  }
}
</style>
