<template>
  <div class="field">
    <div class="control">
      <label>Location</label>
      <FormKit
        v-bind="context.attrs"
        :key="formData"
        :ignore="true"
        :lookup-handler="lookupHandler"
        :no-result-handler="showModal"
        :search-handler="searchLocation"
        :value="formData"
        name="mapInput"
        placeholder="Enter address"
        help="For your safety, we recommend choosing a public location to meet your renters. Choosing your home address is at your own risk."
        type="autocomplete"
        inner-class="has-icons-left"
        @input="handleChange"
      >
        <template #prefixIcon>
          <Icon
            class="formkit-prefix-icon formkit-icon"
            name="outlined/location_on"
            variant="inherit"
          />
        </template>
      </FormKit>
    </div>
  </div>
  <div
    :class="modalActive ? 'is-active' : null"
    class="modal"
  >
    <div class="modal-background" />
    <div class="modal-content">
      <div class="box">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="title is-5">
                Address
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <PsButton
                color="secondary"
                icon="close"
                is-pill
                @click.prevent="closeModal"
              />
            </div>
          </div>
        </div>
        <div v-if="modalActive && step === 1">
          <FormKit
            :actions="false"
            :value="formData"
            preserve
            type="form"
            @submit="nextStep"
          >
            <FormKit
              :options="pickup_country_options"
              inner-class="is-block"
              label="Country"
              name="country"
              placeholder="Select a country"
              type="select"
              validation="required"
            />
            <FormKit
              help="You can fill in a street address or a public place like the library."
              label="Address or public place"
              name="streetAddress"
              type="text"
              validation="required"
            />
            <div class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap">
              <FormKit
                label="City"
                name="city"
                type="text"
                validation="required"
              />
              <FormKit
                label="State"
                name="state"
                type="text"
                validation="required"
              />
            </div>
            <div class="level my-2">
              <div class="level-left" />
              <div class="level-right">
                <div class="level-item">
                  <FormKit type="submit">
                    Next
                  </FormKit>
                </div>
              </div>
            </div>
          </FormKit>
        </div>
        <div v-if="step === 2">
          <PMap
            :initial-coordinates="position"
            :initial-marker="marker"
            :initial-zoom="context.zoom || 10"
            data-chromatic="ignore"
            @update:marker-location="handleLocation"
          />
          <div class="level my-2">
            <div class="level-left" />
            <div class="level-right">
              <div class="level-item">
                <PsButton
                  color="secondary"
                  @click.prevent="previousStep"
                >
                  Back
                </PsButton>
              </div>
              <div class="level-item">
                <PsButton @click.prevent="confirm">
                  Confirm location
                </PsButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import PMap from "@/components/Map.vue";

import { PICKUP_COUNTRY_OPTIONS } from "@/rental/constants";

export default {
  components: {
    PMap,
  },
  props: {
    context: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      pickup_country_options: PICKUP_COUNTRY_OPTIONS,
      formData: this.context._value || {},
      modalActive: this.context.modalActive || false,
      position: this.context.initialCoordinates,
      step: this.context.initialStep || 1,
    };
  },
  computed: {
    marker() {
      return {
        coordinates: this.position,
      };
    },
    locationString() {
      if (!this.formData.streetAddress) {
        return null;
      }
      const v = this.formData;
      return [v.street_address, v.city, v.state].join(", ");
    },
  },
  methods: {
    showModal() {
      this.modalActive = true;
    },
    closeModal() {
      this.modalActive = false;
    },
    previousStep() {
      this.step = 1;
    },
    confirm() {
      this.closeModal();
    },
    async handleChange(v) {
      Object.assign(this.formData, v);
      const geoEncoder = this.context.geoEncoder || this.geoEncode;
      const result = await geoEncoder(v);
      const resultItem = result.items[0];
      const positionArray = [resultItem.position.lat, resultItem.position.lng];
      await this.handleForm(v);
      await this.handleLocation(resultItem.position);
      this.position = positionArray;
    },
    async searchLocation(v) {
      const params = {
        q: v,
        in: "countryCode:USA,MEX,NLD",
        apiKey: import.meta.env.VITE_APP_MAPS_API_KEY,
      };
      const response = await axios.get(
        "https://autocomplete.search.hereapi.com/v1/autocomplete",
        {
          params,
        },
      );
      return response.data.items.map((el) => ({
        label: el.address.label,
        value: {
          country: el.address.countryName,
          streetAddress: el.address.houseNumber
            ? `${el.address.street} ${el.address.houseNumber}`
            : el.address.street,
          city: el.address.city,
          state: el.address.state,
        },
      }));
    },
    lookupHandler(locationItem) {
      const v = locationItem.value ? locationItem.value : locationItem;
      if (!v?.streetAddress) {
        return null;
      }
      return {
        label: [v.streetAddress, v.city, v.state, v.country].join(", "),
        value: v.value ? v.value : v,
      };
    },
    async geoEncode(data) {
      const params = [
        data.streetAddress,
        data.city,
        data.state,
        data.country,
      ].filter((el) => el !== null);
      const query = params.join(", ");
      const response = await axios.get(
        "https://geocode.search.hereapi.com/v1/geocode",
        {
          params: {
            q: query,
            apiKey: import.meta.env.VITE_APP_MAPS_API_KEY,
          },
        },
      );
      return response.data;
    },
    async nextStep(data) {
      const geoEncoder = this.context.geoEncoder || this.geoEncode;
      const result = await geoEncoder(data);
      const resultItem = result.items[0];
      const positionArray = [resultItem.position.lat, resultItem.position.lng];
      await this.handleForm(data);
      await this.handleLocation(resultItem.position);
      this.position = positionArray;
      Object.assign(this.formData, data);
      this.step = 2;
    },
    handleForm(e) {
      return this.context.node.input(e.value ? e.value : e);
    },
    handleLocation(e) {
      const newValue = {
        ...this.context._value,
        position: [e.lng, e.lat],
        latitude: e.lat,
        longitude: e.lng,
      };
      return this.context.node.input(newValue);
    },
  },
};
</script>
