import { type Ref, ref, watch } from "vue";

import formats, { type AnyDateType } from "@/helpers/formats";
import {
  type Listing,
  usePublicListings,
} from "@/rental/stores/listings";
import type {Pricing} from "@/rental/composables/pricing";

export interface bookerCostsOpts {
  listingId: Listing["id"];
  dateRange: Ref<[AnyDateType, AnyDateType | undefined]>;
  pickupTimeRange: Ref<[string, string] | undefined>;
  dropoffTimeRange: Ref<[string, string] | undefined>;
}

const toHourMinutes = (s: string) => {
  return s.split(":");
};

const combineWith = (d: AnyDateType, s: string) => {
  const [hour, minute] = toHourMinutes(s);
  return formats.dateTimeSystemWithoutTimezoneFormat(
    formats.combine(d, {
      hours: hour,
      minutes: minute,
    }),
  );
};

export const useBookerCosts = (opts: bookerCostsOpts) => {
  const listings = usePublicListings();
  const pricing: Ref<Pricing | undefined> = ref(undefined);
  watch(
    [opts.dateRange, opts.dropoffTimeRange, opts.pickupTimeRange],
    async (newValue) => {
      const [dateRange, dropoffTimeRange, pickupTimeRange] = newValue;
      if (dateRange.length !== 2 || !dateRange[1]) {
        return undefined;
      }
      const [startsAt, endsAt] = dateRange;
      pricing.value = await listings.costs(
        opts.listingId,
        pickupTimeRange ? combineWith(startsAt, pickupTimeRange[0]) : startsAt,
        dropoffTimeRange ? combineWith(endsAt, dropoffTimeRange[0]) : endsAt,
      );
    },
    { immediate: true },
  );
  return pricing;
};
