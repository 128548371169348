<template>
  <div
    :class="['is-' + size, ...(src ? ['has-img'] : [])]"
    class="photo-input is-empty is-clickable is-justify-content-center is-align-content-center is-align-items-center"
    @click="initFilet"
  >
    <div
      v-if="!src"
      class="photo-control"
    >
      <PsButton
        :is-loading="loading"
        color="secondary"
        full-width
        icon="plus"
        styling="ghost"
        @click.prevent="initFile"
      />
    </div>
    <div
      v-else
      class="photo-container"
    >
      <div class="photo-overlay">
        <PsButton
          color="secondary"
          is-pill
          is-rounded
          styling="ghost"
          @click.prevent="removePhoto"
        >
          <Icon
            name="outlined/delete"
            variant="white"
          />
        </PsButton>
      </div>
      <img
        :src="src"
        @click.prevent="initFile"
      >
    </div>

    <input
      v-show="false"
      :id="nodeId + '_file'"
      accept="image/png, image/jpeg"
      type="file"
      @input="handleChange"
    >
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      nodeId: this.$id(),
      loading: false,
      size: this.context.size,
      src: this.context._value ? this.getSrc(this.context._value) : null,
    };
  },
  methods: {
    getSrc(f) {
      try {
        return URL.createObjectURL(f);
      } catch {
        return this.$helpers.CDNImage(f.url);
      }
    },
    removePhoto() {
      this.src = undefined;
      this.context.node.input(undefined);
    },
    initFile() {
      const el = document.getElementById(this.nodeId + "_file");
      el.click();
    },
    async handleChange(e) {
      const f = e.target.files[0];
      this.loading = true;
      const upload = await this.$helpers.uploader(f);
      this.src = this.getSrc(f);
      this.context.node.input(upload);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.photo-input {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-smoke);
  border-radius: 4px;
  position: relative;

  @include touch {
    padding: 0;
  }

  &.has-img:hover {
    .photo-container::before {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $gray-A10;
    }

    .photo-container {
      background-color: $gray-A10;
    }
  }

  .photo-container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;

    .photo-overlay {
      position: absolute;
      z-index: 5;
      width: 100%;
      padding: 1em;
      display: flex;
      justify-content: flex-end;
    }
  }

  &.is-big {
    height: 246px;

    @include touch {
      height: 200px;
    }
  }

  &.is-small {
    height: 119px;

    @include touch {
      height: 75px;
    }
  }

  .photo-control {
    flex-grow: 1;
    width: 100% !important;
    height: 100% !important;

    & > * {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
