<script setup lang="ts">
const props = defineProps<{
  label: string;
}>();
</script>

<template>
  <div class="is-flex is-align-items-center flex-gap-2 is-flex-wrap-wrap readonly-form">
    <label>{{ props.label }}</label>
    <span><slot /></span>
  </div>
</template>

<style scoped lang="scss">
@import '../../../styles/variables.scss';

label {
  color: $gray-muted;
}
</style>
