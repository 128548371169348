import { defineStore } from "pinia";
import { type Ref, computed } from "vue";

import { useCollection } from "@/helpers/store";
import { useApi } from "@/plugins/Api";
import { Actor } from "@/store/user";
import type { Booking } from "@/store/rental/models";
import {useBookings} from "@/rental/stores/bookings";

class Transaction {
  id: string;
  createdAt: string;
  recipient: Actor;
  bookingId: Booking["id"];
  sender: Actor;
  amount: number;
  status: string;
  statusReason?: string;
  type = "transaction";

  constructor(params) {
    this.id = params.id;
    this.createdAt = params.created_at;
    this.bookingId = params.booking_id;
    this.recipient = new Actor(params.recipient);
    this.sender = new Actor(params.sender);
    this.amount = params.amount;
    this.status = params.status;
    this.statusReason = params.status_reason;
  }

  get booking() {
    const store = useBookings();
    return store.getById(this.bookingId);
  }
}

export class Payment extends Transaction {
  type = "payment";

  get user(): Actor {
    return this.recipient;
  }
}

export class Payout extends Transaction {
  type = "payout";

  get user(): Actor {
    return this.recipient;
  }
}

const useTotals = (payments: Ref<Payment[]>, payouts: Ref<Payout[]>) => {
  const totalOut = computed(() =>
    payments.value.reduce((val, el) => el.amount + val, 0),
  );
  const totalIn = computed(() =>
    payouts.value.reduce((val, el) => el.amount + val, 0),
  );
  const total = computed(() => totalIn.value - totalOut.value);
  return {
    totalIn,
    totalOut,
    total,
  };
};

export const useTransactionStore = defineStore("transactions", () => {
  const api = useApi();
  const payments = useCollection<Payment>(async () => {
    const result = await api.c.payments.list();
    return [...result.map((el) => new Payment(el))];
  });
  const payouts = useCollection<Payout>(async () => {
    const result = await api.c.payouts.list();
    return [...result.map((el) => new Payout(el))];
  });

  const items = computed<(Payment | Payout)[]>(() => {
    return (
      [...payments.items.value, ...payouts.items.value]
        // Sort by date created, newest first
        .sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1))
    );
  });

  const isLoading = computed(() => {
    return payments.isLoading.value || payouts.isLoading.value;
  });

  const isEmpty = computed(() => {
    return payments.isEmpty.value && payouts.isEmpty.value;
  });

  const sync = async () => {
    await payments.sync();
    await payouts.sync();
  };

  return {
    sync,
    items,
    isLoading,
    isEmpty,
    ...useTotals(payments.items, payouts.items),
    payouts,
    payments,
  };
});
