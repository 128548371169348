<template>
  <FormKit id="emailSignin" v-model="formData" :disabled="loading" :actions="false" type="form">
    <div class="field">
      <FormKit name="email" placeholder="Email address" type="email" validation="required|email"
        validation-label="Email address" />
    </div>
    <div class="field">
      <FormKit name="password" placeholder="Password" type="password" validation="required" validation-label="Password"
        suffix-icon="eyeClosed" @suffix-icon-click="handlePasswordShowIconClick" />
    </div>
    <div class="field">
      <PsButton full-width :is-loading="loading" @click.prevent="handleSignIn">Sign in</PsButton>
    </div>
  </FormKit>
</template>

<script setup>
import { FormKit } from "@formkit/vue";
import { ref } from 'vue';

const emit = defineEmits(["sign-in"]);

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const formData = ref({});

function handleSignIn() {
  emit("sign-in", {
    formData: formData.value,
  });
}

function handlePasswordShowIconClick(node) {
  node.props.suffixIcon = node.props.suffixIcon === "eye" ? "eyeClosed" : "eye";
  node.props.type = node.props.type === "password" ? "text" : "password";
}
</script>
