<template>
  <div
    :class="classes"
    :style="imageStyle"
  >
    <img
      v-if="src"
      :src="src"
      @error="
        (e) => {
          e.preventDefault();
          e.target.style.display = 'none';
        }
      "
    >
  </div>
</template>

<script>
export default {
  props: ["src", "size"],
  computed: {
    imageStyle() {
      return '--placeholder-src: url("/thumbnail.png");';
    },
    classes() {
      return ["listing-image", "is-3by3", `is-${this.size || "medium`"}`];
    },
  },
};
</script>

<style scoped lang="scss">
.listing-image {
  width: 100%;
  height: 180px;

  display: flex;
  justify-content: center;

  border: 1px solid rgba(6, 30, 62, 0.1);
  border-radius: 8px;
  background-image: var(--placeholder-src);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.is-small {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--gray-alpha-gray-a-10, rgba(6, 30, 62, 0.1));
    width: 56px;
    height: 56px;
  }

  img {
    object-fit: cover;
  }
}
</style>
