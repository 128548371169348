<template>
  <NotificationCard :notification="notification">
    <template #side>
      <div class="booking-preview">
        <img
          v-if="listingImage"
          :alt="listing.name"
          :onError="
            (e) => {
              const target = e.target as HTMLImageElement;
              if (target) target.src = '/thumbnail.png';
            }
          "
          :src="listingImage"
        >
        <Icon
          v-else
          :name="listing.category.icon_name ?? listing.category.icon"
          variant="secondary"
        />
      </div>
    </template>

    <template #actions>
      <BookingActions
        v-if="isFirst"
        :booking="booking"
        :perspective="perspective"
        :status="booking.status"
        disable-emit-action
      />
    </template>
  </NotificationCard>
</template>

<script lang="ts" setup>
import {computed} from "vue";

import NotificationCard from "@/components/account/notifications/NotificationCard.vue";
import BookingActions from "@/components/rental/booking-actions/BookingActions.vue";

import type {AccountNotification} from "@/account/stores/notifications";
import {useBookings} from "@/rental/stores/bookings";
import {getCurrentUser} from "@/app/stores/session";
import {storeToRefs} from "pinia";
import {until} from "@vueuse/core";

const props = defineProps<{
  notification: AccountNotification;
  isFirst: boolean;
}>();

const currentUser = getCurrentUser();
const bookings = useBookings();

const {isLoading} = storeToRefs(bookings);

await until(isLoading).toBe(false);

const booking = bookings.getById(props.notification.event.messagePayload.booking_id);

const perspective = computed(() => {
  return booking.isOwner(currentUser.id) ? "owner" : "renter";
});

const listing = booking.listing;
const listingImage = computed<string | undefined>(() => {
  if (listing.hasImage) {
    return listing.mainImageSrc;
  }
  return undefined;
});
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.booking-preview {
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: 8px;
  background-color: $gray-mist;
  border: 1px solid opacify($gray-A20, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    object-position: center center;
    transition: 0.3s;
  }

  .icon {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}
</style>
