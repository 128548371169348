<template>
  <Modal
    title="Forgot password"
    :model-value="isActive"
    size="small"
    @update:model-value="checkModalState"
  >
    <ForgotPassword
      :is-active="isActive"
      :initial-step="initialStep"
    />
  </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";

import ForgotPassword from "./ForgotPassword.vue";
import {inject} from "vue";

defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  initialStep: {
    type: String,
    default: "enter",
  },
});

function checkModalState(value) {
  if (value === false) {
    closeModal();
  }
}

const { closeModal } = inject('authOverlay');
</script>

<style lang="scss" scoped></style>
