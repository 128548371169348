import {CostType, CostUnitType} from "@/rental/types";

export interface ApiPricingItem {
  total: number;
  ppu: number;
  quantity: number;
  cost_type: CostType;
  unit_type: CostUnitType;
  owed_by: "owner" | "renter";
}

export interface PricingItem {
  amount: number; // cost of this pricing item (ppu * units)
  ppu: number; // price per unit
  units: number; // amount of units
  costType: CostType;
  unitType: CostUnitType;
  owedBy: "owner" | "renter";
}

export const pricingItemDeserializer = (obj: ApiPricingItem): PricingItem => ({
  amount: obj.total / 100,
  ppu: obj.ppu / 100,
  units: obj.quantity,
  costType: obj.cost_type,
  unitType: obj.unit_type,
  owedBy: obj.owed_by,
});

export class Pricing {
  items: PricingItem[];

  constructor(items: PricingItem[]) {
    this.items = items;
  }

  get total() {
    return this.sumItems(this.items);
  }

  get rentalCosts() {
    return this.sumBy(CostType.RENTAL_COST);
  }

  get rentalItems() {
    return this.filter(CostType.RENTAL_COST);
  }

  get fees() {
    return this.sumItems(this.filter(CostType.FEE));
  }

  get taxes() {
    return this.sumItems(this.filter(CostType.TAX));
  }

  get insurance() {
    return this.sumItems(this.filter(CostType.INSURANCE));
  }

  get hasInsurance() {
    return this.filter(CostType.INSURANCE).length > 0;
  }

  get hasDeposit() {
    return this.filter(CostType.DEPOSIT).length > 0;
  }

  get hasProtection() {
    return this.hasInsurance || this.hasDeposit;
  }

  filter(costType: CostType) {
    return this.items.filter((el) => el.costType === costType);
  }

  sumItems(items: PricingItem[]) {
    return Number(
      items.reduce((value, currentValue) => currentValue.amount, 0),
    );
  }

  sumBy(costType: CostType) {
    return this.sumItems(this.filter(costType));
  }

  addDeposit(amount: number) {
    this.items.push({
      amount,
      ppu: amount,
      costType: CostType.DEPOSIT,
      unitType: CostUnitType.rental,
      units: 1,
      owedBy: "renter",
    });
  }

  addInsurance(ratio: number) {
    const amount = this.rentalCosts * ratio;
    this.items.push({
      amount: amount,
      ppu: amount,
      costType: CostType.INSURANCE,
      unitType: CostUnitType.rental,
      units: 1,
      owedBy: "renter",
    });
  }

  addFee(ratio: number) {
    const amount = this.rentalCosts * ratio;
    this.items.push({
      amount: amount,
      ppu: amount,
      costType: CostType.FEE,
      unitType: CostUnitType.rental,
      units: 1,
      owedBy: "renter",
    });
  }

  addTax(ratio: number) {
    const amount = this.rentalCosts * ratio;
    this.items.push({
      amount: amount,
      ppu: amount,
      costType: CostType.TAX,
      unitType: CostUnitType.rental,
      units: 1,
      owedBy: "renter",
    });
  }
}
