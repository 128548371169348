<template>
  <p class="title is-3">
    Bookings
  </p>

  <div v-if="store.isLoading && !store.items.length">
    <LoadingFullSection/>
  </div>
  <CardGallery
    v-else
    hide-controls
  >
    <template #modifiers>
      <div class="filter-groups">
        <Filtering
          :active-item="currentFilterId"
          :items="filters"
          @filtered="handleFilterChange($event)"
        />

        <Filtering
          :active-item="currentFilterId"
          :items="filtersSecondary"
          hide-counts
          @filtered="handleFilterChange($event)"
        />
      </div>
    </template>
    <template #default="slotProps">
      <EmptyState v-if="!currentFilterGroup?.items?.length">
        <p>
          You don't have any "{{ currentFilterGroup?.label }}" bookings yet.<br>
          {{ currentFilterGroup?.label === 'Requests' ? "Let's get a-move on!" : '' }}
        </p>
      </EmptyState>
      <BookingCard
        v-for="booking in currentFilterGroup.items"
        v-else
        :key="booking.id"
        :booking="booking"
        :orientation="slotProps.orientation"
        :show-status="true"
        is-clickable
        show-highlights
        :show-pickup-time-range="false"
        :show-pickup-location="false"
        show-booking-period
        show-next-action
        size="medium"
      >
        <template #actions>
          <BookingActions
            :booking="booking"
            :perspective="booking.isOwner(currentUser.id) ? 'owner' : 'renter'"
            :status="booking.status"
            @booking-action="handleAction"
          />
        </template>
      </BookingCard>
    </template>
  </CardGallery>
</template>

<script lang="ts" setup>
import {computed, type Ref, ref} from "vue";
import {useRouter} from "vue-router";

import BookingCard from "@/components/BookingCard.vue";
import CardGallery from "@/components/CardGallery.vue";
import EmptyState from "@/components/EmptyState.vue";
import Filtering from "@/components/Filtering.vue";
import BookingActions from "@/components/rental/booking-actions/BookingActions.vue";
import LoadingFullSection from "@/sections/LoadingFull.vue";

import {ApiValidationError} from "@/errors";
import {useHelpers} from "@/plugins/Form";
import {useAppNotifications} from "@/plugins/notifications";
import type {Booking} from "@/store/rental/models";
import {useBookings} from "@/rental/stores/bookings";
import {useSession} from "@/app/stores/session";
import {BookingStatus} from "@/rental/types";
import {storeToRefs} from "pinia";

const session = useSession();
const router = useRouter();
const helpers = useHelpers();
const notify = useAppNotifications();
const store = useBookings();

const {user: currentUser} = storeToRefs(session);

const requestedBookings = store.useFiltered(({status}) => status === BookingStatus.review);
const confirmedBookings = store.useFiltered(({status}) => {
  const confirmedStatuses: BookingStatus[] = [
    BookingStatus.pending, BookingStatus.open, BookingStatus.waiting_pickup_renter, BookingStatus.waiting_pickup_lender,
    BookingStatus.active, BookingStatus.waiting_deliver_renter, BookingStatus.waiting_deliver_lender,
    BookingStatus.confirmed, BookingStatus.ready_for_pickup,
  ]
  return confirmedStatuses.includes(status);
});
const completedBookings = store.useFiltered(({status}) => status === BookingStatus.closed);
const disputedBookings = store.useFiltered(({status}) => status === BookingStatus.disputed);
const declinedBookings = store.useFiltered(({status}) => status === BookingStatus.rejected);
const canceledBookings = store.useFiltered(({status}) => {
  const canceledStatuses: BookingStatus[] = [
    BookingStatus.canceled, BookingStatus.expired,
  ]
  return canceledStatuses.includes(status);
});

const filters = computed(() => {
  return [
    {
      id: 1,
      label: "Requests",
      items: requestedBookings.value,
    },
    {
      id: 2,
      label: "Confirmed",
      items: confirmedBookings.value,
    },
    {
      id: 3,
      label: "Completed",
      items: completedBookings.value,
    },
  ];
});

const filtersSecondary = computed(() => {
  return [
    {
      id: 100,
      label: "Disputed",
      items: disputedBookings.value,
    },
    {
      id: 101,
      label: "Declined",
      items: declinedBookings.value,
    },
    {
      id: 102,
      label: "Canceled",
      items: canceledBookings.value,
    },
  ];
});

const currentFilterId: Ref<number> = ref(
  filters.value.find((filter) => !!filter.items?.length)?.id ??
  filters.value[0].id,
);

const currentFilterGroup = computed(() => {
  const foundGroup = [...filters.value, ...filtersSecondary.value].find(
    (filter) => filter.id === currentFilterId.value,
  );

  return foundGroup;
});

function handleFilterChange(filterId: number) {
  currentFilterId.value = filterId;
}

interface ActionParams {
  action: keyof typeof actions;
  booking: Booking;
  node: HTMLElement;
}

const actions = {
  async acceptRequest(booking: Booking) {
    await store.acceptBooking(booking.id);
  },
  async rejectRequest(booking: Booking) {
    await store.rejectBooking(booking.id);
  },
  async cancel(booking: Booking) {
    try {
      await store.cancelBooking(booking.id);
    } catch (err) {
      if (err instanceof ApiValidationError) {
        notify.error(err.message);
      }
    }
  },
  async confirmPickup(booking: Booking) {
    await store.confirmPickup(booking.id);
  },
  async confirmReturn(booking: Booking) {
    await store.confirmReturn(booking.id);
  },
  async contactRenter(booking: Booking) {
    const chatId =
      booking.chatId ??
      (await store.createBookingChat(booking.id, booking.renter.id)).chatId;

    if (!chatId) return;

    await router.push({
      name: "chat",
      params: {
        chatId,
      },
    });
  },
  async contactOwner(booking: Booking) {
    const chatId =
      booking.chatId ??
      (await store.createBookingChat(booking.id, booking.lender.id)).chatId;

    if (!chatId) return;

    await router.push({
      name: "chat",
      params: {
        chatId,
      },
    });
  },
};

function handleAction(params: ActionParams) {
  helpers.useLoading(params.node, async () => {
    await actions[params.action](params.booking);
  });
}

</script>

<style lang="scss" scoped>
.filter-groups {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 8px;
}

.booking-actions {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
