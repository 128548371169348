<template>
  <div class="container">
    <article>
      <p class="title is-capitalized">
        {{ t('listing.category', 2) }}
      </p>
      <CategoryCollage :categories="categoryStore.getRootItems()" />
    </article>
  </div>
</template>

<script lang="ts" setup>
import CategoryCollage from "@/components/CategoryCollage.vue";

import {useCategories} from "@/rental/stores/categories";
import {useI18n} from "vue-i18n";

const { t } = useI18n();
const categoryStore = useCategories();
</script>

<style scoped></style>
