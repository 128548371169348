<script lang="ts" setup>
import CardGallery from "@/components/CardGallery.vue";
import EmptyState from "@/components/EmptyState.vue";
import ListingCard from "@/rental/components/ListingCard.vue";
import LoadingFullSection from "@/sections/LoadingFull.vue";

import { useFavoriteListings } from "@/rental/stores/listings";
import {storeToRefs} from "pinia";

const favorites = useFavoriteListings();
const {isLoading} = storeToRefs(favorites);

</script>

<template>
  <p class="title is-3">
    Saved listings
  </p>
  <div v-if="favorites.isLoading">
    <LoadingFullSection />
  </div>
  <div v-else-if="favorites.items.length === 0">
    <EmptyState>
      <p>
        You don't have any saved listings yet.<br>
        Hit the 'Save' button when viewing a listing to save them!
      </p>
      <div class="field is-grouped">
        <p class="control">
          <router-link :to="{ name: 'listings' }">
            <PsButton> Browse listings </PsButton>
          </router-link>
        </p>
      </div>
    </EmptyState>
  </div>
  <div v-else>
    <CardGallery>
      <template #default="slotProps">
        <ListingCard
          v-for="(listing, idx) in favorites.items"
          :key="idx"
          :listing="listing"
          :orientation="slotProps.orientation"
        />
      </template>
    </CardGallery>
  </div>
</template>

<style lang="scss" scoped></style>
