import { ActorIO, ReviewIO } from "@/io/account";

const locationHandlers = {
  lnglat: (v) => v,
  latlng: (v) => v.reverse(),
};
export const toPosition = (v, format = "lnglat") => {
  if (v.length !== 2) {
    return v;
  }
  const toLngLat = locationHandlers[format](v);
  return {
    longitude: toLngLat[0],
    latitude: toLngLat[1],
  };
};

export const toLocation = (d) => ({
  state_long: d.state,
  geo_position: toPosition(d.position),
  street_address: d.street_address,
  country: d.country,
  city: d.city,
});

const serializer = (d) => {
  return {
    name: d.name,
    description: d.description,
    category_id: d.category_id,
    pricing_options: (d.pricing_options || []).map((el) => ({
      interval: el.interval,
      amount: Number(el.amount * 100),
    })),
    timezone: "Europe/Amsterdam",

    media: d.media
      ? d.media.filter((el) => el !== undefined).map((el) => el.id)
      : [],

    age: d.age,
    condition: d.condition,

    protection: d.protection,
    restrictions: d.restrictions ? d.restrictions.split(",") : [],
    features: d.features ? d.features.split(",") : [],
    deposit_value: Number(d.deposit_value * 100),
    market_value: Number(d.market_value * 100),

    /* Availability */
    available_weekdays: d.available_weekdays,
    explicit_available_days: d.explicit_available_days ?? [],
    explicit_unavailable_days: d.explicit_unavailable_days ?? [],
    unavailable_days: d.unavailable_days,

    pickup_dropoff_timerange: d.pickup_dropoff_timerange,
    pickup_location: d.pickup_location ? toLocation(d.pickup_location) : null,
  };
};

class Position extends Array {
  get longitude() {
    return this[0];
  }

  get latitude() {
    return this[1];
  }
}

const PositionField = {
  deserialize(v) {
    if (v === undefined) {
      return undefined;
    }
    if (typeof v === "object") {
      return new Position(v.longitude, v.latitude);
    }
    return new Position(...v);
  },
};

const deserializer = (d) => {
  return {
    id: d.id,
    name: d.name,
    description: d.description,
    category_id: d.category?.id,
    category__parent__id:
      d.category__parent__id ?? d.category?.parent_id ?? d.category?.parent?.id,
    media: d.media,
    owner: ActorIO.deserializer(d.owner),
    status: d.state,
    category: d.category,
    is_favorite: d.is_favorite || false,

    numReviews: d.num_reviews,
    rating: d.rating ? d.rating / 10 : 0,

    /* Availability */
    available_weekdays: d.available_weekdays || [],
    explicit_unavailable_days: d.explicit_unavailable_days || [],
    explicit_available_days: d.explicit_available_days || [],
    unavailable_days: d.unavailable_days || [],

    pricing_options: (d.pricing_options || []).map((el) => ({
      interval: el.interval,
      amount: Number(el.amount / 100),
    })),
    protection: d.protection,
    deposit_value: d.deposit_value ? d.deposit_value / 100 : 0,
    market_value: d.market_value ? d.market_value / 100 : 0,
    insurance_premium: d.insurance_premium ? d.insurance_premium / 100 : 0,
    restrictions: d.restrictions,
    features: d.features,

    age: d.age,
    condition: d.condition,
    pickup_dropoff_timerange: d.pickup_dropoff_timerange,
    distance: d.distance,

    pickup_location: d.pickup_location
      ? {
          street_address: d.pickup_location.street_address,
          city: d.pickup_location.city,
          country: d.pickup_location.country,
          state: d.pickup_location.state_long,
          position: PositionField.deserialize(d.pickup_location.geo_position),
        }
      : {},
  };
};

export default {
  serializer,
  deserializer,
};
