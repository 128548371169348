<script lang="ts" setup>
import { computed } from "vue";

import PsButton from "@/elements/PsButton.vue";

const props = withDefaults(
  defineProps<{
    isActive: boolean;
    isDisabled?: boolean;
    isFullWidth?: boolean;
    withButton?: boolean;
    label?: string;
  }>(),
  {
    isFullWidth: false,
    isDisabled: false,
    isActive: false,
    withButton: false,
    label: undefined,
  },
);

const emit = defineEmits(["selected"]);

const classes = computed(() => [
  "option",
  props.isActive ? "is-active" : null,
  props.isDisabled ? "is-disabled" : null,
  props.isFullWidth ? "is-fullwidth" : null,
]);

function onSelect() {
  if (props.isDisabled) return;

  emit("selected");
}
</script>

<template>
  <div :class="classes">
    <div
      v-if="!isFullWidth"
      class="head"
      @click.prevent="onSelect"
    >
      <slot name="head">
        <label>{{ label }}</label>
      </slot>

      <input
        :checked="isActive"
        type="radio"
      >

      <Icon
        v-if="!isFullWidth && !isDisabled"
        :name="isActive ? 'base/check_circle' : 'base/radio_button_unchecked'"
        :variant="isActive ? 'primary' : 'secondary-inactive'"
      />
    </div>
    <hr class="my-1">
    <div
      class="body"
      @click.prevent="onSelect"
    >
      <div>
        <slot
          v-if="isFullWidth"
          name="head"
        >
          <label>{{ label }}</label>
        </slot>

        <slot> Body</slot>
      </div>

      <slot
        v-if="isFullWidth"
        name="actions"
      >
        <PsButton
          v-if="withButton && !isActive"
          :disabled="isDisabled"
          class="mt-2"
          color="secondary"
          styling="outlined"
          @click.prevent="onSelect"
        >
          Select
        </PsButton>
        <PsButton
          v-if="withButton && isActive"
          class="mt-2"
          disabled
        >
          Selected
        </PsButton>
      </slot>
    </div>

    <slot
      v-if="!isFullWidth"
      name="actions"
    >
      <PsButton
        v-if="withButton && !isActive"
        :disabled="isDisabled"
        class="mt-2"
        color="secondary"
        styling="outlined"
        @click.prevent="onSelect"
      >
        Select
      </PsButton>
      <PsButton
        v-if="withButton && isActive"
        class="mt-2"
        disabled
      >
        Selected
      </PsButton>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.option {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1em;

  height: auto;
  margin-bottom: 0;
  min-height: 100px;
  justify-content: space-between;
  border: 2px solid var(--gray-smoke);
  border-radius: 12px;

  input {
    display: none;
  }

  :hover {
    cursor: pointer;
  }

  &:hover {
    background-color: var(--gray-mist);
  }

  &.is-active {
    border: 2px solid var(--primary);
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  &.is-fullwidth {
    grid-column: span 2;

    .body {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }

    input,
    hr {
      display: none;
    }
  }

  &.disabled {
    color: var(--gray-muted);
    background: var(--gray-mist);
  }

  .head {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    label {
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-weight: 600;
    }
  }

  .body {
    flex-grow: 1;
    line-height: 2em;
  }
}
</style>
