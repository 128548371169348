<template>
  <div class="content protection-options">
    <PanelSelect>
      <!-- <Panel
        is-full-width
        :is-active="context.value === 'none'"
        @selected="handleSelect('none')"
      >
        <template #head>
          <label>No additional protection</label>
        </template>
        <p>You&apos;ll take the risk, no additional protection against theft and loss</p>
      </Panel> -->

      <!-- <Panel
        :is-active="context.value === 'deposit'"
        is-disabled
        @selected="handleSelect('deposit')"
      >
        <template #head>
          <label
            class="is-flex is-flex-wrap-wrap is-flex-grow-1 is-align-items-center is-justify-content-space-between flex-gap-1"
          >
            <span class="is-flex is-align-items-center flex-gap-1">
              <Icon
                is-backed
                name="deposit"
                variant="success"
              />
              Security deposit
            </span>
          </label>
        </template>
        <p>Coming soon!</p>
      </Panel> -->

      <Panel
        :is-active="context.value === 'none'"
        @selected="handleSelect('none')"
      >
        <template #head>
          <label
            class="is-flex is-flex-wrap-wrap is-flex-grow-1 is-align-items-center is-justify-content-space-between flex-gap-1"
          >
            No insurance
            <Money
              :amount="0"
              class="has-text-weight-normal mr-1"
            />
          </label>
        </template>
        <p>You&apos;ll take the risk, no additional protection against theft and loss</p>
      </Panel>

      <Panel
        :class="getClasses('insurance')"
        :is-active="context.value === 'insurance'"
        :is-disabled="context.insuranceDisabled"
        @selected="handleSelect('insurance')"
      >
        <template #head>
          <label
            class="is-flex is-flex-wrap-wrap is-flex-grow-1 is-align-items-center is-justify-content-space-between flex-gap-1"
          >
            <span class="is-flex is-align-items-center flex-gap-1">
              <Icon
                is-backed
                name="protection"
                :variant="context.insuranceDisabled ? 'secondary' : 'primary'"
              />
              {{ context.isOwner ? 'Owner protection' : 'PS Insurance' }}
            </span>
            <Money
              v-if="!context.insuranceDisabled"
              :amount="context.insurancePremium"
              class="has-text-weight-normal mr-1"
            />
            <span
              v-if="context.insuranceDisabled"
              class="has-text-weight-normal"
            >Not available</span>
          </label>
        </template>
        <p>
          <b>Protection against theft and loss</b>
        </p>
        <p>
          <b>Coverage up to $4,000</b>
          <br>
          Coverage is limited to a maximum of $4,000 after a deductible of $250
        </p>
        <template #actions>
          <span class="mt-2"><InsuranceModal /></span>
        </template>
      </Panel>
    </PanelSelect>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue";

import InsuranceModal from "@/components/rental/explainers/InsuranceModal.vue";
import Panel from "@/form/panel-select/Panel.vue";
import PanelSelect from "@/form/panel-select/PanelSelect.vue";

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
});

watch(
  () => props.context.insuranceDisabled,
  (insuranceDisabled: boolean) => {
    if (
      insuranceDisabled &&
      !["none", "deposit"].includes(props.context.value)
    ) {
      props.context.node.input("none");
    }
  },
  { immediate: true },
);

watch(
  () => props.context.value,
  (value?: string) => {
    if (!value) {
      props.context.node.input("none");
    }
  },
  { immediate: true },
);

function handleSelect(val: string) {
  if (props.context.insuranceDisabled && val === "insurance") {
    props.context.node.input("none");
  } else {
    props.context.node.input(val);
  }
}

function getClasses(opt: string) {
  const result = [];
  if (opt === "insurance" && props.context.insuranceDisabled) {
    result.push("disabled");
  }
  return result;
}
</script>

<style lang="scss" scoped>
.protection-options {
  label {
    min-height: 48px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  .icon {
    line-height: 0 !important;

    &.material-icons-outlined {
      line-height: 1 !important;
    }
  }
}
</style>
