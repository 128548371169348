<script setup lang="ts">
import { computed } from "vue";

import Icon from "@/elements/Icon.vue";

interface IconOpts {
  name: string;
  variant?: string;
  size?: string;
  isBacked?: boolean;
  isInline?: boolean;
}

const props = defineProps<{ icon?: IconOpts }>();

const classes = computed(() => {
  return [props.icon ? "has-icon" : null];
});
</script>

<template>
  <li :class="classes">
    <Icon
      v-if="icon"
      is-inline
      v-bind="icon"
    />
    <div><slot /></div>
  </li>
</template>

<style scoped lang="scss">
li {
  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &.has-icon {
    display: flex;
    list-style: none;
    margin-left: 0.5em;

    .icon {
      margin-right: 0.5em;
      flex-grow: 1;
    }

    & > div {
      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
    }
  }
}
</style>
