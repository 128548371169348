import type { App } from "vue";

import BookingStatus from "@/elements/BookingStatus.vue";
import DataSource from "@/elements/DataSource.vue";
import Icon from "@/elements/Icon.vue";
import Money from "@/elements/Money.vue";
import PsButton from "@/elements/PsButton.vue";

declare module "@vue/runtime-core" {
  export interface GlobalComponents {
    Icon: typeof Icon;
    PsButton: typeof PsButton;
    DataSource: typeof DataSource;
    BStatus: typeof BookingStatus;
    Money: typeof Money;
  }
}

export default {
  install(app: App) {
    app.component("Icon", Icon);
    app.component("PsButton", PsButton);
    app.component("DataSource", DataSource);
    app.component("BStatus", BookingStatus);
    app.component("Money", Money);
  },
};
