<template>
  <div :class="classes">
    <div
      v-for="(item, ix) in items"
      :key="item.id"
      class="list-item"
      :class="getItemClasses(item)"
    >
      <span class="list-item-number">{{ ix + 1 }}</span>
      <PsButton
        v-bind="item.button"
        @click.prevent="handleClick(item)"
      >
        {{ item.label }}
      </PsButton>
      <Icon
        class="list-item-divider-horizontal"
        name="outlined/chevron_right"
        variant="secondary"
      />
      <span class="list-item-divider-vertical">|</span>
      <div />
    </div>
  </div>
</template>
<script setup lang="ts">
import { type PropType, computed, getCurrentInstance, watch } from "vue";
import type {Step, StepChangedEvent, StepItem} from "@/app/components/workflow/types";

const STATE_CLASS = {
  todo: "is-todo",
  working: "is-working",
  done: "is-done",
};

const STATE_BUTTON = {
  todo: {
    icon: "circleStale",
    color: "secondary",
    styling: "ghost",
    disabled: true,
    isInactive: true,
  },
  next: {
    icon: "circleStale",
    color: "secondary",
    styling: "ghost",
    disabled: true,
    isInactive: true,
  },
  working: {
    icon: "circleProgress",
    color: "primary",
    styling: "ghost",
  },
  done: {
    icon: "done",
    color: "secondary",
    styling: "ghost",
  },
};

const emit = defineEmits<(e: "stepChanged", event: StepChangedEvent) => void>();

const props = defineProps({
  steps: {
    type: Array as PropType<Step[]>,
    default: () => [],
  },
  currentStep: {
    type: Number,
    default: 1,
  },
  progressedStep: {
    type: Number,
    default: 1,
  },
  direction: {
    type: String as PropType<"vertical" | "horizontal">,
    default: "vertical",
  },
  isNumbered: {
    type: Boolean,
    default: false,
  },
});

const items = computed(() => {
  return getItems(props.currentStep, props.progressedStep);
});

watch(
  () => props.currentStep,
  async () => {
    forceUpdate();
  },
);

function getItemClasses(item) {
  return [
    STATE_CLASS[item.state || "todo"],
    props.isNumbered && item.state !== "done" ? "show-number" : "",
  ];
}

function handleClick(item: StepItem) {
  if (!["done", "working", "next"].includes(item.state)) {
    console.log("Invalid state for clicked item");
    return;
  }
  emit("stepChanged", { stepId: item.id, stepIndex: item.index + 1 });
}

function getItems(currentStep: number, progressedStep: number) {
  let previousValid = false;
  return props.steps.map((item, ix) => {
    let state: keyof typeof STATE_BUTTON =
      ix + 1 === currentStep
        ? "working"
        : ix + 1 < progressedStep
          ? item.isValid
            ? "done"
            : "todo"
          : ix + 1 === progressedStep
            ? "todo"
            : "todo";
    if (
      state === "todo" &&
      previousValid &&
      ix === currentStep &&
      ix + 1 < progressedStep
    ) {
      state = "next";
    }
    previousValid = item.isValid ?? false;

    let button = STATE_BUTTON[state];

    if (state !== "done" && props.isNumbered) {
      button = {
        ...button,
        icon: state === "working" ? "circleFilledPrimary" : "circleFilled",
      };
    }

    return {
      ...item,
      index: ix,
      state,
      button,
    };
  });
}

function forceUpdate() {
  const instance = getCurrentInstance();
  return () => instance.proxy.$forceUpdate();
}

const classes = computed(() => {
  return ["workflow", "list", `is-${props.direction}`];
});
</script>

<style scoped lang="scss">
@import '../../../styles/variables';

.workflow.list {
  display: flex;
  flex-direction: column;
  background-color: $gray-mist;
  padding: 24px 20px;
  border-radius: 0.75em;

  .list-item {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;

    .list-item-number {
      z-index: 1;
      display: none;
      color: $white;
      height: 20px;
      width: 20px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
    }

    &.show-number {
      .list-item-number {
        display: block;
        position: absolute;
        left: 7.5px;
        top: 10px;
      }
    }

    .ps-button {
      padding: 8px 8px;
      width: 100%;
      justify-content: flex-start;
    }

    .list-item-divider-horizontal.icon {
      display: none;
    }

    .list-item-divider-vertical {
      display: none;
      z-index: 1;
      color: $gray-pebble;
      height: 12px;
      width: 20px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      text-align: center;
    }
  }

  &.is-horizontal {
    background-color: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -4px;
    padding-left: 0;
    padding-right: 0;

    .ps-button {
      width: auto;
    }

    .list-item {
      .list-item-divider-horizontal.icon {
        display: block;
      }
    }
  }

  &:not(.is-horizontal) {
    .list-item {
      .list-item-divider-vertical {
        display: block;
        position: absolute;
        left: 8px;
        top: 34px;
      }

      &.is-working {
        background-color: $white;
        border-radius: 8px;
      }
    }
  }

  .list-item:last-child {
    .list-item-divider-horizontal.icon {
      display: none;
    }

    .list-item-divider-vertical {
      display: none;
    }
  }
}
</style>
