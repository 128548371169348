<script lang="ts" setup>
import { useToggle } from "@vueuse/core";
import { useMachine } from "@xstate/vue";
import { computed, ref, watch } from "vue";
import { createMachine, TransitionConfig } from "xstate";

import Modal from "@/components/Modal.vue";
import ButtonGroup from "@/components/display/ButtonGroup.vue";
import Comment from "@/components/rental/review/Comment.vue";
import Done from "@/components/rental/review/Done.vue";
import Main from "@/components/rental/review/Main.vue";
import Rating from "@/components/rental/review/Rating.vue";
import Review from "@/components/rental/review/Review.vue";
import ThankYou from "@/components/rental/review/ThankYou.vue";

import { useLoading } from "@/composables/loaders";
import type { ColorType } from "@/elements/PsButton.props";
import type { Booking } from "@/store/rental/models";
import type { Actor } from "@/store/user";
import {useBookings} from "@/rental/stores/bookings";

type StepType = "main" | "rating" | "comment" | "review" | "done";

interface ReviewValues {
  comment: string;
  rating: number;
}

const props = withDefaults(
  defineProps<{
    booking: Booking;
    author: Actor;
    currentStep?: StepType;
    values?: ReviewValues;
    isActive?: boolean;
  }>(),
  {
    currentStep: "main",
    isActive: false,
    values: () => ({
      rating: 0,
      comment: "",
    }),
  },
);

const [isActive, toggle] = useToggle(props.isActive);
const store = useBookings();

const showModal = async () => {
  // do review
  toggle(true);
};

const onSubmitRating = async () => {
  await handleRating();
  send({ type: "NEXT" });
};

const values = ref(props.values || {});
const nextDisabled = ref(false);

const { trigger: submitRating, isLoading } = useLoading(onSubmitRating);

const handleRating = async () => {
  await store.review(
    props.booking.id,
    values.value.rating,
    values.value.comment,
  );
};

interface EventData {
  meta: {
    label?: string;
    color?: ColorType;
    onClick?: () => void;
  };
}

type Event =
  | { type: "NEXT" }
  | { type: "CANCEL" }
  | { type: "START" }
  | { type: "PREVIOUS" }
  | { type: "CLOSE" };

const machine = createMachine({
  id: "WriteAReviewMachine",
  initial: props.currentStep,
  context: () => ({
    component: undefined,
  }),
  types: {
    events: {} as Event,
    meta: {} as EventData,
  },
  states: {
    main: {
      on: {
        CANCEL: {
          meta: {
            label: "Cancel",
            color: "secondary",
          },
          target: "exit",
        },
        START: {
          meta: {
            label: "Start",
          },
          target: "rating",
        },
      },
    },
    rating: {
      on: {
        PREVIOUS: {
          meta: {
            label: "Previous step",
            color: "secondary",
          },
          target: "main",
        },
        NEXT: {
          meta: {
            label: "Next step",
          },
          target: "comment",
        },
      },
    },
    comment: {
      on: {
        PREVIOUS: {
          meta: {
            label: "Previous step",
            color: "secondary",
          },
          target: "rating",
        },
        NEXT: {
          meta: {
            label: "Next step",
          },
          target: "review",
        },
      },
    },
    review: {
      on: {
        PREVIOUS: {
          meta: {
            label: "Previous step",
            color: "secondary",
          },
          target: "comment",
        },
        NEXT: {
          meta: {
            label: "Add your review",
            isLoading: isLoading.value,
            onClick: submitRating,
          },
          target: "thanks",
        },
      },
    },
    thanks: {
      on: {
        CLOSE: {
          meta: {
            label: "Close",
            onClick: () => toggle(false),
          },
          target: "exit",
        },
      },
    },
    exit: {},
    done: {
      on: {
        CLOSE: {
          meta: {
            label: "Close",
            onClick: () => toggle(false),
          },
          target: "exit",
        },
      },
    },
  },
});

const { snapshot: state, send } = useMachine(machine);
const buttons = computed(() => {
  const states = machine.config.states
    ? machine.config.states[state.value.value.toString()]?.on
    : undefined;
  if (states) {
    return Object.entries(states).map(([key, value]) => ({
      label: value.meta.label,
      target: key as Event["type"],
      key: key as Event["type"],
      args: value.meta ?? {},
    }));
  }
  return [];
});

watch(
  [values, state],
  (newValue) => {
    if (newValue[1].value === "rating") {
      nextDisabled.value = (Number(newValue[0].rating) || 0) === 0;
    } else if (newValue[1].value === "comment") {
      nextDisabled.value = newValue[0].comment.length === 0;
    } else {
      nextDisabled.value = false;
    }
  },
  {
    deep: true,
    immediate: true,
  },
);
</script>

<template>
  <Modal
    v-model="isActive"
    title="Write a review"
  >
    <Main
      v-if="state.value === 'main'"
      :booking="booking"
    />
    <Rating
      v-else-if="state.value === 'rating'"
      v-model="values.rating"
    />
    <Comment
      v-else-if="state.value === 'comment'"
      v-model="values.comment"
    />
    <Review
      v-else-if="state.value === 'review'"
      :author="author"
      :values="values"
    />
    <ThankYou v-else-if="state.value === 'thanks'" />
    <Done v-else-if="state.value === 'done'" />

    <template #foot>
      <ButtonGroup
        :key="values.toString()"
        full-width
      >
        <PsButton
          v-for="(button, index) in buttons"
          :key="index"
          :disabled="button.key === 'NEXT' ? nextDisabled : false"
          v-bind="button.args"
          @click="!button.args.onClick && button.target ? send({ type: button.target }) : null"
        >
          {{ button.label }}
        </PsButton>
      </ButtonGroup>
    </template>
  </Modal>
  <slot
    :is-loading="isLoading"
    :toggle="showModal"
  >
    <PsButton
      :loading="isLoading"
      :disabled="currentStep === 'done'"
      color="secondary"
      icon="outlined/edit"
      @click.prevent="showModal"
    >
      Write a review
    </PsButton>
  </slot>
</template>
