<script setup lang="ts">
import Chip from "@/elements/Chip.vue";

const props = defineProps<{
  iconName: string;
}>();

const emit = defineEmits(["remove"]);
</script>
<script lang="ts">
export default {
  name: 'BrowseFilter',
};
</script>

<template>
  <Chip
    :has-hover="false"
    color="secondary"
    styling="outlined"
    size="small"
  >
    <Icon
      :name="props.iconName"
      variant="secondary"
    />
    <slot />
    <PsButton
      color="secondary"
      icon="outlined/close"
      size="small"
      styling="ghost"
      @click="emit('remove')"
    />
  </Chip>
</template>

<style scoped lang="scss"></style>
