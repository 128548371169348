import {type Component, markRaw, shallowRef, type UnwrapRef} from "vue";

import ListingEditorDetails from "@/rental/components/ListingEditorDetails.vue";
import ListingEditorLocation from "@/rental/components/ListingEditorLocation.vue";
import ListingEditorPhotos from "@/rental/components/ListingEditorPhotos.vue";
import ListingEditorPricing from "@/rental/components/ListingEditorPricing.vue";
import ListingEditorAvailability from "@/rental/components/ListingEditorAvailability.vue";
import ListingEditorProtection from "@/rental/components/ListingEditorProtection.vue";
import ListingEditorPublish from "@/rental/components/ListingEditorPublish.vue";

import type {Step as WorkflowStep} from "@/app/components/workflow/types";

import type {PrivateListing} from "@/rental/stores/listings";
import formats from "@/helpers/formats";
import {useI18n} from "vue-i18n";

export interface BaseStep extends WorkflowStep {
  label: string;
  name: string;
  component: Component;
  toString: (d: PrivateListing) => string;
  getPart: (d: UnwrapRef<Partial<PrivateListing>>) => Partial<PrivateListing>;
}

export interface Step extends BaseStep {
  isValid: (d: UnwrapRef<Partial<PrivateListing>>, canPublish?: boolean) => boolean;
}

export interface EvaluatedStep extends BaseStep {
  isValid: boolean;
}

const sanitizeListInput = (v: string | string[] | undefined) => {
  if (typeof v === "string") {
    return v.split(",").map((el) => el.trim());
  }
  if (v === undefined) {
    return []
  }
  return v;
}

export default [
  {
    name: "details",
    label: "Details",
    component: shallowRef(ListingEditorDetails),
    toString(d) {
      const {t} = useI18n();
      let result = '';
      result += `${d.name}${d.category ? ` - ${d.category?.name}` : ""}`
      if (d.age) {
        result += ` - ${t(`listing.age.${d.age}`)}`;
      }
      if (d.condition) {
        result += ` - ${t(`listing.condition.${d.condition}`)}`;
      }
      return result;
    },
    isValid: (d) =>
      d.name &&
      d.description &&
      d.categoryId &&
      (d.age ?? -1) > 0 &&
      d.condition !== undefined,
    getPart: (d) => ({
      name: d.name,
      description: d.description,
      categoryId: d.categoryId ?? d.category?.id,
      age: d.age,
      condition: d.condition,
      features: sanitizeListInput(d.features),
      restrictions: sanitizeListInput(d.restrictions),
    }),
  },
  {
    name: "photos",
    label: "Photos",
    component: markRaw(ListingEditorPhotos),
    toString: (d) =>
      `Added ${d.media?.filter((el) => el !== undefined).length || 0} photo${(d.media?.filter((el) => el !== undefined).length || 0) === 1 ? "" : "s"}`,
    isValid: (d) =>
      Number(d.media?.filter((el) => el !== undefined).length) > 0,
    getPart: (d) => ({media: d.media?.filter((el) => el !== undefined)}),
  },
  {
    name: "pricing",
    label: "Pricing",
    component: markRaw(ListingEditorPricing),
    isValid: (d) => {
      return !(
        !d.pricingOptions ||
        d.pricingOptions.length === 0 ||
        d.pricingOptions.some((el) => (el.amount ?? 0) === 0) ||
        !d.marketValue
      );
    },
    getPart: (d) => ({
      marketValue: d.marketValue,
      pricingOptions: d.pricingOptions,
    }),
    toString: (d) => {
      if (!d.pricingOptions || d.pricingOptions.length === 0) {
        return "No pricing options set!";
      }
      const option = d.pricingOptions[0];
      return `${formats.money({value: option.amount})} ${option.interval}`;
    },
  },
  {
    name: "availability",
    label: "Availability",
    component: markRaw(ListingEditorAvailability),
    isValid: (d) => {
      return (d.pickupDropOffTimeRange?.length ?? 0) > 0;
    },
    getPart: (d) => ({
      availableWeekdays: d.availableWeekdays,
      pickupDropOffTimeRange: d.pickupDropOffTimeRange,
      explicitUnavailableDays: d.explicitUnavailableDays,
    }),
    toString: (d) => {
      const availability =
        d.availableWeekdays && d.availableWeekdays.length > 0
          ? `Available on ${d.availableWeekdays.join(", ")}`
          : "No availability set";
      return availability;
    },
  },
  {
    name: "location",
    label: "Location",
    component: markRaw(ListingEditorLocation),
    getPart: (d) => ({
      pickupLocation: d.pickupLocation,
    }),
    isValid: (d) => {
      if (!d.pickupLocation) {
        return false;
      }
      return [
        d.pickupLocation.state,
        d.pickupLocation.city,
        d.pickupLocation.country,
        d.pickupLocation.position,
      ].every((v) => v != null);
    },
    toString: (d) =>
      d.pickupLocation
        ? `${d.pickupLocation.city}, ${d.pickupLocation.state}`
        : "No pickup location set!",
  },
  {
    name: "protection",
    label: "Protection",
    component: markRaw(ListingEditorProtection),
    isValid: (d) => ["none", "insurance"].includes(d.protection ?? "none"),
    getPart: (d) => ({
      protection: d.protection,
      depositValue: Math.round(d.depositValue ?? 0),
    }),
    toString: (d) =>
      ["none", null].indexOf(d.protection) === -1
        ? `Protected with a ${d.protection === "insurance" ? "PS Insurance" : "Security deposit"}`
        : "Not protected",
  },
  {
    name: "publish",
    label: "Summary",
    component: markRaw(ListingEditorPublish),
    isValid: (d, cp) => cp,
    toString: () => "",
    getPart: (d) => d,
  },
] as Step[];
