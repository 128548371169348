<template>
  <HeaderSection
    :styling="headerStyle"
    :variant="isAuthenticated ? 'authenticated' : 'unauthenticated'"
    position="fixed"
  />

  <HomePageHero />

  <span ref="headerSwitchRef" />

  <main>
    <Suspense>
      <template #fallback>
        <LoadingFullSection />
      </template>

      <router-view />
    </Suspense>
  </main>

  <FooterSection v-if="withFooter" />
</template>

<script lang="ts" setup>
import { onUnmounted, ref, watch } from "vue";

import HomePageHero from "@/components/HomePageHero.vue";
import FooterSection from "@/sections/Footer.vue";
import HeaderSection from "@/sections/Header.vue";
import LoadingFullSection from "@/sections/LoadingFull.vue";

const props = defineProps({
  isAuthenticated: {
    type: Boolean,
    default: false,
  },
  withFooter: {
    type: Boolean,
    default: true,
  },
  withActionNav: {
    type: Boolean,
    default: true,
  },
});

const headerSwitchRef = ref(null);
const headerStyle = ref("transparent");

function handleScroll(event) {
  const anchorOffsetTop = headerSwitchRef.value?.offsetTop;

  if (event.currentTarget.scrollY >= anchorOffsetTop) {
    headerStyle.value = "normal";
  } else {
    headerStyle.value = "transparent";
  }
}

window.addEventListener("scroll", handleScroll);

onUnmounted(() => window.removeEventListener("scroll", handleScroll));
</script>
