import { createMachine } from "xstate";

interface bookingMachineOpts {
  initialState?: string;
}

export default (options: bookingMachineOpts = {}) => {
  return createMachine({
    id: "createBooking",
    initial: "flow",
    context: () => ({
      flow: "",
    }),
    states: {
      flow: {
        id: "flow",
        initial: options.initialState || "created",
        states: {
          created: {
            meta: {
              label: "Insurance",
            },
            on: {
              NEXT: { target: "payment" },
            },
          },
          payment: {
            meta: {
              label: "Payment method",
            },
            on: {
              TO_CREATED: { target: "created" },
              BACK: { target: "created" },
              NEXT: { target: "cause" },
            },
          },
          cause: {
            meta: {
              label: "Choose a cause",
            },
            on: {
              TO_CREATED: { target: "created" },
              TO_PAYMENT: { target: "payment" },
              BACK: { target: "payment" },
              NEXT: { target: "confirm" },
            },
          },
          confirm: {
            meta: {
              label: "Confirmation",
            },
            on: {
              TO_CREATED: { target: "created" },
              TO_PAYMENT: { target: "payment" },
              TO_CAUSE: { target: "cause" },
              BACK: { target: "cause" },
            },
          },
        },
        on: {
          FAIL: { target: "failed" },
        },
      },
      failed: {},
    },
  });
};
