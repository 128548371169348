import { type Ref, computed } from "vue";
import type { AnyMachineSnapshot, AnyStateMachine, AnyStateNode } from "xstate";

export const useMachineToWorkflowBinds = (
  state: Ref<AnyMachineSnapshot>,
  machine: AnyStateMachine | AnyStateNode,
) => {
  const steps = Object.entries(machine.states).map((item) => {
    const [key, config]: [string, AnyStateNode] = item;
    return {
      id: key,
      label: config.meta?.label,
    };
  });

  const currentStep = computed(() => {
    return steps.findIndex((el) => el.id === state.value.value.flow) + 1;
  });

  return {
    steps,
    currentStep,
  };
};
