import {createActor} from "xstate";
import {defineStore} from "pinia";
import createAppMachine from "@/app/machines/AppMachine";
import {useSession} from "@/app/stores/session";
import {useEventBus} from "@/app/eventBus";
import {AppIdleToActive, SessionReady} from "@/app/events";
import {useActor, useActorRef} from "@xstate/vue";

export const useAppContext = defineStore("app", () => {
  const bus = useEventBus();
  const appMachine = createAppMachine();
  const appService = useActor(appMachine);

  bus.subscribe(SessionReady, () => {
    appService.send({type: "AUTH_INITIALIZED"});
  })

  appService.actorRef.start();

  return {
    appService,
  }
});
