<template>
  <Modal
    :model-value="isActive"
    size="small"
    title="Sign in"
    @update:model-value="checkModalState"
  >
    <SignIn :is-active="isActive" />
  </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";

import SignIn from "./SignIn.vue";
import {inject} from "vue";


defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

function checkModalState(value) {
  if (value === false) {
    closeModal();
  }
}
const { closeModal } = inject('authOverlay');
</script>
