<template>
  <div>
    <p class="title is-3 mb-4">
      Protection
    </p>
    <p class="my-4">
      Add a protection plan against theft and loss. The daily fee for insurance is based on the estimated market of your
      item. If Owner Protection is unavailable, it indicates that the daily insurance fee equals or exceeds the daily
      rental price.
    </p>

    <div class="mt-6 mb-4">
      <FormKit
        :insurance-disabled="values.marketValue > INSURANCE_THRESHOLD || values.insurancePremium >= (values.getRate ? values.getRate(BookingInterval.daily) ?? 0 : 0)"
        :insurance-premium="values.insurancePremium"
        :is-owner="true"
        name="protection"
        type="protection"
      />
      <div
        v-show="values.protection === 'deposit'"
        class="box is-shadowless is-dark"
      >
        <FormKit
          :value="Math.trunc(Number(values.marketValue * 0.2))"
          help="We calculated the deposit based on the estimated market value"
          label="Deposit"
          max="1000"
          name="depositValue"
          type="number"
          number="integer"
          na-validation="required|number"
        >
          <template #prefixIcon>
            <Icon
              class="formkit-prefix-icon formkit-icon"
              name="outlined/attach_money"
              variant="inherit"
            />
          </template>
        </FormKit>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {INSURANCE_THRESHOLD} from "@/constants/listings";
import {useStepContext} from "@/rental/composables/listingEditor";
import {BookingInterval} from "@/rental/types";

const { values } = useStepContext();
</script>
