import type { ApiModel } from "@/io/base";
import { camelizeObject, snakeObject } from "@/io/helpers";
import {
  Actor as ActorModel,
  type INotification as NotificationModel,
} from "@/store/account/models";
import type { Review } from "@/store/rental/models";

export interface Avatar {
  id: string;
  url?: string;
}

export interface Actor {
  id: string;
  first_name?: string;
  last_name?: string;
  avatar?: Avatar;
  created_at: string;
  updated_at: string;
  num_listings?: number;
  rating?: number;
  reviews?: number;
}

export interface User extends Actor {
  email?: string;
  username?: string;
  phone_number?: string;
  account_type?: string;
  has_new_messages?: boolean;
  favorites: unknown[];
}

export enum MessageType {
  AcceptBooking = 0,
  RenterRequestedBooking = 1,
  AcceptedBooking = 2,
}

export type MessagePayload = {};

export interface BookingMessagePayload extends MessagePayload {
  booking_id: string;
}

export interface ApiNotificationEvent {
  id: string;
  message_type_id: keyof MessageType;
  message_payload: MessagePayload;
}

export interface ApiNotification extends ApiModel {
  event: ApiNotificationEvent;
  subject: string;
  body: string;
}

export interface ApiReview extends ApiModel {
  author: Actor;
  subject: Actor;
  comment: string;
  rating: number;
}

export const NotificationIO = {
  serializer: (d: NotificationModel) =>
    ({
      ...snakeObject(d),
      event: snakeObject(d.event),
    }) as unknown as ApiNotification,
  deserializer: (d: ApiNotification) =>
    ({
      ...camelizeObject(d),
      event: camelizeObject(d.event),
    }) as unknown as NotificationModel,
};

export const ReviewIO = {
  serializer: (d: Review) =>
    ({
      ...snakeObject(d),
    }) as unknown as ApiReview,
  deserializer: (d: ApiReview) =>
    ({
      ...camelizeObject(d),
      author: new ActorModel(ActorIO.deserializer(d.author)),
    }) as unknown as Review,
};

export const ActorIO = {
  serializer: (d: Actor) => d,
  deserializer: (d: Actor) => ({
    ...d,
    rating: d.rating ? d.rating / 10 : undefined,
  }),
};
