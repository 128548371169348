<template>
  <div>
    <p class="title is-3 mb-4">
      Location
    </p>
    <p class="my-4">
      Enter a location to your listing so that we can estimate the distance to the renter. This
      location will also be used as default pick-up and drop-off location.
    </p>

    <div class="mt-6 mb-4">
      <FormKit
        name="pickupLocation"
        type="map"
        validation="required"
        v-test-id="'input-location'"
        zoom="16"
      />
    </div>
  </div>
</template>
