<script lang="ts" setup>
import { loadStripe } from "@stripe/stripe-js";
import { ref, watch } from "vue";
import { StripeElement, StripeElements } from "vue-stripe-js";

const props = withDefaults(
  defineProps<{
    clientSecret: string;
    /* Allows rendering in storybook */
    isDemo?: boolean;

    /* Url to return to after stripe submit */
    returnUrl?: string;
  }>(),
  {
    isDemo: false,
    returnUrl: undefined,
  },
);

const pk = import.meta.env.VITE_APP_STRIPE_KEY || "fake";
loadStripe(pk);
const elms = ref();
const card = ref();
const isReady = ref(false);
const elementsOptions = {
  appearance: {}, // appearance options
};
if (props.isDemo) {
  /* We need a client secret, in demo mode we will use a work around */
  Object.assign(elementsOptions, {
    mode: "payment",
    amount: 1232,
    currency: "usd",
  });
} else {
  Object.assign(elementsOptions, {
    clientSecret: props.clientSecret,
  });
}
const instanceOptions = {};

watch(card, (val) => {
  if (!val.stripeElement) {
    return;
  }
  val.stripeElement.on("change", (event) => {
    isReady.value = event.complete;
  });
});

const onSubmit = async () => {
  elms.value.elements.submit();
  return await elms.value.instance.confirmSetup({
    elements: {
      ...elms.value.elements,
    },
    redirect: "if_required",
  });
};
</script>

<template>
  <StripeElements
    ref="elms"
    v-slot="{ elements }"
    :elements-options="elementsOptions"
    :instance-options="instanceOptions"
    :stripe-key="pk"
  >
    <StripeElement
      ref="card"
      :elements="elements"
      type="payment"
    />
  </StripeElements>
  <div class="actions my-4 is-flex flex-gap-1 is-justify-content-end mt-6">
    <slot
      :is-ready="isReady"
      :on-submit="onSubmit"
      name="actions"
    >
      <PsButton
        :disabled="!isReady"
        @click="onSubmit"
      >
        Submit
      </PsButton>
    </slot>
  </div>
</template>

<style lang="scss" scoped></style>
