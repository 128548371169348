<template>
  <div class="gallery">
    <nav
      v-show="hasNavLeft || hasNavRight"
      :class="navClasses"
    >
      <PsButton
        v-show="hasNavLeft"
        is-pill
        is-rounded
        color="white"
        styling="outlined"
        icon="base/chevron_left"
        @click="onPrev"
      />
      <div />
      <PsButton
        v-show="hasNavRight"
        is-pill
        is-rounded
        color="white"
        styling="outlined"
        icon="base/chevron_right"
        @click="onNext"
      />
    </nav>
    <div
      ref="galleryRef"
      :class="wrapperClasses"
      @scroll="onScroll"
    >
      <div
        v-for="category in categories"
        :key="category.id"
        class="gallery-item"
      >
        <ListingCategory
          v-if="variant === 'card'"
          :category="category"
          class="is-clickable"
          :is-active="selectedCategory === category.id"
          @click="onClick(category)"
        />
        <ListingCategoryButton
          v-else
          :category="category"
          :is-active="selectedCategory === category.id"
          @click="onClick(category)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import debounce from "lodash/debounce";
import { computed, ref, watch } from "vue";

import ListingCategory from "@/components/Category.vue";
import ListingCategoryButton from "@/components/CategoryButton.vue";

const CARD_OFFSET_LEFT_PX = 100;

const emit = defineEmits(["categorySelect"]);

const props = defineProps({
  categories: {
    type: Array,
    default: () => [],
  },
  selectedCategory: {
    type: String,
    default: "",
  },
  variant: {
    type: String,
    default: "",
  },
});

const galleryRef = ref(null);
const count = ref(props.categories.length);
const selectedCategoryIndex = props.selectedCategory
  ? props.categories.findIndex((el) => el.id === props.selectedCategory)
  : 0;
const current = ref(selectedCategoryIndex === -1 ? 0 : selectedCategoryIndex);
const scrollLeft = ref(0);

scrollToCurrent(current.value);

watch(
  () => props.categories,
  (categories) => {
    galleryRef.value = null;
    scrollLeft.value = 0;
    count.value = categories.length;
    const selectedCategoryIndex = props.selectedCategory
      ? categories.findIndex((el) => el.id === props.selectedCategory)
      : 0;
    const newCurrentValue =
      selectedCategoryIndex === -1 ? 0 : selectedCategoryIndex;
    current.value = newCurrentValue;
  },
  { deep: true },
);

watch(
  () => props.selectedCategory,
  (selectedCategory) => {
    const selectedCategoryIndex = selectedCategory
      ? props.categories.findIndex((el) => el.id === selectedCategory)
      : 0;
    const newCurrentValue =
      selectedCategoryIndex === -1 ? 0 : selectedCategoryIndex;
    current.value = newCurrentValue;
  },
  { immediate: true },
);

watch(
  () => current.value,
  (newCurrentValue) => {
    scrollToCurrent(newCurrentValue);
  },
);

const hasNavLeft = computed(() => current.value > 0);

const hasNavRight = computed(() => {
  // If current item in view is becoming equal or bigger than count, dont show scroll
  if (current.value + 1 >= count.value) {
    return false;
  }

  if (galleryRef.value) {
    if (galleryRef.value.scrollWidth <= galleryRef.value.clientWidth) {
      return false;
    }

    return (
      scrollLeft.value + CARD_OFFSET_LEFT_PX + galleryRef.value.clientWidth <
      galleryRef.value.scrollWidth
    );
  }

  return true;
});

const navClasses = computed(() => {
  return [
    hasNavLeft.value && "has-nav-left",
    hasNavRight.value && "has-nav-right",
  ];
});

const wrapperClasses = computed(() => {
  return [
    "wrapper",
    hasNavLeft.value && "has-nav-left",
    hasNavRight.value && "has-nav-right",
  ];
});

function scrollToCurrent(val) {
  const currentItem = !val || val <= -1 ? 0 : val;

  if (galleryRef.value) {
    if (currentItem === 0) {
      scrollLeft.value = 0;
      galleryRef.value.scrollTo({ left: 0 });
    } else {
      const item = galleryRef.value.children[currentItem];
      if (!item) {
        scrollLeft.value = 0;
        galleryRef.value.scrollTo({ left: 0 });
      } else {
        const newScrollLeft = item.offsetLeft - CARD_OFFSET_LEFT_PX;
        scrollLeft.value = newScrollLeft;
        galleryRef.value.scrollTo({ left: newScrollLeft });
      }
    }
  } else {
    scrollLeft.value = 0;
  }
}

function onPrev() {
  current.value -= 1;
}

function onNext() {
  current.value += 1;
}

function onClick(category) {
  emit("categorySelect", category);

  scrollToCurrent(props.categories.findIndex((el) => el.id === category.id));
}

function setScrollLeft(newScrollLeft) {
  scrollLeft.value = newScrollLeft;
}

function onScroll(event) {
  debounce(() => setScrollLeft(event.target.scrollLeft || 0), 500);
}
</script>

<style scoped lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.gallery {
  width: 100%;
  position: relative;

  .wrapper {
    width: 100%;
    display: flex;
    column-gap: 1em;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
    z-index: 9;

    @include touch {
      -webkit-overflow-scrolling-x: touch;
    }

    &.has-nav-left {
      padding-left: 20px;

      @include touch {
        width: calc(100% + 20px);
        margin-left: -20px;
        padding-left: 0;
        justify-content: flex-end;
      }
    }

    &.has-nav-right {
      padding-right: 20px;

      @include touch {
        width: calc(100% + 20px);
        margin-right: -20px;
        padding-right: 0;
      }
    }

    &.has-nav-left.has-nav-right {
      @include touch {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  nav {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 10;

    &.has-nav-right {
      margin-right: -20px;
      width: calc(100% + 20px);

      @include touch {
        width: 100%;
        margin-right: 0;
      }
    }

    * {
      pointer-events: auto;
      z-index: 90;
    }
  }
}
</style>
