import AccountBase from "@/pages/AccountBase.vue";
import AccountBookings from "@/pages/account-children/Bookings.vue";
import AccountFavorites from "@/pages/account-children/Favorites.vue";
import AccountListings from "@/pages/account-children/Listings.vue";
import AccountTransactions from "@/pages/account-children/Transactions.vue";
import AccountProfile from "@/pages/account-children/Profile.vue";
import AccountNotificationsList from "@/account/components/AccountNotificationsList.vue";
import type {Booking} from "@/store/rental/models";
import {createRoutes, RouteFactory} from "@/app/router";
import {bookingGetterById} from "@/rental/routes";

const AccountBooking = () => import("@/pages/account-children/Booking.vue");

const factory = new RouteFactory();

factory.defaults = {
  requiresAuthenticated: true,
  showFooter: false,
}

factory.addRoute({
  name: "accountBase",
  path: "/account",
  component: AccountBase,
  children: [
    {
      name: "accountListings",
      path: "listings",
      withBreadcrumb: {
        label: "My listings",
      },
      component: AccountListings,
    },
    {
      name: "accountTransactions",
      path: "transactions",
      withBreadcrumb: {
        label: "Transactions",
      },
      component: AccountTransactions,
    },
    {
      name: "accountProfile",
      path: "profile",
      withBreadcrumb: {
        label: "Profile",
      },
      component: AccountProfile,
    },
  ],
});

factory.addSubRoute("accountBase", {
  path: "bookings",
  name: "accountBookingsBase",
  withBreadcrumb: {
    label: "Bookings",
  },
})

factory.addSubRoute("accountBookingsBase", {
  path: "",
  name: "accountBookings",
  component: AccountBookings,
});

interface BookingParams {
  bookingId: string;
}

interface BookingContext {
  booking: Booking;
}

factory.addSubRoute<BookingParams, BookingContext>("accountBookingsBase", {
  name: "accountBooking",
  path: ":bookingId([\\w\\d\\-]{36})",
  props: true,
  withBreadcrumb: {
    deps: {
      booking: bookingGetterById<BookingParams>("bookingId"),
    },
    label: (params, context) =>
      `${(context.booking?.listing?.name || `Booking ${params.id}`)}`,
  },
  component: AccountBooking,
});

factory.addSubRoute('accountBase', {
  name: "accountFavorites",
  path: "saved",
  withBreadcrumb: {
    label: "Saved listings",
  },
  component: AccountFavorites,
});

factory.addSubRoute('accountBase', {
  path: "notifications",
  name: "accountNotificationsBase",
  title: "PrettyShell - Notifications",
  withBreadcrumb: {
    label: "Notifications",
  },
});

factory.addSubRoute('accountNotificationsBase', {
  path: "",
  name: "accountNotifications",
  component: AccountNotificationsList,
});

factory.addSubRoute('accountNotificationsBase', {
  name: "accountNotification",
  path: ":notificationId([\\w\\d\\-]{36})",
  props: true,
  component: AccountNotificationsList,
  breadcrumb: {
    label: "Notification",
  },
})

export const routes = factory.routes;
