<template>
  <div v-if="isDebug" :class="classes">
    <Icon name="menu" @click="isOpen = !isOpen" />
    <div class="is-flex p-2 is-justify-content-center">
      <PsButton @click="onAuthError">Auth error</PsButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useApi } from "@/plugins/Api";

const api = useApi();
const isDebug = import.meta.env.DEV;
const isOpen = ref(false);
const classes = computed(() => {
  return {
    "debug-overlay": true,
    "debug-overlay-open": isOpen.value,
  };
});

const onAuthError = () => {
  api.c.tests.authError();
};
</script>

<style lang="scss">
.debug-overlay {
  &.debug-overlay-open {
    height: 82px;
  }

  position: fixed;
  bottom: 0;
  height: 24px;
  width: 100%;
  background-color: var(--gray-snow);
}
</style>
