import {
  Api,
  Components,
  Form,
  Rum,
  Sentry,
  Translations,
  Pinia,
} from "@/plugins";
import "@/styles/main.scss";
import { App as vueApp, bus, registerAppDomain } from "@/app";

import { registerDomain as registerAccountDomain } from "@/account/index";
import { registerRentalDomain } from "@/rental";
import { createRouter } from "@/plugins/Router";


vueApp.use(Pinia);
vueApp.use(Api, {
  apiUrl: import.meta.env.VITE_APP_API_URL,
});

const router = createRouter();

registerAccountDomain({ app: vueApp, bus, router });
registerAppDomain({ app: vueApp, bus, router });
registerRentalDomain({ app: vueApp, bus, router });

vueApp.use(router);

vueApp.use(Translations);
if (import.meta.env.VITE_APP_SENTRY_DSN) {
  vueApp.use(Sentry, {
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  });
}

vueApp.use(Components);
vueApp.use(Form);

if (process.env.NODE_ENV === "main") {
  vueApp.use(Rum, {
    applicationId: "c613c34b-0800-4444-9925-5f8ed1c33b50",
    applicationVersion: "1.0.0",
    applicationRegion: "us-east-2",
  });
}
router.isReady().then(() => {
  vueApp.mount("#app");
})
