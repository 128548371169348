import { defineStore } from "pinia";
import {computed, ref} from "vue";
import {useGenericApiCollection} from "@/app/api";

export class Cause {
  id: string;
  name: string;
  description: string | undefined;
  icon_name: string;
  image: string;

  constructor(params: {
    id: string;
    name: string;
    description?: string;
    icon_name: string;
    image: string;
  }) {
    this.id = params.id;
    this.name = params.name;
    this.description = params.description;
    this.icon_name = params.icon_name;
    this.image = params.image;
  }

  get isFacade() {
    /* This cause is not an actual cause, it's an option to let someone else decide on the cause (eg. "pick for me") */
    return this.icon_name.indexOf("sync") > -1;
  }
}

export default defineStore("causes", () => {
  const items = ref<Cause[]>([]);
  const loading = ref(true);
  const api = useGenericApiCollection<Cause, Cause>("charity/causes", {
    deserializer: (el) => new Cause(el),
  });
  return {
    fetchCauses: async () => {
      items.value = await api.list({ordering: "order"})
      loading.value = false;
    },
    causes: items,
    loading: loading,
    ready: computed(() => loading.value === false),
    nonFacades: computed<Cause[]>(() => items.value.filter((el) => !el.isFacade)),
  }
});
