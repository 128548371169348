<template>
  <HeaderSection :variant="props.isAuthenticated ? 'authenticated' : 'unauthenticated'" />

  <BreadcrumbsSection v-show="withActionNav" />

  <main>
    <Suspense>
      <template #fallback>
        <LoadingFullSection />
      </template>

      <router-view />
    </Suspense>
  </main>

  <FooterSection v-if="withFooter" />
</template>

<script setup>
import BreadcrumbsSection from "@/sections/Breadcrumbs.vue";
import FooterSection from "@/sections/Footer.vue";
import HeaderSection from "@/sections/Header.vue";
import LoadingFullSection from "@/sections/LoadingFull.vue";

const props = defineProps({
  isAuthenticated: {
    type: Boolean,
    default: false,
  },
  withFooter: {
    type: Boolean,
    default: true,
  },
  withActionNav: {
    type: Boolean,
    default: true,
  },
});
</script>
