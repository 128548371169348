import type {ApiModel} from "@/io/base";
import {type Model, useCollection} from "@/helpers/store";
import {useDeserializer} from "@/app/io";
import {defineStore} from "pinia";
import {useApiCollection} from "@/app/api";
import {computed} from "vue";

export interface ApiCategory extends ApiModel {
  name: string;
  parent?: ApiCategory;
  parent_id?: string;
  is_featured: boolean;
  icon?: string;
  media?: string,
}

export interface Category extends Model {
  name: string;
  parent?: Category;
  parent_id?: string;
  icon?: string;
  media?: string,
  isFeatured: boolean;
}

const categoryDeserializer = useDeserializer<ApiCategory, Category>({
  id: "id",
  name: "name",
  parent: (obj): Category | undefined => obj.parent ? categoryDeserializer.deserializer(obj.parent) : undefined,
  parent_id: "parent_id",
  icon: "icon",
  media: "media",
  isFeatured: "is_featured",
})

export const useCategories = defineStore("categories", () => {
  const categoriesApi = useApiCollection<Category, ApiCategory>("rental/categories", {
    isCached: false,
    deserializer: categoryDeserializer.deserializer,
  });
  const {items, sync, ensureById, getById, isLoading, useFiltered, getByFiltered, hasId} = useCollection<Category>(async () => {
    return await categoriesApi.list({limit: 200, ordering: "name"});
  });

  const useGetByParentId = (parentId: string | null) => useFiltered((obj) => obj.parent_id === parentId);

  return {
    ready: computed(() => !isLoading.value),
    items,
    sync,
    hasId,
    fetchCategories: sync,
    listByParentId: (parentId: string | null) => getByFiltered((obj) => obj.parent_id === parentId),
    getRootItems() {
      return this.listByParentId(null)
    },
    useGalleryItems() {
      return useFiltered((obj) => obj.isFeatured);
    },
    getById,
    getByName: (name: string) => getByFiltered((obj) => obj.name === name)[0],
    search: (q: string) => getByFiltered((obj) => obj.name.toLowerCase().indexOf(q) > -1),
    useGetByParentId,
  }
})
