<template>
  <header :class="classes">
    <div class="container">
      <div class="hamburger-wrapper">
        <PsButton
          is-pill
          is-rounded
          color="secondary"
          styling="ghost"
          :theme="styling === 'transparent' ? 'dark' : 'light'"
          icon="menu"
          v-test-id="'button-open-sidebar'"
          @click="openSidebarNav"
        />

        <div class="logo-wrapper is-flex flex-gap-1 is-flex-wrap-nowrap">
          <Logo
            class="is-hidden-desktop is-clickable"
            :variant="styling === 'transparent' ? 'light' : 'color'"
            size="small"
            @click="router.push('/')"
          />
          <Logo
            class="is-hidden-touch is-clickable"
            :variant="styling === 'transparent' ? 'light' : 'color'"
            @click="router.push('/')"
          />
          <img
            class="beta-logo"
            src="/beta.svg"
          >
        </div>
      </div>

      <div
        v-if="isMinimal"
        class="is-hidden-mobile search-container"
      />
      <div
        v-if="!isMinimal"
        class="is-hidden-mobile search-container"
      >
        <Search />
      </div>

      <div class="action-wrapper">
        <div class="level">
          <div
            v-if="variant === 'authenticated' && user"
            class="level-right"
          >
            <div class="level-item">
              <div class="is-hidden-mobile is-flex flex-gap-1">
                <PsButton
                  v-if="!isMinimal"
                  is-pill
                  is-rounded
                  color="secondary"
                  styling="ghost"
                  :theme="styling === 'transparent' ? 'dark' : 'light'"
                  icon="outlined/notifications"
                  :show-alert="hasNewNotifications"
                  @click="router.push({ name: 'accountNotifications' })"
                />
                <PsButton
                  v-if="!isMinimal"
                  is-pill
                  is-rounded
                  color="secondary"
                  styling="ghost"
                  :theme="styling === 'transparent' ? 'dark' : 'light'"
                  icon="outlined/forum"
                  :show-alert="user.hasNewMessages"
                  @click="router.push({ name: 'messages' })"
                />
                <Avatar
                  :user="actor"
                  class="is-clickable"
                  @click="openSidebarUserNav"
                />
                <PsButton
                  v-if="!isMinimal"
                  class="ml-3"
                  icon="outlined/post_add"
                  v-test-id="'button-create-listing'"
                  @click="router.push('/listings/create')"
                >
                  New listing
                </PsButton>
              </div>
              <div class="is-hidden-tablet is-flex flex-gap-0">
                <PsButton
                  v-if="!isMinimal"
                  is-pill
                  is-rounded
                  color="secondary"
                  styling="ghost"
                  :theme="styling === 'transparent' ? 'dark' : 'light'"
                  icon="outlined/notifications"
                  :show-alert="notifications.hasNew"
                  @click="router.push({ name: 'accountNotifications' })"
                />
                <Avatar
                  :user="actor"
                  class="is-clickable"
                  @click="openSidebarUserNav"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="level-right"
          >
            <div
              v-if="!isMinimal"
              class="is-hidden-mobile level-item"
            >
              <div class="control">
                <PsButton
                  styling="ghost"
                  color="secondary"
                  :theme="styling === 'transparent' ? 'dark' : 'light'"
                  @click="setModal('signUp')"
                >
                  Sign up
                </PsButton>
              </div>
            </div>
            <div class="level-item">
              <div class="control">
                <PsButton
                  class="is-hidden-tablet"
                  color="primary"
                  @click="router.push({ name: 'signIn' })"
                >
                  Sign in
                </PsButton>
                <PsButton
                  class="is-hidden-mobile"
                  color="primary"
                  @click="setModal('signIn')"
                >
                  Sign in
                </PsButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isMinimal"
        class="is-hidden-desktop search-container"
      >
        <Search />
      </div>
    </div>
  </header>

  <SidebarNav
    :is-open="sidebarNavIsActive"
    @close="closeSidebarNav"
  />

  <SidebarUserNav
    v-if="variant === 'authenticated' && user"
    :is-open="sidebarUserNavIsActive"
    @close="closeSidebarUserNav"
  />
</template>

<script setup lang="ts">
import {computed, inject, ref} from "vue";
import { useRouter } from "vue-router";

import Search from "@/components/Search.vue";
import SidebarNav from "@/components/SidebarNav.vue";
import SidebarUserNav from "@/components/SidebarUserNav.vue";
import Avatar from "@/elements/Avatar.vue";
import Logo from "@/elements/Logo.vue";

import { useNotifications } from "@/account/stores/notifications";
import { useSession } from "@/app/stores/session";
import {storeToRefs} from "pinia";

const router = useRouter();
const session = useSession();
const {actor, user} = storeToRefs(session);

const { setModal } = inject('authOverlay');

const props = defineProps({
  variant: {
    type: String,
    default: "unauthenticated",
  },
  styling: {
    type: String,
    default: "normal",
  },
  position: {
    type: String,
    default: "fixed",
  },
  isMinimal: {
    type: Boolean,
    default: false,
  },
});

const notifications = useNotifications();
const sidebarNavIsActive = ref(false);
const sidebarUserNavIsActive = ref(false);

const hasNewNotifications = notifications.useHasNew();

const classes = computed(() => {
  return [`is-${props.styling}`, `is-${props.position}-header`];
});

function openSidebarNav() {
  sidebarNavIsActive.value = true;
}

function closeSidebarNav() {
  sidebarNavIsActive.value = false;
}

function openSidebarUserNav() {
  sidebarUserNavIsActive.value = true;
}

function closeSidebarUserNav() {
  sidebarUserNavIsActive.value = false;
}
</script>

<style lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

header {
  display: flex !important;
  justify-content: center;

  padding: 16px 64px;
  box-shadow: 0px 1px 0px rgba(6, 30, 62, 0.1);
  background-color: var(--gray-snow);
  width: 100%;
  top: 0;
  z-index: 10;
  -webkit-transition:
    background-color 500ms ease-in-out,
    box-shadow 500ms ease-in-out;
  -ms-transition:
    background-color 500ms ease-in-out,
    box-shadow 500ms ease-in-out;
  transition:
    background-color 500ms ease-in-out,
    box-shadow 500ms ease-in-out;

  @include touch {
    padding: 16px 0;
  }

  & > .container {
    display: grid !important;
    grid-template-columns: 20% 30% 20%;
    align-items: center;

    @include touch {
      display: flex !important;
    }
  }

  &.is-transparent {
    box-shadow: none;
    background-color: transparent;
    color: white;

    .avatar {
      color: $gray-slate;
    }
  }

  &.is-inline-header {
    position: absolute;
    top: auto;
  }

  &.is-fixed-header {
    position: fixed;
  }

  &.is-top-header {
    position: absolute;
    top: 0;
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include touch {
      flex-wrap: wrap;
      gap: 12px;
    }
  }

  .hamburger-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    @include touch {
      flex-grow: 0.5;
      gap: 8px;
    }

    .logo-wrapper {
      img {
        max-width: inherit;
      }

      @include touch {
        gap: 8px;
      }

      @include mobile {
        gap: 0;
      }

      @include until(356px) {
        .beta-logo {
          display: none;
        }
      }
    }
  }

  .search-container {
    flex-grow: 0.33;

    @include touch {
      flex-grow: 1;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include touch {
      flex-grow: 0.5;
    }
  }

  div.navbar-item {
    display: inline-block;
  }

  .navbar-item .avatar img {
    max-height: fit-content;
  }

  .navbar-dropdown {
    border: 1px solid var(--gray-muted);
    border-radius: 8px;
    left: -92px;
    min-width: 140px;
    position: absolute;
    top: 100%;
    width: 264px;
    transition-property: opacity;
    transition-duration: 500ms;
    transition-delay: 0;
    opacity: 0;
    display: none;
    margin-top: 16px;
    z-index: 90;

    &.is-active {
      display: block;
      background-color: var(--gray-snow);
      opacity: 1;
    }

    .icon {
      margin-right: 10px;
    }
  }
}
</style>
