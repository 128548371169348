<template>
  <div class="content">
    <div>
      <h2>
        <b>Step 1: Look for the feedback button</b>
      </h2>
      <p>
        Look for the blue feedback button at the bottom of the page.
      </p>
    </div>

    <div class="mt-6">
      <h2>
        <b>Step 2: Provide detailed feedback</b>
      </h2>
      <p>
        Click on the feedback button to open the feedback form. Choose the type of feedback you wish to provide. In the text box, provide a detailed description of the bug, feature request, or feedback. Be as specific as possible.
      </p>
      <ul>
        <li>For bugs, include steps to reproduce the issue.</li>
        <li>For feature requests, describe how the feature would benefit you and other users.</li>
      </ul>
    </div>

    <div class="mt-6">
      <h2>
        <b>Step 3: Submit your feedback</b>
      </h2>
      <p>
        If your email address is not automatically filled, enter your email address so we can follow up if needed. Click the “Submit” button to send your feedback to our team. You will see a confirmation message indicating that your feedback has been successfully submitted.
      </p>
      <p>
        Our team will review your feedback and may reach out to you for further details or updates.
      </p>
    </div>

    <hr>

    <div class="mt-6">
      <h2>
        <b>Tips for effective feedback</b>
      </h2>
      <p>
        <b>Be Specific:</b> The more details you provide, the easier it is for us to understand and address your feedback.
      </p>
      <p>
        <b>Include Screenshots:</b> Visual aids can significantly help in diagnosing issues or understanding your suggestions.
      </p>
      <p>
        <b>Stay Engaged:</b> Check your email for any follow-up questions or updates from our team.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss"></style>
