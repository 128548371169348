<template>
  <nav class="breadcrumbs">
    <div class="container">
      <div class="is-flex is-align-items-center">
        <PsButton
          is-pill
          is-rounded
          color="secondary"
          styling="outlined"
          @click="handleBackCrumb"
        >
          <Icon
            name="outlined/chevron_left"
            variant="secondary"
          />
        </PsButton>
        <div class="is-flex is-align-items-center is-flex-wrap-wrap">
          <router-link to="/">
            <span>Home</span>
          </router-link>
          <router-link
            v-for="(crumb, idx) in crumbs"
            :key="idx"
            :to="crumb.to"
            active-class="active"
            :active="crumb.isActive"
          >
            <span>{{ crumb.label }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {type RouteRecordRaw, useRoute, useRouter} from "vue-router";
import type {Breadcrumb} from "@/app/router";

const route = useRoute();
const router = useRouter();

type CrumbRoute = {
  to: {
    name: string,
    params: object,
    path?: string,
  },
  isActive?: boolean,
  label: string,
};

const getLabel = (segment, crumb: Breadcrumb) => {
  if (crumb.label) {
    if (typeof crumb.label === "function") {
      return crumb.label(route.params, route.meta.context || {});
    }
    return crumb.label;
  }
  return segment.name;
};

// Select the previous crumb if existing and navigate to it, or go to 'home'
function handleBackCrumb() {
  const previousCrumbTo =
    (crumbs.value.length > 1
      ? (crumbs.value.reverse()[1].to ?? undefined)
      : undefined) as RouteRecordRaw;

  router.push(previousCrumbTo ?? { name: "home" });
}

function handleRouteCrumb(segment, crumb): CrumbRoute{
  const crumbTo: CrumbRoute['to'] = {};
  if (segment.name) {
    crumbTo.name = crumb.name ?? segment.name;
    crumbTo.params =
      typeof segment.params === "function"
        ? segment.params(route.params, route.meta.context || {})
        : segment.params;
  } else {
    crumbTo.path = segment.path;
  }
  return {
    to: {
      ...crumbTo,
    },
    label: getLabel(segment, crumb),
  };
}

function* iterCrumbs(segments): Generator<CrumbRoute, void, unknown> {
  for (const routeSegment of segments) {
    const routeCrumb = routeSegment.meta?.breadcrumb;
    if (!routeCrumb) {
      continue;
    }
    if (routeCrumb.before) {
      yield* routeCrumb.before
        .filter((c) =>
          c.show ? c.show(route.params, route.meta.context) : true,
        )
        .map((c) => handleRouteCrumb(c, c));
    }
    yield handleRouteCrumb(routeSegment, routeCrumb);
  }
}

const crumbs = computed(() => {
  const result = Array(...iterCrumbs(route.matched));
  if (result.length > 0) {
    result[result.length - 1].isActive = true;
  }
  return result;
});
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

nav.breadcrumbs {
  width: 100%;
  padding: 16px 0;
  background-color: $gray-snow;

  a {
    &:hover {
      text-decoration: unset !important;

      &:not(.router-link-exact-active) > span {
        text-decoration: underline;
      }
    }

    &:last-child {
      color: $gray-slate;
      font-weight: 600;
    }

    &:not(:last-child) {
      color: $gray-muted;

      &:after {
        padding: 0 0.5em;
        content: '/';
        color: $gray-ash !important;
        text-decoration: unset !important;
      }
    }

    &:hover:not(:last-child) {
      &:after {
        text-decoration: unset !important;
      }
    }
  }

  button {
    margin-right: 1em;
  }
}
</style>
