<template>
  <div class="container">
    <div class="pb-2 is-flex is-flex-direction-column has-text-centered big-thanks">
      <p class="title is-3">
        Thanks!
      </p>
      <div class="media is-flex is-flex-direction-row is-justify-content-center">
        <div class="image">
          <img src="/sheldon-thumbs-up.png">
        </div>
      </div>
      <p>Your rental has been booked and is awaiting owner approval</p>
      <p class="help">
        Your booking request has been sent to the owner for review.
        <br>They’ll let you know if the rental is approved.
      </p>
      <div class="field is-flex is-flex-direction-row is-justify-content-center">
        <PsButton
          :is-loading="isNavigating"
          @click="gotoBooking(bookingId)"
        >
          View your booking
        </PsButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import type { Booking } from "@/store/rental/models";

defineProps<{ bookingId: Booking["id"] }>();

const router = useRouter();

const isNavigating = ref(false);

async function gotoBooking(bookingId: Booking["id"]) {
  isNavigating.value = true;

  await router.push({
    name: "accountBooking",
    params: { bookingId },
  });

  isNavigating.value = false;
}
</script>
<style scoped>
.big-thanks {
  gap: 20px;
}
</style>
