<template>
  <SignInModal :is-active="activeModal === 'signIn'" />
  <SignUpModal :is-active="activeModal === 'signUp'" />
  <SignedUpModal :is-active="activeModal === 'signedUp'" />
  <ForgotPasswordModal :is-active="activeModal === 'forgotPassword'" />
  <slot />
</template>

<script setup lang="ts">
import ForgotPasswordModal from "@/components/auth/ForgotPasswordModal.vue";
import SignInModal from "@/components/auth/SignInModal.vue";
import SignUpModal from "@/components/auth/SignUpModal.vue";
import SignedUpModal from "@/components/auth/SignedUpModal.vue";

import {provide, ref} from "vue";

type Modal = "signIn" | "signUp" | "signedUp" | "forgotPassword";

const activeModal = ref<Modal | undefined>();

provide("authOverlay", {
  setModal: (modal: Modal) => {
    activeModal.value = modal;
  },
  closeModal: () => {
    activeModal.value = undefined;
  }
})
</script>
