<template>
  <div class="weekday-input container control">
    <div
      v-for="(weekday, index) in WEEKDAYS"
      :key="index"
      :class="{ 'is-selected': isSelected(weekday), 'is-hightlight-unselected': context.highlightUnselected && !isSelected(weekday) }"
      class="weekday-item mr-2"
      @click.prevent="handleSelect(weekday)"
    >
      <span>{{ shortenDisplayWeekday(weekday, 2) }}</span>
    </div>
    <p
      v-if="context.showSummaryText"
      class="ml-2"
    >
      {{ context.summaryTextPrefix }} {{ summaryTextBasedOnSelection }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { isEqual } from "lodash";

import Formats from "@/helpers/formats";

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
});

const WEEKDAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const shortenDisplayWeekday = (weekday: string, length: number) =>
  Formats.toCamelCase(weekday.slice(0, length));

const summaryTextBasedOnSelection = computed(() => {
  const selectedValues = props.context.value;

  if (!selectedValues?.length) return "no days";
  if (selectedValues.length === 7) return "everyday";
  if (selectedValues.length === 1)
    return `every ${Formats.toCamelCase(selectedValues[0])}`;
  if (
    isEqual(selectedValues, [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
    ])
  )
    return "on weekdays";
  if (isEqual(selectedValues, ["saturday", "sunday"])) return "on weekends";

  return `on ${selectedValues
    .slice(0, selectedValues.length - 1)
    .map((value: string) => shortenDisplayWeekday(value, 3))
    .join(
      ", ",
    )}, and ${shortenDisplayWeekday(selectedValues[selectedValues.length - 1], 3)}`;
});

const isSelected = (weekday: string) =>
  (props.context.value || []).indexOf(weekday) > -1;

const handleSelect = (weekday: string) => {
  const newSelectedDays = WEEKDAYS.filter((el) =>
    el === weekday ? !isSelected(weekday) : isSelected(el),
  );
  props.context.node.input(newSelectedDays);
};
</script>

<style lang="scss" scoped>
.weekday-input {
  display: flex;
  align-items: center;
  width: 100%;
}

.weekday-item {
  background-color: var(--gray-smoke);
  border: 2px solid var(--gray-smoke);
  border-radius: 20px;
  color: var(--gray-slate);
  width: 40px;
  height: 40px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  cursor: pointer;

  &:hover {
    border: 2px solid var(--primary);
    border-radius: 20px;
  }

  &.is-selected {
    border: 2px solid var(--primary);
    background-color: var(--primary);
    color: var(--gray-snow);
    box-shadow: inset 0px -2px 0px rgba(6, 30, 62, 0.1);

    &:hover {
      background-color: var(--gray-mist);
      color: var(--gray-muted);
    }
  }

  &.is-hightlight-unselected {
    border: 1px solid var(--gray-mist);
    background-color: var(--gray-mist);
    color: var(--gray-muted);
    position: relative;

    &:hover {
      border: 2px solid var(--primary);
    }

    &:before {
      content: ' ';
      position: absolute;
      background-color: var(--gray-smoke);
      width: 2px;
      height: 32px;
      top: 4px;
      left: 50%;
      transform: rotate(45deg);
    }

    span {
      z-index: 1;
    }
  }
}
</style>
