<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import Modal from "@/components/Modal.vue";
import TermsConditionsInsuranceContent from "@/components/TermsConditionsInsuranceContent.vue";
import InsuranceModal from "@/components/rental/explainers/InsuranceModal.vue";
import PsButton from "@/elements/PsButton.vue";
import Panel from "@/form/panel-select/Panel.vue";
import PanelSelect from "@/form/panel-select/PanelSelect.vue";

import { useLoading } from "@/composables/loaders";
import { getCurrentUser } from "@/app/stores/session";
import { useBookings } from "@/rental/stores/bookings";
import type { Booking } from "@/rental/stores/bookings";
import { costTypesWithoutProtection } from "@/store/rental/types";
import {useI18n} from "vue-i18n";

const emits = defineEmits(["previous", "next", "update:modelValue"]);
const bookings = useBookings();
const currentUser = getCurrentUser();

const props = defineProps<{
  booking: Booking,
}>();
const modelValue = defineModel<boolean>({default: false});

const termsAccepted = ref(false);
const showTermsAcceptedWarning = ref(false);
const showTermsAndConditionsModal = ref(false);
const { t } = useI18n();

watch(
  () => termsAccepted.value,
  (isAccepted) => {
    if (isAccepted) {
      showTermsAcceptedWarning.value = false;
      return;
    }
  },
);

const rentalPricing = computed(() =>
  props.booking.costsBy(currentUser.id, costTypesWithoutProtection).at(0),
);

const insurancePrice = computed(() => {
  return props.booking.listing.insurancePremium ?? 0;
});

const saveAndContinue = async () => {
  await bookings.updateReservation(
    props.booking.id, {
      dates: props.booking.dates,
      renterInsuranceEnabled: modelValue.value,
  });
  emits("next");
};

const { trigger: onNext, isLoading } = useLoading(saveAndContinue);

const changeTermsAccepted = (newValue: boolean) => {
  termsAccepted.value = newValue;
};

const showTermsWarning = () => {
  showTermsAcceptedWarning.value = true;
};

const showTermsModal = () => {
  showTermsAndConditionsModal.value = true;
};

const closeTermsModal = () => {
  showTermsAndConditionsModal.value = false;
};

function printTermsPdf() {
  const elem = document.getElementById("terms-and-conditions");

  if (elem) {
    const domClone = elem.cloneNode(true);
    let printSection = document.getElementById("print-terms-and-conditions");
    if (!printSection) {
      printSection = document.createElement("div");
      printSection.id = "print-terms-and-conditions";
      document.body.appendChild(printSection);
    }
    printSection.innerHTML = "";
    printSection.appendChild(domClone);

    const mywindow = window.open(
      "terms-and-conditions",
      "PRINT",
      "height=400,width=600",
    );
    if (mywindow) {
      mywindow.document.write(
        "<html><head><title>" + document.title + "</title>",
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write("<h1>" + document.title + "</h1>");
      mywindow.document.write(printSection.innerHTML);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    }
  }
}
</script>

<template>
  <p class="title">
    Add insurance
  </p>
  <div class="content insurance-options">
    <PanelSelect>
      <Panel
        :is-active="modelValue"
        @selected="emits('update:modelValue', true)"
      >
        <template #head>
          <label
            class="is-flex is-flex-wrap-wrap is-flex-grow-1 is-align-items-center is-justify-content-space-between flex-gap-1"
          >
            <span class="is-flex is-align-items-center flex-gap-1">
              <Icon
                is-backed
                name="protection"
              />
              PS Insurance
            </span>
            <span class="has-text-centered">
              <Money
                :amount="insurancePrice"
                class="has-text-weight-normal mr-1"
              />
              <span
                v-if="rentalPricing"
                class="help"
              >
                x {{ rentalPricing.units }}
                {{ t(`units.${rentalPricing.unitType}`, rentalPricing.units) }}
              </span>
            </span>
          </label>
        </template>
        <p>
          <b>Protection against theft and loss</b>
        </p>
        <p>
          <b>Coverage up to $4,000</b>
          <br>
          Coverage is limited to a maximum of $4,000 after a deductible of $250
        </p>
        <template #actions>
          <span class="mt-2"><InsuranceModal /></span>
        </template>
      </Panel>
      <Panel
        :is-active="!modelValue"
        @selected="emits('update:modelValue', false)"
      >
        <template #head>
          <label
            class="is-flex is-flex-wrap-wrap is-flex-grow-1 is-align-items-center is-justify-content-space-between flex-gap-1"
          >
            No insurance
            <Money
              :amount="0"
              class="has-text-weight-normal mr-1"
            />
          </label>
        </template>
        <p>You&apos;ll take the risk, no additional protection against theft and loss</p>
      </Panel>
    </PanelSelect>

    <label class="checkbox">
      <input
        type="checkbox"
        :value="termsAccepted"
        @change="(e) => changeTermsAccepted((e?.target as any)?.checked ?? false)"
      >
      <span class="terms">
        I understand and agree to the Pretty Shell
        <a @click.prevent="showTermsModal">Terms & conditions</a>
      </span>
    </label>

    <div
      class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap is-justify-content-end px-8"
    >
      <PsButton
        :is-loading="isLoading"
        :disabled="!termsAccepted"
        @mouseover="!termsAccepted && showTermsWarning()"
        @click.prevent="!termsAccepted ? showTermsWarning() : onNext()"
      >
        Next: Payment method
      </PsButton>
    </div>

    <div
      class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap is-justify-content-end px-8"
    >
      <p
        v-if="showTermsAcceptedWarning"
        class="is-size-7 has-text-danger"
      >
        Please accept the Terms & conditions.
      </p>
    </div>
  </div>

  <Modal
    v-model="showTermsAndConditionsModal"
    title="Terms & Conditions"
    size="medium"
  >
    <div
      id="terms-and-conditions"
      class="container"
    >
      <p class="super-title">
        Terms & Conditions
      </p>
      <p class="title mt-1">
        Pretty Shell Insurance
      </p>
      <p class="is-size-6 mb-6">
        These terms and conditions ("Terms") govern the insurance coverage provided by Pretty Shell
        LLC ("the Company") for participants engaging in peer-to-peer rental activities facilitated
        through our platform. By utilizing our services, you agree to abide by these Terms. Please
        read them carefully.
      </p>

      <TermsConditionsInsuranceContent />
    </div>

    <template #foot>
      <section class="is-flex is-justify-content-space-between">
        <PsButton
          color="secondary"
          styling="outlined"
          @click="printTermsPdf"
        >
          Download as PDF
        </PsButton>

        <PsButton @click="closeTermsModal">
          Done
        </PsButton>
      </section>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.insurance-options {
  label {
    min-height: 48px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  .terms {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
