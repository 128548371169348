import * as Sentry from "@sentry/vue";
import type { App } from "vue";

interface SentryOptions {
  dsn: string;
}

export default {
  install: (app: App, options: SentryOptions): void => {
    const router = app.config.globalProperties.$router;
    Sentry.init({
      app,
      dsn: options.dsn,
      release: "prettyshell-web@" + APP_VERSION,
      environment: import.meta.env?.MODE,
      ignoreErrors: ["ApiValidationError"],
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/app\.prettyshell\.com/,
        /^https:\/\/staging\.prettyshell\.com/,
      ],
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.feedbackIntegration({
          // Additional SDK configuration goes in here, for example:
          colorScheme: "system",
          isEmailRequired: true,
          triggerLabel: "Feedback",
          submitButtonLabel: "Send feedback",
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.8, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  },
};
