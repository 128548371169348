<template>
  <div>
    <p class="title is-3 mb-4">
      {{ $t('flows.listing.photos.title') }}
    </p>
    <p class="my-4">
      {{ $t('flows.listing.photos.subtitle') }}
    </p>

    <div class="mt-6 mb-4">
      <FormKit
        name="media"
        type="list"
      >
        <div class="tile photos">
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                :validation-messages="{
                required: 'Upload at least 1 photo',
              }"
                outer-class="full-height"
                type="photo"
                size="big"
                validation="required"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-vertical is-horizontal-mobile">
            <div class="tile">
              <div class="tile is-parent">
                <div class="tile is-child">
                  <FormKit
                    outer-class="full-height"
                    type="photo"
                    size="small"
                    wrapper-class="full-height"
                  />
                </div>
              </div>
              <div class="tile is-parent">
                <div class="tile is-child">
                  <FormKit
                    outer-class="full-height"
                    type="photo"
                    size="small"
                    wrapper-class="full-height"
                  />
                </div>
              </div>
            </div>
            <div class="tile">
              <div class="tile is-parent">
                <div class="tile is-child">
                  <FormKit
                    outer-class="full-height"
                    type="photo"
                    size="small"
                    wrapper-class="full-height"
                  />
                </div>
              </div>
              <div class="tile is-parent">
                <div class="tile is-child">
                  <FormKit
                    outer-class="full-height"
                    type="photo"
                    size="small"
                    wrapper-class="full-height"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="photosUploadedLength >= 5"
          class="tile photos"
        >
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
        </div>
        <div
          v-if="photosUploadedLength >= 9"
          class="tile photos"
        >
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
        </div>
        <div
          v-if="photosUploadedLength >= 13"
          class="tile photos"
        >
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child">
              <FormKit
                outer-class="full-height"
                type="photo"
                size="small"
                wrapper-class="full-height"
              />
            </div>
          </div>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  values: {
    type: Object,
    default: () => ({}),
  },
  isNew: {
    type: Boolean,
    default: false,
  },
});

const photosUploadedLength = computed(() => {
  const lastIndexOfDefinedMedia = props.values.media?.findLastIndex(
    (el) => el !== undefined,
  );
  return lastIndexOfDefinedMedia + 1;
});
</script>

<style lang="scss" scoped>
.photos {
  .tile.is-parent {
    padding: 4px;
  }
}
</style>
