<script lang="ts" setup>
import { useRouter } from "vue-router";

import Avatar from "@/elements/Avatar.vue";

import { useHelpers } from "@/plugins/Form";
import { useBookings } from "@/rental/stores/bookings";
import { useConversationStore } from "@/store/chatter/chats";
import type { Actor } from '@/app/models/actor';
import RatingSmall from "@/components/RatingSmall.vue";
import {useCurrentActor} from "@/app/stores/session";

const router = useRouter();
const currentUser = useCurrentActor();
const helpers = useHelpers();
const convoStore = useConversationStore();
const bookingStore = useBookings();

const props = withDefaults(
  defineProps<{
    person: Actor;
    size?: "small" | "medium";
    showRating?: boolean;
    showContact?: boolean;
    bookingId?: string;
    bookingStatus?: string;
    bookingChatId?: string;
  }>(),
  {
    size: "medium",
    showRating: false,
    showContact: false,
    bookingId: undefined,
    bookingStatus: undefined,
    bookingChatId: undefined,
  },
);

const isDisabledContactAndMessageButtons: boolean =
  props.person.id === currentUser.value?.id ||
  (!props.bookingChatId &&
    !!props.bookingId &&
    props.bookingStatus === "expired");

async function gotoChat() {
  let chatId;

  // If on a booking, we can use the booking chat id
  if (props.bookingChatId) {
    chatId = props.bookingChatId;
    // If on a booking, but no chat id yet, create one (EXCEPT for expired bookings)
  } else if (props.bookingStatus !== "expired" && props.bookingId) {
    const updatedBooking = await bookingStore.createBookingChat(
      props.bookingId,
      props.person.id,
    );
    chatId = updatedBooking.chat;
  } else {
    const convo = await convoStore.createConversation(props.person.id);
    chatId = convo.id;
  }

  if (!chatId) return;

  router.push({
    name: "chat",
    params: {
      chatId,
    },
  });
}
</script>

<template>
  <section
    class="is-flex is-align-content-center is-justify-content-flex-start is-align-items-center"
  >
    <Avatar
      :user="person"
      class="mr-3 my-2"
      :size="size"
    />
    <div class="details my-1 is-half is-flex is-flex-direction-column is-justify-content-center">
      <div class="is-flex is-align-items-center flex-gap-1 has-text-weight-semibold">
        <span class="fullname">{{ person.fullName }}</span>
        <RatingSmall
          v-if="showRating && person.reviews > 0"
          :value="person.rating"
          :reviews="person.reviews"
        />
      </div>
      <p
        v-if="size === 'medium'"
        class="joined is-size-6"
      >
        Member since {{ helpers.toThen(person.createdAt) }} •
        <span class="num-listings">{{ person.numListings }} active listings</span>
      </p>
      <p
        v-else-if="size === 'small'"
        class="joined"
      >
        {{ helpers.dateLocalFormat(person.createdAt) }}
      </p>
    </div>
    <slot name="side">
      <div
        v-if="showContact"
        class="is-hidden-tablet actions my-1 is-flex is-flex-grow-1 is-align-items-center is-justify-content-flex-start flex-gap-1 is-flex-wrap-wrap"
      >
        <!-- <PsButton
          :disabled="isDisabledContactAndMessageButtons"
          color="secondary"
          @click="gotoChat"
        >
          Contact
        </PsButton> -->
        <PsButton
          :disabled="isDisabledContactAndMessageButtons"
          color="secondary"
          icon="outlined/forum"
          @click="gotoChat"
        >
          Send message
        </PsButton>
      </div>
      <div
        v-if="showContact"
        class="is-hidden-mobile actions my-1 is-flex is-flex-grow-1 is-align-items-center is-justify-content-flex-end flex-gap-1 is-flex-wrap-wrap"
      >
        <!-- <PsButton
          :disabled="isDisabledContactAndMessageButtons"
          color="secondary"
          @click="gotoChat"
        >
          Contact
        </PsButton> -->
        <PsButton
          :disabled="isDisabledContactAndMessageButtons"
          color="secondary"
          icon="outlined/forum"
          @click="gotoChat"
        >
          Send message
        </PsButton>
      </div>
    </slot>
  </section>
</template>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.num-listings {
  color: $gray-muted;
}
</style>
