<template>
  <div class="is-flex is-align-items-center">
    <span :class="classes">
      <img
        v-if="user && user.avatarUrl"
        :src="user.avatarUrl"
      >
      <span
        v-else
        class="initials"
      >
        {{ user.initials ?? '-' }}
      </span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Actor } from "@/store/user";
import { computed } from "vue";

const props = withDefaults(defineProps<{
  user: Actor,
  size?: "small" | "medium" | "large",
}>(), {
  size: "medium",
});

const classes = computed(() => {
  return ["avatar", `is-${props.size}`];
});
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.avatar {
  width: 2.5em;
  height: 2.5em;
  margin: 0 4px;
  border-radius: 50px;
  background-color: $gray-smoke;
  text-align: center;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  img {
    object-fit: cover;
    width: auto;
    border-radius: 50px;
  }

  &.is-small {
    width: 2em;
    height: 2em;

    .initials {
      font-size: 0.7rem;
    }
  }

  &.is-medium {
    width: 2.5em;
    height: 2.5em;
  }

  &.is-large {
    width: 3em;
    height: 3em;

    .initials {
      font-size: 1.25rem;
    }
  }

  &.is-extra-large {
    width: 3.5em;
    height: 3.5em;

    .initials {
      font-size: 1.5rem;
    }
  }
}
</style>
