<template>
  <slot
    name="button"
    :handler="onClick"
  />
  <Modal
    v-model="isActive"
    size="small"
    :title="title ?? 'Confirm action'"
  >
    <p>
      {{ message }}
    </p>
    <template #foot>
      <div class="is-flex is-align-items-center is-justify-content-end flex-gap-1">
        <PsButton
          color="secondary"
          @click.prevent="onCancel"
        >
          Cancel
        </PsButton>
        <PsButton
          ref="confirmButton"
          @click.prevent="onConfirm"
        >
          {{ confirmButtonTitle ?? 'Confirm' }}
        </PsButton>
      </div>
    </template>
  </Modal>
</template>
<script lang="ts" setup>
import { ref } from "vue";

import Modal from "@/components/Modal.vue";

defineProps({
  title: {
    type: String,
    default: "",
  },
  message: {
    type: String,
    default: "",
  },
  confirmButtonTitle: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["onConfirmed"]);
const isActive = ref(false);

const onClick = () => {
  isActive.value = true;
};

const onConfirm = () => {
  emit("onConfirmed");
  isActive.value = false;
};

const onCancel = () => {
  isActive.value = false;
};
</script>

<style scoped lang="scss"></style>
