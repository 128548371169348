import {EventBus} from 'ts-bus';
import type {App} from 'vue';
import {onMounted, onUnmounted} from 'vue';
import type {EventCreatorFn, BusEvent} from "ts-bus/types";
import {EventBusInjectionKey} from "@/plugins/symbols";
import {Logger} from "@/app/logger";

export const bus = new EventBus();

bus.subscribe("**", (e) => Logger.info(`🌳 Logged event: ${e.type}`));

export const useEvents = <T extends BusEvent>(subscription: EventCreatorFn<T>, handler: (e: ReturnType<typeof subscription>) => void) => {
  onMounted(() => {
    const unsubscribe = bus.subscribe(subscription, handler);
    onUnmounted(unsubscribe);
  })
}

export const withEventBus = {
  install: (app: App) => {
    app.provide(EventBusInjectionKey, bus);
    return bus;
  }
};

export const useEventBus = () => {
  return bus;
}

export { EventBus };
