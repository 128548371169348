<template>
  <PsButton
    class="is-fullwidth"
    color="secondary"
    @click.prevent="toggleActive"
  >
    <Icon
      name="base/share"
      variant="secondary"
    />
    Share
  </PsButton>

  <Modal
    ref="listingShareModalRef"
    v-model="isActive"
    :head-background-url="listing.mainImageSrc"
  >
    <template #head>
      <div class="image" />

      <PsButton
        color="secondary"
        icon="base/close"
        is-pill
        is-rounded
        theme="light"
        @click.prevent="toggleInactive"
      />
    </template>
    <template #default>
      <div class="content">
        <p class="title is-5">
          {{ $t('listing.modals.share.title') }}
        </p>
        <p class="has-text-justified">
          {{ $t('listing.modals.share.text') }}
        </p>
      </div>
      <div class="is-flex is-justify-content-space-between flex-gap-1 is-flex-wrap-wrap">
        <div class="copy-input control is-flex-grow-1">
          <input
            ref="cloneRef"
            :value="currentUrl"
            class="input"
            readonly
            type="text"
            @focus="$event.target.select()"
          >
        </div>
        <div class="control">
          <PsButton
            color="secondary"
            @click="copy"
          >
            Copy link
          </PsButton>
        </div>
      </div>
    </template>
    <template #foot>
      <div class="field is-flex is-justify-content-end">
        <div class="control">
          <PsButton
            color="primary"
            @click="toggleActive"
          >
            Done
          </PsButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from "vue";

import Modal from "@/components/Modal.vue";

import { useModalActions } from "@/composables/modal";

defineProps({
  listing: {
    type: Object,
    default: () => {},
  },
});

const listingShareModalRef = ref(null);
const cloneRef = ref(null);
const isActive = ref(false);
const currentUrl = ref(window.location.href);

const [toggleActive, toggleInactive] = useModalActions(isActive);

async function copy() {
  cloneRef.value.focus();

  try {
    await navigator.clipboard.writeText(currentUrl.value);
  } catch (err) {
    console.log(err);
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.image {
  height: 200px;
  max-width: 380px;
  flex-grow: 1;

  @include touch {
    max-width: 80vw;
  }
}

.copy-input {
  max-width: 100%;
}
</style>
