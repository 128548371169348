import {type ApiCollectionModel, type ListQueryParams, type Model, useApiCollection} from "@/app/api";
import {actorDeserializer, type ApiActor} from "@/app/stores/session";
import type {ApiModel} from "@/io/base";
import {type ApiListing, type Listing, ListingDeserializer} from "@/rental/stores/listings";
import {ApiBookingStatus, BookingStatus} from "@/rental/types";
import type {CollectionModel} from "@/store/base/models";
import {DateRange} from "@/store/browse";
import type {Actor} from "@/app/models/actor";
import {deserialize, useCollectionDeserializer, useDeserializer, useSerializer} from "@/app/io";
import {CostType, type CostUnitType} from "@/store/rental/types";
import dayjs from "dayjs";
import {defineStore} from "pinia";
import {useCollection} from "@/helpers/store";
import {BOOKING_LOCAL_STATUS_MAPPER} from "@/rental/constants";
import {ref, type Ref, toRefs} from "vue";
import {useConversationStore} from "@/store/chatter/chats";

interface ApiCause extends ApiModel {
  image: string;
}

interface ApiBookingCost extends ApiModel {
  amount: number;
  owed_by: string;
  owed_to: string;
  description: string;
  label: string;
  unit_type: CostUnitType;
  cost_type: CostType;
  units: number;
  ppu: number;
}

interface ApiBooking extends ApiCollectionModel {
  state: ApiBookingStatus;

  dates: [string, string];
  pickup_timerange: [string, string];
  dropoff_timerange: [string, string];
  cancellation_period_ends_at?: string;
  renter_confirmed_pickup_at?: string;
  lender_confirmed_pickup_at?: string;
  renter_return_at?: string;
  lender_return_at?: string;
  starts_at: string;
  ends_at: string;

  listing_id: ApiListing["id"];
  listing: ApiListing;
  lender: ApiActor;
  renter: ApiActor;

  costs: ApiBookingCost[];
  renter_intent_id?: string;
  renter_insurance_enabled: boolean;

  rating: number;
  num_reviews: number;
  reviewed_at?: string;

  cause?: ApiCause;
  renter_cause_id?: string;
  chat?: string;
}

type ApiBookingMutables =
  "dates" |
  "listing_id" |
  "pickup_timerange" |
  "dropoff_timerange" |
  "renter_intent_id" |
  "renter_insurance_enabled" |
  "renter_cause_id";

export interface Cause extends Model {
  image: string;
};

export interface BookingCost extends Model {
  amount: number;
  owedBy: Actor["id"];
  owedTo: Actor["id"];
  description: string;
  label: string;
  unitType: CostUnitType;
  costType: CostType;
  units: number;
  ppu: number;
};

export interface PriceBreakDown {
  bookingId: Booking["id"];
}

const deserializeStatus = (obj: ApiBooking): BookingStatus => {
  if (obj.state === ApiBookingStatus.ready_for_pickup) {
    if (obj.renter_confirmed_pickup_at) {
      return BookingStatus.waiting_pickup_lender;
    }
    if (obj.lender_confirmed_pickup_at) {
      return BookingStatus.waiting_pickup_renter;
    }
  }
  if (obj.state === ApiBookingStatus.active) {
    if (obj.renter_return_at) {
      return BookingStatus.waiting_deliver_lender;
    }
    if (obj.lender_return_at) {
      return BookingStatus.waiting_deliver_renter;
    }
  }
  return BookingStatus[obj.state];
}

export interface Booking extends CollectionModel {
  status: BookingStatus;

  dates: DateRange;
  pickupTimeRange: [string, string];
  dropoffTimeRange: [string, string];
  cancellationPeriodEndsAt?: Date;
  renterConfirmedPickupAt?: Date;
  lenderConfirmedPickupAt?: Date;
  renterReturnAt?: Date;
  lenderReturnAt?: Date;
  startsAt: Date;
  endsAt: Date;

  listingId: Listing["id"];
  listing: Listing;
  lender: Actor;
  renter: Actor;

  costs: BookingCost[];
  renterIntentId?: string;
  renterInsuranceEnabled: boolean;

  rating: number;
  numReviews: number;
  reviewedAt?: Date;

  cause?: Cause;
  renterCauseId?: string;
  chat?: string;

  canCancel: boolean;
  costsBy: (userId: Actor["id"], costTypes?: CostType[]) => BookingCost[];
  isOwner: (userId: Actor["id"]) => boolean;
  isRenter: (userId: Actor["id"]) => boolean;
  totalCostsWithoutDeposit: (userId: Actor["id"]) => number;
  localStatus: () => string;
};

type BookingMutables =
  "dates" |
  "listingId" |
  "pickupTimeRange" |
  "dropoffTimeRange" |
  "renterIntentId" |
  "renterInsuranceEnabled" |
  "renterCauseId";

type bookingExtKeys = "canCancel" | "costsBy" | "isOwner" | "isRenter" | "totalCostsWithoutDeposit" | "localStatus";

const CauseDeserializer = useDeserializer<ApiCause, Cause>({
  id: "id",
  image: "image",
});

const BookingCostDeserializer = useDeserializer<ApiBookingCost, BookingCost>({
  id: "id",
  amount: (obj) => deserialize.cents_amount(obj.amount),
  owedBy: "owed_by",
  owedTo: "owed_to",
  description: "description",
  label: "label",
  unitType: "unit_type",
  costType: "cost_type",
  units: "units",
  ppu: (obj) => deserialize.cents_amount(obj.ppu),
});

const BookingDeserializer = useCollectionDeserializer<ApiBooking, Booking, bookingExtKeys>({
  listing: (obj) => ListingDeserializer(obj.listing),
  lender: (obj) => actorDeserializer(obj.lender),
  renter: (obj) => actorDeserializer(obj.renter),
  costs: (obj) => obj.costs.map(BookingCostDeserializer.deserializer),
  listingId: "listing_id",

  status: deserializeStatus,

  renterIntentId: "renter_intent_id",
  renterInsuranceEnabled: "renter_insurance_enabled",
  cause: (obj) => obj.cause ? CauseDeserializer.deserializer(obj.cause) : undefined,
  renterCauseId: "renter_cause_id",

  dates: (obj) => new DateRange(obj.dates[0], obj.dates[1]),
  pickupTimeRange: (obj) => obj.pickup_timerange,
  dropoffTimeRange: (obj) => obj.dropoff_timerange,
  startsAt: (obj) => new Date(obj.starts_at),
  endsAt: (obj) => new Date(obj.ends_at),

  rating: (obj) => obj.rating / 10,
  cancellationPeriodEndsAt: (obj) => obj.cancellation_period_ends_at ? new Date(obj.cancellation_period_ends_at) : undefined,
  renterConfirmedPickupAt: (obj) => obj.renter_confirmed_pickup_at ? new Date(obj.renter_confirmed_pickup_at) : undefined,
  lenderConfirmedPickupAt: (obj) => obj.lender_confirmed_pickup_at ? new Date(obj.lender_confirmed_pickup_at) : undefined,
  renterReturnAt: (obj) => obj.renter_return_at ? new Date(obj.renter_return_at) : undefined,
  lenderReturnAt: (obj) => obj.lender_return_at ? new Date(obj.lender_return_at) : undefined,

  numReviews: (obj) => obj.num_reviews,
  reviewedAt: (obj) => obj.reviewed_at ? new Date(obj.reviewed_at) : undefined,
  chat: "chat",
}, {
  canCancel: (obj) => {
    const now = dayjs.utc();
    return now < dayjs(obj.cancellationPeriodEndsAt);
  },
  isOwner: (obj) => (userId) => userId === obj.lender.id,
  isRenter: (obj) => (userId) => userId === obj.renter.id,
  costsBy: (obj) => (userId, costTypes?) => {
    let costs: BookingCost[] = [];
    if (obj.isOwner(userId)) {
      costs = obj.costs.filter((el) => el.owedTo === userId);
    } else {
      costs = obj.costs.filter((el) => el.owedBy === userId);
    }
    if (costTypes) {
      costs = costs.filter((el) => costTypes.includes(el.costType));
    }
    return costs;
  },
  totalCostsWithoutDeposit: (obj) => (userId) => {
    const costs = obj.costsBy(userId).filter(
      el => el.costType !== CostType.DEPOSIT
    );
    return costs.reduce((acc, el) => acc + el.amount, 0);
  },
  localStatus: (obj) => () => BOOKING_LOCAL_STATUS_MAPPER.find(el => el[0] === obj.status)?.[1] ?? obj.status,
});

export type BookingSubmitParams = Pick<Booking, "renterInsuranceEnabled" | "renterCauseId" | "renterIntentId">;
export type BookingCreateParams = Pick<Booking, BookingMutables>;

type BookingsSerializerKeys = Pick<ApiBooking, ApiBookingMutables>;
type SubmitBookingSerializerKeys = Pick<ApiBooking, "renter_insurance_enabled" | "renter_cause_id" | "renter_intent_id">;
const BookingsSerializer = useSerializer<BookingCreateParams, BookingsSerializerKeys>({
  dates: "dates",
  listing_id: "listingId",
  pickup_timerange: "pickupTimeRange",
  dropoff_timerange: "dropoffTimeRange",
  renter_intent_id: "renterIntentId",
  renter_insurance_enabled: "renterInsuranceEnabled",
  renter_cause_id: "renterCauseId",
})
const SubmitBookingSerializer = useSerializer<BookingSubmitParams, SubmitBookingSerializerKeys>({
  renter_intent_id: "renterIntentId",
  renter_insurance_enabled: "renterInsuranceEnabled",
  renter_cause_id: "renterCauseId",
})


export const useBookings = defineStore("bookings", () => {
  const lastUpdated: Ref<Date | undefined> = ref(undefined);
  const convoStore = useConversationStore();
  const bookingsApi = useApiCollection<Booking, ApiBooking>("rental/bookings", {
    isCached: false,
    deserializer: BookingDeserializer.deserializer,
    serializer: BookingsSerializer.serializer,
  });
  const collection = useCollection<Booking>(async () => {
    const params: ListQueryParams = {
      ordering: "-updated_at",
      range: [0],
    }
    if (lastUpdated.value) {
      params.updated_at__gt = lastUpdated.value.toISOString();
    }
    lastUpdated.value = new Date();
    return await bookingsApi.list(params);
  })

  return {
    ...collection,
    ensureById: (id: Booking["id"]) => collection.ensureById(id, () => bookingsApi.get(id)),
    updateReservation: async (id: Booking["id"], values: Pick<Booking, "dates" | "renterInsuranceEnabled">) => {
      const booking = collection.getById(id);
      if (!booking) {
        throw new Error("Invalid booking");
      }
      if (booking.status !== BookingStatus.new) {
        throw new Error("Invalid booking state");
      }
      collection.updateById(id, await bookingsApi.put(id, {
        ...booking,
        ...values,
      }));
    },
    placeNewBooking: async (data: BookingCreateParams) => {
      const result = await bookingsApi.create(data, {isPartial: true});
      const booking = await bookingsApi.get(result.id); // Get again for eventual consistency
      collection.add(booking);
      return booking;
    },
    submitBooking: collection.withAction((rid, data: BookingSubmitParams) => bookingsApi.action(rid, "submit", SubmitBookingSerializer.serializer(data, true))),
    acceptBooking: collection.withAction((rid) => bookingsApi.action(rid, "accept")),
    rejectBooking: collection.withAction((rid) => bookingsApi.action(rid, "reject")),
    cancelBooking: collection.withAction((rid) => bookingsApi.action(rid, "cancel")),
    confirmPickup: collection.withAction((rid) => bookingsApi.action(rid, "pickup")),
    confirmReturn: collection.withAction((rid) => bookingsApi.action(rid, "deliver")),
    review: collection.withAction((rid, rating: number, comment = "") => bookingsApi.action(rid, "review", {
      rating, comment
    })),
    createBookingChat: async (bookingId: Booking["id"], userId: string) => {
      const convo = await convoStore.createConversation(userId);

      if (!convo?.id) return {};

      console.log("put bookings: ", bookingId, {
        chat: convo.id,
      });
      const partialBooking = await bookingsApi.patch(bookingId, {
        chat: convo.id,
      });
      collection.updateById(bookingId, partialBooking);
      return collection.getById(bookingId);
    },
    priceBreakDown: (id: Booking["id"]) => bookingsApi.subResource<ApiBookingCost[], BookingCost[]>(id, "price_breakdown", {}, {
      deserializer: (arr) => arr.map(BookingCostDeserializer.deserializer),
    }),
  }
})
