<template>
  <div>
    <div v-show="!isSubCategory">
      <CategoryGallery
        :categories="catStore.getRootItems()"
        :selected-category="mainCategory?.id"
        class="my-3"
        @category-select="selectCategory"
      />
      <CategoryGallery
        v-show="mainCategory?.id"
        :categories="catStore.listByParentId(mainCategory?.id)"
        :selected-category="subCategory?.id"
        variant="card"
        @category-select="selectCategory"
      />
      <hr>
    </div>
    <div class="listing-browser">
      <PsButton
        v-show="!showFilters"
        class="is-hidden-tablet"
        full-width
        is-pill
        is-rounded
        styling="outlined"
        @click="toggleFilters"
      >
        Show filters ({{ filterCount ?? 0 }})
      </PsButton>
      <aside v-show="showFilters">
        <div class="filters">
          <p class="title is-4 mb-5">
            Filters
            <span
              v-show="filterCount > 0"
              class="filter-count"
            >{{ filterCount }}</span>
          </p>
          <FormKit
            id="filterForm"
            :actions="false"
            type="form"
          >
            <FormKit
              :allow-empty="true"
              :value="filters.selectedDates?.getApiFormat()"
              label="Availability"
              type="bookrange"
              @input="selectDateRange"
            />
            <FormKit
              v-if="false"
              label="Location"
              name="cityState"
              placeholder="Enter location"
              type="address"
            />
            <FormKit
              v-if="false"
              :value="filters.priceRange"
              label="Price"
              type="pricerange"
            />
          </FormKit>

          <PsButton
            class="mt-4 is-hidden-tablet"
            color="secondary"
            full-width
            is-pill
            is-rounded
            styling="outlined"
            @click="toggleFilters"
          >
            Hide filters
          </PsButton>
        </div>
      </aside>
      <div
        v-if="!loading"
        class="is-flex is-flex-grow-1 is-flex-direction-column"
      >
        <div
          v-show="filterCount > 0"
          class="active-filters flex-gap-1 is-flex my-2"
        >
          <BrowseFilter
            v-if="filters.q"
            icon-name="outlined/search"
            @remove="() => browsingStore.setFilters({ q: undefined })"
          >
            "{{ filters.q }}"
          </BrowseFilter>

          <BrowseFilter
            v-if="filters.selectedDates?.filter((el) => el)"
            icon-name="outlined/calendar_today"
            @remove="browsingStore.setFilters({ selectedDates: undefined })"
          >
            {{ browsingStore.filters.selectedDates?.getDisplayFormat().join(' — ') }}
          </BrowseFilter>

          <!-- <BrowseFilter
            v-if="mainCategory && filters.categoryId"
            icon-name="outlined/list"
            @remove="browsingStore.setFilters({ categoryId: undefined })"
          >
            {{ mainCategory?.name }}
            {{ subCategory ? ' / ' + subCategory.name : '' }}
          </BrowseFilter> -->

          <Chip
            v-if="browsingStore.filters.priceRange?.filter((el) => el)"
            :has-hover="false"
            color="secondary"
            styling="outlined"
          >
            <Icon
              name="outlined/attach_money"
              variant="secondary"
            />
            <Money :amount="browsingStore.filters.priceRange[0]" />
            —
            <Money :amount="browsingStore.filters.priceRange[1]" />
            <PsButton
              color="secondary"
              icon="outlined/close"
              size="small"
              styling="ghost"
              @click="browsingStore.setFilters({ priceRange: undefined })"
            />
          </Chip>
        </div>

        <p
          v-if="page && result?.count > 0"
          :class="filterCount > 0 ? 'title is-6 mt-4' : 'title is-6 mt-0'"
        >
          {{ page.num > 0 ? page.num * browsingStore.itemsPerPage : 1 }}
          -
          {{ page.num > 0 ? page.num * browsingStore.itemsPerPage + page.count : page.count }}
          of {{ result.count }} listings
          <span v-if="subCategory || mainCategory">in "{{ subCategory?.name || mainCategory?.name }}"</span>
        </p>

        <Results />

        <Pagination
          v-if="page && result"
          :current-page="page.num"
          :total-pages="result.totalPages"
          @go-to-page="goToPage"
        />
      </div>
      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { type ComputedRef, computed, ref, watch } from "vue";
import {type RouteParamsRaw, useRoute, useRouter} from "vue-router";

import CategoryGallery from "@/components/CategoryGallery.vue";
import Pagination from "@/components/browse/Pagination.vue";
import Results from "@/components/browse/Results.vue";
import BrowseFilter from "@/components/browse/filter.vue";
import Chip from "@/elements/Chip.vue";

import type { AnyDateType } from "@/helpers/formats";
import {
  DateRange,
  type FilterOptions,
  useBrowsingStore,
} from "@/store/browse";
import { type Category, useCategories } from "@/rental/stores/categories";

const props = defineProps<{
  initialMainCategoryName?: string;
  initialSubCategoryName?: string;
}>();

interface ListingsCategoryParams extends RouteParamsRaw {
  mainCategoryName?: string;
  subCategoryName?: string;
}

const router = useRouter();
const route = useRoute();

const query = computed<string | undefined>(() => route.query.q ? String(route.query.q) : undefined);

const browsingStore = useBrowsingStore();
const { page, result, loading, filters, filterCount } =
  storeToRefs(browsingStore);

const catStore = useCategories();

const isSubCategory = ref(false);
const showFilters = ref(true);

watch(query, (newValue) => {
  if (newValue === undefined) return;
  browsingStore.setFilters({
    q: newValue,
  })
}, { immediate: true })

watch(
  props,
  (newValue) => {
    const newFilters: FilterOptions = newValue.query
      ? {
          q: newValue.query,
        }
      : {};


    let categoryName = newValue.initialMainCategoryName;
    if (newValue.initialSubCategoryName) {
      categoryName = newValue.initialSubCategoryName;
    }
    const newInitialCategory = catStore.getByName(categoryName);

    if (!newInitialCategory) {
      newFilters.categoryId = undefined;
    } else if (newInitialCategory?.id) {
      newFilters.categoryId = newInitialCategory.id;
    }

    browsingStore.setFilters(newFilters, { override: true });
  },
  { immediate: true },
);

const mainCategory: ComputedRef<Category | undefined> = computed(() => {
  if (filters.value && catStore.hasId(filters.value.categoryId))
    return catStore.getById(filters.value.categoryId);
  if (filters.value.categoryId && catStore.hasParentId(
    filters.value.categoryId,
  ))
  return undefined;
});

const subCategory: ComputedRef<Category | undefined> = computed(() => {
  if (!filters.value.categoryId) return undefined;
  const filtersCategory = catStore.getById(filters.value.categoryId);
  if (filtersCategory && filtersCategory.parent !== null) {
    return filtersCategory;
  }
  return undefined;
});

function selectDateRange(range: (AnyDateType | undefined)[]) {
  if (
    browsingStore.filters.selectedDates?.at(0) === range?.at(0) &&
    browsingStore.filters.selectedDates?.at(1) === range?.at(1)
  ) {
    // Dont do anything is event send without changes
    return;
  }

  browsingStore.setFilters({
    selectedDates: new DateRange(range?.at(0) ?? null, range?.at(1) ?? null),
  });
}

function selectCategory(newSelectedCategory: Category) {
  browsingStore.setFilters({
    categoryId: newSelectedCategory.id,
  });
}

function toggleFilters() {
  showFilters.value = !showFilters.value;
}

function goToPage(pageNum: number) {
  browsingStore.fetch(pageNum);
}

watch(
  filters,
  async (newFilters) => {
    let routeName = "listings";

    let query;
    if (newFilters.q) {
      query = {
        q: newFilters.q,
      };
    }

    if (newFilters.categoryId) {
      const params: ListingsCategoryParams = {};
      routeName = "listingsCategory";
      const category = catStore.getById(newFilters.categoryId);
      if (category.parent) {
        params.mainCategoryName = category.parent.name;
        params.subCategoryName = category.name;
      } else {
        params.mainCategoryName = category.name;
      }

      router.push({
        name: routeName,
        query,
        params,
      });
    } else {
      router.push({
        name: routeName,
        query,
      });
    }
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
@import '../../styles/mixins.scss';
@import '../../styles/variables';

.listing-browser {
  display: flex;
  width: 100%;
  gap: 3em;

  @include touch {
    flex-direction: column;
  }

  .formkit-actions {
    margin-top: 16px;
  }

  aside {
    width: 300px;
    flex-shrink: 0;

    @include touch {
      width: 100%;
      flex-shrink: unset;
    }

    .title {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .filter-count {
        font-size: 0.8em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 90%;
        background-color: $peach-500;
        color: $gray-snow;
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}
</style>
