<template>
  <FormKit
    id="confirmPassword"
    v-model="formData"
    :disabled="loading"
    submit-label="Confirm password"
    type="form"
    @submit="handleConfirmPassword"
  >
    <div class="field">
      <FormKit
        :value="email"
        label="Email address"
        name="email"
        placeholder="Email address"
        type="email"
        validation="required|email"
      />
    </div>
    <div class="field">
      <FormKit
        label="Verification code"
        name="verificationCode"
        placeholder="Verification code"
        type="number"
        validation="required|number"
      />
    </div>
    <div class="field">
      <FormKit
        label="New password"
        name="password"
        placeholder="New password"
        type="password"
        validation="required"
        validation-label="password"
        suffix-icon="eyeClosed"
        @suffix-icon-click="handlePasswordShowIconClick"
      />
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import { FormKit } from "@formkit/vue";
import {ref} from "vue";

const emit = defineEmits(["confirm-password"]);
defineProps({
  email: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

interface formDataParams {
  email?: string;
  verificationCode?: string;
  password?: string;
}
const formData = ref<formDataParams>({});

function handleConfirmPassword() {
  emit("confirm-password", {
    formData: formData.value,
  });
}

function handlePasswordShowIconClick(node) {
  node.props.suffixIcon = node.props.suffixIcon === "eye" ? "eyeClosed" : "eye";
  node.props.type = node.props.type === "password" ? "text" : "password";
}
</script>
