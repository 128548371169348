<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="20"
      height="20"
      rx="10"
    />
  </svg>
</template>
