<template>
  <div
    :class="sidebarOverlayClasses"
    @click="closeSidebarNav"
  />

  <div :class="sidebarClasses">
    <div class="sidebar-header is-flex is-flex-direction-column is-justify-content-center">
      <slot name="header">
        <div
          class="is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-end"
        >
          <PsButton
            is-pill
            is-rounded
            color="secondary"
            styling="ghost"
            icon="close"
            @click="closeSidebarNav"
          />
        </div>
      </slot>
    </div>

    <hr>

    <div class="sidebar-content">
      <slot />
    </div>

    <div class="sidebar-footer">
      prettyshell@{{ version }}
    </div>
  </div>
</template>

<script setup>
import {computed, onUnmounted, watch} from "vue";

import { version } from "@/../package.json";

const emit = defineEmits(["close"]);

const props = defineProps({
  position: {
    type: String,
    default: "left",
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const sidebarClasses = computed(() => {
  return [
    "sidebar",
    props.isOpen ? "is-open" : "",
    `is-placed-${props.position}`,
  ];
});

const sidebarOverlayClasses = computed(() => {
  return ["sidebar-overlay", props.isOpen ? "is-open" : ""];
});

const close = () => {
  document.body.classList.remove("overflow-hidden");
}

watch(
  () => props.isOpen,
  (currentIsOpen) => {
    if (currentIsOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      close();
    }
  },
);

onUnmounted(close);

function closeSidebarNav() {
  emit("close");
}
</script>

<style lang="scss">
@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.sidebar {
  width: 336px;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-color: $gray-snow;
  transition: all 500ms ease-in-out;

  @include touch {
    width: 100vw;
  }

  &.is-placed-left {
    left: -100%;

    &.is-open {
      left: 0;
    }
  }

  &.is-placed-right {
    right: -100%;

    &.is-open {
      right: 0;
    }
  }

  .sidebar-header {
    padding: 1em;
    height: $header-top-padding;

    @include touch {
      height: 72px;
    }
  }

  hr {
    margin: 0;
  }

  .sidebar-content {
    padding: 1em;
    height: calc(100vh - $header-top-padding);
    overflow-x: hidden;
    overflow-y: auto;

    @include touch {
      height: calc(100vh - 72px);
    }

    hr {
      margin: 1em -1em;
    }
  }

  .sidebar-footer {
    margin-top: -2em;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: var(--gray-muted);
  }
}

.sidebar-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: $modal-background-background-color;
  display: none;
  opacity: 0;
  transition: all 500ms ease-in-out;

  &.is-open {
    display: block;
    opacity: 1;
  }
}
</style>
