<template>
  <span
    class="tag is-rounded is-uppercase has-text-weight-bold"
    :class="colorTag"
  >{{
    labelTag
  }}</span>
</template>
<script>
import { MAPPERS } from "@/store/rental/models";

const STATUS_COLOR_TO_TAG_CLASS = {
  success: "is-success",
  warning: "is-warning",
  danger: "is-danger",
};

export default {
  props: ["status"],
  computed: {
    colorTag() {
      return STATUS_COLOR_TO_TAG_CLASS[MAPPERS.statusColor(this.status)];
    },
    labelTag() {
      return MAPPERS.status(this.status);
    },
  },
};
</script>
