<template>
  <l-map
    ref="map"
    :zoom="zoom"
    :min-zoom="3"
    :max-zoom="18"
    :center="coordinates"
    @update:center="handleLocation"
  >
    <l-tile-layer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      layer-type="base"
      name="OpenStreetMap"
    />

    <l-control-layers />

    <l-marker
      v-if="marker"
      :lat-lng="marker.coordinates || coordinates"
      :draggable="!fixedMarkerPosition"
      @update:lat-lng="handleMarker"
    >
      <l-icon
        :icon-url="LogoImage"
        :icon-size="[40, 48]"
        :icon-anchor="[20, 32]"
      />
    </l-marker>

    <l-circle
      v-if="circleCoordinates"
      :lat-lng="circleCoordinates"
      :radius="1000"
      fill
      color="#FF6d65"
      fill-color="#FF6d65"
      :fill-opacity="0.2"
    />
  </l-map>
</template>

<script setup>
import LogoImage from "/Pointer.png";
import {
  LCircle,
  LControlLayers,
  LIcon,
  LMap,
  LMarker,
  LTileLayer,
} from "@vue-leaflet/vue-leaflet";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { ref, watch } from "vue";

const emits = defineEmits(["update:location", "update:markerLocation"]);

const props = defineProps({
  initialCoordinates: {
    type: Array,
    default: () => [50, 50],
  },
  initialMarker: {
    type: Object,
    default: null,
  },
  initialZoom: {
    type: Number,
    default: 10,
  },
  fixedMarkerPosition: {
    type: Boolean,
    default: false,
  },
  circleCoordinates: {
    type: Array,
    default: null,
  },
});

const dirty = ref(false);
const coordinates = ref(props.initialCoordinates);
const marker = ref(props.initialMarker);
const zoom = ref(props.initialZoom);

watch(
  () => props.initialMarker,
  (newValue) => {
    if (dirty.value) {
      return;
    }
    marker.value = newValue;
  },
  { deep: true },
);

function handleLocation(e) {
  emits("update:location", e);
}

function handleMarker(e) {
  dirty.value = true;
  emits("update:markerLocation", e);
}
</script>

<style lang="scss">
.leaflet-container {
  height: 320px !important;
  border-radius: 1em;
}
.leaflet-pane {
  z-index: 10 !important;
}
</style>
