import type {App, DirectiveBinding} from "vue";

const directives = {
  'test-id': (el: HTMLElement, binding: DirectiveBinding) => {
    if (["staging", "development"].includes(import.meta.env.MODE))
      el.setAttribute('data-testid', binding.value);
  }
};

export const useDirectives = {
  install: (app: App) => {
    for (const [name, directive] of Object.entries(directives)) {
      app.directive(name, directive);
    }
  }
};
