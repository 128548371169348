<template>
  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    <Icon
      class="my-4"
      is-backed
      name="outlined/sentiment_very_satisfied"
      size="large"
      variant="success"
    />
    <p class="title is-6 m-0 mb-5">
      We already have your review
    </p>
    <p class="has-text-centered">
      You can only write one review per booking. Did you just do that? It will be on Pretty Shell
      soon.
    </p>
  </div>
</template>
