
<template>
  <div
    v-if="pricing"
    class="pricing mt-6"
  >
    <div class="pricing-group">
      <div
        class="price-record has-text-weight-semibold total mb-1 pb-0"
      >
        <label>
          Rental price {{ priceIntervalLabel }}
        </label>
        <Money :amount="pricing.rentalCosts" />
      </div>

      <div class="price-record">
        <label>Service fee <span v-if="feesPercentage">
          ({{ feesPercentage }}%)
        </span>
        </label>
        <Money :amount="-pricing.fees" />
      </div>
    </div>

    <hr class="my-0">

    <div class="pricing-group">
      <div class="price-record mb-1 pb-0">
        <label><span class="has-text-weight-semibold">Protection plan</span> (optional)</label>
        <PsButton
          color="primary"
          styling="ghost"
          size="small"
          @click.prevent="gotoListingEditor"
        >
          Edit
        </PsButton>
      </div>
      <div
        v-if="listing.protection === 'insurance' && insurancePremium"
        class="price-record"
      >
        <label>
          <Icon
            name="protection"
          />
          Owner protection <InsuranceModal v-slot="slotProps">
            <Icon
              is-inline
              name="outlined/help"
              variant="secondary"
              @click.prevent="slotProps.openModal"
            />
          </InsuranceModal>
        </label>
        <Money :amount="-insurancePremium" />
      </div>
      <div
        v-else
        class="price-record"
      >
        <label>
          <Icon
            name="base/close"
            variant="secondary"
            size="small"
          />
          No insurance
        </label>
        <Money
          :amount="0"
          class="no-insurance-muted"
        />
      </div>
    </div>

    <hr class="my-0">

    <div class="pricing-group">
      <div class="price-record has-text-weight-semibold total mb-2 pb-0">
        <label>Net earnings {{ priceIntervalLabel }}</label>
        <Money :amount="subTotal" />
      </div>
      <p class="pricing-group-details">
        The amount you receive after insurance and service fees are deducted from the daily rental price. This is your actual payout.
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type Ref, ref, watch } from "vue";
import { useRouter } from "vue-router";
import dayjs from "dayjs";

import Formats, { type AnyDateType } from "@/helpers/formats";
import Money from "@/elements/Money.vue";
import InsuranceModal from "@/components/rental/explainers/InsuranceModal.vue";
import {
  type Listing,
  usePublicListings,
} from "@/rental/stores/listings";
import type {Pricing} from "@/rental/composables/pricing";
import {useI18n} from "vue-i18n";

const router = useRouter();
const { t } = useI18n();

const props = defineProps<{
  listing: Listing;
}>();

const listings = usePublicListings();
const pricing: Ref<Pricing | undefined> = ref(undefined);

const priceInterval = computed(() =>
  props.listing.hasPrices
    ? props.listing.bestPricingOption.interval
    : undefined,
);

const priceIntervalLabel = computed(() => t(`intervals.${priceInterval.value}`));

watch(
  [() => props.listing.id, priceInterval],
  async ([newListingId, newPriceInterval]) => {
    if (newListingId) {
      let dateRange;

      // BookingInterval = 'daily'|'weekly'|'monthly'
      if (newPriceInterval === "daily") {
        dateRange = Formats.dateOrDatesFormatForApiWithoutTimezone([
          new Date(),
          dayjs().add(1, "day"),
        ]) as [AnyDateType, AnyDateType];
      } else if (newPriceInterval === "weekly") {
        dateRange = Formats.dateOrDatesFormatForApiWithoutTimezone([
          new Date(),
          dayjs().add(1, "week"),
        ]) as [AnyDateType, AnyDateType];
      } else if (newPriceInterval === "monthly") {
        dateRange = Formats.dateOrDatesFormatForApiWithoutTimezone([
          new Date(),
          dayjs().add(1, "month"),
        ]) as [AnyDateType, AnyDateType];
      }

      if (dateRange) {
        const [startsAt, endsAt] = dateRange;
        pricing.value = await listings.costs(newListingId, startsAt, endsAt);
      }
    }
  },
  { immediate: true },
);

const feesPercentage = computed(() => {
  if (!pricing.value) {
    return undefined;
  }
  return pricing.value.rentalItems.at(0)
    ? Math.round(
        (pricing.value.fees /
          (pricing.value.rentalItems.at(0) as PricingItem).amount) *
          100,
      )
    : undefined;
});

const insurancePremium = computed((): number => {
  if (
    props.listing.protection !== "insurance" ||
    (props.listing.insurancePremium ?? 0) === 0
  ) {
    return 0;
  }
  return props.listing.insurancePremium * 1;
});

const subTotal = computed(() => {
  if (!pricing.value) {
    return 0;
  }
  return (
    pricing.value.rentalCosts - pricing.value.fees - insurancePremium.value
  );
});

function gotoListingEditor() {
  router.push({
    name: "updateListing",
    params: { listingId: props.listing.id },
  });
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.pricing {
  background-color: $gray-mist;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  gap: 0;
  line-height: 2em;

  .pricing-group {
    padding: 2em;

    .pricing-group-details {
      font-size: 12px;
      line-height: 18px;
      color: $gray-slate;
    }
  }

  .price-record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    &.total {
      font-size: 16px;
      line-height: 24px;
    }

    label {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      gap: 0 4px;
    }

    .no-insurance-muted {
      color: $gray-muted;
    }
  }
}
</style>
