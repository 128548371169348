<template>
  <nav
    class="mt-4 pagination is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <PsButton
      v-if="currentPage > 0"
      class="pagination-previous"
      color="secondary"
      styling="outlined"
      icon="base/chevron_left"
      @click="goToPage(currentPage - 1)"
    />

    <ul class="pagination-list">
      <li
        v-for="(pageNum, idx) in firstThreePages"
        :key="idx"
      >
        <PsButton
          class="pagination-link"
          :color="currentPage === pageNum ? 'primary' : 'secondary'"
          styling="outlined"
          :aria-label="`Go to page ${pageNum + 1}`"
          :active="currentPage === pageNum"
          @click="goToPage(pageNum)"
        >
          {{ pageNum + 1 }}
        </PsButton>
      </li>

      <li
        v-if="
          middleThreePages.length &&
            (middleThreePages.at(0) ?? 0) > (firstThreePages.at(-1) ?? 0) + 1
        "
      >
        <span class="pagination-ellipsis">&hellip;</span>
      </li>

      <li
        v-for="(pageNum, idx) in middleThreePages"
        :key="idx"
      >
        <PsButton
          class="pagination-link"
          :color="currentPage === pageNum ? 'primary' : 'secondary'"
          styling="outlined"
          :aria-label="`Go to page ${pageNum + 1}`"
          :active="currentPage === pageNum"
          @click="goToPage(pageNum)"
        >
          {{ pageNum + 1 }}
        </PsButton>
      </li>

      <li
        v-if="
          middleThreePages.length &&
            lastThreePages.length &&
            (lastThreePages.at(0) ?? 0) > (middleThreePages.at(-1) ?? 0) + 1
        "
      >
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li
        v-if="
          !middleThreePages.length &&
            lastThreePages.length &&
            (lastThreePages.at(0) ?? 0) > (firstThreePages.at(-1) ?? 0) + 1
        "
      >
        <span class="pagination-ellipsis">&hellip;</span>
      </li>

      <li
        v-for="(pageNum, idx) in lastThreePages"
        :key="idx"
      >
        <PsButton
          class="pagination-link"
          :color="currentPage === pageNum ? 'primary' : 'secondary'"
          styling="outlined"
          :aria-label="`Go to page ${pageNum + 1}`"
          :active="currentPage === pageNum"
          @click="goToPage(pageNum)"
        >
          {{ pageNum + 1 }}
        </PsButton>
      </li>
    </ul>

    <PsButton
      v-if="totalPages > currentPage + 1"
      class="pagination-next"
      color="secondary"
      styling="outlined"
      icon="base/chevron_right"
      @click="goToPage(currentPage + 1)"
    />
  </nav>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const emit = defineEmits(["goToPage"]);

const props = defineProps({
  currentPage: {
    type: Number,
    default: 0, // indexed 0
  },
  totalPages: {
    type: Number,
    default: 1, // indexed 1
  },
});

const firstThreePages = computed(() => {
  if (props.totalPages <= 3) {
    return Array.from({ length: props.totalPages }, (_, i) => i);
  }
  if (
    props.totalPages > 6 &&
    props.currentPage > 3 &&
    props.currentPage < props.totalPages - 4
  ) {
    return [0];
  }
  return Array.from({ length: props.totalPages }, (_, i) => i).slice(0, 3);
});

const middleThreePages = computed(() => {
  if (props.totalPages <= 6) {
    return [];
  }
  if (
    props.totalPages > 6 &&
    (props.currentPage < 3 || props.currentPage > props.totalPages - 4)
  ) {
    return [];
  }
  return Array.from({ length: props.totalPages }, (_, i) => i).slice(
    props.currentPage - 1,
    props.currentPage + 2,
  );
});

const lastThreePages = computed(() => {
  if (props.totalPages <= 3) {
    return [];
  }
  if (props.totalPages <= 6) {
    return Array.from({ length: props.totalPages }, (_, i) => i).slice(3, 6);
  }
  if (
    props.totalPages > 6 &&
    props.currentPage > 3 &&
    props.currentPage < props.totalPages - 4
  ) {
    return [props.totalPages - 1];
  }
  return Array.from({ length: props.totalPages }, (_, i) => i).slice(
    props.totalPages - 3,
    props.totalPages,
  );
});

function goToPage(pageNum: number) {
  emit("goToPage", pageNum);
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixins.scss';
@import '../../styles/variables';
</style>
