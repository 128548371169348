<template>
  <div v-if="!currentUser || !actor" class="loading"></div>
  <div v-else>
    <p class="title is-3">
      {{ t('profile.title') }}
    </p>
    <article class="my-6">
      <div class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap editable-form">
        <label>{{ t('profile.picture') }}</label>
        <span>
        <Avatar :user="actor" size="large"/>
      </span>
        <p class="control">
          <input v-show="false" ref="uploadFileBtn" accept=".png,.jpg,.jpeg" class="file-input"
                 help="Select an image as new avatar." type="file" @input="handleChange">
          <PsButton ref="uploadButton" @click.prevent="handleUploadClick">
            Upload new picture
          </PsButton>
        </p>
        <p class="control">
          <PsButton ref="deleteAvatarBtn" :disabled="!currentUser.hasAvatar" color="secondary" @click="deleteAvatar">
            Delete
          </PsButton>
        </p>
      </div>
    </article>
    <p class="title is-4 my-4 mt-6">
      {{ t('profile.details') }}
    </p>
    <article class="my-6">
      <DataTable>
        <Editable :value="currentUser.fullName" label="Name" placeholder="Add your name" @submit="updateName">
          <FormKit :value="currentUser.firstName" label="First name" name="first_name" type="text"/>
          <FormKit :value="currentUser.lastName" label="Last name" name="last_name" type="text"/>
        </Editable>
        <hr>
        <DataTableRow label="Email">
          {{ currentUser.email }}
        </DataTableRow>
        <hr>
        <Editable :value="currentUser.phoneNumber?.toString() ?? ''" label="Phone number"
                  placeholder="Add your phone number"
                  @submit="updatePhone">
          <label>Phone number</label>
          <FormKit :value="currentUser.phoneNumber" label="Phone number" name="phone_number" type="group">
            +1
            <FormKit id="area-number" name="area" size="2" type="text" validation="required|number|length:3,4"
                     validation-label="Area number"/>
            <FormKit id="local-number" name="local" size="3" type="text"
                     validation="required|matches:/^\d{3}[ -]?\d{4}$/"
                     validation-label="Local number"/>
          </FormKit>
        </Editable>
        <hr>
        <DataTableRow label="Member since">
          {{ dateTimeLocalFormat(currentUser.createdAt) }}
        </DataTableRow>
        <hr>
      </DataTable>
    </article>

    <div class="collection-header">
      <p class="title is-4">
        {{ t('payment.method', 2) }}
      </p>
      <PsButton v-show="false" icon="outlined/add">
        Add
      </PsButton>
    </div>
    <article class="my-4">
      <div v-if="pmStore.isLoading">
        Loading...
      </div>
      <EmptyState v-else-if="pmStore.isEmpty">
        No payment methods
      </EmptyState>
      <div v-else class="payment-methods">
        <PaymentMethod v-for="item in pmStore.items" :key="item.id" :payment-method="item"/>
      </div>
    </article>

    <template v-if="!poStore.account?.onboarded">
      <div class="collection-header">
        <p class="title is-4">
          Payouts
        </p>
      </div>
      <article class="my-6">
        <CTAVerifyIdentity/>
      </article>
    </template>

    <div class="collection-header">
      <p class="title is-4">
        Preferences
      </p>
    </div>
    <article class="my-6">
      Coming soon
    </article>

    <div class="collection-header">
      <p class="title is-4">
        Delete account
      </p>
    </div>
    <article class="my-6">
      <p class="mb-4">
        If you no longer wish to use your PrettyShell account, you can easily remove your account
        here. By removing your account we will also remove all your account details and data.
      </p>

      <Confirm ref="confirmButton" confirm-button-title="Delete account"
               message="Are you sure you want to delete your account?" title="Confirm deleting account"
               @on-confirmed="deleteAccount">
        <template #button="{ handler }">
          <PsButton color="secondary" icon="base/delete" styling="outlined" @click.prevent="handler">
            Delete account
          </PsButton>
        </template>
      </Confirm>
    </article>
  </div>
</template>
<script lang="ts" setup>
import Confirm from "@/components/Confirm.vue";
import Editable from "@/components/Editable.vue";
import EmptyState from "@/components/EmptyState.vue";
import DataTable from "@/components/display/data-table/DataTable.vue";
import DataTableRow from "@/components/display/data-table/DataTableRow.vue";
import CTAVerifyIdentity from "@/components/transactions/CTAVerifyIdentity.vue";
import PaymentMethod from "@/components/transactions/PaymentMethod.vue";
import Avatar from "@/elements/Avatar.vue";

import {useAppNotifications} from "@/plugins/notifications";
import {usePaymentMethodStore} from "@/store/PaymentMethods";
import {usePayoutAccount} from "@/store/payments/account";
import {useSession} from "@/app/stores/session";
import {useModalActions} from "@/composables/modal";
import {ref} from "vue";
import {useLoading} from "@/composables/loaders";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import {PhoneNumber} from "@/app/models/address";
import {storeToRefs} from "pinia";
import {useHelpers} from "@/plugins/Form";
import {until} from "@vueuse/core";

const pmStore = usePaymentMethodStore();
const poStore = usePayoutAccount();
const router = useRouter();
const session = useSession();
const notify = useAppNotifications();
const {t} = useI18n();

poStore.sync();

const uploadModalActive = ref(false);
const uploadFileBtn = ref(null);
const deleteAvatarBtn = ref(null);
const currentFile = ref<File | undefined>(undefined);
const currentUrl = ref<string | undefined>(undefined);
const [openUploadModal, closeUploadModal] = useModalActions(uploadModalActive);
const {
  actor, user: currentUser
} = storeToRefs(session);

await until(currentUser).not.toBe(undefined);
if (!currentUser.value) {
  await router.push("home");
  throw ("No user");
}

const {dateTimeLocalFormat, uploader} = useHelpers();

async function notifyOnResult(func, msgOk: string) {
  try {
    await func();
    notify.success(msgOk);
  } catch (err) {
    notify.handleException(err)
  }
}

async function updatePhone(raw: { phone_number: PhoneNumber }) {
  const phoneNumber = new PhoneNumber(raw.phone_number);
  await notifyOnResult(session.updateUser.bind({}, {phoneNumber}), 'Phone number updated');
}

async function updateName(raw: { first_name: string, last_name: string }) {
  await notifyOnResult(session.updateUser.bind({}, {
    firstName: raw.first_name,
    lastName: raw.last_name
  }), 'Name updated');
}

async function updateAvatar(f) {
  await notifyOnResult(async () => {
    const result = await uploader(f);
    await session.updateUser({avatarId: result.id})
  }, 'Avatar updated');
}

async function submitAvatarHandle() {
  if (!currentFile.value) {
    notify.error("No file chosen");
  } else {
    await updateAvatar(currentFile.value)
  }
  closeUploadModal();
}

const {trigger: submitAvatar, isLoading: submitAvatarLoading} = useLoading(
  submitAvatarHandle
)

async function handleChange(e: Event & { target: HTMLInputElement }) {
  currentFile.value = e.target.files ? e.target.files[0] : undefined;

  const reader = new FileReader();
  reader.onload = (progressEvent) => {
    if (typeof progressEvent.target.result === "string") {
      currentUrl.value = progressEvent.target.result;
    }
  };
  reader.readAsDataURL(currentFile.value);
  await submitAvatar();
}

async function deleteAvatarHandle() {
  if (!currentUser.value?.hasAvatar) {
    return
  }
  await notifyOnResult(async () => {
    await session.updateUser({avatarId: null});
  }, 'Avatar deleted');
}

const {trigger: deleteAvatar, isLoading: deleteAvatarLoading} = useLoading(
  deleteAvatarHandle
)

function handleUploadClick() {
  uploadFileBtn.value.click();
}

async function deleteAccountHandle() {
  await session.deleteUser();
  await session.signOut(async () => {
    await router.push({
      name: "home",
    });
  });
}

const {trigger: deleteAccount, isLoading: deleteAccountLoading} = useLoading(
  deleteAccountHandle
)
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.collection-header {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    flex-grow: 1;
  }
}

.editable-form {
  align-items: center;

  label {
    width: 30%;
  }

  span {
    flex-grow: 1;
  }
}

.readonly-form {
  align-items: center;

  label {
    width: 30%;
  }

  span {
    flex-grow: 1;
  }
}

.payment-methods {
  margin: 2em 0;
}

.payment-methods > div {
  border-bottom: 1px solid var(--gray-smoke);
  margin: 0.5rem;
  padding-bottom: 0.5rem;
}

.payment-methods > div:last-child {
  border-bottom: none;
}
</style>
