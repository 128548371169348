<template>
  <div class="container">
    <div class="account-container">
      <aside class="is-hidden-mobile">
        <MenuUser />
      </aside>
      <div class="column is-two-third m-0 p-0">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuUser from "@/components/MenuUser.vue";
import {useBookings} from "@/rental/stores/bookings.js";
import {until} from "@vueuse/core";
import {storeToRefs} from "pinia";

const bookings = useBookings();
const { loading } = storeToRefs(bookings);
await until(loading).toBe(false);
</script>

<style lang="scss" scoped>
@import '../styles/mixins.scss';

.account-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3em;
  margin: 2em 0;

  @include touch {
    flex-direction: column;
    gap: 2em;
    margin: 0.5em 0;
  }

  .menu {
    min-width: 300px;
  }
}
</style>
