<script lang="ts" setup>
import { useToggle } from "@vueuse/core";
import { ref } from "vue";

import Modal from "@/components/Modal.vue";
import AddPaymentMethod from "@/components/transactions/AddPaymentMethod.vue";

import { useLoading } from "@/composables/loaders";
import usePayments from "@/store/payments";

const [isActive, toggle] = useToggle(false);
const emit = defineEmits(["updated"]);
const setupIntent = ref();
const store = usePayments();

const onShowModal = async () => {
  if (setupIntent.value) {
    toggle(true);
    return;
  }

  setupIntent.value = await store.createIntent();
  toggle(true);
};

const onCloseModal = async (cb) => {
  await cb();
  toggle(false);
  emit("updated", setupIntent.value);
};

const onCancel = () => {
  toggle(false);
};

const { trigger: showModal, isLoading } = useLoading(onShowModal);
</script>

<template>
  <Modal
    v-model="isActive"
    title="Add payment method"
  >
    <AddPaymentMethod
      v-if="setupIntent"
      :client-secret="setupIntent?.client_secret"
    >
      <template #actions="slotProps">
        <PsButton
          color="secondary"
          @click="onCancel"
        >
          Cancel
        </PsButton>
        <PsButton
          color="primary"
          :disabled="!slotProps.isReady"
          @click="onCloseModal(slotProps.onSubmit)"
        >
          Add payment method
        </PsButton>
      </template>
    </AddPaymentMethod>
  </Modal>
  <slot
    :toggle="showModal"
    :is-loading="isLoading"
  >
    <PsButton
      :loading="isLoading"
      @click="showModal"
    >
      Add payment method
    </PsButton>
  </slot>
</template>

<style lang="scss" scoped></style>
