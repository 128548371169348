import {createEventDefinition} from "ts-bus";

export const SessionSignedIn = createEventDefinition()("session.signedIn");
export const SessionSignedOut = createEventDefinition()("session.signedOut");

export const SessionAuthenticating = createEventDefinition()("session.authenticating");
export const SessionAuthenticated = createEventDefinition()("session.authenticated");
export const SessionReady = createEventDefinition<{
  authenticated: boolean;
}>()("session.ready");


export const AppActiveToIdle = createEventDefinition()("app.activeToIdle");
export const AppIdleToActive = createEventDefinition()("app.idleToActive");
