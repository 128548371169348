type Level = 'log' | 'info' | 'warn' | 'error'

const log = (level: Level) => {
  if (process.env.NODE_ENV === 'development') {
    return Function.prototype.bind.call(console.log, console, `[${level}]`);
  }
  return () => {}
}

const createLogger = () => {
  return {
    log: log('log'),
    info: log('info'),
    warn: log('warn'),
    error: log('error'),
  }
}

export const Logger = createLogger();
