<script lang="ts" setup>
import { computed } from "vue";

import Formats from "@/helpers/formats";
import { PaymentMethod } from "@/store/PaymentMethods";

const props = defineProps({
  paymentMethod: PaymentMethod,
  showDefaultTag: {
    type: Boolean,
    default: false,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
});

const expired = computed(() => {
  if (!props.paymentMethod) {
    return false;
  }

  const expYear = props.paymentMethod.details?.exp_year ?? undefined;
  const expMonth = props.paymentMethod.details?.exp_month ?? undefined;

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Note: getMonth is indexed 0

  if (expYear && currentYear > expYear) {
    return true;
  } else if (
    expYear &&
    expMonth &&
    currentYear === expYear &&
    currentMonth >= expMonth
  ) {
    return true;
  }
  return false;
});

const onEditPaymentMethod = () => {
  // TODO
};

const onDeletePaymentMethod = () => {
  // TODO
};
</script>

<template>
  <div class="payment-method">
    <template v-if="paymentMethod && paymentMethod.details">
      <div class="logo">
        <img
          :src="`/static/payment-methods/${paymentMethod?.type}-${paymentMethod?.details?.brand}.svg`"
        >
      </div>
      <div class="body">
        <span class="important">
          Card ending with {{ props.paymentMethod?.details?.last4 }}
          <span
            v-if="showDefaultTag && paymentMethod?.isDefault"
            class="tag is-primary mx-3 is-small"
          >
            DEFAULT
          </span>
        </span>
        <span
          v-if="!expired"
          class="detail"
        >
          Expires
          {{
            Formats.dateLocalFormat(
              `${props.paymentMethod?.details?.exp_year}/${props.paymentMethod?.details?.exp_month}`,
              'MMM, YYYY',
            )
          }}
        </span>
        <span
          v-else
          class="detail has-text-danger"
        > Expired </span>
      </div>
      <div
        v-show="!hideActions"
        class="actions"
      >
        <PsButton
          disabled
          color="secondary"
          styling="outlined"
          @click="onEditPaymentMethod"
        >
          Edit
        </PsButton>
        <PsButton
          disabled
          color="secondary"
          icon="base/delete"
          styling="outlined"
          @click="onDeletePaymentMethod"
        />
      </div>
    </template>
    <i v-else>Incorrect payment method</i>
  </div>
</template>

<style lang="scss" scoped>
.important {
  font-weight: 600;
}

.detail {
  font-size: 0.8em;
}

.payment-method {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-grow: 1;

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  .logo {
    border-radius: 4px;
    width: 3em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
