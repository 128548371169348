<template>
  <div
    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center container"
  >
    <div class="is-flex is-align-items-center">
      <Icon
        class="state-loading"
        name="loading"
        is-inline
      /> Loading
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss"></style>
