import dayjs, {type UnitType} from "dayjs";
import duration, { type Duration } from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_API_DATE_TIME_FORMAT,
  DEFAULT_API_MAJOR_TIME_FORMAT,
  DEFAULT_DISPLAY_DATE_FORMAT,
  DEFAULT_DISPLAY_DATE_TIME_FORMAT,
  DEFAULT_DISPLAY_TIME_FORMAT,
} from "@/constants/date";

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);

const DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export type AnyDateType = Date | string | dayjs.Dayjs;
export type InternalDateType = dayjs.Dayjs;

export default {
  now() {
    return dayjs();
  },
  toNow(f: AnyDateType) {
    const fromDate = f || new Date();
    return dayjs(fromDate).toNow();
  },
  fromNow(d: AnyDateType) {
    const toDate = d || new Date();
    return dayjs(toDate).fromNow();
  },
  toThen(d: AnyDateType, f: AnyDateType | undefined) {
    const fromDate = f || new Date();
    return dayjs(fromDate).to(d);
  },
  sinceThen(d: AnyDateType, f: AnyDateType | undefined = undefined) {
    const toDate = f || new Date();
    return dayjs(d).from(toDate);
  },
  diff(d: AnyDateType, d2: AnyDateType, unit: UnitType = "day") {
    return dayjs(d).diff(d2, unit);
  },
  isToday(d: AnyDateType) {
    return dayjs(d).isToday();
  },
  isBefore(target: AnyDateType, before?: AnyDateType) {
    return dayjs(target).isBefore(before ?? new Date());
  },
  getDuration(a: AnyDateType, b: AnyDateType) {
    return dayjs.duration(dayjs(b).diff(dayjs(a)));
  },
  combine(d: AnyDateType, dspec: object) {
    return dayjs(d).add(dayjs.duration(dspec));
  },
  negateDays(d: string[]) {
    // Returns the opposite days in the week
    if (!d) {
      return [];
    }
    const result: string[] = DAYS.map((el) =>
      d.indexOf(el) === -1 ? el : undefined,
    ).filter((el) => !!el) as string[];
    return result;
  },
  daysToInts(d: string[]) {
    return d.map((el) => DAYS.indexOf(el));
  },
  timeLocal(d: AnyDateType) {
    let v = null;
    try {
      v = dayjs.utc("2022-01-01 " + d);
    } catch {
      v = dayjs.utc(d);
    }
    return v;
  },
  timeLocalFormat(d: AnyDateType, fmt = DEFAULT_DISPLAY_TIME_FORMAT) {
    return this.timeLocal(d).format(fmt);
  },
  timeLocalAddHalfHour(d: AnyDateType) {
    return this.timeLocal(d).add(30, "minutes");
  },
  timeLocalSubtractHalfHour(d: AnyDateType) {
    return this.timeLocal(d).subtract(30, "minutes");
  },
  timeLocalSplitIntoChunksOfHalfHours(
    d1: AnyDateType,
    d2: AnyDateType,
  ): [string, string][] {
    const addHalfHoursUntilEndTime = (
      start: AnyDateType,
      end: AnyDateType,
    ): [string, string][] => {
      let values: [string, string][] = [];
      const timeAddedHalfHour = this.timeLocalAddHalfHour(start);
      if (timeAddedHalfHour.isBefore(this.timeLocal(end))) {
        values = [
          [
            this.timeLocal(start).format("HH:mm"),
            timeAddedHalfHour.format("HH:mm"),
          ],
          ...addHalfHoursUntilEndTime(timeAddedHalfHour.format("HH:mm"), end),
        ];
      }
      return values;
    };

    const allOptions = addHalfHoursUntilEndTime(d1, d2);

    return allOptions.length
      ? [
          ...allOptions,
          [allOptions.reverse()[0][0], this.timeLocal(d2).format("HH:mm")],
        ]
      : [];
  },
  dateLocalFormatISO(d: AnyDateType) {
    return dayjs.utc(d).local().format();
  },
  dateLocalFormat(d: AnyDateType, fmt = DEFAULT_DISPLAY_DATE_FORMAT) {
    return dayjs.utc(d).local().format(fmt);
  },
  dateTimeLocalFormat(d: AnyDateType, fmt = DEFAULT_DISPLAY_DATE_TIME_FORMAT) {
    return dayjs.utc(d).local().format(fmt);
  },
  dayLocalFormat(d: AnyDateType, fmt = DEFAULT_DISPLAY_DATE_FORMAT) {
    /* shows dateFormat unless it's today */
    if (this.isToday(d)) {
      return "Today";
    }
    return this.dateLocalFormat(d, fmt);
  },
  dateSystemWithoutTimezoneFormatISO(d: AnyDateType) {
    return dayjs(d).format();
  },
  dateSystemWithoutTimezoneFormat(
    d: AnyDateType,
    fmt = DEFAULT_API_DATE_FORMAT,
  ) {
    return dayjs(d).format(fmt);
  },
  dateTimeSystemWithoutTimezoneFormat(
    d: AnyDateType,
    fmt = DEFAULT_API_DATE_TIME_FORMAT,
  ) {
    return dayjs(d).format(fmt);
  },
  dateOrDatesFormatISOForApiWithoutTimezone(d: AnyDateType | AnyDateType[]) {
    if (Array.isArray(d)) {
      return d
        .filter((el) => !!el)
        .map((el) => this.dateSystemWithoutTimezoneFormatISO(el));
    }
    return this.dateSystemWithoutTimezoneFormatISO(d);
  },
  dateOrDatesFormatForApiWithoutTimezone(d: AnyDateType | AnyDateType[]) {
    if (Array.isArray(d)) {
      return d
        .filter((el) => !!el)
        .map((el) => this.dateSystemWithoutTimezoneFormat(el));
    }
    return this.dateSystemWithoutTimezoneFormat(d);
  },
  daySystemWithoutTimezoneFormat(
    d: AnyDateType,
    fmt = DEFAULT_DISPLAY_DATE_FORMAT,
  ) {
    /* shows dateFormat unless it's today */
    if (this.isToday(d)) {
      return "Today";
    }
    return this.dateSystemWithoutTimezoneFormat(d, fmt);
  },
  parse(v: AnyDateType) {
    return dayjs(v);
  },
  parseMajorTime(v: string) {
    return dayjs(v, DEFAULT_API_MAJOR_TIME_FORMAT);
  },
  miles(v: number) {
    return Math.round(v * 10) / 10;
  },
  money({
    value = 0,
    currency = "USD",
    currencyDisplay,
    hideCurrencySymbol = false,
  }: {
    value?: string | number;
    currency?: string; // ISO 4217 currency codes
    currencyDisplay?: "code" | "symbol" | "narrowSymbol" | "name";
    hideCurrencySymbol?: boolean; // When set, remove Symbol/ISO code from the result
  }) {
    const moneyValue = new Intl.NumberFormat("en-EN", {
      style: "currency",
      currency,
      currencyDisplay: hideCurrencySymbol
        ? "code"
        : (currencyDisplay ?? undefined),
    }).format(Number(value));

    return hideCurrencySymbol
      ? // Remove Symbol/ISO code from the result
        moneyValue
          .replace(currency, "")
          .trim()
      : moneyValue;
  },
  percent(v: number) {
    return new Intl.NumberFormat("en-EN", { style: "percent" }).format(v);
  },
  precise(v: number, precision = 1) {
    return new Intl.NumberFormat("en-EN", {
      minimumFractionDigits: precision,
    }).format(v);
  },
  toCamelCase(s: string) {
    const arr = s
      .split(" ")
      .map((el) => el.charAt(0).toUpperCase() + el.slice(1));
    return arr.join(" ");
  },
};

export type { Duration };
