import {ApiBookingStatus, BookingInterval, BookingStatus, CostUnitType} from "@/rental/types";
import type {UnitTypeShort} from "dayjs";
import {EMPTY_SELECT_OPTION} from "@/constants/form";

export const INTERVAL_TO_DAYS = {
  [BookingInterval.daily]: 1,
  [BookingInterval.weekly]: 7,
  [BookingInterval.monthly]: 30,
};

export const INTERVAL_TO_UNIT: Record<BookingInterval, UnitTypeShort> = {
  [BookingInterval.daily]: "d",
  [BookingInterval.weekly]: "d",
  [BookingInterval.monthly]: "M",
};

export const BOOKING_LOCAL_STATUS_MAPPER: [BookingStatus, string][] = [
  [BookingStatus.new, "reserved"],
  [BookingStatus.requested, "requested"],
  [BookingStatus.review, "requested"],
  [BookingStatus.open, "confirmed"],
  [BookingStatus.pending, "confirmed"],
  [BookingStatus.locked, "confirmed"],
  [BookingStatus.ready_for_pickup, "ready for pickup"],
  [BookingStatus.waiting_pickup_renter, "ready for pickup"],
  [BookingStatus.waiting_pickup_lender, "ready for pickup"],
  [BookingStatus.active, "active"],
  [BookingStatus.waiting_deliver_renter, "returned"],
  [BookingStatus.waiting_deliver_lender, "returned"],
  [BookingStatus.closed, "completed"],
  [BookingStatus.disputed, "disputed"],
  [BookingStatus.rejected, "rejected"],
  [BookingStatus.canceled, "canceled"],
  [BookingStatus.expired, "expired"],
];

export const PICKUP_COUNTRY_OPTIONS = [
  EMPTY_SELECT_OPTION,
  {
    label: "United States",
    value: "United States",
  },
  {
    label: "The Netherlands",
    value: "The Netherlands",
  },
];
const BOOKING_STATUS_COLOR_MAPPER: [BookingStatus, "success"|"warning"|"danger",""] = [
  ["new", ""],
  ["review", ""],
  ["open", "success"],
  ["pending", "success"],
  ["locked", "success"],
  ["ready_for_pickup", "success"],
  ["waiting_pickup_renter", "success"],
  ["waiting_pickup_lender", "success"],
  ["active", "success"],
  ["waiting_deliver_renter", "success"],
  ["waiting_deliver_lender", "success"],
  ["returned", ""],
  ["completed", ""],
  ["closed", ""],
  ["disputed", "danger"],
  ["rejected", "danger"],
  ["canceled", "warning"],
  ["expired", "warning"],
];

