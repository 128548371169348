<template>
  <div
    v-if="store.ready"
    class="collage-gallery"
    :class="rootCategories.length <= 3 ? 'collage-gallery-max-3' : ''"
  >
    <div
      v-for="rootCategory in rootCategories"
      :key="rootCategory.id"
      class="wrapper is-clickable"
      @click="gotoCategory(rootCategory)"
    >
      <div class="collage">
        <div
          v-for="category in rootCategory.children.slice(0, 3)"
          :key="category.id"
          class="sub"
          :style="`background-image: url(${category.media})`"
        />
      </div>
      <p>{{ rootCategory.name }}</p>
      <span>{{ rootCategory.children.length }} categories</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import { useCategories, type Category } from "@/rental/stores/categories";

const props = defineProps({
  categories: {
    type: Array,
    default: () => [],
  },
});
const router = useRouter();
const store = useCategories();
const rootCategories = computed<(Category & {children: Category[]})[]>(() => (props.categories || store.getRootItems()).map((c) => ({
  ...c,
  children: store.listByParentId(c.id),
})));

const gotoCategory = (cat) => {
  router.push({
    name: "listingsCategory",
    params: {
      mainCategoryName: cat.name,
    },
  });
};
</script>

<style lang="scss">
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.collage-gallery {
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: 1fr 1fr 1fr;

  @include mobile {
    grid-template-columns: 1fr;
  }

  @include tablet-only {
    grid-template-columns: 1fr 1fr;
  }

  @include fullhd {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.collage-gallery-max-3 {
    @include fullhd {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .wrapper {
    border-radius: 12px;
    width: 100%;

    &:hover {
      & > .collage {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-radius: 8px;
          background-color: $gray-A10;
        }
      }
    }

    p {
      font-weight: 600;
    }

    span {
      color: var(--gray-muted);
      font-size: 14px;
      line-height: 21px;
    }
  }

  .collage {
    position: relative;
    margin: 1em 0;
    border-radius: 8px;
    display: grid;
    height: 240px;
    width: 100%;
    grid-gap: 2px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'main sub1'
      'main sub2';

    .sub {
      object-fit: cover;
      background-color: var(--gray-mist);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;

      &:first-child {
        grid-area: main;
        border-radius: 8px 0 0 8px;
      }
      &:nth-child(2) {
        grid-area: sub1;
        border-radius: 0 8px 0 0;
      }
      &:nth-child(3) {
        grid-area: sub2;
        border-radius: 0 0 8px 0;
      }
    }
  }
}
</style>
