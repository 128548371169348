import type {PrivateListing} from "@/rental/stores/listings";
import {useI18n} from "vue-i18n";
import {useFormKitContext} from "@formkit/vue";
import {computed} from "vue";

export interface StepProps {
  values: Partial<PrivateListing>,
  isNew: boolean,
}

export const useFormTranslations = (isNew: boolean) => {
  const { t } = useI18n();
  return (key: string, ctx: Record<string, unknown> = {}) => t(`forms.${isNew ? "create" : "edit"}.${key}`, ctx);
}

export const useStepContext = () => {
  const form = useFormKitContext<Partial<PrivateListing>>();
  const isNew = computed(() => form.value?.value.id === undefined);
  const values = computed(() => form.value?.value ?? {});
  const { t } = useI18n();
  return {
    form,
    values,
    isNew,
    ft: useFormTranslations(isNew.value),
    t
  }
}
