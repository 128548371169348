<script setup lang="ts"></script>

<template>
  <ul>
    <slot />
  </ul>
</template>

<style scoped lang="scss">
ul {
  margin-left: 0 !important;

  :deep(label) {
    font-weight: 600;
  }
}
</style>
