import type { App } from "vue";
import { createI18n } from "vue-i18n";

const messages = {
  en: {
    common: {
      "not-available": "Not available",
      "review": "review|reviews",
      save: 'Save',
      cancel: 'Cancel',
    },
    profile: {
      title: "Profile",
      details: "Personal details",
      picture: "Profile picture",
    },
    payment: {
      method: "Payment method|Payment methods",
    },
    booking: {
      "pricing-fee": "Service fee",
      "pricing-tax": "Taxes",
      "pricing-insurance": "PS Insurance",
      "pricing-deposit": "Deposit required",
    },
    account: {
      transactions: "Transactions",
    },
    conversations: {
      title: "Messages",
    },
    listing: {
      category: "category | categories",
      feature: "feature | features",
      "edit-listing": "Edit listing",
      "delete-listing": "Delete listing",
      "archive-listing": "Archive listing",
      insurance: "PS Insurance",
      deposit: "Deposit",
      status: {
        draft: "draft",
        active: "published",
        suspended: "suspended",
      },
      age: {
        1: '0-2 years',
        5: '2-5 years',
        10: '5-10 years',
        25: '10+ years',
      },
      condition: {
        heavily_used: "Heavily used, has damages",
        lightly_used: "Lightly used, small scratches",
        good: "Good condition, maybe some small signs of use",
        excellent: "Excellent, highly maintained",
        like_new: "New or like new!",
      },
      modals: {
        common: {
        },
        share: {
          text: "You can copy the link below and share to your network by pasting it in messages, blogs, etc.",
          title: "Share with your network",
        },
      },
      confirms: {
        archive: {
          message: 'Are you sure you want to archive listing "{listing}"?',
          action: "Archive listing",
        },
        cancel: {
          title: 'Are you sure want to leave?',
          message: 'You have some unsaved changes on this listing. Do you want to save these changes as a draft before you leave?',
          action: 'Save as draft',
          discard: 'Discard changes',
        }
      },
    },
    review: {
      "no-review": "The user only left a rating",
    },
    errors: {
      "domain-error": "Something went wrong",
      "api-error": "Something went wrong",
      "api-validation-error": "Could not process request: {msg}",
      "session-expired": "Your session is expired, please refresh the page.",
      "invalid-action": "Invalid action",
    },
    units: {
      hour: "hour | hours",
      day: "day | days",
      week: "week | weeks",
      month: "month | months",
    },
    intervals: {
      daily: "Per day",
      weekly: "Per week",
      monthly: "Per month",
      yearly: "Per year",
    },
    forms: {
      edit: {
        title: "Edit {name}",
      },
      create: {
        title: "New {name}",
      },
    },
    flows: {
      listing: {
        photos: {
          title: "Photos",
          subtitle:
            "Add photos of your item, make sure to add photos of all angles and possible damages. Also, try to add some detail photos.",
        },
      },
    },
  },
};

export default {
  install(app: App) {
    const i18n = createI18n({
      locale: "en",
      fallbackLocale: "en",
      allowComposition: true,
      messages,
    });
    app.use(i18n);
  },
};
