<template>
  <div class="container">
    <ListingEditor
      :initial-data="{
        status: 'draft',
        pickupDropOffTimeRange: ['10:00', '18:00'],
        pricingOptions: [
          {
            amount: 0,
            interval: 'daily',
          },
        ],
        availableWeekdays: [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ],
      }"
      :progressed-step="1"
      :is-new="true"
    />
  </div>
</template>
<script>
import ListingEditor from "@/rental/components/ListingEditor.vue";

export default {
  components: { ListingEditor },
  data() {
    return {};
  },
};
</script>
