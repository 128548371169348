<script lang="ts" setup>
import { computed } from "vue";

import BookingStatus from "@/elements/BookingStatus.vue";

import { useHelpers } from "@/plugins/Form";
import type { Booking } from "@/rental/stores/bookings";
import BookingNextAction from "@/rental/components/BookingNextAction.vue";
import {getCurrentUser} from "@/app/stores/session";

const props = defineProps<{
  booking: Booking;
  showStatus?: boolean;
  showPickupTimeRange?: boolean;
  showPickupLocation?: boolean;
  showBookingPeriod: boolean;
  showNextAction?: boolean;
}>();

const helpers = useHelpers();
const currentUser = getCurrentUser();

const pickupDropoffTimerange = computed(() => {
  return (
    props.booking.pickupTimeRange ||
    props.booking.listing.pickupDropOffTimeRange
  );
});
</script>

<template>
  <div class="booking-highlights">
    <BookingStatus
      v-if="showStatus"
      :has-hover="false"
      :status="booking.status"
      class="is-uppercase"
    />
    <div v-if="showBookingPeriod">
      <Icon
        name="outlined/calendar_today"
        variant="secondary"
      />
      {{ helpers.daySystemWithoutTimezoneFormat(booking.startsAt) }} -
      {{ helpers.daySystemWithoutTimezoneFormat(booking.endsAt) }}
    </div>

    <div v-if="showPickupTimeRange && pickupDropoffTimerange">
      <Icon
        name="outlined/schedule"
        variant="secondary"
      />
      {{ helpers.timeLocalFormat(pickupDropoffTimerange[0]) }} -
      {{ helpers.timeLocalFormat(pickupDropoffTimerange[1]) }}
    </div>

    <div v-if="showPickupLocation">
      <Icon
        name="outlined/location_on"
        variant="secondary"
      />
      {{ booking.listing.localLocation }}
    </div>

    <BookingNextAction v-if="showNextAction" :status="booking.status" :is-renter="booking.isRenter(currentUser.id)"/>
  </div>
</template>

<style lang="scss" scoped>
.booking-highlights {
  display: flex;
  align-items: center;
  gap: 16px;

  > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
</style>
