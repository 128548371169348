<template>
  <div
    :class="[isActive ? 'is-active' : null, $slots.prefix ? 'has-icons-left' : null]"
    class="autocomplete control has-icons-right"
  >
    <input
      v-model="query"
      :disabled="context.disabled"
      class="input"
      type="text"
      v-bind="context.attrs"
      @focusin="toggleActive(true)"
      @focusout="toggleActive(false)"
      @keyup.down="highlightDown"
      @keydown.enter.prevent="handleSelect(items[activeListItem])"
      @keyup.up="highlightUp"
    >
    <slot name="prefix" />
    <Icon
      v-if="!!query"
      class="formkit-suffix-icon formkit-icon is-right is-clickable"
      name="base/close"
      size="medium"
      variant="inherit"
      @click.prevent="handleEmpty"
    />
    <div class="options box">
      <ul
        v-if="items.length > 0"
        ref="itemsListRef"
      >
        <li
          v-for="(item, idx) in items"
          :key="idx"
          @mouseenter="highlight(idx)"
          @click.prevent="handleSelect(item)"
        >
          <Icon
            v-if="item.icon"
            :name="item.icon"
          />
          <span>{{ item.label }}</span>
        </li>
      </ul>
      <span
        v-else-if="loading"
        class="nothing"
      >Loading...</span>
      <span
        v-else-if="query.length === 0"
        class="nothing"
      >Start typing...</span>
      <span
        v-else
        class="nothing"
      >Nothing found.</span>
      <div
        v-if="context.noResultHandler"
        class="manual-select"
        @click="context.noResultHandler"
      >
        Can’t find my address — Select address manually
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

type Item = {
  label: string;
  value: string;
};

const props = defineProps<{
  context: {
    _value: Item["value"];
    value: Item["value"];
    searchHandler: (q: string) => Item[];
    lookupHandler: (lookupValue: Item[keyof Item], options?: Item[]) => Item;
    options?: Item[];
    prefetchItems: boolean;
    showLimit?: number;
    noResultHandler?: (q: string) => void;
  };
}>();

const itemsListRef = ref(null);
const lookupHandler = props.context.lookupHandler || byIdHandlerFn;
const searchHandler = props.context.searchHandler || searchHandlerFn;
const options = ref(props.context.options || []);
const items = ref([]);
const query = ref(
  lookupHandler(props.context._value, options.value || [])?.label || "",
);
const isActive = ref(false);
const loading = ref(false);
const layBack = ref(null);
const prefetchItems = ref(props.context.prefetchItems || false);
// const showLimit = ref(typeof(props.context.showLimit) === 'number' ? props.context.showLimit : 10)
const activeListItem = ref(-1);

if (prefetchItems.value) {
  search();
}

watch(
  () => props.context.value,
  (v) => {
    if (v && (typeof v === "object" || v.length > 0)) {
      const lookup = lookupHandler(v, options.value);
      query.value = lookup.label || "";
    } else {
      query.value = "";
    }
  },
);

watch(
  () => props.context.options,
  (v) => {
    options.value = v;
    if (prefetchItems.value) {
      search();
    }
  },
);

watch(
  () => query.value,
  (value) => {
    if (
      !prefetchItems.value &&
      (!value || value.length === 0 || !isActive.value)
    ) {
      return;
    }
    clearTimeout(layBack.value);
    layBack.value = setTimeout(search, 300);
  },
);

watch(
  () => activeListItem.value,
  (newValue, oldValue) => {
    const el = itemsListRef;
    const setState = (idx, val) => {
      const child = el.value.children[idx];
      if (child) {
        child.className = val;
      }
    };
    setState(oldValue, "");
    setState(newValue, "is-active");
  },
);

async function search() {
  loading.value = true;
  items.value = await searchHandler(query.value, options.value);
  loading.value = false;
}

async function handleSelect(item) {
  props.context.node.input(item.value);
  toggleActive(false);
  query.value = item.label;
}

function handleEmpty() {
  query.value = "";
  props.context.node.input("");
}

function toggleActive(state) {
  setTimeout(
    () => (isActive.value = state !== undefined ? state : !isActive.value),
    200,
  );
}

function highlightDown() {
  if (activeListItem.value < items.value.length) {
    activeListItem.value += 1;
  }
}

function highlightUp() {
  if (activeListItem.value > 0) {
    activeListItem.value -= 1;
  }
}

function highlight(idx) {
  activeListItem.value = idx;
}

function byIdHandlerFn(id, options) {
  return options.find((el) => el.value === id);
}

async function searchHandlerFn(term, options) {
  return options.filter(
    (el) => el.label.toLowerCase().indexOf(term.toLowerCase()) > -1,
  );
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.autocomplete {
  span.icon {
    pointer-events: all;
  }

  .options {
    width: 100%;
    max-height: 26em;
    margin-top: 0.75rem;
    position: absolute;
    z-index: 20;
    display: none;
    padding: 1em 0;

    overflow-y: auto;
    border-radius: 12px;

    /* Elevation 1 */
    box-shadow:
      0px 2px 6px -2px rgba(6, 30, 62, 0.25),
      0px 0px 0px 1px rgba(6, 30, 62, 0.1);

    .nothing {
      padding: 0.5rem 1rem;
      color: var(--gray-muted);
    }

    .manual-select {
      border-top: 1px $gray-A10 solid;
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: var(--gray-mist);
      }
    }

    ul {
      li {
        padding: 0.5rem 1rem;
        cursor: pointer;
        display: flex;
        gap: 8px;

        > .icon {
          position: relative;
          line-height: 16px;
          padding: 0;
          color: inherit;
        }

        &.is-active {
          background-color: var(--gray-mist);
        }
      }
    }
  }

  &.is-active {
    .options {
      display: block;
    }
  }
}
</style>
