<script lang="ts" setup>
import ReviewModal from "@/components/rental/review/ReviewModal.vue";

import type { ActionProps } from "@/components/rental/booking-actions/types";
import {useCurrentActor} from "@/app/stores/session";

const author = useCurrentActor();

const props = defineProps<ActionProps>();
</script>

<template>
  <ReviewModal
    :author="author"
    :booking="props.booking"
    :current-step="props.booking.reviewedAt ? 'done' : 'main'"
  />
</template>

<style lang="scss" scoped></style>
