<template>
  <FormKit id="emailSignupPassword" v-model="formData" submit-label="Create account" type="form" @submit="handleSignUp">
    <div class="field">
      <FormKit label="First name" name="firstName" placeholder="First name" type="text" validation="required"
        validation-label="First name" />
    </div>
    <div class="field">
      <FormKit label="Last name" name="lastName" placeholder="Last name" type="text" validation="required"
        validation-label="Last name" />
    </div>
    <div class="field">
      <FormKit :value="email" label="Email" name="email" placeholder="Email" type="email" validation="required"
        validation-label="Email address" />
    </div>
    <div class="field">
      <FormKit
        help="Make sure your password is at least 8 characters long and includes at least lowercase, uppercase, symbols and/or numbers."
        label="Password" name="password" placeholder="Password" type="password"
        validation="required|length:8|contains_lowercase|contains_uppercase|contains_symbol|contains_numeric"
        validation-label="Password" suffix-icon="eyeClosed" @suffix-icon-click="handlePasswordShowIconClick" />
    </div>
    <div class="field">
      <FormKit label="Confirm password" name="passwordConfirm" placeholder="Confirm password" type="password"
        validation="required|confirm:password|length:8|contains_lowercase|contains_uppercase|contains_symbol|contains_numeric"
        validation-label="Confirm password" suffix-icon="eyeClosed" @suffix-icon-click="handlePasswordShowIconClick" />
    </div>
  </FormKit>
  <div class="field">
    <p class="help">
      By signing in or creating an account, you agree with our Terms & conditions and Privacy
      statement
    </p>
  </div>
</template>

<script setup>
import { FormKit } from "@formkit/vue";
import { useSession } from "@/app/stores/session";
import { ref } from 'vue';

const session = useSession()

const emit = defineEmits(["next", "signed-up"]);
defineProps({
  email: {
    type: String,
    default: "",
  },
});

const formData = ref({});

async function handleSignUp() {
  try {
    const newUser = {
      firstName: formData.value.firstName,
      lastName: formData.value.lastName,
      email: formData.value.email,
      password: formData.value.password,
    };
    await session.signUp({
      ...newUser,
    });
    emit("signed-up");
  } catch (err) {
    emit("next", {
      flow: "enter",
      email: "",
      error:
        "An account already is registered on this email address, please sign in instead.",
    });
  }
}

function handlePasswordShowIconClick(node) {
  node.props.suffixIcon = node.props.suffixIcon === "eye" ? "eyeClosed" : "eye";
  node.props.type = node.props.type === "password" ? "text" : "password";
}
</script>
