<template>
  <div
    v-if="state === 'readonly'"
    class="editable-form is-flex is-align-items-center flex-gap-2 is-flex-wrap-wrap"
  >
    <label v-if="label">{{ label }}</label>
    <slot
      v-if="renderSlot"
      name="value"
    />
    <span v-else-if="!renderSlot && value">{{ value }}</span>
    <span
      v-else
      class="editable-form-na"
    >{{ placeholder }}</span>
    <PsButton
      color="secondary"
      @click="transition('editable')"
    >
      Edit
    </PsButton>
  </div>
  <div
    v-else-if="state === 'editable'"
    class="editable-form"
  >
    <FormKit
      id="form"
      v-slot="{ state: { valid } }"
      type="form"
      :actions="false"
      @keydown.enter="$formkit.submit('form')"
      @submit="handleSubmit"
    >
      <div class="mb-3 is-flex is-align-items-center flex-gap-2 is-flex-wrap-wrap">
        <slot />
      </div>
      <div class="button-group field is-grouped is-grouped-right">
        <PsButton
          color="secondary"
          class="mr-3"
          @click.prevent="transition('readonly')"
        >
          Cancel
        </PsButton>
        <PsButton
          :disabled="!valid"
          @click.prevent="$formkit.submit('form')"
        >
          Save
        </PsButton>
      </div>
    </FormKit>
  </div>
</template>

<script setup>
import { ref } from "vue";

const emit = defineEmits(["submit"]);

defineProps({
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  value: {
    /*
      The value of the editable field
    */
    type: String,
    default: "",
  },
  renderSlot: {
    type: Boolean,
    default: false,
  },
});

const state = ref("readonly");

function transition(newState) {
  state.value = newState;
}

function handleSubmit(data) {
  emit("submit", data);
  transition("readonly");
}
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.editable-form {
  align-items: center;

  label {
    width: 30%;
    color: $gray-muted;
  }

  span {
    flex-grow: 1;
  }

  .editable-form-na {
    color: $gray-muted;
  }

  .button-group {
    width: 100%;
  }

  .field {
    margin-bottom: 0px !important;
  }
}
</style>
