<template>
  <div>
    <Icon
      name="outlined/calendar_today"
      variant="secondary"
      v-if="showIcon"
    />
    <span>
    {{ f(startsAt) }} —
    {{ f(endsAt) }}
    </span>
    <span>
      ({{days}} days)
    </span>
  </div>
</template>

<script setup lang="ts">
import {helpers} from "@/plugins/Form";
import {computed} from "vue";

const props = defineProps<{
  startsAt: Date;
  endsAt: Date;
  showIcon?: boolean;
}>();

const f = (d) => helpers.daySystemWithoutTimezoneFormat(d);
const days = computed(() => helpers.diff(props.endsAt, props.startsAt, "days"))
</script>

<style scoped lang="scss">

</style>
