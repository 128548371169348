<script lang="ts" setup>
import { computed } from "vue";

import Chip from "@/elements/Chip.vue";

import type { Payout } from "@/store/transactions";

const props = defineProps<{
  status: Payout["status"];
  reason?: Payout["statusReason"];
}>();

const STATUS_COLOR = {
  waiting: "warning",
};

const color = computed(() => {
  return STATUS_COLOR[props.status];
});
</script>

<template>
  <Chip :color="color" :has-hover="false">
    {{ props.status }}
  </Chip>
</template>

<style lang="scss" scoped></style>
