import {computed, ref} from "vue";
import type {Step} from "@/app/components/workflow/types";
import {Logger} from "@/app/logger";

interface useStepOpts<T extends Step> {
  steps: T[];
  initialStep?: number;
  initialProgressedStep?: number;
}

export const useSteps = <T extends Step>(opts: useStepOpts<T>) => {
  const current = ref<number>(opts.initialStep ?? 1);
  const progressed = ref<number>(opts.initialProgressedStep ?? 1);

  const currentStep = computed(() => opts.steps[current.value - 1]);
  const nextStep = () => {
    current.value++;
    progressed.value = Math.max(current.value, progressed.value);
  }
  const gotoStep = (stepIndex: number) => {
    if (stepIndex <= progressed.value) {
      current.value = stepIndex;
    } else {
      Logger.warn(`Trying to go to step ${stepIndex} but progressed is ${progressed.value}`)
    }
  }
  return {
    currentStep,
    currentStepNumber: current,
    currentProgressedNumber: progressed,
    nextStep,
    previousStep: () => current.value--,
    gotoStep,
    isLastStep: computed(() => current.value === opts.steps.length),
    isFirstStep: computed(() => current.value === 1),
  }
}
