<template>
  <p class="title">
    Confirm booking request
  </p>
  <p class="my-4">
    One final check before sending the booking request.
  </p>

  <BookingCard
    :booking="booking"
    :show-status="false"
    :show-booking-period="true"
    :show-booking-creation-date="false"
    :show-renter="false"
    class="my-6"
    orientation="horizontal"
    size="small"
  />

  <div class="booking-details my-6">
    <p class="title is-4 my-3 py-3">
      Details
    </p>

    <div
      v-for="pricing in protectionCosts"
      :key="pricing.id"
      class="my-2"
    >
      <div class="is-flex is-align-items-center is-justify-content-flex-start is-flex-direction-row">
        <p class="booking-details-topic">
          {{ pricing.cost_type === 'insurance' ? 'Insurance' : 'Deposit' }}
        </p>
        <div class="price-record">
          <ProtectionIcon
            :protection="pricing.cost_type === 'insurance' ? 'insurance' : 'deposit'"
          />
        </div>
        <div class="is-flex-grow-1 is-flex is-justify-content-flex-end is-flex-direction-row">
          <PsButton
            color="secondary"
            @click.prevent="emit('stepChanged', { stepId: 'created' })"
          >
            Edit
          </PsButton>
        </div>
      </div>
    </div>

    <hr class="my-0">

    <div
      v-if="intent?.payment_method"
      class="my-2"
    >
      <div class="is-flex is-align-items-center is-justify-content-flex-start is-flex-direction-row">
        <p class="booking-details-topic">
          Payment method
        </p>
        <div class="price-record">
          <PaymentMethod
            :payment-method="intent.payment_method"
            hide-actions
          />
        </div>
        <div class="is-flex-grow-1 is-flex is-justify-content-flex-end is-flex-direction-row">
          <PsButton
            color="secondary"
            @click.prevent="emit('stepChanged', { stepId: 'payment' })"
          >
            Edit
          </PsButton>
        </div>
      </div>
    </div>

    <hr class="my-0">

    <div
      v-if="cause"
      class="my-2"
    >
      <div class="is-flex is-align-items-center is-justify-content-flex-start is-flex-direction-row">
        <p class="booking-details-topic">
          Cause
        </p>
        <CardBase
          orientation="horizontal"
          size="tiny"
        >
          <template #preview>
            <img :src="cause.image">
          </template>
          <template #details>
            <p class="is-flex is-justify-content-space-between is-flex-direction-row my-auto">
              <label class="is-flex-grow-1">{{ cause.name }}</label>
            </p>
          </template>
        </CardBase>
        <div class="is-flex-grow-1 is-flex is-justify-content-flex-end is-flex-direction-row">
          <PsButton
            color="secondary"
            @click.prevent="emit('previous')"
          >
            Edit
          </PsButton>
        </div>
      </div>
    </div>
  </div>

  <div class="my-6">
    <p class="title is-4 my-3 py-3">
      Price breakdown
    </p>

    <PriceBreakdown :booking="booking" />

    <div
      v-for="pricing in depositCosts"
      :key="pricing.id"
      class="mt-5 booking-group-success"
    >
      <div
        class="is-flex is-align-items-center is-justify-content-space-between is-flex-direction-row"
      >
        <div class="is-flex is-align-items-center is-flex-direction-row">
          <ProtectionIcon
            protection="deposit"
            hide-label
          />
          <span class="has-text-weight-semibold pl-2">
            {{ $t('booking.pricing-deposit') }}
          </span>
        </div>
        <money :amount="pricing.amount" />
      </div>
      <p>
        This amount is pre-authorized on your credit card and will be released after the item is
        successfully returned.
      </p>
    </div>
  </div>

  <div
    class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap is-justify-content-end mt-4 px-8"
  >
    <PsButton
      color="secondary"
      @click.prevent="emit('previous')"
    >
      <Icon
        name="base/chevron_left"
        variant="inherit"
      />
      Back
    </PsButton>
    <PsButton
      color="primary"
      :loading="isRequesting"
      @click.prevent="emit('next')"
    >
      Confirm request
    </PsButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import Icon from "@/elements/Icon.vue";
import PsButton from "@/elements/PsButton.vue";
import BookingCard from "@/components/BookingCard.vue";
import CardBase from "@/components/CardBase.vue";
import PaymentMethod from "@/components/transactions/PaymentMethod.vue";
import PriceBreakdown from "@/components/rental/booking/PriceBreakdown.vue";
import ProtectionIcon from "@/elements/ProtectionIcon.vue";

import type { Booking } from "@/store/rental/models";
import type { SetupIntent } from "@/store/payments";
import type { Cause } from "@/store/causes";
import { CostType } from "@/store/rental/types";
import {getCurrentUser} from "@/app/stores/session";

const emit = defineEmits(["stepChanged", "previous", "next"]);

const props = defineProps<{
  booking: Booking;
  intent?: SetupIntent;
  cause?: Cause;
  isRequesting?: boolean;
}>();

const currentUser = getCurrentUser();

const protectionCosts = computed(() =>
  props.booking.costsBy(currentUser.id, [CostType.DEPOSIT, CostType.INSURANCE]),
);

const depositCosts = computed(() =>
  props.booking.costsBy(currentUser.id, [CostType.DEPOSIT]),
);
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.booking-details {
  .booking-details-topic {
    color: $gray-muted;
    min-width: 25%;
  }
}
.booking-group-success {
  background-color: $green-100;
  border-radius: 0.75em;
  padding: 2em;
}
</style>
