import axios, {type AxiosError, AxiosHeaders, type AxiosInstance, type AxiosResponse,} from "axios";
import {setupCache} from "axios-cache-interceptor";
import {inject} from "vue";

import {ApiError, ApiValidationError, SessionExpiredError} from "@/errors";
import type {Model} from "@/helpers/store";
import {NotificationIO, ReviewIO} from "@/io/account";
import BookingIO from "@/io/bookings";
import ChatIO from "@/io/chats";
import ListingIO from "@/io/listings";
import MessageIO from "@/io/messages";
import type {SetupIntent} from "@/io/setup_intents";
import {ApiInjectionKey} from "@/plugins/symbols";
import type {IPaymentMethod} from "@/store/PaymentMethods";
import type {Conversation} from "@/store/chatter/chats";
import type {IBooking} from "@/store/rental/models";
import type {Payment, Payout} from "@/store/transactions";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";
import {until} from "@vueuse/core";
import {Logger} from "@/app/logger";

Object.filter = (obj: object, predicate: (v: [string, string]) => boolean) =>
  Object.fromEntries(Object.entries(obj).filter(predicate));

export interface CollectionOpts<T, TApi> {
  isPartial?: boolean;
  isCached?: boolean;
  deserializer?: (data: TApi) => T;
  serializer?: (data: T) => unknown;
}

export interface SubResourceOpts<T, TApi> {
  deserializer?: (data: TApi) => T;
  serializer?: (data: T) => TApi;
}

type ParamValue = string | number | boolean | undefined | ParamValue[];

export interface ListQueryParams {
  range?: number[];
  ordering?: string;

  [Property: string]: ParamValue;
}

interface ListQueryHeaders extends AxiosHeaders {
  "entity-range"?: string;
}

export class ListResult<T> extends Array<T> {
  constructor(request, items) {
    super(...(items || []));
    this.request = request;
    this.terms = request.terms;
  }

  count(): number {
    let range = null;
    if (this.request.count) {
      return this.request.count;
    }
    try {
      range = this.request.headers["content-range"];
    } catch {
      return this.length;
    }
    if (!range) {
      return this.length;
    }
    return Number(range.split("/")[1]);
  }
}

export class Service {
  public request: AxiosInstance

  constructor(url, options) {
    this.url = url;
    this.options = options;
    this.c = {};
    this.r = {};
    this.context = {
      user: {},
    };
    this.setup();
    this.interceptor = undefined;
    this.request = this.getClient();
  }

  getClient(params = {}) {
    const client = setupCache(
      axios.create({
        baseURL: this.url,
        ...params,
      }),
    );
    client.interceptors.request.use((req) => {
      const {token} = useSession();
      if (token) {
        req.headers.set('Authorization', `Bearer ${token}`)
      }
      return req;
    });
    client.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        if (
          !error.config?.meta?.authRetry &&
          error.response?.status === 401
        ) {
          const session = useSession();
          const {isReady} = storeToRefs(session);
          session.tokenInvalid();
          Logger.info("🚒 Session expired, refreshing...");
          await until(isReady).toBe(true, {timeout: 5000});
          if (!error.config) {
            error.config = {};
          }
          error.config.meta = {authRetry: true};
          return client(error.config);
        }
        if (error.response?.status === 400) {
          /* validation error */
          return Promise.reject(new ApiValidationError(error));
        }
        return Promise.reject(new ApiError(error));
      },
    );
    return client;
  }

  unbind() {
    this.request = this.getClient();
  }

  async sync(session, auth) {
    this.request = this.getClient({
      headers: this.createHeaders(session),
    });
    if (!this.interceptor) {
      this.request.interceptors.response.use(
        (response: AxiosResponse) => response,
        async (error: AxiosError) => {
          if (
            !error.config?.meta?.authRetry &&
            error.response?.status === 401
          ) {
            return auth
              .expire()
              .then((session) => {
                this.sync(session, auth);
                error.config.meta = {authRetry: true};
                return this.request(error.config);
              })
              .catch(() => Promise.reject(new SessionExpiredError()));
          } else if (error.response?.status === 400) {
            /* validation error */
            return Promise.reject(new ApiValidationError(error));
          }
          return Promise.reject(new ApiError(error));
        },
      );
    }

    this.context.user = await this.request
      .get("me", {meta: {authRetry: true}})
      .then((res) => res.data);

    this.setup();
    return this.context.user;
  }

  createHeaders() {
    return {};
  }

  async list<R>(path, params, headers, cache = true) {
    return this.request
      .get<unknown, R>(path, {
        id: path,
        params,
        headers,
        cache,
      })
      .then((r) => r.data);
  }

  async get<R>(path: string, params = {}, cache = true): Promise<R> {
    return this.request
      .get(path, {
        id: path,
        params,
        cache,
      })
      .then((r) => r.data as R);
  }

  async post(path, params) {
    return this.request
      .post(path, params, {
        cache: {
          update: {
            [path]: "delete",
          },
        },
      })
      .then((r) => r.data);
  }

  async del(path) {
    return this.request.delete(path).then((r) => r.data);
  }

  async put(path, params) {
    return this.request
      .put(path, params, {
        cache: {
          update: {
            [path]: "delete",
            [path.split("/").slice(0, -1).join("/")]: "delete",
          },
        },
      })
      .then((r) => r.data);
  }

  async patch(path, params) {
    return this.request.patch(path, params).then((r) => r.data);
  }
}

const useSerializers = (options) => ({
  serialize(data, isPartial = false) {
    if (options.serializer) {
      return options.serializer(data, isPartial);
    }
    return data;
  },
  deserialize(data) {
    if (options.deserializer) {
      return options.deserializer(data);
    }
    return data;
  },
});

export const Resource = <T extends Model, TApi extends Model>(
  service: Service,
  path,
  options: CollectionOpts<T, TApi> = {},
) => ({
  ...useSerializers(options),
  _mounts: {} as Record<string, { options: CollectionOpts<T, TApi> }>,
  composePath(rid: string | undefined, action: string | undefined = undefined) {
    let result = path;
    if (rid) {
      result += `/${rid}`;
    }
    if (action) {
      result += `/${action}`;
    }
    return result;
  },
  get(rid: string | null, params = {}): Promise<T> {
    return service
      .get(this.composePath(rid), params, options.isCached)
      .then(this.deserialize);
  },
  put(rid, params) {
    return service
      .put(this.composePath(rid), this.serialize(params))
      .then(this.deserialize);
  },
  async patch(rid: T["id"] | undefined, params: Partial<T>): Promise<T> {
    const data = await service
      .patch(this.composePath(rid), this.serialize(params, true));
    return this.deserialize(data);
  },
  del() {
    return service.del(path);
  },
  sub<R>(rid: T["id"], action: string, params = {}): Promise<R> {
    return service.get<R>(this.composePath(rid, action), params) as Promise<R>;
  },
  mount(name, options) {
    this._mounts[name] = {
      options,
    };
  },
});

export const Collection = <T extends Model>(
  service: Service,
  path: string,
  options: CollectionOpts = {},
) => ({
  ...Resource<T>(service, path, options),
  list(params: ListQueryParams = {}): Promise<ListResult<T>> {
    const headers: ListQueryHeaders = new AxiosHeaders();
    if (params.range) {
      const range = params.range;
      let rangeHeader = `entities=${range.shift()}`;
      if (range.length > 0) {
        rangeHeader += `-${range.shift()}`;
      } else {
        rangeHeader += "-";
      }
      headers["entity-range"] = rangeHeader;
    }
    return service
      .list(path, params, headers, options.isCached)
      .then(
        (r) => new ListResult<T>(r, (r.results || r).map(this.deserialize)),
      );
  },
  del(rid) {
    return service.del(`${path}/${rid}`);
  },
  action(rid: T["id"], action: string, params = {}) {
    return service.put(`${path}/${rid}/${action}`, params).then((v) => {
      service.request.storage.remove(path);
      return this.deserialize(v);
    });
  },
  sub<R>(rid: T["id"], action: string, params = {}) {
    if (this._mounts[action]) {
      const spec = this._mounts[action];
      return Collection(service, `${path}/${rid}/${action}`, spec.options);
    }
    return service.get<R>(`${path}/${rid}/${action}`, params, options.isCached);
  },
  subResource<RApi, R>(rid: T["id"], action: string, params = {}, sub_options: SubResourceOpts<R, RApi> = {}) {
    const transformers = useSerializers(sub_options);
    return service.get<RApi>(`${path}/${rid}/${action}`, params, options.isCached).then(r => transformers.deserialize(r));
  },
  terms(params) {
    return service.get(`${path}/terms`, params);
  },
  create(params: Partial<T>, options = {}): Promise<T> {
    return service.post(`${path}`, this.serialize(params, options.isPartial)).then(this.deserialize);
  },
});

export const GenericCollection = <T, TApi>(
  service: Service,
  path: string,
  options: CollectionOpts<T, TApi> = {},
) => {
  return {
    ...useSerializers(options),
    async list(params: ListQueryParams = {}) {
      const r = await service.list<TApi>(path, params, {}, options.isCached);
      return new ListResult<T>(r, (r.results || r).map(this.deserialize));
    }
  }
}

export class ApiService extends Service {
  createHeaders(session) {
    return session?.tokens?.accessToken
      ? {
        Authorization: `Bearer ${session.tokens.accessToken}`,
      }
      : {};
  }

  createCollection(path: string, options: CollectionOpts) {
    return Collection(this, path, options);
  }

  setup() {
    this.listings = Collection(this, "/rental/listings", {
      ...ListingIO,
      isCached: false,
    });
    this.accountListings = Collection(this, "/me/listings", {
      ...ListingIO,
      isCached: false,
    });
    this.favorites = Collection(this, "/me/favorites", {
      deserializer: ListingIO.deserializer,
    });
    this.me = Resource(this, "/me");
    this.bookings = Collection(this, "/rental/bookings", {
      ...BookingIO,
    });
    this.setupIntents = Collection<SetupIntent>(this, "me/setup_intents", {
      isCached: false,
    });
    this.categories = Collection(this, "/rental/categories");
    this.search = Collection(this, "/rental/search", {
      isCached: false,
    });
    this.causes = Collection(this, "/charity/causes");
    this.tokens = Collection(this, "/tokens");
    this.uploads = Collection(this, "/uploads");
    this.users = Collection(this, "/user/profiles");
    this.notifications = Collection(this, "/me/notifications", {
      isCached: false,
      ...NotificationIO,
    });
    this.reviews = Collection(this, "/me/reviews", {
      isCached: false,
      ...ReviewIO,
    });
    this.payments = Collection(this, "/me/payments", {isCached: false});
    this.paymentMethods = Collection(this, "/me/payment_methods");
    this.payouts = Collection(this, "/me/payouts", {isCached: false});
    this.chats = Collection(this, "/chatter/chats", {
      ...ChatIO,
    });
    this.chats.mount("messages", {
      ...MessageIO,
    });

    this.tests = {
      authError: () => this.get("/_tests/auth_error"),
    };
  }
}

export default {
  install(app, options) {
    const api = new ApiService(options.apiUrl, {app});
    this.c = api;
    app.$api = this;
    app.config.globalProperties.$api = this;
    app.provide("api", this);
    app.provide(ApiInjectionKey, this);
  },
};

export interface IApi {
  c: {
    setupIntents: ReturnType<typeof Collection<SetupIntent>>;
    bookings: ReturnType<typeof Collection<IBooking>>;
    paymentMethods: ReturnType<typeof Collection<IPaymentMethod>>;
    chats: ReturnType<typeof Collection<Conversation>>;
    payments: ReturnType<typeof Collection<Payment>>;
    payouts: ReturnType<typeof Collection<Payout>>;
  };
}

export function useApi(): IApi {
  const result = inject(ApiInjectionKey);
  if (!result) throw "Misconfigured, no API";
  return result;
}
