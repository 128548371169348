const serializer = (d) => d;

const deserializer = (d) => ({
  ...d,
  createdAt: new Date(d.created_at),
});

export default {
  serializer,
  deserializer,
};
