<template>
  <PsButton
    :class="loading ? 'is-loading' : null"
    color="secondary"
    @click.stop="toggleFavorite"
  >
    <Icon
      v-show="!loading"
      :name="icon"
      :variant="iconVariant"
    />
    <span v-if="withLabel">{{ label }}</span>
  </PsButton>
</template>
<script setup lang="ts">
import {useFavoriteListings} from "@/rental/stores/listings";
import type {Listing} from "@/rental/stores/listings";
import {computed, ref} from "vue";
import {Logger} from "@/app/logger";

const props = withDefaults(defineProps<{
  listingId: Listing["id"];
  isFavorite: boolean;
  withLabel?: boolean;
}>(), {
  withLabel: false,
})
const loading = ref(false);

const favourites = useFavoriteListings();

const label = computed(() => props.isFavorite ? "Saved" : "Save");
const icon = computed(() => props.isFavorite ? "favorite" : "unfavorite");
const iconVariant = computed(() => props.isFavorite ? "active" : "secondary");

const toggleFavorite = async () => {
  loading.value = true;
  try {
    if (props.isFavorite) {
      await favourites.remove(props.listingId);
    } else {
      await favourites.add(props.listingId);
    }
  } catch (err) {
    Logger.error(err);
  }
  loading.value = false;
}
</script>
