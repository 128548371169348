import { defineStore } from "pinia";

import { type ListResult, useApi } from "@/plugins/Api";
import type { PaymentMethod } from "@/store/PaymentMethods";

export interface SetupIntent {
  id: string;
  status: string;
  client_secret: string;
  payment_method: PaymentMethod;
}

export default defineStore("payments", () => {
  const api = useApi();

  return {
    async getIntent(
      intentId: SetupIntent["id"] | undefined = undefined,
    ): Promise<SetupIntent> {
      if (intentId) {
        return await api.c.setupIntents.get(intentId);
      } else {
        return await api.c.me.sub(null, "setup_intent");
      }
    },
    async createIntent(): Promise<SetupIntent> {
      return api?.c.setupIntents.create({});
    },
    async listIntents(): Promise<ListResult<SetupIntent>> {
      return api.c.setupIntents.list();
    },
  };
});
