<template>
  <div>
    <p class="title is-3 mb-4">
      {{ ft('title', {name: 'listing'}) }}
    </p>
    <p class="my-4">
      By creating a new listing you make sure other users can find the items you have available for
      rent. Mention important characteristics such as color, brand or size.
    </p>

    <p class="title is-4 mt-6 mb-4">
      Details
    </p>
    <FormKit
      help="Mention important characteristics such as color, brand or size."
      name="name"
      validation="required|alphanum|length:1,80"
    >
      <template #label>
        <label class="formkit-label label">Listing title <span class="form-required-label">(Required)</span></label>
      </template>
    </FormKit>
    <FormKit
      :help="`${values.description ? values.description.length : 0} / 520`"
      :validation-messages="{
      length: 'Description cannot be more than 520 characters.',
    }"
      name="description"
      placeholder="Tell something more."
      type="textarea"
      validation="required|length:1,520"
    >
      <template #label>
        <label class="formkit-label label">Description <span class="form-required-label">(Required)</span></label>
      </template>
    </FormKit>

    <p class="title is-4 mt-6 mb-4">
      Category
    </p>
    <div
      v-if="categoryStore.ready"
      :key="values.rootCategoryId"
      class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap is-align-items-flex-start"
    >
      <FormKit
        :options="toOptions(categoryStore.getRootItems())"
        :prefetch-items="true"
        name="rootCategoryId"
        placeholder="Select a category"
        type="autocomplete"
        validation="required"
        v-test-id="'input-main-category-id'"
        @input="updateOptions"
      >
        <template #label>
          <label class="formkit-label label">Category <span class="form-required-label">(Required)</span></label>
        </template>
      </FormKit>
      <FormKit
        :disabled="!values.rootCategoryId"
        :options="subOptions"
        :prefetch-items="true"
        name="categoryId"
        placeholder="Select a category"
        type="autocomplete"
        validation="required"
        v-test-id="'input-sub-category-id'"
      >
        <template #label>
          <label class="formkit-label label">Sub category <span class="form-required-label">(Required)</span></label>
        </template>
      </FormKit>
    </div>

    <p class="title is-4 mt-6 mb-4">
      Condition
    </p>
    <div class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap">
      <FormKit
        :options="age_options"
        help="Shiny and new, or classy and vintage?"
        name="age"
        placeholder="Select an age"
        type="select"
        validation="required|number"
      >
        <template #label>
          <label class="formkit-label label">Age <span class="form-required-label">(Required)</span></label>
        </template>
      </FormKit>
      <FormKit
        :options="condition_options"
        help="How does it look?"
        name="condition"
        placeholder="Select a condition"
        type="select"
        validation="required"
      >
        <template #label>
          <label class="formkit-label label">Condition <span class="form-required-label">(Required)</span></label>
        </template>
      </FormKit>
    </div>

    <p class="title is-4 mt-6 mb-4">
      Additional information
    </p>
    <FormKit
      help="Separate features with a comma"
      label="Features"
      name="features"
      placeholder="Cool brand, special design, color or other characteristics"
    />
    <FormKit
      help="Separate rules with a comma"
      label="Rules"
      name="restrictions"
      placeholder="Only use with sunny weather, return with charged batteries"
    />
  </div>
</template>

<script lang="ts" setup>
import {ref} from "vue";

import {type Category, useCategories} from "@/rental/stores/categories";
import {useStepContext} from "@/rental/composables/listingEditor";
import {useTranslationOptions} from "@/composables/form";

const {form, ft, values, isNew} = useStepContext();
type CategoryOption = {
  value: string;
  icon?: string;
  label: string;
};

const age_options = useTranslationOptions('listing.age');
const condition_options = useTranslationOptions('listing.condition');

const categoryStore = useCategories();
await categoryStore.fetchCategories();
const subOptions = ref<CategoryOption[]>(
  values.value.rootCategoryId ? toOptions(categoryStore.listByParentId(values.value.rootCategoryId)) : []
);

function toOptions(items: Category[]): CategoryOption[] {
  return items
    .map((el: Category) => ({
      value: el.id,
      icon: el.icon,
      label: `${el.name}`,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

function updateOptions(newValue: string) {
  subOptions.value = toOptions(categoryStore.listByParentId(newValue));
  values.value.categoryId = undefined;
}

</script>

<style lang="scss" scoped>
</style>
