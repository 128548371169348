<template>
  <LoadingFullSection v-if="store.loading"/>
  <div v-else>
    <ListingEditor
      :listing-id="listingId"
      :initial-data="store.getById(listingId)"
      :initial-progressed-step="8"
    />
  </div>
</template>

<script setup lang="ts">
import ListingEditor from "@/rental/components/ListingEditor.vue";
import LoadingFullSection from "@/sections/LoadingFull.vue";

import {usePrivateListings} from "@/rental/stores/listings";

defineProps<{ listingId: string }>()

const store = usePrivateListings();
await store.sync();
</script>
