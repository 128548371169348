<template>
  <div
    class="is-hoverable is-clickable booker-dates"
    @click.exact="toggleDateSelection"
  >
    <div class="booker-date">
      <label>FROM</label>
      <span class="date">
        {{ formatDate(value?.at(0)) }}
      </span>
      <span
        v-if="pickupTimeRange?.length === 2 || context.pickupDropOffRange?.length === 2"
        class="pickup"
      >
        {{ formatPickupDelivery(pickupTimeRange ?? context.pickupDropOffRange) }}
      </span>
    </div>
    <div class="booker-date">
      <label>TILL</label>
      <span class="date">
        {{ formatDate(value?.at(1)) }}
      </span>
      <span
        v-if="dropoffTimeRange?.length === 2 || context.pickupDropOffRange?.length === 2"
        class="pickup"
      >
        {{ formatPickupDelivery(dropoffTimeRange ?? context.pickupDropOffRange) }}
      </span>
    </div>
  </div>

  <div
    v-if="dateSelectionActive"
    :class="[dateSelectionActive ? 'is-active' : null]"
    class="modal"
  >
    <div class="modal-background" />
    <div class="modal-content">
      <div class="box">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="title is-5">
                Select date {{ showPickupDropoffRangeInputs ? 'and time' : '' }}
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <PsButton
                color="secondary"
                icon="close"
                styling="outlined"
                @click.prevent="toggleDateSelection"
              />
            </div>
          </div>
        </div>
        <FormKit
          :actions="false"
          type="form"
          @submit="submitSelectedDates"
        >
          <div class="is-flex is-align-items-start is-justify-content-center flex-gap-1">
            <FormKit
              :auto-apply="true"
              :disabled-dates="context.isDisabledDate"
              :disabled-week-days="negateDays(context.availableWeekdays)"
              :inline="true"
              :range="true"
              :value="value"
              help="Select the days you intend to rent for."
              name="booking_date_range"
              type="datepicker"
            />

            <div v-if="showPickupDropoffRangeInputs">
              <FormKit
                v-model="pickupTimeIx"
                :options="pickupTimeOptions"
                inner-class="is-block"
                label="Pickup time"
                placeholder="Select a pickup time"
                type="select"
              />

              <FormKit
                v-model="dropoffTimeIx"
                :options="dropoffTimeOptions"
                inner-class="is-block"
                label="Return time"
                placeholder="Select a return time"
                type="select"
              />
            </div>
          </div>
          <div class="field is-grouped is-justify-content-right mt-4">
            <div class="control">
              <PsButton
                color="secondary"
                @click.prevent="clearDates"
              >
                Clear dates
              </PsButton>
            </div>
            <div class="control">
              <FormKit
                label="Select dates"
                type="submit"
              />
            </div>
          </div>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { type Ref, computed, ref, watch } from "vue";

import Formats, { type AnyDateType } from "@/helpers/formats";

const INITIAL_DATE_RANGE_VALUE = [
  Formats.dateOrDatesFormatForApiWithoutTimezone(new Date()),
  Formats.dateOrDatesFormatForApiWithoutTimezone(dayjs().add(1, "day")),
];

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
});

const dateSelectionActive = ref(false);
const pickupTimeRange: Ref<[string, string] | undefined> = ref(
  props.context.pickupTimeRange,
);
const dropoffTimeRange: Ref<[string, string] | undefined> = ref(
  props.context.dropoffTimeRange,
);
const pickupTimeIx: Ref<number> = ref(0);
const dropoffTimeIx: Ref<number> = ref(0);

const value = computed(() => {
  if (props.context._value || props.context.allowEmpty) {
    return props.context._value;
  }

  // Else set default value
  props.context.node.input(INITIAL_DATE_RANGE_VALUE);
  return INITIAL_DATE_RANGE_VALUE;
});

const showPickupDropoffRangeInputs = computed(() => {
  return (
    props.context.pickupDropOffRange?.length === 2 &&
    !!props.context.showPickupDropoffTimeSelection
  );
});

const pickupTimeOptions = computed(() => {
  if (props.context.pickupDropOffRange?.length !== 2) {
    return [];
  }

  const startTime = props.context.pickupDropOffRange.at(0);
  const endTime = props.context.pickupDropOffRange.at(1);
  const chunksOfHalfHours = Formats.timeLocalSplitIntoChunksOfHalfHours(
    startTime,
    endTime,
  );
  return chunksOfHalfHours.map((rangeHalfHour: [string, string], ix) => ({
    label: formatPickupDelivery(rangeHalfHour),
    sysValue: rangeHalfHour,
    value: ix,
  }));
});

const dropoffTimeOptions = computed(() => {
  const currentPickupTimeRange = pickupTimeOptions.value[pickupTimeIx.value]
    ? pickupTimeOptions.value[pickupTimeIx.value].sysValue
    : undefined;
  const dropoffOptions = pickupTimeOptions.value;

  if (!currentPickupTimeRange || currentPickupTimeRange.length !== 2) {
    return dropoffOptions;
  }

  if (value.value?.length === 2 && value.value.at(0) === value.value.at(1)) {
    // When dates are the same limit the dropoff times
    const firstIndexOfReturnTime = dropoffOptions.findIndex(
      (option) => formatPickupDelivery(currentPickupTimeRange) === option.label,
    );
    return firstIndexOfReturnTime > 0
      ? dropoffOptions.slice(firstIndexOfReturnTime)
      : dropoffOptions;
  }

  return dropoffOptions;
});

watch(
  pickupTimeOptions,
  (opts) => {
    if (!opts) return;
    pickupTimeIx.value = 0;
  },
  { immediate: true },
);

watch(
  dropoffTimeOptions,
  (opts) => {
    if (!opts) return;
    dropoffTimeIx.value = 0;
  },
  { immediate: true },
);

function formatDate(d: string) {
  if (!d) {
    return "Any date";
  }
  return Formats.daySystemWithoutTimezoneFormat(d);
}

function formatPickupDelivery(
  d: [string, string] | [AnyDateType, AnyDateType],
) {
  return `${Formats.timeLocalFormat(d[0])} - ${Formats.timeLocalFormat(d[1])}`;
}

function negateDays(d: string[]) {
  return Formats.daysToInts(Formats.negateDays(d));
}

function toggleDateSelection() {
  dateSelectionActive.value = !dateSelectionActive.value;
}

function submitSelectedDates(model: { booking_date_range?: string[] }) {
  props.context.node.input(model.booking_date_range ?? undefined);

  if (showPickupDropoffRangeInputs.value) {
    pickupTimeRange.value =
      pickupTimeOptions.value[pickupTimeIx.value].sysValue;
    dropoffTimeRange.value =
      pickupTimeOptions.value[dropoffTimeIx.value].sysValue;
    props.context.onSelectPickupDropoffTimeRange({
      pickupTimeRange: pickupTimeRange.value,
      dropoffTimeRange: dropoffTimeRange.value,
    });
  }

  toggleDateSelection();
}

function clearDates() {
  if (props.context.allowEmpty) {
    props.context.node.input(undefined);
  } else {
    props.context.node.input(INITIAL_DATE_RANGE_VALUE);
  }

  if (showPickupDropoffRangeInputs.value) {
    pickupTimeRange.value = undefined;
    dropoffTimeRange.value = undefined;
    props.context.onSelectPickupDropoffTimeRange({
      pickupTimeRange: undefined,
      dropoffTimeRange: undefined,
    });
  }

  toggleDateSelection();
}
</script>

<style scoped lang="scss">
.booker-dates {
  margin-top: 16px;
  display: flex;
  justify-content: space-around;
  gap: 0;
}

.booker-date:first-child {
  border-right: none;
}

.booker-date {
  border: 1px solid var(--gray-smoke);
  width: 100%;
  height: 84px;
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 4px;

  label {
    font-size: 14px;
    color: var(--secondary);
  }

  .date {
    font-weight: 600;
  }

  .pickup {
    font-size: 0.75rem;
    color: var(--gray-muted);
  }
}
</style>
, Ref, { AnyDateType }
