import { defineStore } from "pinia";
import { inject } from "vue";

import { useCollection } from "@/helpers/store";
import type { Chat, MessageType } from "@/io/chats";
import { Actor } from "@/store/user";
import {useBookings} from "@/rental/stores/bookings";

export class Conversations {
  constructor() {
    this.items = [];
  }

  getBySenderId(senderId) {
    return this.items.find((el) => el.sender_id === senderId);
  }

  addNotification(notification) {
    const conversation = this.getBySenderId(notification.sender_id);
    if (!conversation) {
      this.items.push({
        sender_id: notification.sender_id,
        sender: notification.sender,
        messages: [notification],
        started_at: notification.createdAt,
      });
    } else {
      if (!conversation.sender && notification.sender) {
        conversation.sender = notification.sender;
      }
      conversation.messages.push(notification);
    }
  }
}

class Message {
  constructor(params) {
    this.id = params.id;
    this.chat = params.chat;
    this.author =
      params.author instanceof Actor ? params.author : new Actor(params.author);
    this.body = params.body;
    this.createdAt = params.createdAt;
    this.resourceType = params.resourcetype;
  }

  isAuthor(uid) {
    return this.author.id === uid;
  }
}

class BookingRequestedMessage extends Message {
  constructor(params) {
    super(params);
    const bookings = useBookings();
    this.booking = bookings.getById(params.booking.id);
  }
}

const MESSAGE_MAP = {
  TextMessage: Message,
  BookingRequestedMessage,
};

function toMessageObject(msg: MessageType) {
  return new MESSAGE_MAP[msg.resourcetype](msg);
}

export class Conversation {
  id: string;
  messages: Message[];
  participants: Actor[];
  sender: Actor;
  updatedAt: string;
  lastMessage?: Message;

  constructor(params: Chat, currentUser) {
    this._currentUser = currentUser;
    this.id = params.id;
    this.participants = params.users;
    const sender = params.users.find((el) => el.id !== this._currentUser.id);
    this.sender = sender instanceof Actor ? sender : new Actor(sender);
    this.messages = [];
    this.updatedAt = params.updatedAt;
    this.lastMessage = params.last_message
      ? toMessageObject(params.last_message)
      : undefined;
  }

  get hasMessages() {
    return this.messages && this.messages.length > 0;
  }
}

export const useConversationStore = defineStore("conversationStore", () => {
  const api = inject("api");
  const collection = useCollection<Conversation>(async () => {
    const response = await api.c.chats.list({
      range: [0],
      ordering: "-updated_at",
    });
    return response.map((el) => new Conversation(el, api.c.context.user));
  });

  const fetchMessages = async (chatId) => {
    const chat = collection.getById(chatId);
    const response = await api.c.chats.sub(chatId, "messages").list({
      ordering: "-created_at",
    });
    chat.messages = response.map((el) => toMessageObject(el)).reverse();
  };

  const sendMessage = async (chatId, body) => {
    const chat = collection.getById(chatId);
    const response = await api.c.chats.sub(chatId, "messages").create({
      body,
    });
    chat.messages.push(toMessageObject(response));
  };

  const createConversation = async (otherParticipantId: string) => {
    const result = await api.c.chats.create({
      other_participant: otherParticipantId,
    });
    const convo = new Conversation(result, api.c.context.user);
    collection.items.value.push(convo);
    return convo;
  };

  return {
    ...collection,
    fetchMessages,
    sendMessage,
    createConversation,
  };
});
