<template>
  <p class="title is-3">Notifications</p>
  <div v-if="notifications.loading && notifications.items.length === 0" class="items">
    <LoadingFullSection/>
  </div>
  <div v-else-if="notifications.items.length === 0">
    <EmptyState>No notifications yet.</EmptyState>
  </div>
  <div v-else class="notifications-items">
    <BookingNotification v-for="[notification, isFirst] in notificationList" :key="notification.id"
                         :notification="notification" :is-first="isFirst"/>
  </div>
</template>

<script setup lang="ts">
import EmptyState from "@/components/EmptyState.vue";
import BookingNotification from "@/components/account/notifications/BookingNotification.vue";
import LoadingFullSection from "@/sections/LoadingFull.vue";

import {type AccountNotification, useNotifications} from "@/account/stores/notifications";
import {useBookings} from "@/rental/stores/bookings";
import {useOrdered} from "@/composables/collections";
import {storeToRefs} from "pinia";
import {computed} from "vue";

const notifications = useNotifications();
const bookings = useBookings();
await bookings.sync();

notifications.fetch();
const { items } = storeToRefs(notifications);

const orderedNotifications = useOrdered<AccountNotification>(items, {key: 'createdAt', direction: 'desc'});
const notificationList = computed(() => {
  const ids: AccountNotification['resourceId'][] = [];
  const result: [AccountNotification, boolean][] = [];
  for (const notification of orderedNotifications.value) {
    let isFirst = true;
    const resourceId = notification.resourceId;
    if (ids.includes(resourceId)) {
      isFirst = false;
    } else {
      ids.push(resourceId);
    }
    result.push([notification, isFirst]);
  }
  return result;
});
</script>

<style scoped lang="scss">
.notifications-items {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
