import {type Context, type Params, RouteFactory} from "@/app/router";
import Categories from "@/pages/Categories.vue";
import CategoriesBrowser from "@/pages/CategoriesBrowser.vue";
import ListingBrowser from "@/pages/listings-children/ListingBrowser.vue";
import Listings from "@/pages/Listings.vue";
import CreateListing from "@/pages/listings-children/CreateListing.vue";
import UpdateListing from "@/pages/listings-children/UpdateListing.vue";
import {type Listing, usePublicListings} from "@/rental/stores/listings";
import {type Category, useCategories} from "@/rental/stores/categories";
import type {Booking} from "@/store/rental/models";
import ListingPage from "@/pages/listings-children/Listing.vue";
import {useBookings} from "@/rental/stores/bookings";

interface ListingParams extends Params {
  listingId: string;
  id: string;
}

interface ListingContext extends Context {
  listing: Listing;
}

export const listingGetterById = <P extends Params>(paramKey: keyof P): (params: P) => Promise<Listing> => async (params: P) => {
  const store = usePublicListings();
  const id = params[paramKey];
  return store.ensureById(Array.isArray(id) ? id[0] : id);
}

export const categoryGetterByName = <P extends Params>(paramKey: keyof P): (params: P) => Promise<Category> => async (params: P) => {
  const store = useCategories();
  const name = params[paramKey];
  await store.fetchCategories();
  return store.getByName(Array.isArray(name) ? name[0] : name);
};

export const bookingGetterById = <P extends Params>(paramKey: keyof P): (params: P) => Promise<Booking> => async (params: P) => {
  const store = useBookings();
  const id = params[paramKey];
  return await store.ensureById(Array.isArray(id) ? id[0] : id);
};

const factory = new RouteFactory();

factory.addRoute({
  path: "/categories",
  component: Categories,
  name: "categoriesBase",
  withBreadcrumb: {
    label: "Categories",
    name: "categories",
  },
});

factory.addSubRoute("categoriesBase", {
  path: "",
  name: "categories",
  component: CategoriesBrowser,
});

type categoryParams = {
  mainCategoryName: string;
  subCategoryName?: string;
};

type categoryContext = {
  mainCategory: Category;
  subCategory?: Category;
}

factory.addSubRoute<categoryParams, categoryContext>("categoriesBase", {
  path: ":mainCategoryName([^/]+)/:subCategoryName([^/]+)?",
  name: "listingsCategory",
  component: ListingBrowser,
  props: (route) => ({
    initialMainCategoryName: route.params.mainCategoryName,
    initialSubCategoryName: route.params.subCategoryName,
  }),
  withBreadcrumb: {
    deps: {
      mainCategory: categoryGetterByName<categoryParams>("mainCategoryName"),
      subCategory: categoryGetterByName<categoryParams>("subCategoryName"),
    },
    before: [
      {
        label: (params, context) => context.mainCategory?.name || undefined,
        show: (params, context) => Boolean(context.subCategory),
        name: "listingsCategory",
        params: (params, context) => ({
          mainCategoryName: context.mainCategory?.name,
        }),
      },
    ],
    label: (params, context) =>
      context.subCategory?.name || context.mainCategory?.name,
  },
});

factory.addRoute({
  path: "/listings",
  name: "listingsBase",
  component: Listings,
})

factory.addSubRoute("listingsBase", {
  path: "",
  name: "listings",
  component: ListingBrowser,
  withBreadcrumb: {
    label: "Listings",
  },
})

const accountListingDefaults = {
  withFooter: false,
  requiresAuthenticated: true,
}

factory.addSubRoute("listingsBase", {
  path: "create",
  name: "createListing",
  withBreadcrumb: {
    label: "New listing",
  },
  component: CreateListing,
}, accountListingDefaults)

factory.addSubRoute<ListingParams, ListingContext>("listingsBase", {
  path: ":listingId([\\w\\d\\-]{36})/edit",
  name: "updateListing",
  props: true,
  component: UpdateListing,
  withBreadcrumb: {
    deps: {
      listing: listingGetterById<ListingParams>("listingId"),
    },
    before: [
      {
        name: "accountListings",
        label: "My listings",
      },
      {
        label: (params, context) =>
          context.listing?.name || `Listing ${params.listingId}`,
        name: "listing",
        params: (params, context) => ({
          id: params.listingId,
        }),
      },
    ],
    label: "Edit listing",
  }
}, accountListingDefaults)

factory.addSubRoute<ListingParams, ListingContext>("listingsBase", {
  path: ":id([\\w\\d\\-]{36})",
  name: "listing",
  props: true,
  component: ListingPage,
  withBreadcrumb:
    {
      deps: {
        listing: listingGetterById<ListingParams>("id"),
      }
      ,
      before: [
        {
          name: "categories",
          label: "Categories",
        },
        {
          label: (params, context) =>
            context.listing?.category?.parent?.name || null,
          name: "listingsCategory",
          params: (params, context) => ({
            mainCategoryName: context.listing?.category?.parent?.name,
          }),
        },
        {
          label: (params, context) =>
            context.listing?.category?.name || null,
          name: "listingsCategory",
          params: (params, context) => ({
            mainCategoryName: context.listing?.category?.parent?.name,
            subCategoryName: context.listing?.category?.name,
          }),
        },
      ],
      label:
        (params, context) =>
          context.listing?.name || `Listing ${params.id}`,
    }
  ,
})

export const routes = factory.routes;
