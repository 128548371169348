<template>
  <CardBase
    :is-clickable="isClickable"
    :orientation="orientation"
    :size="size"
    class="booking-card"
    @card-clicked="onClick"
  >
    <template
      v-if="showHighlights"
      #header
    >
      <BookingHighlights
        :booking="booking"
        :show-booking-period="false"
        :show-next-action="showNextAction"
        :show-pickup-location="showPickupLocation"
        :show-pickup-time-range="showPickupTimeRange"
        :show-status="showStatus"
      />
    </template>

    <template #preview>
      <img
        v-if="listingImage"
        :alt="listing.name"
        :onError="
          (e) => {
            const target = e.target as HTMLImageElement;
            if (target) target.src = '/thumbnail.png';
          }
        "
        :src="listingImage"
      >
    </template>

    <template #details>
      <p class="listing-title">
        {{ listing.name }}
      </p>
      <Person v-if="showRenter" :person="booking.renter" size="small"/>
      <DateRange v-if="showBookingPeriod" :ends-at="booking.endsAt" :starts-at="booking.startsAt"/>
      <p class="booking-total" v-if="showPrice">
        <span class="price">
          <Money :amount="bookingTotal"/>
        </span>
        <span class="interval">total</span>
      </p>
    </template>

    <template #actions>
      <slot name="actions"/>
    </template>
  </CardBase>
</template>
<script lang="ts" setup>
import {computed} from "vue";
import {useRouter} from "vue-router";

import CardBase from "@/components/CardBase.vue";
import BookingHighlights from "@/components/rental/BookingHighlights.vue";
import Money from "@/elements/Money.vue";

import {InvalidArgumentsError} from "@/errors";
import type {Listing} from "@/rental/stores/listings";
import type {Booking} from "@/rental/stores/bookings";

import Formats from "../helpers/formats";
import type {OrientationType, SizeType,} from "@/components/display/card/props";
import {getCurrentUser} from "@/app/stores/session";
import Person from "@/app/components/Person.vue";
import DateRange from "@/rental/components/DateRange.vue";

const props = withDefaults(
  defineProps<{
    booking: Booking;
    showBookingPeriod?: boolean;
    showBookingCreationDate?: boolean;
    showHighlights?: boolean;
    showStatus?: boolean;
    showRenter?: boolean;
    showPickupTimeRange?: boolean;
    showPickupLocation?: boolean;
    showNextAction?: boolean;
    showPrice?: boolean;
    clickTarget?: "listing" | "booking";
    isClickable?: boolean;
    size?: SizeType;
    orientation?: OrientationType;
  }>(),
  {
    showBookingPeriod: false,
    showBookingCreationDate: true,
    showHighlights: false,
    showStatus: true,
    showRenter: true,
    showPickupTimeRange: true,
    showPickupLocation: true,
    showNextAction: false,
    showPrice: true,
    clickTarget: "booking",
    isClickable: false,
    size: "medium" as SizeType,
    orientation: "vertical" as OrientationType,
  },
);

const router = useRouter();
const currentUser = getCurrentUser();

if (!props.booking) {
  throw new InvalidArgumentsError();
}
const bookingDurationInDays = computed(
  () =>
    Math.ceil(
      Formats.getDuration(
        props.booking.startsAt,
        props.booking.endsAt,
      ).asDays(),
    ) || 1,
);

const booking = props.booking;
const listing = props.booking.listing as Listing;

const listingImage = computed<string | undefined>(() => {
  if (listing.hasImage) {
    return listing.mainImageSrc;
  }
  return undefined;
});

const bookingTotal = computed(() => props.booking.totalCostsWithoutDeposit(currentUser.id))

const gotoBooking = (bookingId: Booking["id"]) => {
  router.push({
    name: "accountBooking",
    params: {
      bookingId,
    },
  });
};

const gotoListing = (listingId: Listing["id"]) => {
  router.push({
    name: "listing",
    params: {
      id: listingId,
    },
  });
};

const onClick = () => {
  if (!props.isClickable) {
    return;
  }
  const handler = {
    listing: gotoListing.bind({}, booking.listing.id),
    booking: gotoBooking.bind({}, booking.id),
  }[props.clickTarget];
  handler();
};
</script>
<style lang="scss">
@import '../styles/variables.scss';

.booking-card {
  flex-wrap: wrap;

  .card-header {
    box-shadow: none;
    width: 100%;
  }

  .card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px;

    > * {
      padding: 4px 0;
    }
  }

  .listing-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
    gap: 8px;
  }

  .protection {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
  }

  .listing-title,
  .price {
    font-weight: 600;
  }

  .listing-secondary-price {
    color: $gray-muted;
    text-decoration-line: line-through;
  }

  .deposit {
    color: $green-500;
  }

  .insurance {
    color: $peach-500;
  }

  .divider:after {
    content: '•';
    color: $gray-muted;
  }

  &.is-favorite {
    .overlay-top {
      opacity: 0.9 !important;

      .ps-button {
        color: $primary !important;
      }
    }
  }

  &.is-vertical .card-header {
    display: none;
  }

  .booking-total {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-top: auto;
  }
}
</style>
