<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useClasses } from "@/composables/styling";

const props = withDefaults(
  defineProps<{
    fullWidth?: boolean;
    align?: "left" | "center" | "right";
  }>(),
  {
    fullWidth: false,
    align: "left",
  },
);

const classes = useClasses(
  props,
  {
    fullWidth: "is-fullwidth",
    align: {
      left: "is-left",
      center: "is-center",
      right: "is-right",
    },
  },
  ["button-group", "buttons", "is-grouped"],
);
</script>

<style lang="scss">
.button-group {
  display: flex;
  justify-content: space-evenly;
  gap: 1em;
  margin-top: 2em;

  &.is-fullwidth {
    button {
      flex-grow: 1;
    }
  }

  &.is-left {
    justify-content: flex-start;
  }
  &.is-center {
    justify-content: center;
  }
  &.is-right {
    justify-content: flex-end;
  }
}
</style>
