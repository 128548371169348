<script lang="ts" setup>
import {computed} from "vue";

import PMap from "@/components/Map.vue";
import type {Listing} from "@/rental/stores/listings";


const props = defineProps<{
  listing: Listing;
  isPrecise?: boolean;
}>();

const listingPosition = computed(() => {
  const position = props.listing.pickupLocation?.position;
  if (!position) {
    return null;
  }
  return [position.latitude, position.longitude];
});
</script>

<template>
  <section
    v-if="listingPosition"
    class="is-flex is-flex-direction-column"
  >
    <h4>
      Pickup location
    </h4>
    <p v-if="!isPrecise" class="mb-4">
      The pick-up and drop off location is an estimate and doesn&apos;t reflect the actual location.
    </p>
    <div class="map">
      <PMap
        :circle-coordinates="listingPosition"
        :fixed-marker-position="true"
        :initial-coordinates="listingPosition"
        :initial-marker="listingPosition"
        :initial-zoom="13"
        data-chromatic="ignore"
      />
    </div>
  </section>
</template>

<style>
.map {
  margin: 24px 0;
}
</style>
