<script setup lang="ts"></script>

<template>
  <div class="data-table">
    <slot />
  </div>
</template>

<style lang="scss">
.data-table {
  label {
    width: 30%;
  }
}
</style>
