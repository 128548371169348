import { defineStore } from "pinia";
import {type ComputedRef, type Ref, computed, ref, watch, triggerRef} from "vue";
import { isEqual } from "lodash";

import Formats, { type AnyDateType } from "@/helpers/formats";
import { type ListResult, useApi } from "@/plugins/Api";
import {type ApiListing, type Listing, PublicListingDeserializer, usePublicListings} from "@/rental/stores/listings";
import useUserStore from "@/store/user";
import {useApiCollection} from "@/app/api";

export class DateRange extends Array {
  startDate: AnyDateType | null;
  endDate: AnyDateType | null;

  constructor(startDate: AnyDateType | null, endDate: AnyDateType | null) {
    super(startDate, endDate);
    this.startDate = startDate;
    this.endDate = endDate;
  }

  getDisplayFormat(): string[] {
    if (this.indexOf(null) > -1) {
      return [];
    }
    return [this.startDate, this.endDate].map((el: AnyDateType | null) =>
      el ? Formats.daySystemWithoutTimezoneFormat(el, "MMM D") : "Any date",
    );
  }

  getApiFormat(): string[] {
    if (this.indexOf(null) > -1) {
      return [];
    }
    return [this.startDate, this.endDate]
      .map((el: AnyDateType | null) =>
        el ? Formats.dateSystemWithoutTimezoneFormat(el) : undefined,
      )
      .filter((d) => !!d) as string[];
  }

  serialize(): string {
    const strs = this.getApiFormat();
    if (strs) {
      return strs.join(",");
    }
    return "";
  }
}

export type GeoLocation = {
  latitude: number;
  longitude: number;
  distance: number | undefined;
};

export type FilterOptions = {
  q?: string;
  selectedDates?: DateRange;
  priceRange?: (number | undefined)[];
  categoryId?: string;
  location?: GeoLocation;
  status?: string;
};

export const toListFilter = (options: Partial<FilterOptions>) => {
  const filters = {
    q: (v) => `(name ilike "%${v}%" or description ilike "${v}")`,
    categoryId: (v) =>
      `(category_id eq "${v}" OR category__parent__id eq "${v}")`,
    location: (v) =>
      `pickup_location__geo_position near (${v.longitude} ${v.latitude} ${v.distance || ""})`,
    cityState: (v) => `pickup_location__city_state_long eq "${v}"`,
    cityStateLike: (v) => `pickup_location__city_state_long ilike "%${v}%"`,
    priceRange: (v) => {
      const [minPrice, maxPrice] = v;
      return `pricing_options__amount gt ${
        minPrice * 100
      } AND pricing_options__amount lt ${maxPrice}`;
    },
    status: (v) => `state eq "${v}"`,
  };

  return Object.entries(options)
    .filter(
      ([k, v]) =>
        v &&
        String(v).length > 0 &&
        Object.prototype.hasOwnProperty.call(filters, k),
    )
    .map(([key, value]) => filters[key](value))
    .join(" AND ");
};

export type PageType = {
  num: number;
  hasNextPage: boolean;
  items: Listing[];
  count: number;
};

export type ResultType = {
  count: number;
  totalPages: number;
};

export const useBrowsingStore = defineStore("browsing", () => {
  const api = useApi();
  const listingStore = usePublicListings();
  const browseApi = useApiCollection<Listing, ApiListing>("rental/listings", {
    deserializer: PublicListingDeserializer.deserializer,
    isCached: false,
  })

  const itemsPerPage = ref(9);
  const filters: Ref<Partial<FilterOptions>> = ref({ status: "active" });
  const dirty = ref(false);
  const apiResult: Ref<ListResult<Listing> | undefined> = ref();
  const pageNum: Ref<number | undefined> = ref(0);

  const loading = ref(false);

  function getRange(pageNum: number): [number, number] {
    const offset = (pageNum + 1) * itemsPerPage.value - itemsPerPage.value;
    return [offset, offset + itemsPerPage.value];
  }

  const activeFilters = computed(() => {
    return (
      Object.entries(filters.value)
        // filter out empty values
        .filter((item) => !!item[1])
        // we always have a 'state' filter (`status`) that is invisible to client
        .filter((item) => item[0] !== "status")
        //  we always have a 'category' filter (`categoryId`) that is invisible to client
        .filter((item) => item[0] !== "categoryId")
    );
  });

  const filterCount = computed(() => {
    return activeFilters.value.length;
  });

  function setFilters(
    opts: Partial<FilterOptions>,
    { override } = { override: false },
  ) {
    const previousFilters = { ...filters.value };

    if (override) {
      filters.value = { ...opts };
    } else {
      Object.assign(filters.value, opts);
    }
    if (!isEqual(previousFilters, filters.value)) {
      // Only reset page and fetch again if filters changed
      fetch(0);
    }
  }

  async function fetch(newPageNum = 0) {
    let ordering = "";
    const offset = (newPageNum + 1) * itemsPerPage.value - itemsPerPage.value;

    if (filters.value.location) {
      ordering += " -distance";
    }
    loading.value = true;

    const params = {
      limit: itemsPerPage.value,
      offset,
      filter_by: toListFilter({ status: "active", ...filters.value }),
      ordering,
      selected_dates: "",
    };
    if (filters.value.selectedDates) {
      params.selected_dates = filters.value.selectedDates.serialize();
    }

    apiResult.value = await browseApi.list(params);
    pageNum.value = newPageNum;
    dirty.value = false;
    loading.value = false;
  }

  const result: ComputedRef<ResultType> = computed(() => ({
    count: apiResult.value ? apiResult.value.count() : 0,
    totalPages: apiResult.value
      ? Math.ceil(apiResult.value.count() / itemsPerPage.value)
      : 0,
  }));

  const page: ComputedRef<PageType> = computed(() => ({
    num: pageNum.value ?? 0,
    hasNextPage: result.value.totalPages > (pageNum.value ?? 0),
    items: apiResult.value ?? [],
    count: apiResult.value ? apiResult.value.length : 0,
  }));

  const getById = (listingId: Listing["id"]) =>
    page.value.items.find((el) => el.id === listingId);

  const markFavorite = async (listingId: Listing["id"]) => {
    const listing = getById(listingId);
    if (!listing) {
      return;
    }
    const user = useUserStore();
    await user.addFavorite(listingId);
    listing.is_favorite = true;
    return listing;
  };

  const unmarkFavorite = async (listingId: Listing["id"]) => {
    const listing = getById(listingId);
    if (!listing) {
      return;
    }
    const user = useUserStore();
    await user.dropFavorite(listingId);
    listing.is_favorite = false;
    return listing;
  };

  return {
    filters,
    loading,
    dirty,
    page,
    itemsPerPage,
    result,
    getRange,
    filterCount,
    fetch,
    setFilters,
    markFavorite,
    unmarkFavorite,
    getById,
  };
});
