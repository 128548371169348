<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import ListingCard from "@/rental/components/ListingCard.vue";
import EmptyState from "@/components/EmptyState.vue";

import { useBrowsingStore } from "@/store/browse";
import type { Listing } from "@/rental/stores/listings";

const router = useRouter();
const browsingStore = useBrowsingStore();
const { page } = storeToRefs(browsingStore);

function gotoListing(listing: Listing) {
  router.push({
    name: "listing",
    params: {
      id: listing.id,
    },
  });
}
</script>

<template>
  <EmptyState v-if="!page?.items?.length">
    <p>
      We could not find any listings in the selected category and/or for the filters you applied.<br>
    </p>
  </EmptyState>
  <div v-else>
    <div
      class="is-hidden-tablet is-flex is-flex-wrap-wrap is-flex-direction-column flex-gap-1 listing-cards"
    >
      <ListingCard
        v-for="(listing, idx) in page?.items"
        :key="idx"
        :listing="listing"
        @click="gotoListing(listing)"
      />
    </div>
    <div class="is-hidden-mobile is-justify-content-start listing-cards-grid">
      <ListingCard
        v-for="(listing, idx) in page?.items"
        :key="idx"
        :listing="listing"
        size="large"
        @click="gotoListing(listing)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listing-cards-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1.5em;
}
</style>
