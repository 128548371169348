<template>
  <div class="listing-container">
    <div
      v-if="!listing"
      class="loading"
    >
      Loading...
    </div>
    <template v-else>
      <div class="page">
        <ImageCarousel
          :images="listing.images"
          class="is-hidden-mobile"
        />

        <PersonCard
          :person="listing.owner"
          show-rating
        />

        <ListingSection :title="listing.name">
          <div v-html="listingDescription"></div>
        </ListingSection>

        <ListingSection :title="t('listing.feature', 2)">
          <p>What makes this item special?</p>
          <div
            class="mt-2 is-flex is-align-items-center is-justify-content-flex-start flex-gap-1 is-flex-wrap-wrap"
          >
            <Chip
              v-for="(feature, idx) in listing.features"
              :key="idx"
              color="secondary"
              is-rounded
              styling="outlined"
            >
              {{ feature }}
            </Chip>
          </div>
        </ListingSection>

        <ListingSection
          v-if="listing.restrictions.length > 0"
          title="Rules & restrictions"
        >
          <p>Some rules the owner would like you to follow.</p>
          <div class="restrictions">
            <div
              v-for="(restriction, idx) in listing.restrictions"
              :key="idx"
              class="restriction"
            >
              {{ restriction }}
            </div>
          </div>
        </ListingSection>

        <ListingSection title="Details">
          <table class="table is-fullwidth">
            <tbody>
            <tr>
              <td class="has-text-weight-bold">
                Age
              </td>
              <td>{{ listing.age ? t(`listing.age.${listing.age}`) : t('common.not-available') }}</td>
            </tr>
            <tr>
              <td class="has-text-weight-bold">
                Condition
              </td>
              <td>
                {{ listing.condition ? t(`listing.condition.${listing.condition}`) : t('common.not-available') }}
              </td>
            </tr>
            </tbody>
          </table>
        </ListingSection>

        <ListingPositionSection :listing="listing"/>

        <ListingSection
          ref="reviewsSectionRef"
          title="Reviews"
        >
          <p>This is what other people think.</p>
          <div
            v-if="listing.numReviews > 0"
            class="is-flex-direction-column is-flex"
          >
            <div class="is-flex is-flex-direction-row rating-list my-2">
              <div class="rating-number">
                {{ listing.rating }}
              </div>
              <div
                class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
              >
                <div>
                  <FormKit
                    :read-only="true"
                    :value="Number(listing.rating)"
                    type="rating"
                  />
                </div>
                <p class="help">
                  Average of {{ listing.numReviews }} review{{ listing.numReviews === 1 ? '' : 's' }}
                </p>
              </div>
            </div>
            <div class="is-flex is-flex-direction-column">
              <div
                v-for="(review, ix) in reviews"
                :key="review.id"
              >
                <PersonCard
                  :person="review.author"
                  class="my-2"
                  size="small"
                >
                  <template #side>
                    <div class="is-flex-grow-1 is-justify-content-flex-end is-flex">
                      <Rating
                        :show-reviews="false"
                        :value="review.rating"
                      />
                    </div>
                  </template>
                </PersonCard>
                <p
                  v-if="review.comment"
                  class="my-2"
                >
                  {{ review.comment }}
                </p>
                <p
                  v-else
                  class="my-2 has-text-grey-light is-italic"
                >
                  {{ t('review.no-review') }}
                </p>
                <hr v-if="ix + 1 !== listing.numReviews">
              </div>
            </div>
          </div>
          <div
            v-else
            class="has-text-grey-light is-flex is-align-items-center"
          >
            <Icon
              class="mr-2"
              name="base/star"
              size="medium"
              variant="inherit"
            />
            <span>No reviews yet</span>
          </div>
        </ListingSection>
        <DataSource>Listing: {{ listing }}</DataSource>
      </div>

      <div class="aside">
        <ImageCarousel
          :images="listing.images"
          class="is-hidden-tablet"
        />

        <p v-if="isOwner">
          <Chip
            :color="$helpers.statusToColor(listing.status)"
            :has-hover="false"
            class="is-uppercase"
            size="small"
          >
            {{ t(`listing.status.${listing.status}`) }}
          </Chip>
        </p>
        <Editable
          v-if="editMode"
          :value="listing.name"
          render-slot
          @submit="updateName"
        >
          <template #value>
            <p class="title">
              {{ listing.name }}
            </p>
          </template>
          <FormKit
            :value="listing.name"
            name="name"
            type="text"
          />
        </Editable>
        <p
          v-else
          class="title is-4 has-text-weight-bold"
        >
          {{ listing.name }}
        </p>
        <div
          class="mt-2 mb-1 has-text-weight-semibold cursor-pointer"
        >
          <RatingSmall
            v-if="listing.numReviews > 0"
            :value="listing.rating"
            :reviews="listing.numReviews"
            show-review-count-label
            @click="scrollToReviews"
          />
        </div>
        <div class="details mt-1 pt-1 mb-0">
          <p class="location is-capitalized">
            {{ listing.localLocation }}
            <span
              v-if="listing.distance"
              class="distance"
            ><Icon
              name="outlined/my_location"
              variant="inherit"
            />
              {{ $helpers.miles(listing.distance) }} mi</span>
          </p>
          <p v-if="listing.hasPrices">
            <money :amount="listing?.bestPricingOption.amount"/>
            {{ listing?.bestPricingOption.interval }}
            <ProtectionIcon
              v-if="listing.protection === 'deposit'"
              :protection="listing.protection"
              class="mx-2"
            >
              •
            </ProtectionIcon>
          </p>
          <p
            v-if="listing.hasMultiplePrices"
            class="discount"
          >
            {{ $helpers.percent(listing.discount()) }}
          </p>
        </div>
        <div
          v-if="listing?.status === 'active'"
          class="field is-grouped is-justify-content-space-around mb-5 mt-4"
        >
          <div class="control is-flex-grow-1">
            <FavoriteButtonButton
              :is-favorite="listing.isFavorite"
              :listing-id="listing.id"
              class="is-fullwidth"
              with-label
            />
          </div>
          <div class="control is-flex-grow-1">
            <ShareButton :listing="listing"/>
          </div>
        </div>
        <div class="field">
          <div
            v-if="isOwner"
            class="control is-flex-grow-1 mt-5"
          >
            <PsButton
              v-if="editMode"
              class="is-fullwidth"
              color="secondary"
              @click.prevent="toggleEditMode"
            >
              View mode
            </PsButton>
            <PsButton
              class="is-fullwidth"
              color="secondary"
              styling="outlined"
              @click.prevent="gotoListingEditor"
            >
              <Icon
                name="outlined/edit"
                variant="secondary"
              />
              {{ t('listing.edit-listing') }}
            </PsButton>
          </div>
        </div>
        <div class="field">
          <div
            v-if="isOwner && listing.status == 'active'"
            class="control is-flex-grow-1"
          >
            <Confirm
              ref="confirmButton"
              :message="t('listing.confirms.archive.message', { listing: listing.name })"
              :confirm-button-title="t('listing.confirms.archive.action')"
              @on-confirmed="archiveListing"
            >
              <template #button="{ handler }">
                <PsButton
                  :disabled="archiveButtonLoading"
                  :is-loading="archiveButtonLoading"
                  class="is-fullwidth"
                  color="secondary"
                  styling="outlined"
                  @click.prevent="handler"
                >
                  <Icon
                    name="outlined/archive"
                    variant="secondary"
                  />
                  {{ t('listing.confirms.archive.action') }}
                </PsButton>
              </template>
            </Confirm>
          </div>
        </div>
        <Booker
          v-if="!isOwner"
          :is-authenticated="isAuthenticated"
          :is-owner="isOwner"
          :listing="listing"
          :selected-dates="selectedDateRange"
        />
        <OwnerPriceBreakdown
          v-if="isOwner"
          :listing="listing"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {type Ref, computed, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

import Booker from "@/components/Booker.vue";
import Confirm from "@/components/Confirm.vue";
import Editable from "@/components/Editable.vue";
import FavoriteButtonButton from "@/rental/components/FavoriteButton.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import Rating from "@/components/Rating.vue";
import ShareButton from "@/components/ShareButton.vue";
import PersonCard from "@/components/people/PersonCard.vue";
import ListingPositionSection from "@/components/rental/ListingPositionSection.vue";
import ListingSection from "@/components/rental/listing/ListingSection.vue";
import Chip from "@/elements/Chip.vue";
import ProtectionIcon from "@/elements/ProtectionIcon.vue";
import RatingSmall from "@/components/RatingSmall.vue";
import OwnerPriceBreakdown from "@/components/rental/listing/OwnerPriceBreakdown.vue";

import {useLoading} from "@/composables/loaders";
import type {AnyDateType} from "@/helpers/formats";
import {useApi} from "@/plugins/Api";
import {useBrowsingStore} from "@/store/browse";
import {usePublicListings, usePrivateListings, type Listing} from "@/rental/stores/listings";
import {computedAsync, until} from "@vueuse/core";
import {useI18n} from "vue-i18n";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";
import {useMarkdown} from "@/composables/dom";

const api = useApi();
const {t} = useI18n();

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  selectedDates: {
    type: Array,
    default: undefined,
    required: false,
  },
});

const router = useRouter();
const route = useRoute();

const store = usePublicListings();
const privateStore = usePrivateListings();
const session = useSession();

const {isAuthenticated, actor} = storeToRefs(session);

const accountStore = usePrivateListings();
const browsingStore = useBrowsingStore();

const selectedDateRange: Ref<[AnyDateType, AnyDateType]> = ref(
  props.selectedDates as [AnyDateType, AnyDateType],
);
if (browsingStore.filters.selectedDates?.getApiFormat()?.length) {
  selectedDateRange.value =
    browsingStore.filters.selectedDates.getApiFormat() as [
      AnyDateType,
      AnyDateType,
    ];
}

const confirmButton = ref(null);
const editMode = ref(false);
const listing: Ref<Listing | undefined> = ref(undefined);

const { loading: privateStoreLoading } = storeToRefs(accountStore);

const reviewsSectionRef = ref(null);
const reviews = computedAsync(async () => {
  return await store.reviews(props.id);
}, []);

const isOwner = computed(() => {
  return actor.value ? listing.value?.isOwner(actor.value) : false
});

const listingDescription = computed(() => {
  if (listing.value?.description) {
    return useMarkdown(listing.value.description).value;
  }
})

watch(
  () => listing.value,
  async () => {
    if (!route.meta?.breadcrumb) {
      return;
    }
    route.meta.breadcrumb.label.value = listing.value?.name;
  },
);

function toggleEditMode() {
  editMode.value = !editMode.value;
}

function gotoListingEditor() {
  router.push({
    name: "updateListing",
    params: {listingId: props.id},
  });
}

function scrollToReviews() {
  reviewsSectionRef.value?.$el?.scrollIntoView?.({behavior: "smooth"});
}

function updateName(data) {
  patchListing(data);
}

const {trigger: archiveListing, isLoading: archiveButtonLoading} = useLoading(
  async () => {
    await privateStore.archive(props.id);
  },
);

async function patchListing(patch) {
  const putData = {
    ...listing.value,
    ...patch,
  };
  await api.c.listings.put(props.id, putData);
}

async function getListing() {
  if (isAuthenticated.value) {
    await until(privateStoreLoading).toBe(false);
    if (accountStore.hasId(props.id)) {
      listing.value = await accountStore.getById(props.id);
      return;
    }
  }
  listing.value = await store.ensureById(props.id);
}

await getListing();
await until(listing).not.toBeUndefined();
</script>

<style lang="scss" scoped>
@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.fullname {
  font-weight: 600;
}

.listing-container {
  display: flex;
  margin-top: 2em;
  flex-direction: row;
  gap: 3em;

  @include touch {
    flex-direction: column-reverse;
    gap: 0;
  }

  .page {
    flex-grow: 1;

    section {
      margin: 2em 0;
    }
  }

  .aside {
    min-width: 320px;
    width: 30%;

    @include touch {
      width: 100%;
    }

    p.title {
      margin: 0;
      margin-top: 0.5rem;

      @include touch {
        margin-top: 1em;
      }
    }

    .details {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin: 0.5em 0 1.5em 0;

      & > * {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
      }

      .location {
        color: $gray-muted;
      }

      .distance {
        display: flex;
        justify-content: center;
        vertical-align: center;
        align-items: center;
        gap: 4px;
        color: $gray-muted;
      }
    }
  }
}

.restrictions {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: $gray-mist;

  .restriction {
    padding: 20px 24px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-smoke;
    }
  }
}

.rating-list {
  gap: 16px;

  .field {
    margin-bottom: 0 !important;
  }

  .rating-number {
    font-family: 'RobotoSlab', Helvetica;
    font-size: 3em;
    font-weight: 700;
  }
}
</style>
