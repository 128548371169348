<template>
  <FormKit id="forgotPassword" v-model="formData" :disabled="loading" submit-label="Request new password" type="form"
    @submit="handleForgotPassword">
    <div class="field">
      <FormKit name="email" placeholder="Email address" type="email" validation="required|email"
        validation-label="Email address" />
    </div>
  </FormKit>
</template>

<script setup>
import { FormKit } from "@formkit/vue";
import { ref } from 'vue';

const emit = defineEmits(["forgot-password"]);

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const formData = ref({});

function handleForgotPassword() {
  emit("forgot-password", {
    formData: formData.value,
  });
}
</script>
