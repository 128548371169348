<template>
  <div>
    <p class="title is-3 mb-4">
      Summary
    </p>
    <p class="my-4">
      Have a last look at your listing before you publish.
    </p>

    <div class="mt-6 mb-4">
      <div
        v-for="(step, ix) in steps.slice(0, -1)"
        :key="step.label"
        class="publish-step my-4"
      >
        <div class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap">
          <div>
            <div class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap">
              <Icon
                v-if="step.isValid ? step.isValid : false"
                name="done"
                variant="done"
              />
              <Icon
                v-else
                name="todo"
                variant="danger"
              />
              <p class="title is-5">
                {{ step.label }}
              </p>
            </div>
            <p class="help">
              {{ step.toString(values) }}
            </p>
          </div>
          <PsButton
            class="is-pulled-right"
            color="secondary"
            @click.prevent="gotoStep(ix)"
          >
            Edit
          </PsButton>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {Step} from "@/flows/createListing/steps";
import {useStepContext} from "@/rental/composables/listingEditor";

const emits = defineEmits(["stepChanged"]);
const {values} = useStepContext();

defineProps({
  steps: {
    type: Array<Step>,
    default: () => [],
  },
});

function gotoStep(ix: number) {
  emits("stepChanged", {stepIndex: ix + 1});
}
</script>

<style scoped lang="scss">
.publish-step {
  & > div {
    justify-content: space-between;
  }
}
</style>
