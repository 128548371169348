<template>
  <div>
    <p class="title is-3 mb-4">
      Availability
    </p>
    <p class="my-4">
      Configure the days your item is available and set a time window for pickup and drop-off.
    </p>

    <div class="mt-6 mb-4">
      <FormKit
        label="My rental is available on"
        help="Select or deselect the days to configure the availability"
        name="availableWeekdays"
        type="weekdays"
        highlight-unselected="true"
        show-summary-text="true"
        summary-text-prefix="Available"
      />

      <FormKit
        name="pickupDropOffTimeRange"
        validation="required|min:2"
        type="list"
      >
        <div class="mt-2 mb-6 is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap">
          <FormKit
            type="time"
            step="600"
            validation-label="Pickup time"
            validation="required"
          >
            <template #label>
              <label class="formkit-label label">From <span class="form-required-label">(Required)</span></label>
            </template>
          </FormKit>
          <FormKit
            type="time"
            step="600"
            validation-label="Drop-off time"
            validation="required"
          >
            <template #label>
              <label class="formkit-label label">Till <span class="form-required-label">(Required)</span></label>
            </template>
          </FormKit>
        </div>
      </FormKit>

      <p class="title is-4 mt-6 mb-4">
        Blackout dates
      </p>
      <p class="mb-4">
        Set specific dates when your rental won&apos;t be available, such as during planned holidays or other
        commitments.
      </p>
      <div
        v-if="!blackOutDatesFormattedGroupedByConsecutive.length"
        class="mb-8 is-flex is-align-items-center is-justify-content-space-between flex-gap-1 is-flex-wrap-wrap"
      >
        <p>
          No blackout dates selected yet
        </p>

        <PsButton
          color="secondary"
          @click.prevent="openBODModal"
        >
          Set blackout dates
        </PsButton>
      </div>
      <div
        v-if="blackOutDatesFormattedGroupedByConsecutive.length"
        class="mb-8 is-flex is-align-items-center is-justify-content-space-between flex-gap-1 is-flex-wrap-wrap"
      >
        <div>
          <p
            v-for="(unavailableDayGroup, index) in blackOutDatesFormattedGroupedByConsecutive"
            :key="index"
          >
            {{ unavailableDayGroup }}
          </p>
        </div>

        <PsButton
          color="secondary"
          icon="outlined/edit"
          @click.prevent="openBODModal"
        />
      </div>
    </div>

    <Modal
      v-model="blackOutDatesActive"
      title="Set blackout dates"
    >
      <template #default>
        <FormKit
          :auto-apply="true"
          :disabled-week-days="negateDays(values?.availableWeekdays || [])"
          :inline="true"
          :multi-dates="true"
          help="Click on a day to make that day unavailable for pick-up and drop-off."
          name="explicitUnavailableDays"
          type="datepicker"
        />
      </template>
      <template #foot>
        <PsButton @click.prevent="closeBODModal">
          Save blackout dates
        </PsButton>
      </template>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import {ref, computed} from "vue";

import Modal from "@/components/Modal.vue";

import {useModalActions} from "@/composables/modal";
import Formats from "@/helpers/formats";
import {useStepContext} from "@/rental/composables/listingEditor";

const {values} = useStepContext();

const blackOutDatesActive = ref(false);

const blackOutDatesFormattedGroupedByConsecutive = computed(() => {
  const blackOutDates = [...(values?.value.explicitUnavailableDays ?? [])];

  if (!blackOutDates?.length) return [];

  const groupedBlackOutDates = blackOutDates
    .sort((a, b) => (Formats.isBefore(a, b) ? -1 : 1))
    .reduce(
      (acc: string[][], date: string) => {
        const group = acc[acc.length - 1];
        if (Formats.diff(date, group[group.length - 1] || date, "day") > 1) {
          acc.push([date]);
        } else {
          group.push(date);
        }
        return acc;
      },
      [[]],
    );

  // Format for user display
  return groupedBlackOutDates.map((unavailableDayGroup: string[]) =>
    unavailableDayGroup?.length === 1
      ? Formats.daySystemWithoutTimezoneFormat(unavailableDayGroup[0])
      : `${Formats.daySystemWithoutTimezoneFormat(unavailableDayGroup[0])} - ${Formats.daySystemWithoutTimezoneFormat(unavailableDayGroup[unavailableDayGroup.length - 1])}`,
  );
});

const [openBODModal, closeBODModal] = useModalActions(blackOutDatesActive);

function negateDays(ds: string[]) {
  Formats.daysToInts(Formats.negateDays(ds));
}
</script>
