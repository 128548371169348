<template>
  <div
    class="booking-actions is-flex is-flex-direction-row is-align-items-center flex-gap-1 is-flex-wrap-wrap"
  >
    <div
      v-for="spec in buttons"
      :key="spec.action"
    >
      <component
        :is="spec.component"
        v-if="spec.component && spec.action in actions"
        :booking="booking"
        @click.prevent="onClick(spec.action, $event)"
      />
      <component
        :is="spec.component"
        v-else-if="spec.component"
        :booking="booking"
      />
      <PsButton
        v-else
        :ref="spec.action"
        :color="spec.variant"
        :disabled="spec.disabled"
        @click.prevent="onClick(spec.action, $event)"
      >
        <Icon
          v-if="spec.icon"
          v-bind="spec.icon"
        />
        {{ spec.label }}
      </PsButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import CancelBooking from "@/components/rental/booking-actions/CancelBooking.vue";
import CancelRequest from "@/components/rental/booking-actions/CancelRequest.vue";
import WriteReview from "@/components/rental/booking-actions/WriteReview.vue";

import type { ColorType } from "@/elements/PsButton.props";
import { ApiValidationError } from "@/errors";
import { useHelpers } from "@/plugins/Form";
import { useAppNotifications } from "@/plugins/notifications";
import type { Booking } from "@/store/rental/models";
import {useBookings} from "@/rental/stores/bookings";

const store = useBookings();
const helpers = useHelpers();
const notify = useAppNotifications();
const router = useRouter();

interface Button {
  action: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  label?: string;
  variant?: ColorType;
  disabled?: boolean;
  icon?: {
    name: string;
    variant?: string;
  };
}

interface ButtonByStatus {
  owner: {
    [status: string]: Button[];
  };
  renter: {
    [status: string]: Button[];
  };
}

const BUTTONS: ButtonByStatus = {
  owner: {
    review: [
      {
        label: "Reject booking",
        action: "rejectRequest",
        variant: "secondary",
      },
      {
        label: "Accept booking",
        action: "acceptRequest",
        variant: "primary",
      },
    ],
    pending: [
      {
        component: CancelBooking,
        action: "cancel",
      },
    ],
    open: [
      {
        component: CancelBooking,
        action: "cancel",
      },
    ],
    locked: [
      {
        label: "Cancel booking",
        action: "cancel",
        variant: "secondary",
        disabled: true,
      },
    ],
    ready_for_pickup: [
      {
        label: "Confirm pickup",
        action: "confirmPickup",
        variant: "success",
      },
    ],
    waiting_pickup_lender: [
      {
        label: "Confirm pickup",
        action: "confirmPickup",
        variant: "success",
      },
    ],
    waiting_deliver_lender: [
      {
        label: "Confirm return",
        action: "confirmReturn",
        variant: "success",
      },
    ],
    returned: [
      {
        component: WriteReview,
        action: "writeReview",
      },
    ],
    closed: [
      {
        component: WriteReview,
        action: "writeReview",
      },
    ],
    disputed: [
      {
        label: "Send message",
        action: "contactRenter",
        variant: "secondary",
        icon: {
          name: "outlined/forum",
          variant: "secondary",
        },
      },
    ],
    rejected: [
      {
        label: "Send message",
        action: "contactRenter",
        variant: "secondary",
        icon: {
          name: "outlined/forum",
          variant: "secondary",
        },
      },
    ],
  },
  renter: {
    review: [
      {
        component: CancelRequest,
        action: "cancel",
      },
    ],
    pending: [
      {
        component: CancelBooking,
        action: "cancel",
      },
    ],
    open: [
      {
        component: CancelBooking,
        action: "cancel",
      },
    ],
    locked: [
      {
        label: "Cancel booking",
        action: "cancel",
        variant: "secondary",
        disabled: true,
      },
    ],
    ready_for_pickup: [
      {
        label: "Confirm pickup",
        action: "confirmPickup",
        variant: "success",
      },
    ],
    waiting_pickup_renter: [
      {
        label: "Confirm pickup",
        action: "confirmPickup",
        variant: "success",
      },
    ],
    active: [
      {
        label: "Return item",
        action: "confirmReturn",
        variant: "success",
      },
    ],
    waiting_deliver_renter: [
      {
        label: "Return item",
        action: "confirmReturn",
        variant: "success",
      },
    ],
    waiting_deliver_lender: [
      {
        component: WriteReview,
        action: "writeReview",
      },
    ],
    returned: [
      {
        component: WriteReview,
        action: "writeReview",
      },
    ],
    closed: [
      {
        component: WriteReview,
        action: "writeReview",
      },
    ],
    disputed: [
      {
        label: "Send message",
        action: "contactOwner",
        variant: "secondary",
        icon: {
          name: "outlined/forum",
          variant: "secondary",
        },
      },
    ],
    rejected: [
      {
        label: "Send message",
        action: "contactOwner",
        variant: "secondary",
        icon: {
          name: "outlined/forum",
          variant: "secondary",
        },
      },
    ],
  },
};

const emits = defineEmits(["bookingAction"]);

const props = withDefaults(
  defineProps<{
    booking: Booking;
    status: string;
    perspective: "owner" | "renter";
    disableEmitAction?: boolean;
  }>(),
  {
    perspective: "renter",
    disableEmitAction: false,
  },
);

const buttons = computed((): Button[] => {
  let matchingButtons: Button[] = [];
  try {
    matchingButtons = BUTTONS[props.perspective][props.status] ?? [];
  } catch {
    // no-op
  }
  return matchingButtons;
});

const actions: Record<string, (booking: Booking) => Promise<void>> = {
  async acceptRequest(booking: Booking) {
    await store.acceptBooking(booking.id);
  },
  async rejectRequest(booking: Booking) {
    await store.rejectBooking(booking.id);
  },
  async cancel(booking: Booking) {
    try {
      await store.cancelBooking(booking.id);
    } catch (err) {
      if (err instanceof ApiValidationError) {
        notify.error(err.message);
      }
    }
  },
  async confirmPickup(booking: Booking) {
    await store.confirmPickup(booking.id);
  },
  async confirmReturn(booking: Booking) {
    await store.confirmReturn(booking.id);
  },
  async contactRenter(booking: Booking) {
    const chatId =
      booking.chatId ??
      (await store.createBookingChat(booking.id, booking.renter.id)).chatId;

    if (!chatId) return;

    await router.push({
      name: "chat",
      params: {
        chatId,
      },
    });
  },
  async contactOwner(booking: Booking) {
    const chatId =
      booking.chatId ??
      (await store.createBookingChat(booking.id, booking.lender.id)).chatId;

    if (!chatId) return;

    await router.push({
      name: "chat",
      params: {
        chatId,
      },
    });
  },
};

function onClick(action: string, event: Event) {
  const node = { $el: event.target };
  event.stopPropagation();
  if (!props.disableEmitAction) {
    emits("bookingAction", {
      action,
      booking: props.booking,
      node,
    });
  } else {
    helpers.useLoading(node, async () => {
      await actions[action](props.booking);
    });
  }
}
</script>

<style lang="scss" scoped>
.booking-actions {
  height: 100%;
}
</style>
