<script lang="ts" setup>
import { useArrayReduce } from "@vueuse/core";
import { type Ref, computed } from "vue";

import DepositModal from "@/components/rental/explainers/DepositModal.vue";
import InsuranceModal from "@/components/rental/explainers/InsuranceModal.vue";
import Money from "@/elements/Money.vue";
import ProtectionIcon from "@/elements/ProtectionIcon.vue";

import type { Booking, BookingCost } from "@/rental/stores/bookings";
import { CostType, costTypesWithoutProtection } from "@/store/rental/types";
import {getCurrentUser} from "@/app/stores/session";

const props = defineProps<{
  booking: Booking;
}>();

const currentUser = getCurrentUser();
const useSum = (l: Ref<BookingCost[]>) =>
  useArrayReduce(l, (sum, val) => sum + val.amount, 0);

const isOwner = computed(() => props.booking.isOwner(currentUser.id));

const regularCosts = computed(() =>
  props.booking.costsBy(currentUser.id, costTypesWithoutProtection),
);
const protectionCosts = computed(() =>
  props.booking.costsBy(currentUser.id, [CostType.DEPOSIT, CostType.INSURANCE]),
);
const nonRefundableCosts = computed(() =>
  props.booking.costsBy(currentUser.id, [
    ...costTypesWithoutProtection,
    CostType.INSURANCE,
  ]),
);

const nonRefundableTotal = useSum(nonRefundableCosts);
</script>

<template>
  <div class="pricing">
    <div
      v-for="(pricing, idx) in regularCosts"
      :key="idx"
      class="price-record"
    >
      <label v-if="pricing.costType === CostType.RENTAL_COST"><b><money :amount="pricing.ppu" /></b> x {{ pricing.units }}
        {{ $t(`units.${pricing.unitType}`, pricing.units) }}</label>
      <label v-else>{{ $t(`booking.pricing-${pricing.costType}`) }}</label>
      <money :amount="pricing.amount" />
    </div>

    <hr class="my-2">
    <div
      v-for="pricing in protectionCosts"
      :key="pricing.id"
      class="price-record price-record-protection"
    >
      <label>
        <ProtectionIcon
          :protection="pricing.costType === 'insurance' ? 'insurance' : 'deposit'"
          hide-label
        />
        <span class="pl-1 has-text-weight-semibold">{{
          $t(`booking.pricing-${pricing.costType}`)
        }}</span>
        <InsuranceModal
          v-if="pricing.costType === 'insurance'"
          v-slot="slotProps"
          :is-owner="isOwner"
        >
          <Icon
            name="outlined/help"
            variant="secondary"
            is-inline
            @click.prevent="slotProps.openModal"
          />
        </InsuranceModal>
        <DepositModal
          v-if="pricing.costType === 'deposit'"
          v-slot="slotProps"
        >
          <Icon
            name="outlined/help"
            variant="secondary"
            is-inline
            @click.prevent="slotProps.openModal"
          />
        </DepositModal>
      </label>
      <money :amount="pricing.amount" />
    </div>

    <div class="price-record has-text-weight-semibold total mt-2 mb-0 pb-0">
      <label>Total</label>
      <money :amount="nonRefundableTotal" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pricing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  gap: 0;
  line-height: 2em;

  .total {
    font-size: 16px;
    line-height: 24px;
    padding: 0.5em 0;
  }

  .price-record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    label {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      gap: 0 4px;
    }

    &.price-record-protection {
      border-bottom: 1px solid var(--gray-smoke);
      padding: 1em 0;

      label .description {
        color: var(--gray-muted);
      }
    }
  }
}
</style>
