import type BookingRequestedMessage from "@/components/chat/BookingRequestedMessage.vue";

import type { Actor } from "@/io/account";

export type ResourceType = "BookingRequestMessage" | "TextMessage";

export interface Message {
  id: string;
  created_at: string;
  body: string;
  resourcetype: ResourceType;
  author: Actor;
}

export interface BookingRequestMessage extends Message {
  booking: string;
}

export type MessageType = Message | typeof BookingRequestedMessage;

export interface Chat {
  id: string;
  created_at: string;
  updated_at: string;
  last_message: MessageType;
}

const serializer = (d) => d;

const deserializer = (d) => ({
  ...d,
  createdAt: new Date(d.created_at),
  updatedAt: new Date(d.updated_at),
});

export default {
  serializer,
  deserializer,
};
