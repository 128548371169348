import type {PricingOption} from "@/rental/types";

export enum BookingInterval {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}

export enum CostUnitType {
  hour = "hour",
  day = "day",
  month = "month",
  week = "week",
  rental = "rental",
}

export enum CostType {
  RENTAL_COST = "rental_cost",
  INSURANCE = "insurance",
  DEPOSIT = "deposit",
  TAX = "tax",
  FEE = "fee",
}

export const costTypesWithoutProtection = [
  CostType.RENTAL_COST,
  CostType.FEE,
  CostType.TAX,
];

export interface IListing {
  id: string;
  name: string;
  pricing_options: PricingOption[];

  rating: number;
  numReviews: number;
}

export type ProtectionType = "none" | "deposit" | "insurance";
export type ListingStatus = "active" | "draft";
