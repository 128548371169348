<template>
  <span
    v-if="fontBased"
    :class="[fontClass, sizeClass, variantClass, backedClass, inlineClass]"
  >{{
    fontName
  }}</span>
  <span
    v-else
    :class="['icon', variantClass, sizeClass, backedClass, inlineClass]"
  >
    <img
      v-if="staticBased"
      :src="staticUrl"
    >
    <component
      :is="iconComponent"
      v-else
      :size="sizeNumber"
    />
  </span>
</template>

<script setup lang="ts">
import { computed, markRaw } from "vue";
import Bell from "vue-material-design-icons/Bell.vue";
import Calendar from "vue-material-design-icons/Calendar.vue";
import CreditCard from "vue-material-design-icons/CreditCard.vue";
import CurrencyUsd from "vue-material-design-icons/CurrencyUsd.vue";
import File from "vue-material-design-icons/File.vue";
import Forum from "vue-material-design-icons/Forum.vue";
import Fragile from "vue-material-design-icons/GlassFragile.vue";
import Favorite from "vue-material-design-icons/Heart.vue";
import Unfavorite from "vue-material-design-icons/HeartOutline.vue";
import HelpCircleOutline from "vue-material-design-icons/HelpCircleOutline.vue";
import Loading from "vue-material-design-icons/Loading.vue";
import Menu from "vue-material-design-icons/Menu.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import Power from "vue-material-design-icons/Power.vue";
import SendOutline from "vue-material-design-icons/SendOutline.vue";
import Share from "vue-material-design-icons/Share.vue";
import Protection from "vue-material-design-icons/ShieldCheck.vue";
import ListView from "vue-material-design-icons/ViewAgendaOutline.vue";
import Web from "vue-material-design-icons/Web.vue";

import CircleFilled from "@/icons/CircleFilled.vue";
import CircleFilledPrimary from "@/icons/CircleFilledPrimary.vue";
import CircleProgress from "@/icons/CircleProgress.vue";
import CircleStale from "@/icons/CircleStale.vue";
import Close from "@/icons/Close.vue";
import Deposit from "@/icons/Deposit.vue";
import Done from "@/icons/Done.vue";
import Listing from "@/icons/Listing.vue";
import Search from "@/icons/Search.vue";
import Star from "@/icons/Star.vue";
import User from "@/icons/User.vue";

const icons = {
  search: Search,
  star: Star,
  close: Close,
  user: User,
  listing: Listing,
  bell: Bell,
  circleStale: CircleStale,
  circleProgress: CircleProgress,
  circleFilled: CircleFilled,
  circleFilledPrimary: CircleFilledPrimary,
  todo: CircleProgress,
  done: Done,
  plus: PlusIcon,
  list: ListView,
  calendar: Calendar,
  signout: Power,
  loading: Loading,
  file: File,
  share: Share,
  deposit: Deposit,
  creditCard: CreditCard,
  protection: Protection,
  help: HelpCircleOutline,
  favorite: Favorite,
  unfavorite: Unfavorite,
  conversation: Forum,
  send: SendOutline,
  menu: Menu,
  web: Web,
  currencyUsd: CurrencyUsd,
};

const svgIcons = {
  google: "/static/auth/Google.svg",
  apple: "/static/auth/Apple.svg",
  facebook: "/static/auth/Facebook.svg",
};

const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  variant: {
    type: String,
    default: "primary",
  },
  size: {
    type: String,
    default: "medium",
  },
  isBacked: {
    type: Boolean,
    default: false,
  },
  isInline: {
    type: Boolean,
    default: false,
  },
});

const variantClass = computed(() => {
  return `is-${props.variant}`;
});

const sizeClass = computed(() => {
  return `is-${props.size}`;
});

const backedClass = computed(() => {
  return props.isBacked ? "is-backed" : null;
});

const inlineClass = computed(() => {
  return props.isInline ? "is-inline" : null;
});

const sizeNumber = computed(() => {
  return (
    {
      medium: 24,
      xlarge: 44,
    }[props.size] || 24
  );
});

function getIcon(name) {
  try {
    const result = icons[name] || Fragile;
    return result;
  } catch {
    return Fragile;
  }
}

const iconComponent = computed(() => {
  return markRaw(getIcon(props.name));
});

const fontBased = computed(() => {
  if (!props.name) {
    return false;
  }
  return props.name.indexOf("/") > -1;
});

const staticBased = computed(() => {
  return props.name ? svgIcons[props.name] !== undefined : false;
});

const staticUrl = computed(() => {
  return svgIcons[props.name];
});

const fontName = computed(() => {
  return props.name.split("/")[1];
});

const fontClass = computed(() => {
  const fontType = props.name.split("/")[0];
  if (fontType === "base") {
    return ["icon", "material-icons"];
  } else {
    return ["icon", `material-icons-${fontType}`];
  }
});
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

span.icon {
  position: relative;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  color: $primary;
  justify-content: center;
  height: 40px;
  line-height: 0;

  &.material-icons-outlined {
    line-height: 1;
  }

  path {
    fill: $primary;
  }

  &.is-inherit {
    color: inherit;

    path {
      fill: inherit;
    }
  }

  &.is-primary {
    color: $primary;
    path {
      fill: $primary;
    }
  }

  &.is-secondary {
    color: $gray-muted;
    path {
      fill: $gray-muted;
    }
    &.button {
      background-color: lighten($gray-muted, 40%);
    }
    &.is-backed {
      background-color: lighten($gray-muted, 40%);
    }
  }

  &.is-secondary-inactive {
    color: $gray-smoke;
    path {
      fill: $gray-smoke;
    }
    &.button {
      background-color: lighten($gray-smoke, 40%);
    }
    &.is-backed {
      background-color: lighten($gray-smoke, 40%);
    }
  }

  &.is-backed {
    background-color: $peach-200;
    border-radius: 50%;
    padding: 1em !important;
  }

  &.is-white {
    color: white;

    path {
      fill: white;
    }
  }

  &.is-dark {
    color: $gray-charcoal;

    path {
      fill: $gray-charcoal;
    }
  }

  &.is-success {
    color: $mint;
    path {
      fill: $mint;
    }

    &.is-backed {
      background-color: $green-200;

      path {
        fill: $green-500;
      }
    }
  }

  &.is-inactive {
    color: $inactive;
    path {
      fill: $inactive;
    }
  }

  &.is-done {
    color: $done;
    path {
      fill: $done;
    }
  }

  &.is-small {
    font-size: 16px;
    height: 24px;
  }

  &.is-medium {
    font-size: 24px;
    height: 24px;
    width: 24px;
  }

  &.is-large {
    font-size: 48px;
    padding: 0.5em;
    height: 48px !important;
    width: 48px !important;

    svg {
      height: 48px !important;
      width: 48px !important;
    }
  }

  &.is-xlarge {
    font-size: 60px;
    height: 80px !important;
    width: 80px !important;

    svg {
      height: 80px !important;
      width: 80px !important;
    }
  }

  &.is-inline {
    display: inline;
  }
}

svg {
  fill: inherit;
  width: auto;
  height: auto;
  position: absolute;
}
</style>
<style lang="scss">
.icon {
  .material-design-icon {
    max-height: 24px;
    max-width: 24px;

    .material-design-icon__svg {
      display: inline-flex;
      max-height: 24px;
      max-width: 24px;
    }
  }

  &.is-large {
    .material-design-icon {
      max-height: 48px !important;
      max-width: 48px !important;

      .material-design-icon__svg {
        max-height: 48px !important;
        max-width: 48px !important;
        height: 48px !important;
        width: 48px !important;
      }
    }
  }

  &.is-xlarge {
    .material-design-icon {
      max-height: 80px !important;
      max-width: 80px !important;

      .material-design-icon__svg {
        max-height: 80px !important;
        max-width: 80px !important;
        height: 80px !important;
        width: 80px !important;
      }
    }
  }
}
</style>
