<template>
  <p class="title">
    Choose a cause
  </p>
  <div class="content">
    <p>
      Part of the deal and what makes renting with us feel so good, is that you’re not just renting
      the most awesome items, and therefor saving the environment. You also help us to support great
      causes!
    </p>
    <p>
      Choose one of the causes below and we will donate on your behalf.
    </p>
  </div>
  <div
    v-if="!causes.ready"
    class="loading"
  >
    Loading...
  </div>
  <div
    v-else
    class="causes"
  >
    <CardBase
      v-for="cause in causes.causes"
      :key="cause.id"
      :class="[
        'cause',
        'is-clickable',
        cause.id === modelValue?.id ? 'is-checked' : null,
        cause.icon_name.indexOf('sync') > -1 ? 'is-random' : null,
      ]"
      size="medium-square"
      @click="emit('update:modelValue', cause)"
    >
      <template #preview>
        <img :src="cause.image">
      </template>
      <template #overlay-top>
        <Icon
          name="base/check"
          size="medium"
        />
      </template>
      <template #overlay-bottom>
        <Icon
          :name="cause.icon_name"
          class="my-2"
          size="large"
          variant="inherit"
        />
        {{ cause.name }}
      </template>
    </CardBase>
  </div>
  <div class="callout my-6">
    Don’t worry, the donation will not be added as extra payment. This one is on us!
  </div>
  <div
    class="is-flex is-align-items-center flex-gap-1 is-flex-wrap-wrap is-justify-content-end px-8"
  >
    <PsButton
      color="secondary"
      @click.prevent="emit('previous')"
    >
      <Icon
        name="base/chevron_left"
        variant="inherit"
      />
      Back
    </PsButton>
    <PsButton @click.prevent="emit('next')">
      Next: Confirmation
    </PsButton>
  </div>
</template>

<script setup>
import CardBase from "@/components/CardBase.vue";

import useCauses from "@/store/causes.ts";

const emit = defineEmits(["previous", "next", "update:modelValue"]);

defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  booking: {
    type: Object,
    default: () => {},
  },
});

const causes = useCauses();
await causes.fetchCauses();
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.causes {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.75em;
  width: 100%;
  text-align: center;

  .cause {
    border-radius: 8px;
    color: var(--gray-snow);
    font-size: 15px;
    font-weight: 600;

    background: $gray-A10;
    border: 1px solid rgba(6, 30, 62, 0.1);

    &.is-random {
      color: $gray-slate;
    }

    &.is-checked {
      /* Gray Alpha/Gray A10 */
      box-shadow:
        0px 0px 0px 2px #ffffff,
        0px 0px 0px 4px #ff6d65;
    }

    .card-details {
      display: none !important;
    }

    .overlay-top .icon {
      padding: 0.5em;
      background-color: var(--primary);
      color: inherit;
      border-radius: 1em;
      height: 40px;
      width: 40px;
    }

    &.is-checked .overlay-top {
      opacity: 1 !important;

      .icon {
        background-color: var(--primary);
        color: inherit;
      }
    }

    :deep(.overlay-bottom) {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }

    input {
      opacity: 0;

      &:checked + .cause-content {
        opacity: 100%;

        img {
          filter: invert(0);
        }
      }
    }

    &:hover {
      background-color: var(--peach-200);
    }

    &.is-active {
      background-color: var(--primary);
      color: var(--gray-smoke);
    }
  }
}
</style>
