<script lang="ts" setup>
import { computed } from "vue";

import formats from "@/helpers/formats";
import {
  type AccountNotification,
  useNotifications,
} from "@/account/stores/notifications";
import Chip from "@/elements/Chip.vue";

const props = defineProps<{
  notification: AccountNotification;
}>();

const notifications = useNotifications();

const createdAt = computed(() =>
  // If difference is larger than
  formats.diff(new Date(), props.notification.createdAt, "month") <= 1
    ? formats.sinceThen(props.notification.createdAt)
    : formats.dateTimeLocalFormat(props.notification.createdAt, "MMM D, YYYY"),
);
</script>

<template>
  <div class="notifications-item">
    <div class="notification-side">
      <slot name="side" />
    </div>

    <div class="notification-main">
      <div class="notification-header">
        <Chip
          v-if="!notification.readAt"
          class="mr-2"
          size="small"
        >
          NEW
        </Chip><span class="date">{{ createdAt }}</span>
      </div>
      <div>
        <p class="title is-6 mt-0 mb-2">
          {{ notification.subject }}
        </p>
        <p class="body">
          <vue3-markdown-it
            v-if="notification.body"
            :source="notification.body"
          />
        </p>
      </div>
      <div class="resource-actions">
        <slot name="actions" />
      </div>
    </div>

    <div class="is-flex is-align-items-center is-justify-content-end flex-gap-1 notification-buttons">
      <PsButton
        v-if="!notification.readAt"
        @click="notifications.applyReadNow(notification.id)"
      >
        Mark as read
      </PsButton>

      <PsButton
        color="secondary"
        styling="outlined"
        icon="base/delete"
        @click="notifications.archive(notification.id)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.notifications-item {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0 0 2em 0;
  border-bottom: 1px solid #061e3e1a;

  .notification-main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    flex-grow: 1;

    .notification-header {
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      align-items: center;
    }

    .date {
      color: $gray-slate;
    }

    .body {
      font-size: 14px;
    }
  }
  .resource-actions {
    margin: 12px 0;
  }
}
</style>
