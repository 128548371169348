
<template>
  <div
    v-if="booking && !isRequesting"
    class="booking-container"
  >
    <div class="booking-content">
      <Workflow
        :current-step="workflowBind.currentStep.value"
        :progressed-step="workflowBind.currentStep.value"
        :steps="evaluatedSteps"
        direction="horizontal"
        is-numbered
        @step-changed="goToStep"
      />
      <Insurance
        v-if="state.value.flow === 'created'"
        v-model="insurance"
        :booking="booking"
        @next="goNext"
      />
      <Cause
        v-else-if="state.value.flow === 'cause'"
        v-model="cause"
        :booking="booking"
        @next="goNext"
        @previous="goBack"
      />
      <Payment
        v-else-if="state.value.flow === 'payment'"
        v-model="intent"
        :booking="booking"
        @next="goNext"
        @previous="goBack"
      />
      <Confirm
        v-else-if="state.value.flow === 'confirm'"
        :booking="booking"
        :cause="cause"
        :intent="intent"
        :is-requesting="isRequesting"
        @next="requestBooking"
        @previous="goBack"
        @step-changed="goToStep"
      />
    </div>
    <aside class="booking-aside">
      <div class="booking-aside-group">
        <BookingCard
          :booking="booking"
          :show-booking-creation-date="false"
          :show-booking-period="true"
          :show-status="false"
          :show-renter="false"
          class="mb-2"
          orientation="horizontal"
          size="small"
        />
        <PriceBreakdown :booking="booking"/>
      </div>
      <hr
        v-if="state.value.flow !== 'created' && intent?.payment_method"
        class="my-0"
      >
      <div
        v-if="state.value.flow !== 'created' && intent?.payment_method"
        class="booking-aside-group"
      >
        <p class="has-text-weight-semibold mb-2">
          Payment method
        </p>
        <div class="price-record">
          <PaymentMethod
            :payment-method="intent.payment_method"
            hide-actions
          />
        </div>
      </div>
      <hr
        v-if="state.value.flow === 'confirm' && cause"
        class="my-0"
      >
      <div
        v-if="state.value.flow === 'confirm' && cause"
        class="booking-aside-group"
      >
        <p class="has-text-weight-semibold mb-2">
          You've chosen a cause
        </p>
        <CardBase
          orientation="horizontal"
          size="tiny"
        >
          <template #preview>
            <img :src="cause.image">
          </template>
          <template #details>
            <p class="is-flex is-justify-content-space-between is-flex-direction-row align-items-center my-auto">
              <label class="is-flex-grow-1">{{ cause.name }}</label>
            </p>
          </template>
        </CardBase>
      </div>
      <div
        v-for="pricing in depositCosts"
        :key="pricing.id"
        class="booking-aside-group-success"
      >
        <div
          class="is-flex is-align-items-center is-justify-content-space-between is-flex-direction-row"
        >
          <div class="is-flex is-align-items-center is-flex-direction-row">
            <ProtectionIcon
              hide-label
              protection="deposit"
            />
            <span class="has-text-weight-semibold pl-2">
              {{ t('booking.pricing-deposit') }}
            </span>
          </div>
          <money :amount="pricing.amount"/>
        </div>
        <p>
          This amount is pre-authorized on your credit card and will be released after the item is
          successfully returned.
        </p>
      </div>
    </aside>
  </div>
  <div v-if="booking && isRequesting">
    <div class="is-flex is-flex-direction-column has-text-centered big-thanks">
      <p class="title">
        One moment please
      </p>
      <p class="subtitle is-5">
        Requesting your booking of "{{ booking.listing.name }}".
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "CreateBookingFlow",
});
</script>

<script lang="ts" setup>
import {ref, computed} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useMachine} from '@xstate/vue';

import Workflow, {Step} from '@/app/components/workflow/Workflow.vue';
import BookingCard from '@/components/BookingCard.vue';
import PaymentMethod from '@/components/transactions/PaymentMethod.vue';
import CardBase from '@/components/CardBase.vue';
import Cause from '@/flows/createBooking/Cause.vue';
import Payment from '@/flows/createBooking/Payment.vue';
import Confirm from '@/flows/createBooking/Confirm.vue';
import {Booking, useBookings} from '@/rental/stores/bookings';
import {BookingStatus, CostType} from '@/rental/types';
import createBookingMachine from '@/machines/BookingMachine';
import {useMachineToWorkflowBinds} from '@/app/components/workflow/binds';
import {SetupIntent} from '@/store/payments';
import PriceBreakdown from '@/components/rental/booking/PriceBreakdown.vue';
import Insurance from '@/flows/createBooking/Insurance.vue';
import ProtectionIcon from '@/elements/ProtectionIcon.vue';
import {EvaluatedStep} from "@/flows/createListing/steps";
import {getCurrentUser} from "@/app/stores/session";
import {useI18n} from "vue-i18n";

const props = withDefaults(
  defineProps<{
    bookingId: Booking['id'];
    getSetupIntent: () => Promise<SetupIntent>;
    submitBooking: (data: Partial<Booking>) => Promise<Partial<Booking>>;
    initialState?: string;
  }>(),
  {
    initialState: 'created',
  }
);

const router = useRouter();
const route = useRoute();
const bookings = useBookings();
const machine = createBookingMachine({
  initialState: props.initialState,
});
const {snapshot: state, send} = useMachine<ReturnType<typeof createBookingMachine>>(machine);
const workflowBind = useMachineToWorkflowBinds(state, machine.states.flow);
const currentUser = getCurrentUser();
const t = useI18n();

const cause = ref();
const insurance = ref(false);
const intent = ref<SetupIntent | undefined>();
const isRequesting = ref(false);
const booking = await bookings.ensureById(props.bookingId);

const evaluatedSteps = computed<EvaluatedStep[]>(() => {
  return workflowBind.steps.map((el, index) => ({
    ...el,
    isValid: index + 1 < workflowBind.currentStep.value,
  }));
});

const depositCosts = computed(() => booking.costsBy(currentUser.id, [CostType.DEPOSIT]));

const navigateToStep = (initialState, opts?: { query?: object }) => {
  const query = opts?.query || route.query;
  router.push({
    name: 'bookingCreated',
    params: {
      initialState,
      bookingId: booking.id,
    },
    query,
  });
};

const goToStep = (
  item: {
    stepId: string;
  },
  opts?: { query?: object }
) => {
  send({type: `TO_${item.stepId.toUpperCase()}`});

  navigateToStep(state.value.value.flow, opts);
};

const goNext = (opts: { query?: object }) => {
  send({type: 'NEXT'});

  navigateToStep(state.value.value.flow, opts);
};

const goBack = (opts: { query?: object }) => {
  send({type: 'BACK'});

  navigateToStep(state.value.value.flow, opts);
};

const requestBooking = async () => {
  try {
    isRequesting.value = true;
    let intentId = intent.value?.id;
    if (!intentId) {
      const setupIntent = await props.getSetupIntent();
      intentId = setupIntent.id;
    }
    const data = {
      renterCauseId: cause.value.id,
      renterIntentId: intentId,
      renterInsuranceEnabled: insurance.value,
    };
    const result = await bookings.submitBooking(booking.id, data);
    if (result.status !== BookingStatus.new) {
      await router.push({
        name: 'bookingSuccess',
        params: {
          bookingId: booking.id,
        },
      });
    } else {
      console.error('Booking not submitted');
      send({type: 'FAIL'});
    }
    isRequesting.value = false;
  } catch (err) {
    console.log(err);
    send({type: 'FAIL'});
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.booking-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3em;
  margin: 2em 0;

  @include touch {
    flex-direction: column;
    gap: 2em;
    margin: 0.5em 0;
  }

  .booking-content {
    flex-grow: 1;
  }

  .booking-aside {
    flex-shrink: 1;
    background-color: $gray-mist;
    border-radius: 0.75em;
    min-width: 380px;
    max-width: 380px;
    font-size: 14px;

    .booking-aside-group {
      padding: 2em;
    }

    .booking-aside-group-success {
      background-color: $green-100;
      border-radius: 0.75em;
      padding: 2em;
    }
  }
}
</style>
