<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from "vue";

export type ColorType = "primary" | "secondary" | "success" | "warning";
export type SizeType = "small" | "medium" | "large" | "extra-large";
export type StyleType = "outlined" | "normal" | "ghost";

const props = defineProps({
  color: {
    type: String as PropType<ColorType>,
    default: "primary",
  },
  size: {
    type: String as PropType<SizeType>,
    default: "medium",
  },
  styling: {
    type: String as PropType<StyleType>,
    default: "normal",
  },
  hasHover: {
    type: Boolean,
    default: true,
  },
});

const classes = computed(() => {
  return [
    "chip",
    `is-${props.color}`,
    `is-${props.size}`,
    `is-${props.styling}`,
    `is-${props.hasHover ? "hover" : "no-hover"}`,
  ];
});
</script>

<style lang="scss">
@import '../styles/variables.scss';

.chip {
  box-sizing: border-box;
  border-radius: 20px;
  border-width: 0;
  border-style: solid;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 4px;

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  text-align: center;

  .ps-button {
    padding: 0 !important;
  }

  &.is-primary {
    color: $peach-500;
    background-color: $peach-100;
    border-color: $peach-500;

    &.is-hover:hover {
      background-color: $peach-200;
    }
    &:active,
    &[active] {
      background-color: $peach-200;
      color: $peach-700;
      border-color: $peach-700;
    }
  }

  &.is-secondary {
    color: $gray-slate;
    background-color: $gray-pebble;
    border-color: $gray-pebble;

    &.is-hover:hover {
      background-color: $gray-mist;
    }
    &:active,
    &[active] {
      background-color: $gray-smoke;
      color: $gray-charcoal;
      border-color: $gray-ash;
    }
  }

  &.is-success {
    color: $gray-mist;
    background-color: $green-500;
    border-color: $green-500;

    &.is-hover:hover {
      background-color: $gray-mist;
    }
    &:active,
    &[active] {
      color: $gray-mist;
      background-color: $green-700;
      border-color: $green-700;
    }

    &.is-outlined {
      color: $green-500;
      background-color: transparent;

      &:active,
      &[active] {
        background-color: $green-200;
      }
    }
  }

  &.is-warning {
    background-color: $warning;
    border-color: $red-500;

    &.is-hover:hover {
      background-color: $gray-mist;
    }

    &:active,
    &[active] {
      color: $gray-mist;
      background-color: $green-700;
      border-color: $green-700;
    }

    &.is-outlined {
      color: $green-500;
      background-color: transparent;

      &:active,
      &[active] {
        background-color: $green-200;
      }
    }
  }

  &.is-outlined {
    border-width: 1px;
    background-color: transparent;
  }

  &.is-small {
    line-height: 1em;
  }

  &.is-medium {
    padding: 8px 16px;
  }
}
</style>
