import type {ApiCollectionModel, ListQueryParams} from "@/app/api";
import {useApiCollection} from "@/app/api";
import type {CollectionModel} from "@/store/base/models";
import {defineStore} from "pinia";
import {useCollection} from "@/helpers/store";
import {useCollectionDeserializer, useDeserializer} from "@/app/io";
import {computed, ref, type Ref, toRefs} from "vue";

interface ApiNotificationEvent {
  id: string;
  message_payload: object;
  message_type_id: string;
}

export interface NotificationEvent {
  id: string;
  messagePayload: object;
  messageTypeId: string;
}

export interface AccountNotification extends CollectionModel {
  id: string;
  subject: string;
  readAt: string;
  domain: string;
  body: string;
  resourceId: string;
  resourceType: string;
  event: NotificationEvent;
  isNew: boolean;
}

interface ApiAccountNotification extends ApiCollectionModel {
  subject: string;
  read_at: string;
  body: string;
  domain: string;
  event: ApiNotificationEvent;
}

const eventDeserializer = useDeserializer<ApiNotificationEvent, NotificationEvent>({
  id: "id",
  messagePayload: "message_payload",
  messageTypeId: "message_type_id",
});

const notificationDeserializer = useCollectionDeserializer<ApiAccountNotification, AccountNotification>({
  subject: "subject",
  readAt: "read_at",
  body: "body",
  domain: "domain",
  event: (obj) => eventDeserializer.deserializer(obj.event),
  isNew: (obj) => obj.read_at === null,
  resourceType: (obj) => obj.domain,
  resourceId: (obj) => obj.event.message_payload.booking_id,
}, {});

export const useNotifications = defineStore("Notifications", () => {
  const lastUpdated: Ref<Date | undefined> = ref(undefined);
  const notificationsApi = useApiCollection<AccountNotification, ApiAccountNotification>("me/notifications", {
    deserializer: notificationDeserializer.deserializer,
    isCached: false,
  });

  const collection = useCollection<AccountNotification>(async () => {
    const params: ListQueryParams = {
      ordering: "-created_at",
      available: true,
    }
    if (lastUpdated.value) {
      params.since = lastUpdated.value.toISOString();
    }
    lastUpdated.value = new Date();
    return notificationsApi.list(params);
  })

  const useNewItems = () => collection.useFiltered(el => el.isNew);

  const getNew = (domain?: string) => {
    if (collection.isEmpty.value) return [];
    return collection.items.value.filter((el) => {
      if (!el.isNew) {
        return false;
      }
      if (domain) {
        return el.domain === domain;
      }
      return true;
    });
  }

  return {
    ...collection,
    getNew,
    useNewItems,
    useHasNew: () => {
      const newItems = useNewItems();
      return computed(() => newItems.value.length > 0);
    },
    hasNew: computed(() => getNew().length > 0),
    newCount: computed(() => getNew().length),
    applyReadNow: collection.withAction((rid) => notificationsApi.action(rid, "read", notificationDeserializer.deserializer)),
    archive: async (rid: AccountNotification["id"]) => {
      await notificationsApi.action(rid, "archive", notificationDeserializer.deserializer)
      collection.removeById(rid);
    },
    loading: collection.loading,
    fetch: collection.sync,
  }
});
