<template>
  <CardBase
    :class="isFavorite ? 'is-favorite' : null"
    :orientation="orientation"
    class="listing-card"
    is-clickable
    @card-clicked="gotoListing(listing.id)"
  >
    <template #overlay-top>
      <FavoriteButton
        v-if="showFavoriteButton"
        :listingId="listing.id" :isFavorite="isFavorite"
        is-pill
        is-rounded
        styling="ghost"
        theme="dark"
      />
    </template>
    <template #overlay-bottom>
      <span v-if="listing.hasMultiplePrices" class="tag is-primary has-text-weight-bold">
        SAVE {{ $helpers.percent(listing.discount()) }}
      </span>
    </template>
    <template #preview>
      <Icon
        :name="listing.category.iconName ?? listing.category.icon"
        size="large"
        variant="secondary"
      />
      <img
        v-if="listing.hasImage"
        :alt="listing.name"
        :src="listing.mainImageSrc"
        @error="
          (e) => {
            e.target.src = '/thumbnail.png';
          }
        "
      />
    </template>

    <template #details>
      <div class="listing-details-title is-flex is-flex-direction-column">
        <div
          class="is-flex is-align-items-center is-justify-content-space-between flex-gap-1 is-flex-wrap-wrap mb-1"
        >
          <p class="flex-grow listing-title">
            {{ listing.name }}
          </p>
          <RatingSmall :reviews="listing.numReviews" :value="listing.rating" />
        </div>
        <p class="listing-location">
          {{ listing.localLocation }}
        </p>
      </div>
      <div class="is-flex is-align-content-center flex-gap-1 is-flex-wrap-wrap">
        <p v-if="listing.hasPrices" class="listing-details">
          <span class="price">{{ $helpers.money({ value: bestPrice.amount }) }}</span>
          <span class="interval">{{ bestPrice.interval }}</span>
        </p>
        <p v-if="listing.hasMultiplePrices" class="listing-secondary-price">
          {{ $helpers.money({ value: listing.worstPricingOption.amount }) }}
        </p>
        <div
          v-if="listing.protection && listing.protection === 'deposit'"
          :class="listing.protection"
          class="protection is-flex"
        >
          <i class="divider mr-3" />
          <ProtectionIcon :protection="listing.protection" />
        </div>
      </div>
    </template>

    <template #actions>
      <slot name="actions" />
    </template>
  </CardBase>
</template>

<script lang="ts" setup>
import { computedWithControl } from "@vueuse/core";
import { computed } from "vue";
import { useRouter } from "vue-router";

import CardBase from "@/components/CardBase.vue";
import RatingSmall from "@/components/RatingSmall.vue";
import ProtectionIcon from "@/elements/ProtectionIcon.vue";

import { useBrowsingStore } from "@/store/browse";
import type { Listing } from "@/rental/stores/listings";
import FavoriteButton from "@/rental/components/FavoriteButton.vue";
import {useFavoriteListings} from "@/rental/stores/listings";

const props = withDefaults(
  defineProps<{
    listing: Listing;
    orientation?: "vertical" | "horizontal";
    showFavoriteButton?: boolean;
  }>(),
  {
    orientation: "vertical",
    showFavoriteButton: true,
  },
);
const router = useRouter();

const store = useBrowsingStore();
const favorites = useFavoriteListings();
const bestPrice = computed(() => props.listing.bestPricingOption);

const gotoListing = (listingId: string) =>
  router.push({
    name: "listing",
    params: {
      id: listingId,
    },
  });
const isFavorite = computedWithControl(
  favorites.items,
  () => {
    return favorites.isFavorite(props.listing.id);
  },
);
</script>

<style lang="scss">
@import '../../styles/variables';

.listing-card {
  flex-grow: 1;

  .card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px;

    > * {
      padding: 4px 0;
    }
  }

  .listing-details-title {
    width: 100%;
  }

  .listing-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
    gap: 8px;
  }

  .protection {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
  }

  .listing-title,
  .price {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  .listing-secondary-price {
    line-height: 24px;
    color: $gray-muted;
    text-decoration-line: line-through;
  }

  .deposit {
    color: $green-500;
  }

  .divider:after {
    content: '•';
    color: $gray-muted;
  }

  &.is-favorite {
    .overlay-top {
      opacity: 0.9 !important;

      .ps-button {
        color: $primary !important;
      }
    }
  }
}
</style>
