interface FilterOptions {
  q: string;
  categoryId: string;
  location: {
    longitude: number;
    latitude: number;
    distance: number;
  };
  cityState: string;
  cityStateLike: string;
  selectedDates: Date[];
  minPrice: number;
  maxPrice: number;
  isFavorite: boolean;
}

type Filters<Opts extends FilterOptions = FilterOptions> = {
  [Property in keyof Opts]: (v: Opts[Property]) => string;
};

export const ListFilter = (options: Partial<FilterOptions>) => {
  const filters: Filters = {
    q: (v) => `(name ilike "%${v}%" or description ilike "${v}")`,
    categoryId: (v) =>
      `(category_id eq "${v}" OR category__parent__id eq "${v}")`,
    location: (v) =>
      `pickup_location__geo_position near (${v.longitude} ${v.latitude} ${v.distance || ""})`,
    cityState: (v) => `pickup_location__city_state_long eq "${v}"`,
    cityStateLike: (v) => `pickup_location__city_state_long ilike "%${v}%"`,
    selectedDates: (v) => v.join(","),
    minPrice: (v) => `pricing_options__amount gt ${v * 100}`,
    maxPrice: (v) => `pricing_options__amount lt ${v * 100}`,
    isFavorite: (v) => `is_favorite eq ${v}`,
  };

  return Object.entries(options)
    .filter((item) => item[1] && String(item[1]).length > 0)
    .map(([key, value]) => filters[key](value))
    .join(" AND ");
};
