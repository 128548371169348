<template>
  <div :class="classes">
    <div class="modal-background" />
    <div class="modal-card">
      <slot name="outer-head">
        <div
          class="modal-card-head"
          :style="'--head-bg: url(' + headBackgroundUrl + ');'"
        >
          <slot name="head">
            <span class="modal-card-title">{{ title }}</span>
            <PsButton
              is-pill
              theme="light"
              is-rounded
              color="secondary"
              @click.prevent="closeModal"
            >
              <Icon
                variant="inherit"
                name="base/close"
              />
            </PsButton>
          </slot>
        </div>
      </slot>
      <div class="modal-card-body">
        <slot />
      </div>
      <div class="modal-card-foot">
        <slot name="foot" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onKeyStroke } from "@vueuse/core";
import { type PropType, computed, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  size: {
    type: String as PropType<"small" | "medium">,
    default: "small",
  },
  background: {
    type: String as PropType<"default" | "primary">,
    default: "default",
  },
  headBackgroundUrl: {
    type: String,
    default: null,
  },
  noPaddingBody: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);

watch(
  () => props.modelValue,
  (currentIsActive) => {
    if (currentIsActive) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  },
);

const isActive = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit("update:modelValue", value);
  },
});

const classes = computed(() => {
  return [
    "modal",
    isActive.value ? "is-active" : null,
    `is-${props.size || "medium"}`,
    props.background !== "default" ? `is-background-${props.background}` : null,
    props.noPaddingBody ? "is-no-padding-body" : null,
    props.headBackgroundUrl ? "with-head-background" : null,
  ];
});

const closeModal = () => {
  isActive.value = false;
};
onKeyStroke("Escape", closeModal);
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.modal {
  z-index: 1000;

  &.with-head-background .modal-card .modal-card-head {
    background: var(--head-bg), $gray-pebble;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-items: flex-start;
  }

  .modal-card {
    font-weight: normal;

    .modal-card-title {
      font-size: 18px;
      font-weight: 600;
    }

    .modal-card-head,
    .modal-card-foot {
      background: var(--gray-snow);
      border: none;
    }
    .modal-card-body {
      padding-top: 0;
      :deep(> p) {
        margin: 2em 0;
      }
    }

    .modal-card-foot {
      display: block;
      width: 100%;
    }

    @include touch {
      height: 95vh;
    }
  }

  &.is-small .modal-card {
    width: 460px;

    @include touch {
      width: 95vw;
    }
  }

  &.is-background-primary {
    .modal-card-head,
    .modal-card-body,
    .modal-card-foot {
      background-color: $peach-500;
      color: $gray-snow;

      .modal-card-title {
        color: $gray-snow;
      }
    }
  }

  &.is-no-padding-body {
    .modal-card-body {
      padding: 0;
    }
  }
}
</style>
