<template>
  <DatePicker
    v-model="value"
    :auto-apply="context.autoApply"
    :disabled-dates="context.disabledDates || []"
    :disabled-week-days="context.disabledWeekDays"
    :enable-time-picker="false"
    :format="'yyyy-mm-dd'"
    :inline="context.inline"
    :min-date="new Date()"
    :min-range="1"
    :max-range="30"
    :multi-calendars="context.multiCalendars || false"
    :multi-dates="context.multiDates || false"
    :range="context.range"
    no-disabled-range
    prevent-min-max-navigation
    @invalid-select="handleInvalid"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { computed } from "vue";

import Formats from "@/helpers/formats";

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
});

const value = computed(() => {
  let value = props.context._value;

  // NOTE: we force this DatePicker to only pick dates and ignore times and timezones, but it needs ISO to properly display the dates
  if (
    props.context._value ||
    (Array.isArray(props.context._value) && props.context._value.length)
  ) {
    value = Formats.dateOrDatesFormatISOForApiWithoutTimezone(
      props.context._value,
    );
  }

  return value;
});

function handleInvalid(e: string | string[]) {
  console.log("Invalid date selected: ", e);
}

function handleChange(e: string | string[]) {
  // NOTE: we force this DatePicker to only pick dates and ignore times and timezones
  const newValue = e
    ? Formats.dateOrDatesFormatForApiWithoutTimezone(e)
    : undefined;
  props.context.node.input(newValue);
}
</script>

<style>
.dp__theme_light {
  --dp-text-color: var(--gray-slate);
  --dp-hover-color: var(--peach-200);
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: var(--primary);
  --dp-primary-disabled-color: var(--disabled-background);
  --dp-primary-text-color: var(--gray-snow);
  --dp-secondary-color: var(--inactive);
  --dp-menu-border-color: transparant;
  --dp-range-between-dates-background-color: var(--dp-hover-color, var(--peach-200));
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
  --dp-font-family: 'Poppins' !important;
}

.dp__main {
  font-family: 'Poppins' !important;
}

.dp__main {
  justify-content: center;
}

.dp__menu {
  border: none;
}

.dp__calendar_header_item {
  margin: 2px;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__calendar_row {
  margin: 0;
}

.dp__calendar_item {
  margin: 2px;
}

.dp__cell_inner {
  font-size: 14px !important;
  width: 40px;
  height: 40px;
}

.dp__cell_disabled {
  background-color: var(--disabled-background);
}
</style>
