import {computed, type Ref} from "vue";

interface OrderOptions<T> {
  direction: "asc" | "desc";
  key: keyof T;
}

export const useOrdered = <T>(items: Ref<T[]>, opts: OrderOptions<T>) => {
  return computed(() => {
    return items.value.sort((a, b) => {
      return a[opts.key] < b[opts.key] ? 1 : -1;
    })
  })
}
