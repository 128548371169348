<template>
  <a id="how-it-works"/>

  <div class="container">
    <article>
      <div class="hero">
        <div class="hero-body">
          <p class="super-title">
            Our causes
          </p>
          <p class="title mt-1">
            What drives us
          </p>
          <p>
            Rent anything on the Pretty Shell app, and we&apos;ll donate a portion of our revenue to the
            humanitarian cause of your choice.
          </p>
        </div>
      </div>

      <hr>

      <section class="pt-6 mb-6">
        <div class="hero hero-split">
          <div>
            <p class="super-title">
              Caring for the Homeless
            </p>
            <p class="title mt-4">
              Our mission to end homelessness
            </p>
            <p class="mb-4">
              Care For the Homeless fights homelessness by delivering high-quality and
              client-centered health care, human services and shelter to homeless individuals and
              families, and by advocating for policies to ameliorate, prevent and end homelessness.
            </p>
            <PsButton
              class="is-hidden-tablet"
              color="secondary"
              disabled
              full-width
              styling="outlined"
              @click="goToSignUp"
            >
              Make a donation
            </PsButton>
            <PsButton
              class="is-hidden-mobile"
              disabled
              @click="handleSignUp"
            >
              Make a donation
            </PsButton>
          </div>
          <div>
            <img
              src="/frame_homeless.png"
              width="544"
            >
          </div>
        </div>
      </section>

      <section class="pt-6 mb-6">
        <div class="hero hero-split">
          <div>
            <img
              src="/frame_school_security.png"
              width="544"
            >
          </div>
          <div>
            <p class="super-title">
              School Security Systems
            </p>
            <p class="title mt-4">
              Security Challenges Schools Face
            </p>
            <p class="mb-4">
              When it comes to school security, today&apos;s challenges range from the spread of
              viruses to gun violence. Mitigating these risks and improving the health and safety of
              education facilities requires a comprehensive approach.
            </p>
            <PsButton
              class="is-hidden-tablet"
              color="secondary"
              disabled
              full-width
              styling="outlined"
              @click="goToSignUp"
            >
              Make a donation
            </PsButton>
            <PsButton
              class="is-hidden-mobile"
              disabled
              @click="handleSignUp"
            >
              Make a donation
            </PsButton>
          </div>
        </div>
      </section>

      <section class="pt-6 mb-6">
        <div class="hero hero-split">
          <div>
            <p class="super-title">
              Public Safety
            </p>
            <p class="title mt-4">
              Give back to our first responders who give so much each and every day
            </p>
            <p class="mb-4">
              Every day, police and firefighters risk their lives to make your community safer. To
              show your support, you may consider making a donation when a fundraiser calls on
              behalf of a fire or police service organisation.
            </p>
            <PsButton
              class="is-hidden-tablet"
              color="secondary"
              disabled
              full-width
              styling="outlined"
              @click="goToSignUp"
            >
              Make a donation
            </PsButton>
            <PsButton
              class="is-hidden-mobile"
              disabled
              @click="handleSignUp"
            >
              Make a donation
            </PsButton>
          </div>
          <div>
            <img
              src="/frame_public_safety.png"
              width="544"
            >
          </div>
        </div>
      </section>

      <section class="pt-6 mb-6">
        <div class="hero hero-split">
          <div>
            <img
              src="/frame_rehabilitation.png"
              width="544"
            >
          </div>
          <div>
            <p class="super-title">
              Rehabilitation & Sex Traffic Victims
            </p>
            <p class="title mt-4">
              28 million people are trafficked worldwide
            </p>
            <p class="mb-4">
              Healing and lasting freedom are possible after trafficking. And every survivor
              deserves support to reclaim and rebuild their life. We cannot end this one survivor at
              a time. But with your help, we can target the systems that make human trafficking
              possible.
            </p>
            <PsButton
              class="is-hidden-tablet"
              color="secondary"
              disabled
              full-width
              styling="outlined"
              @click="goToSignUp"
            >
              Make a donation
            </PsButton>
            <PsButton
              class="is-hidden-mobile"
              disabled
              @click="handleSignUp"
            >
              Make a donation
            </PsButton>
          </div>
        </div>
      </section>

      <section class="pt-6 mb-6">
        <div class="hero hero-split">
          <div>
            <p class="super-title">
              Child Advocacy
            </p>
            <p class="title mt-4">
              Giving young people and children a voice
            </p>
            <p class="mb-4">
              Young people deserve to have someone on their side. Child advocacy is all about
              speaking up for, or on behalf of, children and young people. An advocate helps them
              take part in decisions that affect their lives and can voice their opinions in
              environments they might find challenging, such as with adults they might not feel safe
              around.
            </p>
            <PsButton
              class="is-hidden-tablet"
              color="secondary"
              disabled
              full-width
              styling="outlined"
              @click="goToSignUp"
            >
              Make a donation
            </PsButton>
            <PsButton
              class="is-hidden-mobile"
              disabled
              @click="handleSignUp"
            >
              Make a donation
            </PsButton>
          </div>
          <div>
            <img
              src="/frame_advocacy.png"
              width="544"
            >
          </div>
        </div>
      </section>

      <hr>

      <section class="mb-6">
        <div class="hero hero-split">
          <div>
            <p class="title my-2">
              Rent something today, help us do good
            </p>
            <p class="my-4">
              Rent anything on the Pretty Shell app, and we&apos;ll a portion
            </p>
            <CategoryCollage :categories="categoryStore.getGalleryItems"/>
            <PsButton
              class="mt-6 is-hidden-tablet"
              color="secondary"
              full-width
              icon="outlined/search"
              styling="outlined"
              @click="gotoCategories"
            >
              Browse all categories
            </PsButton>
            <div class="mt-6 is-hidden-mobile is-flex is-justify-content-center">
              <PsButton
                color="secondary"
                icon="outlined/search"
                styling="outlined"
                @click="gotoCategories"
              >
                Browse all categories
              </PsButton>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";

import CategoryCollage from "@/components/CategoryCollage.vue";

import {useCategories} from "@/rental/stores/categories";
import {inject} from "vue";

const router = useRouter();

const { setModal } = inject('authOverlay');

const categoryStore = useCategories();

categoryStore.fetchCategories();

function handleSignUp() {
  setModal("signUp");
}

function goToSignUp() {
  router.push({
    name: "signUp",
  });
}

function gotoCategories() {
  router.push({
    name: "categories",
  });
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

article {
  margin-top: 82px;
}

hr {
  margin: 4rem 0;
  overflow: visible;

  &:before {
    content: '';
    position: absolute;
    display: block;
    justify-content: center;
    background: no-repeat center url(/logo.svg) white;
    color: black;
    width: 94px;
    height: 30px;
    margin: 0 calc(50% - 94px / 2);
    left: 0;
    margin-top: -15px;
  }
}
</style>
