<template>
  <div class="reviews">
    <div
      v-if="value"
      class="rating"
    >
      <div
        ref="primaryEl"
        class="primary"
      >
        <Icon
          v-for="ix in Array(5).keys()"
          :key="ix"
          size="small"
          name="base/star"
          variant="primary"
        />
      </div>
      <div class="secondary">
        <Icon
          v-for="ix in Array(5).keys()"
          :key="ix"
          size="small"
          name="base/star"
          variant="secondary"
        />
      </div>
    </div>
    <template v-if="showReviews">
      <span v-if="numReviews">({{ numReviews }})</span>
      <div v-else>
        No rating
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";

const primaryEl = ref();
const props = withDefaults(
  defineProps<{
    value: number;
    numReviews?: number;
    showReviews?: boolean;
  }>(),
  {
    showReviews: true,
    numReviews: undefined,
  },
);

onMounted(() => {
  if (props.value) {
    const value: number = props.value > 5 ? props.value / 10 : props.value;
    primaryEl.value.style.width = `${(value / 5) * 100}%`;
  }
});
</script>

<style scoped lang="scss">
.rating {
  position: relative;
  display: inline-block;
  height: 18px;

  .primary {
    display: inline-flex;
    position: absolute;
    overflow: hidden;
    align-items: center;
    height: 24px;
    justify-content: flex-start;
    z-index: 100;
  }

  .secondary {
    display: inline-flex;
    height: 24px;
  }
}

.reviews {
  margin: 0 0 0 0.5em;
}

.reviews,
.num-listings {
  color: var(--gray-muted);
}
</style>
